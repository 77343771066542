var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    attrs: {
      "columns-data": _vm.header,
      data: _vm.rowsData,
      sortable: true,
      loading: _vm.loading,
      paginate: _vm.rowsData && _vm.rowsData.length > 0,
      "row-length": _vm.rowsData.length,
    },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function ({ column }) {
          return [
            column === "checkbox"
              ? _c("th", { staticClass: "pl-0 text-center" }, [
                  _c(
                    "div",
                    { staticClass: "d-inline-flex px-2" },
                    [
                      _c("Checkbox", {
                        ref: "select_all",
                        attrs: { binary: true },
                        on: {
                          input: function ($event) {
                            return _vm.handleCheckAll()
                          },
                        },
                        model: {
                          value: _vm.allItemsSelected,
                          callback: function ($$v) {
                            _vm.allItemsSelected = $$v
                          },
                          expression: "allItemsSelected",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "columns",
        fn: function ({ item, column }) {
          return [
            column === "checkbox"
              ? _c(
                  "td",
                  { staticClass: "text-center px-2" },
                  [
                    _c("Checkbox", {
                      attrs: { value: item.userId },
                      model: {
                        value: _vm.selectedItems,
                        callback: function ($$v) {
                          _vm.selectedItems = $$v
                        },
                        expression: "selectedItems",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            column === "fullName"
              ? _c("td", [_vm._v(" " + _vm._s(item.fullName) + " ")])
              : _vm._e(),
            column === "email"
              ? _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "p-link",
                      attrs: { href: `mailto:${item.email}` },
                    },
                    [_vm._v(" " + _vm._s(item.email) + " ")]
                  ),
                ])
              : _vm._e(),
            column === "job"
              ? _c("td", [_vm._v(" " + _vm._s(item.job) + " ")])
              : _vm._e(),
            column === "products"
              ? _c(
                  "td",
                  _vm._l(_vm.products, function (product, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        staticClass: "p-field-checkbox ml-2 d-inline",
                      },
                      [
                        _c("Checkbox", {
                          attrs: { value: product, name: "products" },
                          on: {
                            input: function ($event) {
                              return _vm.$emit(
                                "responsibletable-products-change",
                                item
                              )
                            },
                          },
                          model: {
                            value: item.products,
                            callback: function ($$v) {
                              _vm.$set(item, "products", $$v)
                            },
                            expression: "item.products",
                          },
                        }),
                        _c("label", [_vm._v(_vm._s(product))]),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "actions",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: _vm.$t("delete"),
                      expression: "$t('delete')",
                      modifiers: { top: true },
                    },
                  ],
                  ref: "row_delete",
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("responsibletable:delete", item)
                    },
                  },
                },
                [_c("i", { staticClass: "icon-r-trash" })]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }