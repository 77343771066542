
import mixins from 'vue-typed-mixins';
import RegisterDigitalCertificate from '@/resources/digital-certificates/RegisterDigitalCertificate.vue';
import StepButtons from '@/resources/onboarding/components/StepButtons.vue';
import OnboardMixin from '@/resources/onboarding/OnboardMixin';
import locales from './locales';

export default mixins(OnboardMixin).extend({
  name: 'StepDigitalCertificate',

  i18n: {
    messages: locales,
  },

  components: {
    RegisterDigitalCertificate,
    StepButtons,
  },

  data() {
    return {
      disableNext: true as boolean,
      alertIsVisible: false,
    };
  },

  computed: {
    textTitle() {
      return this.$t('stepDigitalCertificate.alert.textTitle');
    },
    textContent() {
      return this.$t('stepDigitalCertificate.alert.textContent');
    },
    textBtnOk() {
      return this.$t('stepDigitalCertificate.alert.textBtnOk');
    },
    textBtnCancel() {
      return this.$t('stepDigitalCertificate.alert.textBtnCancel');
    },
  },

  methods: {
    handleJumpStep() {
      if (this.disableNext === true) {
        this.alertIsVisible = true;
      } else {
        this.jumpStep();
      }
    },
    hadleAlertIsVisible(bool: boolean) {
      this.alertIsVisible = bool;
    },
  },
});
