/* eslint-disable */
import validCron from './cron';

export const validator = {
  getMessage (field: any) { // will be added to default English messages.
    return 'Invalid cron format'
  },
  validate (value: any, args: any) {
    return validCron(value, args);
  }
}
