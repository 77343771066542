var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "TwoColumn",
    { staticClass: "change-password", attrs: { title: "Alterar Senha" } },
    [
      _c("ChangePasswordForm", {
        attrs: {
          payload: _vm.payload,
          "auth-service-token": _vm.token,
          "use-default-btn-continue": true,
        },
        on: {
          submit: function ($event) {
            return _vm.handleSubmit()
          },
          success: function ($event) {
            return _vm.handleResponse()
          },
          error: function ($event) {
            return _vm.handleResponse()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }