var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "upload-dropzone" },
    [
      _c(
        "vue-dropzone",
        {
          ref: "myVueDropzone",
          staticClass: "customdropzone",
          attrs: {
            id: "dropzone",
            options: _vm.dropzoneOptions,
            "duplicate-check": true,
            "use-custom-slot": true,
          },
          on: {
            "vdropzone-mounted": _vm.dropzoneMounted,
            "vdropzone-error": function ($event) {
              return _vm.$emit("response-error", $event)
            },
            "vdropzone-success": function ($event) {
              return _vm.$emit("response-success", $event)
            },
            "vdropzone-files-added": _vm.added,
            "vdropzone-removed-file": _vm.removed,
            "vdropzone-sending": _vm.sending,
            "vdropzone-duplicate-file": _vm.duplication,
            "vdropzone-max-files-exceeded": _vm.exceeded,
          },
        },
        [
          _c("div", { staticClass: "dropzone-custom-content" }, [
            _c("img", {
              staticClass: "file-icon",
              attrs: {
                src: require("@/common/assets/images/img_upload_file.svg"),
                alt: "upload",
              },
            }),
            _c("p", [_vm._v(" " + _vm._s(_vm.description) + " ")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }