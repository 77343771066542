
import { VForm } from '@/types';
import Vue from 'vue';
import { GroupAndEstablishmentsPayload } from './types';

export default Vue.extend({
  name: 'CompanyForm',
  data: () => ({
    form: {
      groupName: '',
      establishments: Array(20)
        .fill({})
        .map(() => ({ nationalRegister: '' })),
    } as GroupAndEstablishmentsPayload,
  }),
  watch: {
    form: {
      deep: true,
      handler(data) {
        this.$emit('input', data);
      },
    },
  },
  methods: {
    async formValid(): Promise<boolean> {
      const form: any = this.$refs.form as VForm;
      return form.validate();
    },
  },
});
