
import mixins from 'vue-typed-mixins';
import CompanyGroupAccordion from '@/common/components/companyGroup/accordion/CompanyGroupAccordion.vue';
import EditableCompanyTable from '@/common/components/company/table/EditableCompanyTable.vue';
import CompanyGroupModal from '@/common/components/companyGroup/modal/CompanyGroupModal.vue';
import { CompanyGroupsEstablishment, EstablishmentsCompany } from '@/types';
import CompanyGroupMixin from '@/mixins/CompanyGroupMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import {
  CompanyInformation,
  CreateCompanyGroupPayload,
  EditCompanyGroupPayload,
  InUpdateFrom,
} from '@/common/components/companyGroup/types';
import { EditCompanyGroupForm } from '@/common/components/companyGroup/form/CompanyGroupForm.vue';
import CompanyGroupNotFound from '@/common/components/companyGroup/notice/CompanyGroupNotFound.vue';
import ParentCompanyModalNotFound from '@/common/components/companyGroup/notice/ParentCompanyModalNotFound.vue';
import InfiniteLoading from 'vue-infinite-loading';
import CompanyGroupService from '@/common/services/CompanyGroupService';
import HeadOfficeEstablishmentService from '@/common/services/HeadOfficeEstablishmentService';

export default mixins(NotificationMixin, CompanyGroupMixin).extend({
  name: 'EditableCompanyGroupAccordion',
  components: {
    CompanyGroupAccordion,
    EditableCompanyTable,
    CompanyGroupModal,
    CompanyGroupNotFound,
    ParentCompanyModalNotFound,
    InfiniteLoading,
  },
  props: {
    enabledNotFound: {
      type: Boolean,
      default: false,
    },
    showMessageError: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    companyGroups: [] as EstablishmentsCompany[],
    searchCompanyGroups: [] as EstablishmentsCompany[],
    modalData: {} as EditCompanyGroupForm,
    loading: [] as Array<boolean>,
    showModal: false,
    showParentCompanyModalNotFound: false,
    page: 0,
    perPage: 25,
    totalEstablisments: 0,
    establishmentsPageSize: 10,
  }),
  mounted() {
    this.getCompanyGroups(this.search);
  },
  computed: {
    establishments(): CompanyGroupsEstablishment[] {
      return this.companyGroups.reduce(
        (prev, curr) => [...curr.establishments, ...prev],
        [] as CompanyGroupsEstablishment[],
      );
    },
    showCompanyGroupNotFound(): boolean {
      if (this.enabledNotFound) {
        return this.search
          ? this.searchCompanyGroups.length === 0
          : this.companyGroups.length === 0;
      }

      return false;
    },
  },
  watch: {
    messageErrors(msn: any[]) {
      if (msn && msn.length > 0 && this.showMessageError === false) {
        this.$emit('messageErrors', msn);
        this.messageErrors = [];
      }
    },
    showProcessingWarning(showing) {
      this.$emit('edited', showing);
    },
    async search(search) {
      if (search === '') {
        this.searchCompanyGroups = [];
      } else {
        await this.getCompanyGroups(search);
      }
    },
  },
  methods: {
    async getCompanyGroups(search = '') {
      try {
        const { masterId } = this.$store.state.auth.userCompany;

        const result = (
          await CompanyGroupService.findByMaster(masterId, { page: this.page, perPage: this.perPage, serializer: 'my-companies' })
        );

        const companyGroups = result.map((group) => {
          this.loading.push(false);
          return { ...group, establishments: [] };
        });

        if (Array.isArray(companyGroups)) {
          if (search) {
            this.searchCompanyGroups = companyGroups;
          } else {
            this.page += 1;
            this.companyGroups.push(...companyGroups);
            this.$emit('groups:update', companyGroups);
          }

          return companyGroups;
        }
      } catch (e) {
        this.errorToast({
          text: this.$t(
            'onboarding.registerCompany.table.listMsgError',
          ) as string,
        });
      }
      return undefined;
    },
    async establishmentUpdate(data: {
      index: number;
      company: CompanyGroupsEstablishment;
    }): Promise<void> {
      try {
        await this.updateCompany(data.company);
      } catch (error) {
        console.log({ error });
      }

      this.rowUpdate(data.company, data.index);
    },

    rowUpdate(item: CompanyGroupsEstablishment, index: number): void {
      this.establishments[index] = item;
    },

    setModalData(item: EstablishmentsCompany, inUpdateFrom: InUpdateFrom) {
      this.modalData = {
        data: {
          groupName: item.name,
          companies: item.establishments.map((establishment) => ({
            nationalRegister: establishment.nationalRegister,
          })) as CompanyInformation[],
          companyGroupId: item.id,
        },
        inUpdateFrom,
      };
    },

    btnUpdateCompanyGroup(item: EstablishmentsCompany) {
      this.setModalData(item, InUpdateFrom.CompanyGroup);
      this.showModal = true;
    },

    btnRegisterCompany(item: EstablishmentsCompany) {
      this.setModalData(item, InUpdateFrom.Company);
      this.showModal = true;
    },

    async getEstablishments(item: EstablishmentsCompany) {
      const groupIdx = this.companyGroups.findIndex((group) => group.id === item.id);

      if (this.companyGroups[groupIdx].establishments.length > 0 || !item.companies?.length) {
        return;
      }
      this.loading.splice(groupIdx, 1, true);

      const result = await HeadOfficeEstablishmentService
        .findHeadOfficeAndCheckHasBranchByCompanyId(
          item.companies || [],
          { page: 0, perPage: this.establishmentsPageSize },
        );

      this.totalEstablisments = result.metadata.totalItens;

      this.companyGroups[groupIdx].establishments.push(...result.headOffices);

      this.loading.splice(groupIdx, 1, false);
    },

    async paginateEstablishments(page: number, item: EstablishmentsCompany) {
      const groupIdx = this.companyGroups.findIndex((group) => group.name === item.name);
      this.loading.splice(groupIdx, 1, true);

      const result = await HeadOfficeEstablishmentService
        .findHeadOfficeAndCheckHasBranchByCompanyId(
          item.companies || [],
          { page: page - 1, perPage: this.establishmentsPageSize },
        );
      this.companyGroups[groupIdx].establishments.splice(0, 10, ...result.headOffices);
      this.loading.splice(groupIdx, 1, false);
    },

    async handleCompanyGroupRegistration(
      groupCompanies: CreateCompanyGroupPayload,
    ): Promise<void> {
      this.handleLoading(true);

      await this.companyGroupRegistration(groupCompanies);

      this.showModal = false;

      this.handleLoading(false);

      await this.getCompanyGroups(this.search);
    },

    async handleCompanyGroupUpdate(
      payload: EditCompanyGroupPayload,
    ): Promise<void> {
      this.handleLoading(true);

      await this.companyGroupUpdate(payload);

      this.showModal = false;

      this.handleLoading(false);

      await this.getCompanyGroups(this.search);
    },

    async handleCompanyRegistrations(
      payload: EditCompanyGroupPayload,
    ): Promise<void> {
      this.handleLoading(true);

      await this.companyRegistrations(payload);

      this.showModal = false;

      this.handleLoading(false);

      await this.getCompanyGroups(this.search);
    },
    async infiniteHandler($state: any) {
      let result;

      if (this.companyGroups.length > 0) {
        result = await this.getCompanyGroups();
      }

      if (result) {
        if (result.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
    async updatePageSize(pageSize: number, item: EstablishmentsCompany) {
      this.page = 0;
      this.establishmentsPageSize = pageSize;
      await this.getEstablishments(item);
    },
  },
});
