import { i18n } from '@/i18n';
import { SweetAlertOptions } from 'sweetalert2';
import Vue from 'vue';

function genDeleteHtml(text: string, extraText: string) {
  return `
    <div class="d-flex justify-content-start align-items-center">
      <i class="icon-r-circle-error-filled" style="font-size: 1.3rem"></i>
      <span class="delete-text" style="width: 100%;"><strong>${text}</strong></span>
    </div>
    ${extraText
    ? `<div class="confirm-content d-block p-mt-2 p-p-2 bg-f4f5f7 rounded p-text-nowrap p-text-truncate">${extraText}</div>`
    : extraText}
  `;
}

function genNotDeleteOnlyUpdateHtml(text: string, extraText: string) {
  return `
    <div class="d-flex justify-content-start align-items-center pl-3">
      <i class="icon-r-circle-error-filled" style="font-size: 1.3rem"></i>
      <span class="delete-text" style="width: 100%;"><strong>${text}</strong></span>
    </div>
    ${extraText
    ? `<div class="confirm-content d-block p-mt-2 p-p-2 bg-f4f5f7 rounded">${extraText}</div>`
    : extraText}
  `;
}

function genEnableHtml(text: string, extraText: string) {
  return `
    <div class="d-flex justify-content-start align-items-center">
      <i class="icon-r-circle-accept color__primary" style="font-size: 1.3rem"></i>
      <span class="delete-text" style="width: 100%;"><strong>${text}</strong></span>
    </div>
    ${extraText
    ? `<div class="confirm-content d-block p-mt-2 p-p-2 bg-f4f5f7 rounded p-text-nowrap p-text-truncate">${extraText}</div>`
    : extraText}
  `;
}

const enableOptions: SweetAlertOptions = {
  customClass: {
    confirmButton: 'btn-confirm p-button primary p-button-raised mx-1',
    cancelButton: 'btn-cancel p-button outlined p-button-raised  p-button-outlined mx-1',
  },
  buttonsStyling: false,
  closeButtonHtml: `
    <div class="header-icon">
      <span class="header-close-icon pi pi-times"></span>
    </div>`,
};

const defaultOptions: SweetAlertOptions = {
  customClass: {
    confirmButton: 'btn-confirm p-button p-button-danger p-button-raised mx-1',
    cancelButton: 'btn-cancel p-button p-button-danger p-button-raised  p-button-outlined mx-1',
  },
  buttonsStyling: false,
  closeButtonHtml: `
    <div class="header-icon">
      <span class="header-close-icon pi pi-times"></span>
    </div>`,
};

const notDeleteOnlyUpdateOptions: SweetAlertOptions = {
  customClass: {
    confirmButton: 'btn-confirm p-button primary p-button-raised mx-1',
    cancelButton: 'btn-cancel p-button p-button-raised  p-button-outlined mx-1',
  },
  buttonsStyling: false,
  closeButtonHtml: `
    <div class="header-icon">
      <span class="header-close-icon pi pi-times"></span>
    </div>`,
};

const toastOptions = {
  life: 3000,
};

type defaultType = {
  title?: string;
  text: string;
  extraText?: string;
};

export default Vue.extend({
  name: 'NotificationMixin',
  methods: {
    successToast({ title = i18n.t('success') as string, text }: defaultType, options = {} as object) {
      return this.$toast.add({
        severity: 'success',
        summary: title,
        detail: text,
        ...toastOptions,
        ...options,
      });
    },

    infoToast({ title = i18n.t('info') as string, text }: defaultType, options = {} as object) {
      return this.$toast.add({
        severity: 'info',
        summary: title,
        detail: text,
        ...toastOptions,
        ...options,
      });
    },

    warningToast({ title = i18n.t('warning') as string, text }: defaultType, options = {} as object) {
      return this.$toast.add({
        severity: 'warn',
        summary: title,
        detail: text,
        ...toastOptions,
        ...options,
      });
    },

    errorToast({ title = i18n.t('error') as string, text }: defaultType, options = {} as object) {
      return this.$toast.add({
        severity: 'error',
        summary: title,
        detail: text,
        ...toastOptions,
        ...options,
      });
    },

    clearToast(): void {
      this.$toast.removeAllGroups();
    },

    confirmDelete({ title, text, extraText = '' }: defaultType) {
      return this.$swal({
        title,
        html: genDeleteHtml(text, extraText),
        reverseButtons: true,
        showDenyButton: false,
        showCancelButton: true,
        focusCancel: true,
        showCloseButton: true,
        confirmButtonText: i18n.t('delete') as string,
        cancelButtonText: i18n.t('cancel') as string,
        ...defaultOptions,
        width: '30rem',
      });
    },

    confirmDisable({ title, text, extraText = '' }: defaultType) {
      return this.$swal({
        title,
        html: genDeleteHtml(text, extraText),
        reverseButtons: true,
        showDenyButton: false,
        showCancelButton: true,
        focusCancel: true,
        showCloseButton: true,
        confirmButtonText: i18n.t('confirm') as string,
        cancelButtonText: i18n.t('cancel') as string,
        ...defaultOptions,
        width: '30rem',
      });
    },

    confirmEnable({ title, text, extraText = '' }: defaultType) {
      return this.$swal({
        title,
        html: genEnableHtml(text, extraText),
        reverseButtons: true,
        showDenyButton: false,
        showCancelButton: true,
        focusCancel: true,
        showCloseButton: true,
        confirmButtonText: i18n.t('confirm') as string,
        cancelButtonText: i18n.t('cancel') as string,
        ...enableOptions,
        width: '30rem',
      });
    },

    confirmRedefine({ title, text, extraText = '' }: defaultType) {
      return this.$swal({
        title,
        html: genDeleteHtml(text, extraText),
        reverseButtons: true,
        showDenyButton: false,
        showCancelButton: true,
        focusCancel: true,
        showCloseButton: true,
        confirmButtonText: i18n.t('redefine') as string,
        cancelButtonText: i18n.t('cancel') as string,
        ...defaultOptions,
        width: '30rem',
      });
    },

    confirmNotDeleteOnlyUpdate({ title, text, extraText = '' }: defaultType) {
      return this.$swal({
        title,
        html: genNotDeleteOnlyUpdateHtml(text, extraText),
        reverseButtons: true,
        showDenyButton: false,
        showCancelButton: true,
        focusCancel: true,
        showCloseButton: true,
        confirmButtonText: i18n.t('update') as string,
        cancelButtonText: i18n.t('cancel') as string,
        ...notDeleteOnlyUpdateOptions,
        width: '30rem',
      });
    },
  },
});
