
import Vue from 'vue';
import { mapGetters } from 'vuex';
import StorageService from '@/common/services/onboarding-bank/StorageService';
import OnboardingButtons from '@/resources/onboarding-bank/components/OnboardingButtons.vue';
import { StorageCsvPayload } from '../../common/model/StorageCsvPayload';
import ModelTableModal from './components/tables/ModelTableModal.vue';
import ModelProductLaunchTable from './components/tables/ModelProductLaunchTable.vue';
import ModelServiceLaunchTable from './components/tables/ModelServiceLaunchTable.vue';
import ModelAccountTable from './components/tables/ModelAccountTable.vue';

export default Vue.extend({
  components: {
    OnboardingButtons,
    ModelTableModal,
    ModelAccountTable,
    ModelProductLaunchTable,
    ModelServiceLaunchTable,
  },
  data() {
    return {
      showModal: false as boolean,
      modelDoc: '' as string,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    async downloadCsvFile(fileName: string): Promise<void> {
      const payload = new StorageCsvPayload(fileName);
      const { data } = await StorageService.downloadCsvAccountant(payload);
      window.open(data as string, '_blank');
    },
    toogleModalWithDoc(doc: string) {
      this.showModal = !this.showModal;
      this.modelDoc = doc;
    },
  },
});
