
import Vue from 'vue';
import { get } from 'lodash';
import DeleteAll from '@/common/components/table/DeleteAll.vue';

export default Vue.extend({
  components: {
    DeleteAll,
  },
  props: {
    id: {
      type: [String as () => string, Number as () => number],
      required: false,
      default: 'table',
    },
    data: {
      type: Array as () => any[],
      default: [],
    },
    columnsData: {
      type: Object as () => any,
      default: () => ({}),
    },
    rowLength: {
      type: [Number as () => number, String as () => string],
      default: 0,
    },
    paginate: {
      type: Boolean as () => boolean,
      default: false,
    },
    loading: {
      type: Boolean as () => boolean,
      default: false,
    },
    sortable: {
      type: Boolean as () => boolean,
      default: false,
    },
    expandable: {
      type: Boolean as () => boolean,
      default: false,
    },
    expandableItems: {
      type: String as () => string,
      default: '',
    },
    highlightRow: {
      type: Number,
      default: null,
    },
    deleteAll: {
      type: Boolean as () => boolean,
      default: false,
    },
    initialSort: {
      type: String as () => string,
      default: 'title',
    },
    pageSizeProp: {
      type: Number as () => number,
      default: 25,
    },
    expandableEvent: {
      type: Function,
      default: () => null,
    },
    seeMoreItensLabel: {
      type: String as () => string,
      default: 'Ver mais iten',
    },
    totalExpandableItens: {
      type: Number as () => number,
      default: 0,
    },
    sortData: {
      type: Boolean as () => boolean,
      default: true,
    },
  },
  data() {
    return {
      expandLine: [] as boolean[],
      cacheExpandLine: 0 as number,
      openClose: [] as string[],
      options: [
        {
          value: 10 as number,
          label: 10 as number,
        },
        {
          value: 25 as number,
          label: 25 as number,
        },
        {
          value: 50 as number,
          label: 50 as number,
        },
        {
          value: 100 as number,
          label: 100 as number,
        },
        {
          value: 200 as number,
          label: 200 as number,
        },
      ],
      listaModel: [] as any[],
      currentPage: 1 as number, // página atual
      pageSize: this.pageSizeProp as number, // Table rows number,
      idInput: 0 as number,
      currentSortDir: 'asc' as string,
      currentSort: this.initialSort,
    };
  },
  computed: {
    pagesQtd(): number {
      if (this.rowLength) {
        return Math.ceil((this.rowLength as number) / this.pageSize);
      }
      return Math.ceil(this.rows.length / this.pageSize);
    },
    rows(): any[] {
      if (!this.data.length) {
        return [];
      }

      return this.data.filter((item: any): any => {
        const props = Object.values(item);

        return props.some(
          (prop: any): any => !this.listaModel[this.idInput]
            || (typeof prop === 'string'
              ? prop
                .toLowerCase()
                .includes(this.listaModel[this.idInput].toLowerCase())
              : prop
                .toString(10)
                .toLowerCase()
                .includes(this.listaModel[this.idInput])
                .toLowerCase()),
        );
      });
    },

    sortedList(): any[] {
      if (!this.sortData) {
        return this.rows.slice();
      }
      return this.rows
        .slice()
        .sort((a: any, b: any): number => {
          let modifier = 1;
          if (this.currentSortDir === 'desc') {
            modifier = -1;
          }
          if (this.currentSort !== 'title') {
            if (get(a, this.currentSort, null) < get(b, this.currentSort, null)) {
              return -1 * modifier;
            }
            if (get(a, this.currentSort, null) > get(b, this.currentSort, null)) {
              return 1 * modifier;
            }
          }
          return 0;
        })
        .filter((row: any, index: number): boolean => {
          let start = 0;
          if (!this.paginate) {
            start = 0;
          } else if (this.rowLength > this.rows.length) {
            start = 0;
          } else if (this.rowLength <= this.pageSize) {
            this.currentPage = 1;
            start = (this.currentPage - 1) * this.pageSize;
          } else {
            start = (this.currentPage - 1) * this.pageSize;
          }
          const end = this.currentPage * this.pageSize;
          if (index >= start && index < end) return true;
          return false;
        });
    },
  },
  watch: {
    pageSize(newValue: unknown) {
      this.$emit('pageSize', newValue);
    },
    pageSizeProp(newValue: number) {
      this.pageSize = newValue;
    },
  },
  updated() {
    if (this.expandable && !this.loading) this.insertClassexpandable();
  },
  methods: {
    paginateArr(newValue: number) {
      this.currentPage = newValue;
    },
    paginationUpdate(newValue: number) {
      this.currentPage = newValue;
      this.$emit('currPage', newValue);
    },
    insertClassexpandable() {
      const elementClass = this.$el.querySelectorAll('.cel-expandable');
      const elementRows = this.$el.querySelectorAll('.r-custom-table__row');
      let aux = 0;
      this.sortedList.forEach((el: any) => {
        if (el[this.expandableItems] && el[this.expandableItems].length > 0) {
          aux += el[this.expandableItems].length;
          // eslint-disable-next-line no-unused-expressions
          elementClass[aux - 1]?.classList.add('last-expanded-line');
        }
      });
      if (this.sortedList.length > 0) {
        (elementRows[
          this.sortedList.length - 1
        ] as HTMLElement).style.borderBottom = 'none';
      }
    },
    async retractableLine(i: number): Promise<void> {
      if (this.cacheExpandLine !== i) {
        this.$set(this.expandLine, this.cacheExpandLine, false);
        this.$set(this.openClose, this.cacheExpandLine, 'plus');
        this.cacheExpandLine = i;
      }

      if (typeof this.expandLine[i] === 'undefined') {
        this.$set(this.expandLine, i, true);
      } else this.$set(this.expandLine, i, !this.expandLine[i]);

      if (this.openClose[i] === 'undefined') {
        this.$set(this.openClose, i, 'less');
      } else {
        this.$set(
          this.openClose,
          i,
          this.openClose[i] === 'less' ? 'plus' : 'less',
        );
      }
      if (this.expandableEvent) {
        await this.expandableEvent(this.rows[i]);
      }
    },
    returnStatusClass(type: string): string {
      const classes: any = {
        WARNING: 'status--warning',
        PROCESSING: 'status--warning',
        DONE: 'status--success',
        ERROR: 'status--danger',
      };

      return classes[type];
    },
    returnClassName(status: string) {
      if (status === 'AUTHORIZED') {
        return 'valid-document';
      }
      if (status === 'CANCELED') {
        return 'invalid-document';
      }

      return '';
    },
    splitName(name: string): string {
      if (name) {
        const tam = (name.match(/-/g) || []).length;
        let result = '';
        if (tam >= 1) {
          name
            .toString()
            .split('-')
            .map((item, i): string => {
              if (i !== 0) {
                if (result === '') {
                  result += name.toString().split('-')[i];
                } else {
                  result = `${result}-${name.toString().split('-')[i]}`;
                }
                return result;
              }
              return result;
            });
          return result;
        }
        return name;
      }
      return '';
    },
    sort(dir: string, prop: string): void {
      this.currentSortDir = dir;
      this.currentSort = prop;

      this.currentPage = 1;
      this.listaModel = [];
    },
    formatCurrency(value: number) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
  },
});
