var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { on: { click: _vm.getEstablisments } },
    [
      _c(
        "Accordion",
        {
          key: `group-${_vm.companyGroup.name}`,
          staticClass: "accordion-custom mb-4",
          attrs: { multiple: "" },
        },
        [
          _c(
            "AccordionTab",
            {
              attrs: { active: "" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", { staticClass: "secondary-text" }, [
                            _vm._v(_vm._s(_vm.companyGroup.name)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-right actions",
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: _vm.$t(
                                    "companyGroupComponent.tooltip.addCompanies"
                                  ),
                                  expression:
                                    "$t('companyGroupComponent.tooltip.addCompanies')",
                                  modifiers: { top: true },
                                },
                              ],
                              staticClass: "action mx-0.2",
                              on: {
                                click: (e) => {
                                  _vm.handleAdd()
                                  e.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "icon-r-circle-plus color__secondary",
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: _vm.$t(
                                    "companyGroupComponent.tooltip.editGroup"
                                  ),
                                  expression:
                                    "$t('companyGroupComponent.tooltip.editGroup')",
                                  modifiers: { top: true },
                                },
                              ],
                              staticClass: "action mx-0.2",
                              on: {
                                click: (e) => {
                                  _vm.handleUpdate()
                                  e.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "icon-r-edit color__secondary",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("div", { staticClass: "wrap-table" }, [_vm._t("default")], 2)]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }