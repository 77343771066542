
import CertificateService from '@/common/services/CertificateService';
import { CreateCertificateAlert, UpdateCertificateAlert } from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';
import mixins from 'vue-typed-mixins';
import DigitalCertificateValidateModalContent from './DigitalCertificateValidateModalContent.vue';

type DigitalCertificateValidateModalContent = (
  InstanceType<typeof DigitalCertificateValidateModalContent>
)

export default mixins(NotificationMixin).extend({
  name: 'DigitalCertificateValidateModal',
  components: {
    DigitalCertificateValidateModalContent,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      days: 60,
      emails: [] as string[],
      originalEmails: [] as string[],
      editMode: false,
      disableSaveButton: false,
      emptySaveModal: false,
    };
  },
  watch: {
    modal(opened: boolean) {
      if (opened && this.emails.length === 0) {
        this.disableSaveButton = true;
      }
    },
  },
  computed: {
    modal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean): void {
        if (value) {
          this.$emit('update:showModal', value);
        } else {
          this.closeModal();
        }
      },
    },
  },
  async created() {
    const { masterId } = this.$store.state.auth.userCompany;

    const data = await CertificateService.getCertificateAlert(masterId);

    if (data) {
      this.days = data.alertWithDays;
      this.emails = [...data.recipient];
      this.originalEmails = [...data.recipient];
    }

    this.editMode = !!data;
  },
  methods: {
    handleCreateOrUpdateCertificateAlert() {
      if (this.emails.length === 0) {
        this.emptySaveModal = true;
      } else {
        this.createOrUpdateCertificateAlert();
      }
    },
    async createOrUpdateCertificateAlert() {
      let isFormValid;

      try {
        const digitalCertificateValidateModalContent = (
          this.$refs.digitalCertificateValidateModalContent
        ) as DigitalCertificateValidateModalContent;

        isFormValid = await digitalCertificateValidateModalContent.validateForm();
      } catch {
        this.errorToast({
          text: this.$t('digitalCertificates.validateCertificate.modal.formInvalid') as string,
        });
      }

      if (isFormValid) {
        const { masterId } = this.$store.state.auth.userCompany;

        const payload = {
          recipient: this.emails,
          alertWithDays: this.days,
          masterId,
        } as CreateCertificateAlert;

        if (this.editMode) {
          try {
            await CertificateService.updateCertificateAlert(payload as UpdateCertificateAlert);

            this.successToast({
              text: this.$t('digitalCertificates.validateCertificate.modal.requestCertificateAlertSuccess.update') as string,
            } as any);
          } catch {
            this.errorToast({
              text: this.$t('digitalCertificates.validateCertificate.modal.requestCertificateAlertError') as string,
            });
          }
        } else {
          try {
            await CertificateService.createCertificateAlert(payload as CreateCertificateAlert);

            this.successToast({
              text: this.$t('digitalCertificates.validateCertificate.modal.requestCertificateAlertSuccess.create') as string,
            } as any);
            this.editMode = true;
          } catch {
            this.errorToast({
              text: this.$t('digitalCertificates.validateCertificate.modal.requestCertificateAlertError') as string,
            });
          }
        }

        this.originalEmails = [...this.emails];
        this.emptySaveModal = false;
        this.$emit('update:showModal', false);
      }
    },
    closeModal(): void {
      this.emails = [...this.originalEmails];

      this.$emit('update:showModal', false);
    },
  },
});
