export type Supplier = {
  id: number;
  name: string;
  email: string;
  masters: string[];
}

export type State = {
  supplier: Supplier | null;
}

export default {
  supplier: null as Supplier | null,
};
