
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import IntegrationService from '@/common/services/IntegrationService';
import CorporateControlService from '@/common/services/corporate-control/CorporateControlService';
import NotificationMixin from '@/mixins/NotificationMixin';
import { IntegrationSystem, SelectOption, VForm } from '@/types';
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import { CommonParameter, Option, SystemParameter } from '../../types';
import IntegrationOptionsSectionForm from './IntegrationOptionsSectionForm.vue';
import NetSuiteAuthSectionForm from './netSuiteSection/NetSuiteAuthSectionForm.vue';
import ProtheusAuthSectionForm from './protheusSection/ProtheusAuthSectionForm.vue';
import ProtheusParametersSectionForm from './protheusSection/ProtheusParametersSectionForm.vue';
import QuestorAuthSectionForm from './questorSection/QuestorAuthSectionForm.vue';
import S4HanaAuthSectionForm from './s4HanaSection/S4HanaAuthSectionForm.vue';
import SapB1AuthSectionForm from './sapB1Section/SapB1AuthSectionForm.vue';
import SapB1DatabaseSectionForm from './sapB1Section/SapB1DatabaseSectionForm.vue';
import SapB1ParametersSectionForm from './sapB1Section/SapB1ParametersSectionForm.vue';
import SapEccAuthSectionForm from './sapEccSection/SapEccAuthSectionForm.vue';

export default mixins(NotificationMixin).extend({
  name: 'IntegrationSystemForm',
  components: {
    InputPassword,
    SapB1AuthSectionForm,
    SapB1DatabaseSectionForm,
    SapB1ParametersSectionForm,
    IntegrationOptionsSectionForm,
    ProtheusAuthSectionForm,
    ProtheusParametersSectionForm,
    SapEccAuthSectionForm,
    S4HanaAuthSectionForm,
    NetSuiteAuthSectionForm,
    QuestorAuthSectionForm,
  },
  props: {
    payload: {
      type: Object as () => IntegrationSystem,
      default: {} as IntegrationSystem,
    },
  },
  data() {
    return {
      formData: JSON.parse(JSON.stringify(this.payload)) as IntegrationSystem,
      companies: [] as SelectOption[],
      commonParameter:
        this.payload.commonParameter || ({} as CommonParameter),
      systemParameter:
        this.payload.systemParameter || ({} as SystemParameter),
      systemOptions: [] as {value: string; label: string}[],
      SAPB1: 'SAPB1',
      SAPECC: 'SAPECC',
      SAPS4HANA: 'SAPS4HANA',
      NETSUITE: 'NETSUITE',
      PROTHEUS: 'PROTHEUS',
      QUESTOR: 'QUESTOR',
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'formData.system': function () {
      if (this.payload.system === this.formData.system) {
        this.systemParameter = this.payload.systemParameter;
      } else {
        this.systemParameter = {} as SystemParameter;
      }
    },
  },
  computed: {
    isUpdate(): boolean {
      return this.payload?.id !== undefined;
    },
    integrationPurposes(): Option[] {
      return [
        {
          value: 'TAX_ANALYSIS',
          label: this.$t('integrations.purpose.tax') as string,
        },
        {
          value: 'ACCOUNTING_CLASSIFICATION',
          label: this.$t('integrations.purpose.accounting') as string,
        },
        {
          value: 'FINANCIAL_BOT',
          label: this.$t('integrations.purpose.financial') as string,
        },
      ];
    },
  },
  async mounted() {
    this.handleLoading(true);

    await this.getCompanies();
    await this.loadSystemOptions();

    this.handleLoading(false);
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async submit(): Promise<void> {
      const valid = await this.validateForm();
      if (!valid) return;

      const integration: IntegrationSystem = {
        ...this.formData,
        systemParameter: this.systemParameter,
        commonParameter: this.commonParameter,
      };

      this.onSubmitEmit();

      let submitSuccess = false;
      if (this.isUpdate) {
        submitSuccess = await this.integrationSystemUpdate(integration);
      } else {
        submitSuccess = await this.integrationSystemRegistration(integration);
      }

      if (submitSuccess) {
        this.formClear();
      }

      this.submittedEmit();
    },

    async validateForm(): Promise<boolean> {
      const form: any = this.$refs.form as VForm;

      const valid = await form.validate() as Promise<boolean>;

      return valid;
    },

    formClear() {
      this.formData = {} as IntegrationSystem;
      this.systemParameter = {} as SystemParameter;
      this.commonParameter = {} as CommonParameter;
    },

    async getCompanies(): Promise<void> {
      try {
        const { companies } = await CorporateControlService.findCompaniesByMaster({ fields: ['tradeName', 'name', 'companyName'] });
        this.companies = companies.map((company) => ({
          name: company.id,
          optionLabel: company.companyName || company.tradeName || company.name,
          optionValue: company.id,
        }));
      } catch (e) {
        this.errorToast({
          text: this.$t('users.list.establishment.msgError') as string,
        });
      }
    },

    async loadSystemOptions() {
      // this.systemOptions = (await ERPsIntegrationsService.findAll())
      //   .sort((a, b) => {
      //     if (a.erpName < b.erpName) {
      //       return -1;
      //     }
      //     if (a.erpName > b.erpName) {
      //       return 1;
      //     }
      //     return 0;
      //   });

      const {
        SAPB1, PROTHEUS, SAPECC, SAPS4HANA, NETSUITE, QUESTOR,
      } = this;

      this.systemOptions = await Promise.resolve([
        {
          value: SAPB1,
          label: 'SAP B1',
        }, {
          value: PROTHEUS,
          label: 'Protheus',
        }, {
          value: SAPECC,
          label: 'SAP ECC',
        }, {
          value: SAPS4HANA,
          label: 'S4 HANA',
        }, {
          value: NETSUITE,
          label: 'NetSuite',
        }, {
          value: QUESTOR,
          label: 'Questor',
        },
      ]);
    },

    async integrationSystemRegistration(integrationSystem: IntegrationSystem): Promise<boolean> {
      try {
        this.handleLoading(true);

        await IntegrationService.createIntegration(integrationSystem);

        this.successToast({
          text: this.$t('integrations.create.success') as string,
        } as any);

        return true;
      } catch (e) {
        this.errorToast({
          text: this.$t('integrations.create.error') as string,
        } as any);
      } finally {
        this.handleLoading(false);
      }

      return false;
    },
    async integrationSystemUpdate(integrationSystem: IntegrationSystem): Promise<boolean> {
      try {
        this.handleLoading(true);

        const { id, ...integrationSystemData } = integrationSystem;

        await IntegrationService.updateIntegration(id, integrationSystemData);

        this.successToast({
          text: this.$t('integrations.update.success') as string,
        } as any);

        return true;
      } catch (e) {
        this.errorToast({
          text: this.$t('integrations.update.error') as string,
        } as any);
      } finally {
        this.handleLoading(false);
      }

      return false;
    },

    onSubmitEmit() {
      this.$emit('onSubmit', true);
    },

    submittedEmit() {
      this.$emit('submitted', true);
    },
  },
});
