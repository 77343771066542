var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_vm.component, {
    ref: "component",
    tag: "component",
    attrs: { rows: _vm.integrations, loading: _vm.loadingTable },
    on: {
      edit: function ($event) {
        return _vm.editIntegration($event)
      },
      delete: function ($event) {
        return _vm.deleteIntegration($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }