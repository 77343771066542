var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "register-company" },
    [
      _c("p", { staticClass: "small grey300" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("stepCompanies.warnMandatoryFieldFilling")) + " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "p-d-flex p-jc-between" },
        [
          _c("Button", {
            ref: "toggleModal",
            staticClass: "p-button-raised",
            attrs: {
              label: _vm.$t("onboarding.registerCompany.btnRegister"),
              icon: "icon-r-circle-plus bg-white",
            },
            on: {
              click: function ($event) {
                return _vm.toggleAddCompanyModal()
              },
            },
          }),
          _c("input-validation", {
            ref: "search",
            attrs: { rules: "min:2|max:40", slim: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "p-input-icon-left",
                        staticStyle: { width: "300px" },
                      },
                      [
                        _c("i", { staticClass: "icon-r-magnifier-tool ml-1" }),
                        _c("InputText", {
                          ref: "inputSearch",
                          class: [
                            { "p-invalid": errors[0] },
                            "w-100",
                            "no-box",
                          ],
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("search"),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.establishmentSearch($event)
                            },
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("company-group-modal", {
        attrs: { control: _vm.showingCompanyGroupModal },
        on: {
          "update:control": function ($event) {
            _vm.showingCompanyGroupModal = $event
          },
          registrations: function ($event) {
            return _vm.groupCompanyRegistration($event)
          },
          loaded: function ($event) {
            return _vm.establishmentLoaded($event)
          },
        },
      }),
      _vm.messageErrors.length
        ? _c("CompaniesMessage", {
            model: {
              value: _vm.messageErrors,
              callback: function ($$v) {
                _vm.messageErrors = $$v
              },
              expression: "messageErrors",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-4 relative" },
        [
          _c("EditableCompanyGroupAccordion", {
            attrs: { "enabled-not-found": true },
          }),
        ],
        1
      ),
      _c("StepButtons", {
        attrs: { "disable-next": _vm.disableNext, "visible-jump": false },
        on: {
          next: function ($event) {
            return _vm.nextStep()
          },
          back: _vm.backStep,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }