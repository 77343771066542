var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          staticClass: "text-125",
          style: { minWidth: "500px", maxWidth: "500px" },
          attrs: {
            header: _vm.$t(
              "digitalCertificates.validateCertificate.modal.header"
            ),
            modal: true,
            visible: _vm.modal,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c("Button", {
                        staticClass: "outlined",
                        attrs: { label: _vm.$t(`cancel`) },
                        on: {
                          click: function ($event) {
                            return _vm.closeModal()
                          },
                        },
                      }),
                      _c("Button", {
                        staticClass: "white-button-icon",
                        attrs: {
                          label: _vm.$t("save"),
                          disabled: _vm.disableSaveButton,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleCreateOrUpdateCertificateAlert()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("DigitalCertificateValidateModalContent", {
            ref: "digitalCertificateValidateModalContent",
            attrs: { "days-prop": _vm.days, "emails-prop": _vm.emails },
            on: {
              "updateData:days": function ($event) {
                _vm.days = $event
              },
              "updateData:emails": function ($event) {
                _vm.emails = $event
              },
              "updateData:disableSaveButton": function ($event) {
                _vm.disableSaveButton = $event
              },
            },
          }),
          _c(
            "Dialog",
            {
              staticClass: "text-125",
              style: { minWidth: "400px", maxWidth: "400px" },
              attrs: {
                header: _vm.$t(
                  "digitalCertificates.validateCertificate.modal.emptySaveModal.header"
                ),
                modal: true,
                visible: _vm.emptySaveModal,
                "content-style": { overflow: "visible", borderRadius: "10px" },
              },
              on: {
                "update:visible": function ($event) {
                  _vm.emptySaveModal = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        [
                          _c("Button", {
                            staticClass: "outlined danger",
                            attrs: { label: _vm.$t(`no`) },
                            on: {
                              click: function ($event) {
                                _vm.emptySaveModal = false
                              },
                            },
                          }),
                          _c("Button", {
                            staticClass: "danger",
                            attrs: { label: _vm.$t("yes") },
                            on: {
                              click: function ($event) {
                                return _vm.createOrUpdateCertificateAlert()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "my-4" }, [
                _c("p", { staticClass: "text-sm color-black87" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "digitalCertificates.validateCertificate.modal.emptySaveModal.description"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }