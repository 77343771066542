var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          style: { width: "50vw" },
          attrs: {
            header: "Header",
            visible: _vm.displayModal,
            modal: true,
            "content-style": { overflow: "visible" },
          },
          on: {
            "update:visible": function ($event) {
              _vm.displayModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("h3", [_vm._v("Comunicar Emissor")])]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("Button", {
                    staticClass: "outlined p-button-raised",
                    attrs: { label: "Cancelar" },
                    on: {
                      click: function ($event) {
                        _vm.displayModal = false
                      },
                    },
                  }),
                  _c("Button", {
                    staticClass: "p-button-raised",
                    attrs: { label: "Enviar" },
                    on: {
                      click: function ($event) {
                        _vm.displayModal = false
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "p-fluid p-formgrid p-grid" }, [
            _c(
              "div",
              { staticClass: "p-field p-col-12" },
              [
                _c("label", { attrs: { for: "titulo" } }, [_vm._v("Título")]),
                _c("InputText", {
                  attrs: { id: "titulo", type: "username" },
                  model: {
                    value: _vm.value[0],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, 0, $$v)
                    },
                    expression: "value[0]",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "p-field p-col-12" },
              [
                _c("label", { attrs: { for: "range" } }, [_vm._v("E-mail")]),
                _c("Chips", {
                  staticClass: "border-radius-6",
                  attrs: { "add-on-blur": true },
                  model: {
                    value: _vm.value[1],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, 1, $$v)
                    },
                    expression: "value[1]",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "p-field p-col-12" },
              [
                _c("label", { attrs: { for: "motivo" } }, [_vm._v("Mensagem")]),
                _c("Textarea", {
                  staticClass: "form-input",
                  attrs: {
                    id: "motivo",
                    rows: "5",
                    cols: "30",
                    placeholder: "Digite Aqui...",
                  },
                  model: {
                    value: _vm.value[2],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, 2, $$v)
                    },
                    expression: "value[2]",
                  },
                }),
              ],
              1
            ),
            _c("p", { staticClass: "p-col-12" }, [
              _vm._v(" O seguinte documento segue em anexo: "),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }