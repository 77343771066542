
import Vue from 'vue';
import locales from './locales';

export default Vue.extend({
  name: 'RAlert',

  i18n: {
    messages: locales,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    textTitle: {
      type: String,
      default(): string {
        return this.$t('rAlert.textTitle') as string;
      },
    },
    textContent: {
      type: String,
      default(): string {
        return this.$t('rAlert.textContent') as string;
      },
    },
    textBtnOk: {
      type: String,
      default(): string {
        return this.$t('rAlert.textBtnOk') as string;
      },
    },
    textBtnCancel: {
      type: String,
      default(): string {
        return this.$t('rAlert.textBtnCancel') as string;
      },
    },
  },
  computed: {
    isVisible: {
      get(): boolean {
        return this.visible;
      },
      set(bool: boolean) {
        this.$emit('close', !bool);
      },
    },
  },
  methods: {
    setVisible(bool: boolean) {
      this.isVisible = bool;
    },
    handleBtnClose() {
      if (this.isVisible === true) {
        this.setVisible(false);
      }
    },
    handleBtnOk() {
      this.$emit('ok', true);
    },
  },
});

