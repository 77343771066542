var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "w-full h-screen flex" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "relative flex-1" },
      [
        _c("SupportInternationalization"),
        _c(
          "div",
          { staticClass: "flex items-center justify-center w-full height" },
          [
            _c(
              "div",
              { staticClass: "centered-text p-10 rounded" },
              [
                _c("h3", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("onboardingBank.start.hello")) + " "
                  ),
                  _c("span", { staticClass: "font-weight-bold primary-text" }, [
                    _vm._v(" " + _vm._s(_vm.user.name) + ", "),
                  ]),
                  _c("br"),
                ]),
                _c("h3", { staticClass: "mb-4 font-weight-bold" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("onboardingBank.start.greetings")) + " "
                  ),
                ]),
                _c("p", { staticClass: "mb-4" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("onboardingBank.start.message")) + " "
                  ),
                ]),
                _c(
                  "Button",
                  {
                    staticClass: "button",
                    on: { click: _vm.redirectWithMenu },
                  },
                  [
                    _c("span", { staticClass: "button__text mx-auto" }, [
                      _vm._v(_vm._s(_vm.$t("onboardingBank.start.buttonText"))),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "relative" }, [
      _c("img", {
        staticClass: "img h-full",
        attrs: {
          width: "560",
          src: require("@/common/assets/images/bg-web.jpg"),
          alt: "Mulher usando um notebook",
        },
      }),
      _c("img", {
        staticClass: "absolute top-12 left-12",
        attrs: {
          width: "236",
          src: require("@/common/assets/images/logo-roit-solutions-white.svg"),
          alt: "Logo Roit Bank",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }