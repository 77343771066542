import { Company } from '@/types';

export default {
  token: '' as string,
  user: {} as object,
  acceptLanguage: '' as string,
  userCompany: {} as Company,
  firstAccess: true,
  permissions: '' as any,
};
