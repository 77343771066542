import { Route } from 'vue-router';

export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/resources/profile/Profile.vue'),
  },
  {
    path: '/profile/personal/edit',
    name: 'profile-edit',
    redirect: {
      name: 'tab-one',
    },
    component: () => import(/* webpackChunkName: "profile" */ '@/resources/profile/components/EditProfile/ProfileTabMenu.vue'),
    props: (route: Route) => ({
      profile: route.params.profile,
    }),
    children: [
      {
        path: '/profile/personal/edit',
        name: 'tab-one',
        component: () => import(/* webpackChunkName: "profile" */ '@/resources/profile/components/EditProfile/PersonalBuildEdit.vue'),
        props: (route: Route) => ({
          profile: route.params.profile,
          routeToBack: route.params.routeToBack,
        }),
      },
      {
        path: '/profile/change-password/edit',
        name: 'tab-two',
        component: () => import(/* webpackChunkName: "profile" */ '@/resources/profile/components/EditProfile/UserAndPasswordBuildEdit.vue'),
        props: (route: Route) => ({
          profile: route.params.profile,
          routeToBack: route.params.routeToBack,
        }),
      },
    ],
  },
];
