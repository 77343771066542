import axios from '@/common/http';
import { ChangePassword } from '@/types';

class AuthService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_IAM_AUTH_URL}/api/v1`;

  public async authorization(transitionalToken: string): Promise<string> {
    const { data } = await this.service.post(
      `${process.env.VUE_APP_ROIT_IAM_AUTH_URL}/public/api/v1/auth/tokens/authorization`,
      {
        transitionalToken,
      },
    );

    const { authorizationToken } = data.data;

    return authorizationToken;
  }

  public async changePassword(userData: ChangePassword) {
    return this.service.post(`${this.baseUrl}/api/v1/authentication/change-password`, {
      newPassword: userData.newPassword,
      oldPassword: userData.oldPassword,
    });
  }

  async redefineMfa(userId: string): Promise<void> {
    return this.service.post(`${process.env.VUE_APP_ROIT_IAM_AUTH_URL}/api/v1/auth/mfa/redefine`, { userId });
  }
}

export default AuthService;
