
import Vue from 'vue';

export default Vue.extend({
  name: 'OnboardingButtons',
  props: {
    visibleBack: {
      type: Boolean,
      default: true,
    },
    visibleNext: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    nextStep() {
      if (this.$router.currentRoute.path === '/onboarding-bank/welcome-video') {
        this.$router.push('/onboarding-bank/tax-robot');
      } else if (this.$router.currentRoute.path === '/onboarding-bank/tax-robot') {
        this.$router.push('/onboarding-bank/accountant-robot');
      } else if (this.$router.currentRoute.path === '/onboarding-bank/accountant-robot') {
        this.$router.push('/config-home-screen');
      }
    },
    previousStep() {
      if (this.$router.currentRoute.path === '/onboarding-bank/tax-robot') {
        this.$router.push('/onboarding-bank/welcome-video');
      } else if (this.$router.currentRoute.path === '/onboarding-bank/accountant-robot') {
        this.$router.push('/onboarding-bank/tax-robot');
      }
    },
  },
});
