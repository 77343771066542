import Vue from 'vue';
import '@/common/components';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './i18n';
import '@/common/assets/plugins';
import '@/common/assets/scss/app.scss';

import { AuthHandle } from './auth/AuthHandle';

Vue.config.productionTip = false;

Vue.prototype.$authHandle = new AuthHandle();

declare global {
  interface Window {
    Conpass: any;
  }
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
