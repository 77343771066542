var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Message", { attrs: { severity: _vm.type } }, [
    _c("div", [
      _vm._v(
        _vm._s(_vm.$t("digitalCertificates.notifications.titleMessageError"))
      ),
    ]),
    _c(
      "ul",
      { staticClass: "p-mb-0 p-pl-5" },
      _vm._l(_vm.value, function (error, i) {
        return _c("li", { key: i }, [
          _c("span", [
            _c("strong", [_vm._v(_vm._s(error.fileName))]),
            _vm._v(": " + _vm._s(error.message) + " "),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }