
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';
import { SelectOption, UserAdmin, VForm } from '@/types';
import UserService from '@/common/services/UserService';

export default mixins(NotificationMixin).extend({
  name: 'RegisterResponsibleModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    selfResponsible: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Object as () => UserAdmin,
      required: false,
      default: (): UserAdmin => ({
        products: [] as string[],
        firstName: '',
        lastName: '',
        email: '',
        job: '',
      }),
    },
  },
  data: () => ({
    products: ['ROIT'],
    occupations: [] as SelectOption[],
    onLoading: [] as string[],
  }),
  computed: {
    payload: {
      get(): UserAdmin {
        return this.value;
      },
      set(value: UserAdmin) {
        this.$emit('input', value);
      },
    },
    modal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean) {
        this.$emit('update:showModal', value);
      },
    },
    isUpdate(): boolean {
      return !!this.payload.id;
    },
    isOnLoading(): boolean {
      return this.onLoading.length > 0;
    },
  },
  watch: {
    onLoading(onLoading: string[]) {
      this.handleLoading(onLoading.length > 0);
    },
    showModal(showModal) {
      if (showModal) {
        this.getOccupations();
      }
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    setOnLoading(bool: boolean): void {
      if (bool === true) {
        this.onLoading.push('1');
      } else {
        this.onLoading.pop();
      }
    },
    async confirm() {
      const form: any = this.$refs.form as VForm;
      const valid: boolean = await form.validate();
      if (valid) {
        this.$emit(`responsible:${this.isUpdate ? 'update' : 'add'}`, {
          ...this.payload,
        });
      }
    },
    async getOccupations(): Promise<void> {
      try {
        this.setOnLoading(true);
        const { data: response } = await UserService.getOccupations();
        this.occupations = response.data.map(
          (item: {label: string; value: string}) => ({
            optionLabel: item.label,
            name: item.value,
          } as SelectOption),
        );
      } catch (e) {
        this.errorToast({
          text: this.$t('users.list.occupations.msgError') as string,
        });
      } finally {
        this.setOnLoading(false);
      }
    },
  },
});
