const getters = {
  loadingTrigger: (state: any) => state.loadingTrigger,
  renderApp: (state: any) => state.renderApp,
  renderMenu: (state: any) => state.renderMenu,
  locale: (state: any) => state.locale,
  reloadApp: (state: any) => state.reloadApp,
  onboardingWithoutMenu: (state: any) => state.onboardingWithoutMenu,
};

export default getters;
