var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "p-col-10" },
    [
      _vm.firstAccess
        ? _c("p", { staticClass: "info-text mb-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("pageSubtitle")) + " "),
          ])
        : _vm._e(),
      _c("label", { staticClass: "mb-1 fs13" }, [
        _vm._v(" " + _vm._s(_vm.$t("user")) + " "),
      ]),
      _c("div", { staticClass: "p-field" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex relative align-items-center align-items-center",
          },
          [
            _c("i", { staticClass: "bg-color-grey" }),
            _c("InputText", {
              staticClass: "input-text p-disabled",
              attrs: { disabled: "", value: _vm.username, type: "text" },
            }),
          ],
          1
        ),
      ]),
      _c("ValidationObserver", { ref: "form" }, [
        _c(
          "div",
          { staticClass: "p-field" },
          [
            _c("ValidationProvider", {
              attrs: {
                label: _vm.$t("oldPassword"),
                rules: "required|min:8|max:20",
                name: _vm.$t("oldPassword"),
                slim: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", { staticClass: "mb-1 fs13" }, [
                        _vm._v(" " + _vm._s(_vm.$t("oldPassword")) + " "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex relative align-items-center align-items-center",
                        },
                        [
                          _c("InputText", {
                            ref: "oldPassword",
                            class: ["input-text", { "p-invalid": errors[0] }],
                            attrs: {
                              id: "oldPassword",
                              autocomplete: "off",
                              type: _vm.showOldPassword ? "text" : "password",
                            },
                            model: {
                              value: _vm.fields.oldPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.fields, "oldPassword", $$v)
                              },
                              expression: "fields.oldPassword",
                            },
                          }),
                          _c("i", {
                            staticClass: "absolute right-3.5 w-5 h-5",
                            class: [
                              _vm.showOldPassword
                                ? "icon-r-eye"
                                : "icon-r-eye-hide",
                            ],
                            on: {
                              click: function ($event) {
                                _vm.showOldPassword = !_vm.showOldPassword
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "p-field" },
          [
            _c("ValidationProvider", {
              attrs: {
                vid: "newPassword",
                label: _vm.$t("newPassword"),
                name: _vm.$t("newPassword"),
                rules: {
                  required: true,
                  regex: /^(?=.*[a-z|A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                },
                slim: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", { staticClass: "mb-1 fs13" }, [
                        _vm._v(" " + _vm._s(_vm.$t("newPassword")) + " "),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-flex relative align-items-center align-items-center",
                        },
                        [
                          _c("InputText", {
                            class: ["input-text", { "p-invalid": errors[0] }],
                            attrs: {
                              autocomplete: "off",
                              type: _vm.showNewPassword ? "text" : "password",
                            },
                            model: {
                              value: _vm.fields.newPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.fields, "newPassword", $$v)
                              },
                              expression: "fields.newPassword",
                            },
                          }),
                          _c("i", {
                            staticClass: "absolute right-3.5 w-5 h-5",
                            class: [
                              _vm.showNewPassword
                                ? "icon-r-eye"
                                : "icon-r-eye-hide",
                            ],
                            on: {
                              click: function ($event) {
                                _vm.showNewPassword = !_vm.showNewPassword
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "p-field" },
          [
            _c("ValidationProvider", {
              attrs: {
                vid: "confirmPassword",
                label: _vm.$t("confirmNewPassword"),
                name: _vm.$t("confirmNewPassword"),
                rules: "required|confirmed:newPassword",
                slim: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", { staticClass: "mb-1 fs13" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("confirmNewPassword")) + " "
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-flex relative align-items-center align-items-center",
                        },
                        [
                          _c("InputText", {
                            class: ["input-text", { "p-invalid": errors[0] }],
                            attrs: {
                              autocomplete: "off",
                              type: _vm.showConfirmPassword
                                ? "text"
                                : "password",
                            },
                            model: {
                              value: _vm.confirmPassword,
                              callback: function ($$v) {
                                _vm.confirmPassword = $$v
                              },
                              expression: "confirmPassword",
                            },
                          }),
                          _c("i", {
                            staticClass: "absolute right-3.5 w-5 h-5",
                            class: [
                              _vm.showConfirmPassword
                                ? "icon-r-eye"
                                : "icon-r-eye-hide",
                            ],
                            on: {
                              click: function ($event) {
                                _vm.showConfirmPassword =
                                  !_vm.showConfirmPassword
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("steps-change-password", {
            attrs: { rules: _vm.passwordRules },
            model: {
              value: _vm.fields.newPassword,
              callback: function ($$v) {
                _vm.$set(_vm.fields, "newPassword", $$v)
              },
              expression: "fields.newPassword",
            },
          }),
        ],
        1
      ),
      _vm.useDefaultBtnSubmit
        ? _c("div", { staticClass: "mt-4" }, [
            _vm.success
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "success-box d-flex p-p-2 p-jc-center p-ai-center mb-4",
                    },
                    [
                      _c("i", { staticClass: "icon-correct-check mr-1" }),
                      _vm._v(" " + _vm._s(_vm.$t("msgSuccess")) + " "),
                    ]
                  ),
                  _vm.useDefaultBtnContinue
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("Button", {
                            staticClass: "h-50 px-10",
                            attrs: { label: "Continuar" },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "Finish",
                                  params: { finish: true },
                                })
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center",
                  },
                  [
                    _c("Button", {
                      staticClass: "h-50 px-10",
                      attrs: { label: _vm.$t("changePasswordButton") },
                      on: { click: _vm.handleChangePassword },
                    }),
                  ],
                  1
                ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }