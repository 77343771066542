import { PaginationLimitDTO } from '@/types';
import { CompanyGroup } from '@roit-intern/roit-company-interfaces';
import axios from 'axios';
import {
  PaginatedBranchsDTO, PaginatedCompaniesDTO, PaginatedEstablishmentsDTO, PaginatedGroupsDTO,
} from './types';

class CorporateControlService {
  private service: typeof axios = axios;

  private baseURL = `${process.env.VUE_APP_CORPORATE_CONTROL_URL_GATEWAY}`;

  async findByMaster(pagination?: PaginationLimitDTO): Promise<PaginatedGroupsDTO> {
    const { limit, cursor, orientation } = pagination || {};
    const params = new URLSearchParams();
    if (limit) {
      params.set('limit', limit);
    }
    if (cursor) {
      params.set('cursor', cursor);
    }
    if (orientation) {
      params.set('orientation', orientation);
    }
    const result = await this.service.get(`${this.baseURL}/corporates/by-master`, { params });
    return result.data.data;
  }

  async findBranchsByCompanyId(
    companyId: string,
    pagination?: PaginationLimitDTO,
  ): Promise<PaginatedBranchsDTO> {
    const { limit, cursor, orientation } = pagination || {};
    const params = new URLSearchParams();
    if (limit) {
      params.set('limit', limit);
    }
    if (cursor) {
      params.set('cursor', cursor);
    }
    if (orientation) {
      params.set('orientation', orientation);
    }

    const result = await this.service.get(
      `${this.baseURL}/branchs/by-company/${companyId}`,
      { params },
    );
    return result.data.data;
  }

  async findByName(name: string): Promise<PaginatedGroupsDTO> {
    const result = await this.service.get(`${this.baseURL}/corporates/by-name/${name}`);
    return result.data.data;
  }

  async updateEstablishment(dto: unknown, id: string): Promise<void> {
    await this.service.patch(`${this.baseURL}/establishments/${id}`, dto);
  }

  async updateCompanyGroupName(name: string, id: string): Promise<void> {
    await this.service.put(`${this.baseURL}/company-groups/${id}/name`, { name });
  }

  async updateCompany(dto: unknown, id: string): Promise<void> {
    await this.service.patch(`${this.baseURL}/companies/${id}`, dto);
  }

  async findCompaniesByMaster(
    params?: {pagination?: PaginationLimitDTO; fields?: string[]},
  ): Promise<PaginatedCompaniesDTO> {
    const searchParams = new URLSearchParams();
    if (params?.fields) {
      searchParams.set('fields', params.fields.join(','));
    }
    if (params?.pagination) {
      const { limit, cursor, orientation } = params.pagination;
      if (limit) {
        searchParams.set('limit', limit);
      }
      if (cursor) {
        searchParams.set('cursor', cursor);
      }
      if (orientation) {
        searchParams.set('orientation', orientation);
      }
    }

    const result = await this.service.get(`${this.baseURL}/companies/by-master`, { params: searchParams });
    return result.data.data;
  }

  async findCompanyGroupsByMaster(
  ): Promise<CompanyGroup[]> {
    const result = await this.service.get(`${this.baseURL}/company-groups/by-master`);
    return result.data.data;
  }

  async findEstablishmentsByMaster(
    params?: {pagination?: PaginationLimitDTO; fields?: string[]},
  ): Promise<PaginatedEstablishmentsDTO> {
    const searchParams = new URLSearchParams();
    if (params?.fields) {
      searchParams.set('fields', params.fields.join(','));
    }
    if (params?.pagination) {
      const { limit, cursor, orientation } = params.pagination;
      if (limit) {
        searchParams.set('limit', limit);
      }
      if (cursor) {
        searchParams.set('cursor', cursor);
      }
      if (orientation) {
        searchParams.set('orientation', orientation);
      }
    }

    const result = await this.service.get(`${this.baseURL}/establishments/by-master`, { params: searchParams });
    return result.data.data;
  }
}

export default new CorporateControlService();
