var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "relative", attrs: { id: "tax-robot" } },
    [
      _c(
        "ModelTableModal",
        {
          attrs: {
            "show-modal": _vm.showModal,
            model: "modelCompanies",
            page: "taxRobot",
          },
          on: {
            "update:showModal": function ($event) {
              _vm.showModal = $event
            },
            "update:show-modal": function ($event) {
              _vm.showModal = $event
            },
          },
        },
        [_c("ModelCompaniesTable")],
        1
      ),
      _c("img", {
        staticClass: "absolute -left-28 top-60",
        attrs: {
          src: require("@/common/assets/images/onboarding-bank/dots-gray.png"),
          alt: "Pontinhos cinzas",
        },
      }),
      _c("div", { staticClass: "container mt-5" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-xl-6" },
            [
              _c("h3", [
                _vm._v(
                  " " + _vm._s(_vm.$t("onboardingBank.taxRobot.text.how")) + " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("onboardingBank.taxRobot.text.first")) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("onboardingBank.taxRobot.text.second")) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("onboardingBank.taxRobot.text.third")) +
                    " "
                ),
              ]),
              _c(
                "button",
                { staticClass: "download-csv", on: { click: _vm.toogleModal } },
                [
                  _c("img", {
                    staticClass: "download-icon",
                    attrs: {
                      src: require("@/common/assets/icons/download.svg"),
                      alt: "Download Icon",
                    },
                  }),
                  _c("span", { staticClass: "download-text pl-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("onboardingBank.taxRobot.csv.companies")
                        ) +
                        " "
                    ),
                  ]),
                  _c("br"),
                ]
              ),
              _c("OnboardingButtons", { staticClass: "mt-5" }),
            ],
            1
          ),
          _vm._m(0),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "col-lg-12 col-xl-6", attrs: { id: "robot-img" } },
      [
        _c("img", {
          staticClass: "w-full",
          attrs: {
            src: require("@/common/assets/images/onboarding-bank/bg-accounting.png"),
            alt: "Robo Fiscal",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }