
import Vue from 'vue';
import { VForm } from '@/types';
import { EditCompanyGroupPayload, CreateCompanyGroupPayload, InUpdateFrom } from '@/common/components/companyGroup/types';
import CompanyService from '@/common/services/CompanyService';
import { Company } from '@roit-intern/roit-company-interfaces';
import { debounce } from 'lodash';

export type CreateCompanyGroupForm = { data: CreateCompanyGroupPayload };
export type EditCompanyGroupForm = { data: EditCompanyGroupPayload; inUpdateFrom: InUpdateFrom };

export default Vue.extend({
  name: 'CompanyGroupForm',
  props: {
    formData: {
      type: Object as () => EditCompanyGroupForm,
      default: undefined,
    },
  },
  data: () => ({
    form: {
      groupName: '',
      companies: Array(1)
        .fill({})
        .map(() => ({
          nationalRegister: '',
          retreat: '',
          polling: '',
          taxRegime: '',
        })),
    } as CreateCompanyGroupPayload | EditCompanyGroupPayload,
    tributaryRegime: [
      { name: 'SIMPLES', value: 'SIMPLES' },
      { name: 'PRESUMIDO', value: 'PRESUMIDO' },
      { name: 'REAL', value: 'REAL' },
      { name: 'ARBITRADO', value: 'ARBITRADO' },
      { name: 'ISENTO', value: 'ISENTO' },
      { name: 'IMUNE', value: 'IMUNE' },
    ] as Array<{ name: string; value: string }>,
    polling: [
      { name: 'COMPETÊNCIA', value: 'COMPETENCIA' },
      { name: 'ESTIMATIVA', value: 'ESTIMATIVA' },
      { name: 'CAIXA', value: 'CAIXA' },
      { name: 'TRIMESTRAL', value: 'TRIMESTRAL' },
      { name: 'ANUAL', value: 'ANUAL' },
      { name: 'OUTROS', value: 'OUTROS' },
    ] as Array<{ name: string; value: string }>,
    retreat: [
      { name: 'TRIBUTO E DOCUMENTO', value: 'document' },
      { name: 'TRIBUTO E FORNECEDOR', value: 'provider' },
      { name: 'ACUMULADO POR TRIBUTO', value: 'tax' },
    ] as Array<{ name: string; value: string }>,
    disableSelects: [true],
    loadings: { 0: false } as {[key: string]: any},
  }),
  computed: {
    inUpdate(): boolean {
      return this.formData !== undefined;
    },
    inUpdateCompanies(): boolean {
      return this.formData && (this.formData.inUpdateFrom === InUpdateFrom.Company);
    },
    inUpdateFrom(): string {
      return this.formData?.inUpdateFrom;
    },
    canDeleteItem(): boolean {
      return this.form.companies.length > 1;
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        this.$emit('input', form);
      },
    },
  },
  mounted() {
    if (this.formData) {
      this.form = {
        ...this.form,
        companyGroupId: this.formData.data.companyGroupId,
        groupName: this.formData.data.groupName,
      };
    }
  },
  methods: {
    async formValid(): Promise<boolean> {
      const form: any = this.$refs.form as VForm;
      return form.validate();
    },

    addNewCompany() {
      this.disableSelects.push(true);
      const key = this.form.companies.length;
      this.loadings = {
        [key]: false,
        ...this.loadings,
      };

      this.form.companies.push({
        nationalRegister: '',
        retreat: '',
        polling: '',
        taxRegime: '',
      });
    },

    deleteInfo(index: number) {
      console.log(index);
      if (index >= 0 && this.form.companies.length > 1) {
        this.disableSelects.splice(index, 1);
        this.form.companies.splice(index, 1);
        delete this.loadings[index];
      }
    },
    findCompanyDebounce: debounce(
      // eslint-disable-next-line func-names
      async function (companyIdx, event) {
        // @ts-ignore
        this.findCompany(companyIdx, event);
      },
      500,
    ),
    async findCompany(companyIdx: number, event: Event) {
      if (this.inUpdateCompanies) {
        const nationalRegisterMasked = (event.target as HTMLTextAreaElement).value;
        // eslint-disable-next-line no-useless-escape
        const nationalRegister = nationalRegisterMasked.replaceAll(/[_\./-]/g, '');

        if (nationalRegister?.length === 14) {
          if (companyIdx in this.loadings) {
            this.loadings[companyIdx] = true;
          }

          this.cleanCompany(companyIdx);

          const rootNationalRegister = nationalRegister.slice(0, 8);
          const hasCompany = Boolean(await this.getCompany(rootNationalRegister));
          this.disableSelects.splice(companyIdx, 1, hasCompany);

          if (companyIdx in this.loadings) {
            this.loadings[companyIdx] = false;
          }
        }
      }
    },
    cleanCompany(companyIdx: number) {
      this.form.companies[companyIdx] = {
        ...this.form.companies[companyIdx],
        retreat: '',
        polling: '',
        taxRegime: '',
      };
    },
    getCompany(nationalRegister: string): Promise<Company> {
      return CompanyService.findByNationalRegister(nationalRegister);
    },
    disableInUpdateCompanies(disable: boolean): boolean {
      return disable && this.inUpdateCompanies;
    },
  },
});
