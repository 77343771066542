var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "p-grid p-fluid" }, [
    _c("div", { staticClass: "p-col-6" }, [
      _c(
        "div",
        { staticClass: "p-field mb-0" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("username"),
              name: _vm.$t("username"),
              rules: "required",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "span",
                      { staticClass: "p-input-icon-left" },
                      [
                        _c("i", { staticClass: "icon-r-user" }),
                        _c("InputText", {
                          class: { "p-invalid": errors[0] },
                          attrs: { placeholder: _vm.$t("username") },
                          model: {
                            value: _vm.auth.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.auth, "username", $$v)
                            },
                            expression: "auth.username",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "p-col-6" }, [
      _c(
        "div",
        { staticClass: "p-field mb-0" },
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("password"),
              name: _vm.$t("password"),
              rules: "required",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("InputPassword", {
                      attrs: {
                        autocomplete: "off",
                        errors: errors[0],
                        placeholder: _vm.$t("password"),
                        "icon-left": true,
                      },
                      model: {
                        value: _vm.auth.userpass,
                        callback: function ($$v) {
                          _vm.$set(_vm.auth, "userpass", $$v)
                        },
                        expression: "auth.userpass",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }