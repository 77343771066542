var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "onboarding-bank table-container" }, [
    _c("table", { staticClass: "w-full" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.bookValue.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.bookValue.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.bookValue.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.CNPJOrigin.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.CNPJOrigin.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.CNPJOrigin.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.CNPJDestination.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.CNPJDestination.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.CNPJDestination.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.debitAccount.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.debitAccount.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.debitAccount.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.serviceCode.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.serviceCode.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.serviceLaunch.serviceCode.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }