var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "register-responsible" },
    [
      _c("RegisterResponsibleModal", {
        key: `modalKey${_vm.responsible.modalKey}`,
        attrs: {
          "show-modal": _vm.responsible.showModal,
          "self-responsible": _vm.responsible.selfResponsible,
        },
        on: {
          "update:showModal": function ($event) {
            return _vm.$set(_vm.responsible, "showModal", $event)
          },
          "update:show-modal": function ($event) {
            return _vm.$set(_vm.responsible, "showModal", $event)
          },
          "responsible:add": function ($event) {
            return _vm.addNewResponsible($event)
          },
          "responsible:update": function ($event) {
            return _vm.updateUserAdmin($event)
          },
        },
        model: {
          value: _vm.responsible.payload,
          callback: function ($$v) {
            _vm.$set(_vm.responsible, "payload", $$v)
          },
          expression: "responsible.payload",
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        [
          _c("Button", {
            staticClass: "p-button-raised",
            attrs: {
              id: "newResponsibleButton",
              icon: "icon-r-circle-plus bg-white",
              "icon-pos": "left",
              label: _vm.$t("onboarding.registerResponsible.newResponsible"),
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.responsible.showModal = true
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _vm.responsible.table.data.length === 0
          ? _c(
              "div",
              {
                staticClass: "card rounded-lg mt-4",
                staticStyle: { height: "282px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-center justify-content-center",
                    staticStyle: { height: "100%", color: "#03181E54" },
                  },
                  [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: require("@/common/assets/images/users.jpg"),
                          height: "106px",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "fs16" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "onboarding.registerResponsible.clickToRegister"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]
            )
          : _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c("ResponsibleTable", {
                  staticClass: "mt-4",
                  attrs: {
                    rows: _vm.responsible.table.data,
                    loading: _vm.responsible.table.loading,
                    "hide-checkbox": true,
                  },
                  on: {
                    "responsibletable:edit": function ($event) {
                      return _vm.editResponsible($event)
                    },
                    "responsibletable:delete": function ($event) {
                      return _vm.deleteResponsible($event)
                    },
                    "responsibletable:change": function ($event) {
                      return _vm.updateUserAdmin($event)
                    },
                    "responsibletable-products-change": function ($event) {
                      return _vm.updateProductsUserAdmin($event)
                    },
                  },
                }),
              ],
              1
            ),
      ]),
      _c("StepButtons", {
        attrs: { "visible-jump": false, "disable-next": _vm.disableNext },
        on: {
          next: function ($event) {
            return _vm.nextStep()
          },
          back: _vm.backStep,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }