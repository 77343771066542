import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  menu: {
    headerBar: {
      btnGoToMagicFlow: 'Acessar a Esteira',
      myData: 'Meus dados',
    },
    sideBar: {
      manageAccount: 'Gerenciar Conta ROIT',
      myProducts: 'Meus Produtos',
      myCompanies: 'Minhas Empresas',
      users: 'Usuários',
      digitalCertificates: 'Certificados Digitais',
      integrations: 'Integrações',
      billing: 'Faturamento',
      accessPass: 'Senhas de Acessos',
      taxInvoiceInbound: 'Baixas Automáticas',
      taskManager: 'Gerenciador de Tarefas',
    },
  },
};

export const es = {
  menu: {
    headerBar: {
      btnGoToMagicFlow: 'Acceder a la cinta de correr',
      myData: 'Mis datos',
    },
    sideBar: {
      manageAccount: 'Gestionar cuenta ROIT',
      myProducts: 'Mis Productos',
      myCompanies: 'Mis Empresas',
      users: 'Usuarios',
      digitalCertificates: 'Certificados Digitales',
      integrations: 'Integraciones',
      billing: 'Facturación',
      accessPass: 'Contraseñas de Acceso',
      taxInvoiceInbound: 'Deducciones Automáticas',
      taskManager: 'Task Manager',
    },
  },
};

export const en = {
  menu: {
    headerBar: {
      btnGoToMagicFlow: 'Access the MagicFlow',
      myData: 'Access ',
    },
    sideBar: {
      manageAccount: 'Manage ROIT Account',
      myProducts: 'My Products',
      myCompanies: 'My Companies',
      users: 'Users',
      digitalCertificates: 'Digital Certificates',
      integrations: 'Integrations',
      billing: 'Billing',
      accessPass: 'Access Passwords',
      taxInvoiceInbound: 'Automatic Deductions',
      taskManager: 'Gerente de tareas',
    },
  },
};
const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
