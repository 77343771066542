export default [
  {
    path: '/my-companies',
    name: 'my-companies-page',
    component: () => import(/* webpackChunkName: "my-companies" */ '@/resources/my-companies/MyCompaniesPage.vue'),
  },
  {
    path: '/my-companies/:companyId',
    name: 'company-table',
    component: () => import(/* webpackChunkName: "my-companies" */ '@/resources/my-companies/CompanyTablePage.vue'),
  },
];
