
import Vue from 'vue';

export default Vue.extend({
  name: 'ParentCompanyModalNotFound',
  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },
    messageErrors: {
      type: Array as () => { message: string }[],
      default: () => [],
    },
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },
  },
  watch: {
    messageErrors(msn: any[]) {
      if (msn && msn.length > 0) {
        const toFind = 'notFoundParentCompany';

        const notFoundParentCompany = msn.find((item) => item.message === toFind);

        if (notFoundParentCompany) {
          // Open alert modal
          this.$emit('update:control', true);
        }
      }
    },
  },
});
