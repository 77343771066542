
import Vue from 'vue';
import { ValidationResult } from 'vee-validate/dist/types/types.d';

export default Vue.extend({
  name: 'InputValidation',
  props: {
    label: {
      type: String,
      required: false,
      default: undefined,
    },

    vid: {
      type: String,
      required: false,
      default: '',
    },

    name: {
      type: String,
      required: false,
      default: '',
    },

    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },

    customMessages: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    immediate: {
      type: Boolean,
      required: false,
      default: false,
    },

    mode: {
      type: String,
      required: false,
      default: 'aggressive',
    },

    tag: {
      type: String,
      required: false,
      default: 'span',
    },

    slim: {
      type: Boolean,
      required: false,
      default: false,
    },

    debounce: {
      type: Number,
      required: false,
      default: 0,
    },

    persist: {
      type: Boolean,
      required: false,
      default: false,
    },

    bails: {
      type: Boolean,
      required: false,
      default: true,
    },

    classInvalidText: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  methods: {
    validate(value?: any): Promise<ValidationResult> {
      return (this.$refs as any).validationProvider.validate(value);
    },
    validateSilent(): Promise<ValidationResult> {
      return (this.$refs as any).validationProvider.validateSilent();
    },
    applyResult(value: ValidationResult): void {
      return (this.$refs as any).validationProvider.applyResult(value);
    },
    reset(): void {
      return (this.$refs as any).validationProvider.reset();
    },
    setFlags(value: any): void {
      return (this.$refs as any).validationProvider.setFlags(value);
    },
    setErrors(value: string[]): void {
      return (this.$refs as any).validationProvider.setErrors(value);
    },
    syncValue(value?: any): void {
      return (this.$refs as any).validationProvider.syncValue(value);
    },
  },
});
