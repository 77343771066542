import axios, { CancelTokenSource } from '@/common/http';
import {
  CompanyGroup,
  PaginationSerializer,
  PatchCompanyGroup,
  SelectOption,
} from '@/types';
import { CompanyInformation } from '../components/companyGroup/types';
import EmployeeService from './EmployeeService';

class CompanyGroupService {
  private service: typeof axios = axios;

  private cancelTS?: CancelTokenSource;

  private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/company-groups`;

  private rebornUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1`;

  private cancelToken(): CancelTokenSource {
    if (this.cancelTS) {
      this.cancelTS.cancel();
    }

    return this.service.CancelToken.source();
  }

  public findCompaniesGrouped(search?: string) {
    let { baseUrl } = this;

    if (search) {
      baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
    }

    this.cancelTS = this.cancelToken();
    return this.service.get(baseUrl, {
      cancelToken: this.cancelTS.token,
    });
  }

  public findCompaniesWithoutGroup() {
    return this.service.get(`${this.rebornUrl}/company/master/find-by-master`);
  }

  public async createCompanyGroup(
    companyGroup: Omit<CompanyGroup, 'id' | 'companies'> & { companiesInformation: CompanyInformation[] },
  ): Promise<CompanyGroup> {
    const { data: result } = await this.service.post(`${this.rebornUrl}/company-group/company-group-registration`, companyGroup);
    return result.data;
  }

  public async companiesRegistrations(
    companyGroupId: string,
    payload: { companies: CompanyInformation[] },
  ) {
    const uri = `${this.rebornUrl}/company-group/${companyGroupId}/companies-registration`;
    const { data: result } = await this.service.post(uri, payload);
    return result.data;
  }

  public async companyGroupNameUpgrade(companyGroupId: string, name: string) {
    const uri = `${this.rebornUrl}/company-group/${companyGroupId}/name`;
    const { data: result } = await this.service.put(uri, { name });
    return result.data;
  }

  public async createGroupCompanies(companyGroup: CompanyGroup) {
    const employee = await EmployeeService.me();
    const payload: CompanyGroup = companyGroup;
    payload.master = employee.master;

    const result = this.service.post(`${this.rebornUrl}/company-group`, payload);
    const groupInfo = (await result).data.data;

    // eslint-disable-next-line max-len
    const patchCompanyGroup: PatchCompanyGroup = { id: employee.id, companyGroupIds: [groupInfo.id] };

    await EmployeeService.addCompanyGroup(patchCompanyGroup);
    return result;
  }

  public updateGroupCompanies(groupName: string, cnpjs: Array<string>) {
    return this.service.put(`${this.baseUrl}/${groupName}`, { cnpjs });
  }

  public async findByLoggedUser(): Promise<CompanyGroup[]> {
    const result = await this.service.get(`${this.rebornUrl}/company-group`);
    return result.data.data;
  }

  public async selectOptions(): Promise<Array<CompanyGroup & SelectOption>> {
    const companiesGroup = await this.findByLoggedUser();

    return companiesGroup
      .filter((item: CompanyGroup) => item.name)
      .map((comp: CompanyGroup) => ({
        ...comp,
        name: comp.id,
        optionValue: comp.id,
        optionLabel: comp.name,
      }));
  }

  public async findByMaster(
    masterId: string,
    paginationSerializer?: PaginationSerializer,
  ): Promise<CompanyGroup[]> {
    const url = new URL(`${this.rebornUrl}/company-group/master/${masterId}`);

    if (paginationSerializer) {
      const { perPage, page, serializer } = paginationSerializer;
      if (perPage !== undefined && page !== undefined) {
        url.searchParams.append('page', String(page));
        url.searchParams.append('perPage', String(perPage));
      }
      if (serializer) {
        url.searchParams.append('serializer', serializer);
      }
    }
    const result = await this.service.get(url.toString());
    return result.data.data;
  }
}

export default new CompanyGroupService();
