import ConvertFile from '@/common/classes/ConvertFile';
import axios, { CancelTokenSource } from '@/common/http';
import {
  AddTaxGuide, AddTaxRegime, SelectOption, UpdateCompanyGroup,
} from '@/types';
import { Company, Master } from '@roit-intern/roit-company-interfaces';

interface Header {
  title: string;
  value: string;
}

class CompanyService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/companies`;

  private rebornBaseUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1/company`;

  private cancelTokenSource?: CancelTokenSource;

  public findCompanies(search = '') {
    let { rebornBaseUrl } = this;

    if (search) {
      rebornBaseUrl = rebornBaseUrl.concat(`?search=${encodeURIComponent(search)}`);
    }

    return this.service.get(rebornBaseUrl);
  }

  public createCompany(payload: Company[]) {
    return this.service.post(this.baseUrl, {
      companies: payload,
    });
  }

  public updateCompany(cnpj: string, payload: Company) {
    return this.service.put(`${this.baseUrl}/${cnpj}`, payload);
  }

  public deleteCompany(cnpj: string) {
    return this.service.delete(`${this.baseUrl}/${cnpj}`);
  }

  public deleteCompanies(cnpjs: Array<string>) {
    return this.service.post(`${this.baseUrl}/delete`, { cnpjs });
  }

  public importCompanies(file: File) {
    return ConvertFile.toBase64(file)
      .then((base64) => this.service.post(`${this.baseUrl}/import`, { file: base64 }));
  }

  public findCompaniesGroups(search = '') {
    let baseUrl = `${this.baseUrl}/groups`;

    if (search) {
      baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
    }

    return this.service.get(baseUrl);
  }

  public updateCompanyGroup(companyGroup: string, payload: UpdateCompanyGroup) {
    return this.service.put(`${this.baseUrl}/groups/${companyGroup}`, payload);
  }

  public addTaxRegime(payload: AddTaxRegime) {
    const url = `${this.rebornBaseUrl}/add-tax-regime`;

    return this.service.post(
      url,
      payload,
      this.getHeader(),
    );
  }

  public addTaxGuide(payload: AddTaxGuide) {
    const url = `${this.rebornBaseUrl}/add-tax-guide`;

    return this.service.patch(
      url,
      payload,
      this.getHeader(),
    );
  }

  private getHeader(header?: Header) {
    this.cancelTokenSource = this.cancelToken();

    if (header) {
      return {
        headers: {
          cancelToken: this.cancelTokenSource.token,
          header,
        },
      };
    }

    return {
      cancelToken: this.cancelTokenSource.token,
    };
  }

  private cancelToken(): CancelTokenSource {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
    return this.service.CancelToken.source();
  }

  public async selectOptions(): Promise<Array<Company & SelectOption>> {
    const { data: response } = await this.findCompanies();

    return response.data
      .filter((item: Company) => item?.companyName)
      .map((comp: Company) => {
        const partialCnpj = comp.nationalRegister
          .substring(comp.nationalRegister.length - 6)
          .replace(/(\d{4})(\d{2})/g, '$1-$2');

        return {
          ...comp,
          name: `${comp.companyName} (${partialCnpj})`,
          optionValue: comp.id,
          optionLabel: `${comp.companyName} (${partialCnpj})`,
        };
      });
  }

  public importCompaniesFromFile(file: File) {
    return ConvertFile.toBase64(file)
      .then((base64) => this.service.post(
        `${this.rebornBaseUrl}/import`,
        { file: base64 },
      ));
  }

  async createCompanyWithEstablishment(
    company: { groupName: string; nationalRegisters: string[] },
    master: string,
  ) {
    const companies = company.nationalRegisters.map((nationalRegister) => ({
      nationalRegister,
      master,
    }));

    const baseUrl = `${this.rebornBaseUrl}/create-with-establishment-bulk`;
    const header = this.getHeader();

    return this.service.post(baseUrl,
      { groupName: company.groupName, companies },
      header);
  }

  public findByMaster() {
    return this.service.get(`${this.rebornBaseUrl}/master/find-by-master`);
  }

  public async selectCompanyOptions(): Promise<Array<Company & SelectOption>> {
    const { data: response } = await this.findByMaster();

    return response.data
      .filter((item: Company) => item.companyName)
      .map((comp: Company) => ({
        ...comp,
        name: comp.companyId,
        optionValue: comp.id,
        optionLabel: comp.companyName,
      }));
  }

  public async masterInformation(): Promise<Master> {
    const baseUrl = `${this.rebornBaseUrl}/master-company-information`;

    return (await this.service.get(baseUrl)).data.data;
  }

  public async findByNationalRegister(rootNationalRegister: string): Promise<Company> {
    return (await this.service.get(`${this.rebornBaseUrl}/root/${rootNationalRegister}`)).data.data;
  }

  public async transferCompanies(companiesIds: string[], destinyGroupId: string) {
    this.service.patch(`${this.rebornBaseUrl}/transfer-companies`, { companiesIds, destinyGroupId });
  }
}

export default new CompanyService();
