import axios, { CancelTokenSource } from '@/common/http';
import { PatchCompanyGroup } from '@/types';

interface Header {
  title: string;
  value: string;
}

class EmployeeService {
  private service: typeof axios = axios;

  private cancelTokenSource?: CancelTokenSource;

  private baseUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1/employee`;

  private getHeader(header?: Header) {
    this.cancelTokenSource = this.cancelToken();

    if (header) {
      return {
        cancelToken: this.cancelTokenSource.token,
        headers: {
          header: {
            ...header,
          },
        },
      };
    }

    return {
      cancelToken: this.cancelTokenSource.token,
    };
  }

  private cancelToken(): CancelTokenSource {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
    return this.service.CancelToken.source();
  }

  async addCompanyGroup(patchCompanyGroup: PatchCompanyGroup): Promise<void> {
    const baseUrl = `${this.baseUrl}/add-company-group`;
    await this.service.patch(baseUrl, patchCompanyGroup, this.getHeader());
  }

  async me(): Promise<any> {
    const baseUrl = `${this.baseUrl}/me`;
    const result = await this.service.get(baseUrl, this.getHeader());
    return result.data.data;
  }
}

export default new EmployeeService();
