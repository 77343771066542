import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  myCompanies: {
    title: 'Grupos e Empresas',
    newRegister: 'Cadastrar Grupo',
    transferEstablishment: 'Trânsferencia entre Grupos',
    paginatorLabel: 'empresas matrizes',
    filterGroupLabel: 'Buscar empresa pelo nome',
    groupCompany: {
      title: 'Agrupar Empresas',
      registerGroup: {
        msgSuccess: 'Grupo de empresas criado com sucesso!',
        msgWarning: 'Selecione pelo menos uma empresa para agrupar',
        msgError: 'Não foi possível registrar o grupo de empresas',
      },
      updateGroup: {
        msgSuccess: 'Grupo de empresas atualizado com sucesso!',
        msgWarning: 'Deve haver pelo menos uma empresa no grupo para atualizar',
        msgError: 'Não foi possível atualizar o grupo de empresas',
      },
      deleteGroup: {
        title: 'Excluir Grupo',
        message: 'Confirma a exclusão deste Grupo?',
        msgSuccess: 'Grupo de empresas excluído com sucesso!',
        msgError: 'Não foi possível excluir o grupo de empresas',
      },
      withoutGroup: {
        msgInfo: 'Não foram encontradas empresas sem grupo',
        msgError: 'Não foi possível buscar as empresas',
      },
    },
    group: 'Grupo',
    groupCompanySubTitle: 'Crie um Grupo Empresarial e selecione as Empresas para agrupá-las.',
    inputTitle: 'Nome do Grupo',
    inputPlaceholder: 'Defina um nome para o Grupo',
    searchCompany: 'Buscar Empresa',
    companiesGrouped: {
      msgError: 'Não foi possível buscar os Grupos de Empresas',
    },
    processingWarning: {
      title: 'Cadastro realizado!',
      message: 'Encontra-se neste momento em processamento, aguarde alguns instantes e recarregue a página',
    },
    modals: {
      addCompanyModal: {
        title: 'Cadastrar Empresas',
        firstTab: {
          title: 'Cadastrar Empresa (CNPJ)',
          body: 'Informe abaixo o(s) CNPJ(s) da(s) empresa(s) que deseja cadastrar:',
        },
        secondTab: {
          title: 'Importar Empresas (em lote)',
          body: 'Para importar suas empresas, baixe o template padrão, preencha-o e arraste-o para o campo abaixo:',
          dropzone: 'Arraste o template aqui ou clique para selecioná-lo.',
          dropzoneEmpty: 'Nenhum template adicionado para importação',
        },
      },
      notFoundParentCompany: {
        title: 'Atenção!',
        message: 'Empresa não cadastrada, o CNPJ informado é de uma filial e sua matriz não existe em nossa base de dados.',
      },
      transfer: {
        transfer: 'Transferir',
        header: 'Transferência entre Grupos',
        description: 'Selecione os grupos de origem e destino abaixo para realizar as transferências.',
        groupOrigin: 'Grupo de Origem',
        groupDestiny: 'Grupo de Destino',
        selectPlaceholder: 'Selecione um Grupo',
        emptyFilterMsg: 'Nenhuma empresa encontrada',
        filterPlaceholder: 'Buscar Empresa',
        selectBothGroups: 'Selecione os grupos de origem e destino',
        errorTransfer: 'Erro ao transferir as empresas, tente novamente',
        confirm: {
          header: 'Atenção!!',
          message: 'Caso mude a empresa de destino todas as mudanças serão perdidas',
          acceptLabel: 'Confirmar',
          rejectLabel: 'Cancelar',
        },
      },
      createGroup: {
        header: 'Cadastrar grupo e empresa matriz',
        description: 'Para cadastrar um grupo, você também precisa cadastrar pelo menos uma empresa matriz. As empresas filiais serão cadastradas automaticamente.',
        headOfficeData: 'Dados da empresa matriz',
        addEstablishment: 'Adicionar empresa',
        groupData: 'Dados do grupo',
        groupName: 'Nome do  grupo',
        countryFrom: 'País de origem',
        establishmentType: 'Tipo de empresa',
        naturalPerson: 'Pessoa Física',
        artificialPerson: 'Pessoa Jurídica',
        tributaryRegime: 'Regime Tributário',
        retreat: 'Recolhimento',
        polling: 'Apuração',
        fullName: 'Nome completo',
        companyName: 'Nome da empresa',
        taxIdentification: 'Identificação Fiscal',
        registerAlreadyExists: 'Já existe um registro com os dados informados.',
      },
      confirmCreateGroup: {
        header: 'Confirmar Cadastro',
        description: 'Você está cadastrando um novo grupo, verifique o país de origem antes de confirmar o cadastro.',
        groupName: 'Nome do Grupo',
        countryFrom: 'País de Origem',
        warningMsg: 'Após realizar o cadastro de um grupo, não será possível alterar o país de origem, deletar o grupo e mover as empresas cadastradas para um grupo com um país de origem diferente.',
        correct: 'Corrigir',
      },
    },
    groupCardTable: {
      expandablePaginatorLabel: 'empresas filiais',
      brazil: {
        nationalRegister: 'CNPJ/CPF',
        name: 'Razão Social/Nome',
        location: 'Localização',
        municipalRegistration: 'Inscrição Muni.',
        municipalRegistrationFull: 'Inscrição Municipal',
        stateRegistration: 'Inscrição Esta.',
        stateRegistrationFull: 'Inscrição Estadual',
        taxRegime: 'Regime Tributário',
        assessment: 'Apuração',
        retreat: 'Recolhimento',
        statusRfb: 'Status RFB',
        expandable: {
          nationalRegister: 'CNPJ/CPF Filial',
          name: 'Razão Social/Nome Filial',
          location: 'Localização da Filial',
          municipalRegistration: 'Inscrição Municipal',
          stateRegistration: 'Inscrição Estadual',
        },
        notifications: {
          failToUpdateMunicipalRegistration: 'Falha ao atualizar a inscrição municipal',
          failToUpdateStateRegistration: 'Falha ao atualizar a inscrição estadual',
          failToUpdateTaxRegime: 'Falha ao atualizar o regime tributário',
          failToUpdatePolling: 'Falha ao atualizar a apuração',
          failToUpdateTaxGuide: 'Falha ao atualizar o recolhimento',
        },
      },
      foreign: {
        nationalRegister: 'Registro Nacional',
        name: 'Nome',
        location: 'Localização',
        expandable: {
          nationalRegister: 'Registro Nacional da Filial',
          name: 'Nome da Filial',
          location: 'Localização da Filial',
        },
      },
    },
    notifications: {
      failToGetCompanies: 'Falha ao pegar as empresas matrizes',
      failToGetBranchs: 'Falha ao pegar as filiais',
      failToRegisterGroup: 'Falha ao registrar o grupo',
      failToAddCompany: 'Falha ao adicionar a empresa matriz',
      failToAddCompanyInvalidType: 'No momento não é possível adicionar pessoa física a este grupo.',
    },
  },
};

export const es = {
  myCompanies: {
    title: 'Consulta tus Grupos y Empresas o realiza nuevas altas:',
    newRegister: 'Registrar grupo',
    transferEstablishment: 'Transferencia entre Grupos',
    groupCompany: {
      title: 'Empresas del grupo',
      registerGroup: {
        msgSuccess: '¡Grupo de empresas creado con éxito!',
        msgWarning: 'Seleccione al menos una empresa para agrupar',
        msgError: 'No se puede registrar el grupo de empresas',
      },
      updateGroup: {
        msgSuccess: '¡Grupo de empresas actualizado con éxito!',
        msgWarning: 'Debe haber al menos una empresa en el grupo para actualizar',
        msgError: 'No se pudo actualizar el grupo de empresas',
      },
      deleteGroup: {
        title: 'Eliminar grupo',
        message: '¿Confirmas la eliminación de este grupo?',
        msgSuccess: '¡Grupo de empresas eliminado con éxito!',
        msgError: 'No se pudo eliminar el grupo de empresas',
      },
      withoutGroup: {
        msgInfo: 'No se encontraron empresas sin grupo',
        msgError: 'No se pueden buscar empresas',
      },
    },
    group: 'Grupo',
    groupCompanySubTitle: 'Crea un Grupo de Empresas y selecciona las Empresas para agruparlas.',
    inputTitle: 'Nombre del grupo',
    inputPlaceholder: 'Establecer un nombre para el grupo',
    searchCompany: 'Buscar empresa',
    companiesGrouped: {
      msgError: 'No se pueden recuperar los grupos de empresas',
    },
    processingWarning: {
      title: '¡Registro completado!',
      message: 'Actualmente se está procesando, espere unos momentos y vuelva a cargar la página',
    },
    modals: {
      addCompanyModal: {
        title: 'Registro de Empresas',
        firstTab: {
          title: 'Registrar Empresa (CNPJ)',
          body: 'Ingrese a continuación el/los CNPJ(s) de la(s) empresa(s) que desea registrar:',
        },
        secondTab: {
          title: 'Importar Empresas (Lote)',
          body: 'Para importar sus empresas, descargue la plantilla predeterminada, complétela y arrástrela al campo a continuación:',
          dropzone: 'Arrastra la plantilla aquí o haz clic para seleccionarla.',
          dropzoneEmpty: 'No se agregó ninguna plantilla para importar',
        },
      },
      notFoundParentCompany: {
        title: '¡Atención!',
        message: 'Empresa no registrada, el CNPJ informado es de una sucursal y su casa matriz no existe en nuestra base de datos.',
      },
      transfer: {
        transfer: 'Transferir',
        header: 'Transferencia entre grupos',
        description: 'Seleccione los grupos de origen y destino a continuación para realizar las transferencias.',
        groupOrigin: 'Origen del grupo',
        groupDestiny: 'Destino del grupo',
        selectPlaceholder: 'Seleccionar un grupo',
        emptyFilterMsg: 'No se encontró ninguna empresa',
        filterPlaceholder: 'Buscar empresa',
        selectBothGroups: 'Seleccionar grupos de origen y destino',
        errorTransfer: 'Error al transferir empresas, inténtalo de nuevo',
        confirm: {
          header: '¡Atención!',
          message: 'Si cambia la empresa de destino, se perderán todos los cambios',
          acceptLabel: 'Confirmar',
          rejectLabel: 'Cancelar',
        },
      },
    },
  },
};

export const en = {
  myCompanies: {
    title: 'Confirm your Groups and Companies or make new registrations:',
    newRegister: 'Register Group',
    transferEstablishment: 'Transfer Between Groups',
    groupCompany: {
      title: 'Group Companies',
      registerGroup: {
        msgSuccess: 'Group of companies created with success!',
        msgWarning: 'Select at least one company to group',
        msgError: 'It was not possible to register or group of companies',
      },
      updateGroup: {
        msgSuccess: 'Company group updated with success!',
        msgWarning: 'There must be at least one company or group to update',
        msgError: 'It was not possible to update the group of companies',
      },
      deleteGroup: {
        title: 'Exclude Group',
        message: 'Confirm exclusion from this Group?',
        msgSuccess: 'Company group excluded with success!',
        msgError: 'It was not possible to exclude the group of companies',
      },
      withoutGroup: {
        msgInfo: 'No foram found companies without group',
        msgError: 'It was not possible to search for companies',
      },
    },
    group: 'Group',
    groupCompanySubTitle: 'Create a Business Group and select the Companies to group them.',
    inputTitle: 'Group Name',
    inputPlaceholder: 'Define a name for the Group',
    searchCompany: 'Search Company',
    companiesGrouped: {
      msgError: 'It was not possible to search for the Company Groups',
    },
    processingWarning: {
      title: 'Cadastre done!',
      message: 'This moment is found in the process, wait a few moments and reload the page',
    },
    modals: {
      addCompanyModal: {
        title: 'Register Companies',
        firstTab: {
          title: 'Cadastrar Empresa (CNPJ)',
          body: 'Report below or CNPJ(s) gives(s) company(ies) that wishes to register:',
        },
        secondTab: {
          title: 'Import Companies (in batch)',
          body: 'To import your companies, scroll down to the default template, click and drag to the field below:',
          dropzone: 'Drag or template here or click to select it.',
          dropzoneEmpty: 'Nenhum template added for importation',
        },
      },
      notFoundParentCompany: {
        title: 'Attention!',
        message: 'Company not registered, or CNPJ informed of a subsidiary and its parent company does not exist in our data base.',
      },
      transfer: {
        transfer: 'Transfer',
        header: 'Transfer between Groups',
        description: 'Select the source and destination groups below to make the transfers.',
        groupOrigin: 'Group Origin',
        groupDestiny: 'Group Destination',
        selectPlaceholder: 'Select a Group',
        emptyFilterMsg: 'Nenhuma company found',
        filterPlaceholder: 'Search Company',
        selectBothGroups: 'Select the source and destination groups',
        errorTransfer: 'Error transferring the companies, try again',
        confirm: {
          header: 'Attention!!',
          message: 'If you move to the destination company, all the moves will be lost',
          acceptLabel: 'Confirm',
          rejectLabel: 'Cancel',
        },
      },
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
