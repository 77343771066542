
import Vue from 'vue';
import { Option, SapB1Params } from '../../../types';

type Parameter = SapB1Params['parameter']

export default Vue.extend({
  name: 'SapB1ParametersSectionForm',
  props: {
    systemParameters: {
      type: Object as () => SapB1Params,
      required: true,
    },
  },
  data() {
    const {
      sendPurchaseOrderEvery,
      launchInvoiceEvery,
      sendAccountsChartEvery,
      sendSupplierRegistrationEvery,
      sendUmbrellaContractEvery,
      paymentEvery,
      sendTaxCodeEvery,
    } = this.systemParameters.parameter || {};

    return {
      parameter: {
        sendPurchaseOrderEvery,
        launchInvoiceEvery,
        sendAccountsChartEvery,
        sendSupplierRegistrationEvery,
        sendUmbrellaContractEvery,
        paymentEvery,
        sendTaxCodeEvery,
      },
    };
  },
  computed: {
    eachAnyMinuteOptions(): Option[] {
      return [
        {
          value: '300',
          label: `${this.$t('every')} 5 ${this.$t('minutes')}`,
        },
        {
          value: '600',
          label: `${this.$t('every')} 10 ${this.$t('minutes')}`,
        },
        {
          value: '900',
          label: `${this.$t('every')} 15 ${this.$t('minutes')}`,
        },
        {
          value: '1800',
          label: `${this.$t('every')} 30 ${this.$t('minutes')}`,
        },
        {
          value: '2700',
          label: `${this.$t('every')} 45 ${this.$t('minutes')}`,
        },
        {
          value: '3600',
          label: `${this.$t('every')} 1 ${this.$t('hour')}`,
        },
      ];
    },
  },
  watch: {
    parameter: {
      deep: true,
      handler(parameter: Parameter) {
        this.$emit('update:systemParameters', { ...this.systemParameters, parameter, __type: 'SapB1Params' });
      },
    },
  },
});
