
import mixins from 'vue-typed-mixins';
import SupportInternationalization from '@/resources/onboarding-bank/components/SupportInternationalization.vue';
import OnboardMixin from '@/resources/onboarding-bank/OnboardBankMixin';
import OnboardingButtons from '@/resources/onboarding-bank/components/OnboardingButtons.vue';

export default mixins(OnboardMixin).extend({
  name: 'Onboarding',
  components: { SupportInternationalization, OnboardingButtons },
  data: () => ({
    formObject: {},
    items: [
      {
        label: 'Vídeo Instrutivo',
        class: 'steps first-step',
        to: '/onboarding-bank/welcome-video',
      },
      {
        label: 'Robô Fiscal',
        class: 'steps middle-step',
        to: '/onboarding-bank/tax-robot',
      },
      {
        label: 'Robô Contábil',
        class: 'steps last-step full',
        to: '/onboarding-bank/accountant-robot',
      },
    ],
  }),
  mounted() {
    (this.$refs.steps as any).$watch(
      'activeRoute',
      (currentRoute: string) => {
        const index = this.items.findIndex((item) => item.to === currentRoute);

        if (index !== -1) {
          const items = [...this.items];
          items.forEach((item, idx) => {
            if (idx === index) {
              const classes = item.class.split(' ');
              const completedIndex = classes.findIndex((itemClass) => itemClass === 'completed-step');

              if (completedIndex !== -1) {
                classes.splice(completedIndex, 1);
                // eslint-disable-next-line no-param-reassign
                item.class = classes.join(' ');
              }
            }
            if (idx < index) {
              if (!item.class.includes('completed-step')) {
                // eslint-disable-next-line no-param-reassign
                item.class += ' completed-step';
              }
            }
          });
        }
      },
      { immediate: true },
    );
  },
});
