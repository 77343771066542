var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          class: { dialog: true },
          style: { maxWidth: "800px", minWidth: "500px" },
          attrs: { visible: _vm.displayModal, modal: true },
          on: {
            "update:visible": function ($event) {
              _vm.displayModal = $event
            },
          },
          scopedSlots: _vm._u(
            [
              !_vm.isUpdating
                ? {
                    key: "header",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "companyGroupComponent.modal.title.registerGroupCompany"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : _vm.isInsertingCompanies
                ? {
                    key: "header",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "companyGroupComponent.modal.title.addCompaniesToGroup"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : {
                    key: "header",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "companyGroupComponent.modal.title.editGroup"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("Button", {
                      staticClass: "outlined",
                      attrs: { label: _vm.$t("cancel") },
                      on: {
                        click: function ($event) {
                          _vm.displayModal = false
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-raised mr-0",
                      attrs: {
                        label: _vm.isUpdating
                          ? _vm.$t("save")
                          : _vm.$t("register"),
                      },
                      on: { click: _vm.confirm },
                    }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _c("CompanyGroupForm", {
            ref: "companyGroupForm",
            style: { padding: "0.75rem 0 0.25rem 0" },
            attrs: { "form-data": _vm.editData },
            on: {
              validForm: function ($event) {
                _vm.addCompanyIsValid = $event
              },
            },
            model: {
              value: _vm.payload.companyGroup,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "companyGroup", $$v)
              },
              expression: "payload.companyGroup",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }