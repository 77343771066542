
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import { debounce } from 'lodash';
import EditableCompanyGroupAccordion from '@/common/components/companyGroup/action/EditableCompanyGroupAccordion.vue';
import OnboardMixin from '@/resources/onboarding/OnboardMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import StepButtons from '@/resources/onboarding/components/StepButtons.vue';
import CompaniesMessage from '@/resources/my-companies/components/CompaniesMessage.vue';
import EstablishmentService from '@/common/services/EstablishmentService';
import CompanyGroupMixin from '@/mixins/CompanyGroupMixin';
import { EstablishmentsGrouped, CompanyGroupsEstablishment } from '@/types';
import { CreateCompanyGroupPayload } from '@/common/components/companyGroup/types';
import locales from './locales';

export default mixins(OnboardMixin, NotificationMixin, CompanyGroupMixin).extend({
  name: 'StepCompanies',

  i18n: {
    messages: locales,
  },

  components: {
    StepButtons,
    CompaniesMessage,
    EditableCompanyGroupAccordion,
  },

  data: () => ({
    search: '',
    tableLoading: false as boolean,
    showingCompanyGroupModal: false as boolean,
    tableKey: 0,
    modalKey: 0,
    companyGroups: [] as EstablishmentsGrouped[],
  }),

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),

    establishments(): CompanyGroupsEstablishment[] {
      return this.companyGroups
        .reduce(
          (prev, curr) => ([...curr.establishments, ...prev]),
          [] as CompanyGroupsEstablishment[],
        );
    },

    disableNext(): boolean {
      const establishment = this.establishments
        .find((item) => !item?.company?.taxRegime?.type
            || !item?.company?.taxRegime?.assessment
            || item.company.taxRegime.type === ''
            || item.company.taxRegime.assessment === '');

      return this.establishments.length === 0 || establishment !== undefined;
    },
  },

  watch: {
    token(token: string): void {
      if (token && this.userLoggedIn) {
        this.getEstablishments();
      }
    },
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    async getEstablishments(search = '') {
      try {
        this.handleLoading(true);
        this.tableLoading = true;

        const { data: { data } } = await EstablishmentService.findEstablishmentsGrouped(search);

        if (Array.isArray(data)) {
          this.companyGroups = data;
        }
      } catch (e) {
        this.errorToast({
          text: this.$t('onboarding.registerCompany.table.listMsgError') as string,
        });
      } finally {
        this.handleLoading(false);
        this.tableLoading = false;
      }
    },

    toggleAddCompanyModal(): void {
      this.modalKey += 1;
      this.showingCompanyGroupModal = true;
    },

    async groupCompanyRegistration(groupCompanies: CreateCompanyGroupPayload): Promise<void> {
      this.handleLoading(true);

      await this.companyGroupRegistration(groupCompanies);

      this.modalKey += 1;
      this.showingCompanyGroupModal = false;

      this.handleLoading(false);

      await this.getEstablishments();
    },

    async establishmentLoaded(file: File): Promise<void> {
      await this.registerCompaniesLoaded(file);

      this.modalKey += 1;
      this.showingCompanyGroupModal = false;

      await this.getEstablishments();
    },

    async establishmentSearch(): Promise<void> {
      try {
        const { valid } = await (this.$refs.search as any).validate();

        if (
          (valid || this.search.length >= 2)
          && this.search.length >= 2
          && this.search.length <= 40
        ) {
          this.searchable();
        } else if (this.search === '') {
          this.searchable();
        }
      } catch (e) {
        this.errorToast({
          text: this.$t('onboarding.registerCompany.table.errorSearch') as string,
        });
      }
    },

    searchable(): void {
      debounce(
        async () => {
          this.tableLoading = true;
          await this.getEstablishments(this.search);
          this.tableLoading = false;
        },
        1000,
      )();
    },
  },
});
