var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-content-between align-items-center" },
    [
      _vm.visibleBack
        ? _vm._t("back", function () {
            return [
              _c("Button", {
                staticClass: "outlined back-step on-bank-back",
                attrs: {
                  id: "stepBackButton",
                  label: _vm.$t("onboarding.stepButtons.back"),
                  "icon-pos": "left",
                  icon: "icon-r-chevron-left",
                },
                on: { click: _vm.previousStep },
              }),
            ]
          })
        : _vm._e(),
      _c("div", { staticClass: "flex-grow-1" }),
      _vm.visibleNext
        ? _vm._t("next", function () {
            return [
              _c("Button", {
                staticClass:
                  "p-button-raised next-step on-bank-next white-button-icon",
                attrs: {
                  id: "stepNextButton",
                  label: _vm.$t("onboarding.stepButtons.nextStep"),
                  "icon-pos": "right",
                  icon: "icon-r-chevron-right",
                },
                on: { click: _vm.nextStep },
              }),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }