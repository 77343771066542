export default [
  // {
  //   path: '/integrations',
  //   name: 'integrations',
  //   redirect: { name: 'integrations' },
  //   component: () => import(
  //     /* webpackChunkName: "integrations" */
  //     '@/layouts/Default.vue',
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       name: 'integrations',
  //       component: () => import(
  //         /* webpackChunkName: "integrations" */
  //         '@/resources/integrations/Integrations.vue',
  //       ),
  //     },
  //   ],
  // },
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import(/* webpackChunkName: "integrations" */'@/resources/integrations/Integrations.vue'),
  },
];
