var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("fieldset", { staticClass: "mt-2" }, [
    _c("legend", [
      _vm._v(_vm._s(_vm.$t("integrations.forms.protheus.auth.title"))),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t("username") + " Protheus",
                rules: "required",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("InputText", {
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          placeholder: _vm.$t("username") + " Protheus",
                        },
                        model: {
                          value: _vm.auth.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.auth, "username", $$v)
                          },
                          expression: "auth.username",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t("password") + " Protheus",
                name: _vm.$t("password") + " Protheus",
                rules: "required",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("InputPassword", {
                        attrs: {
                          autocomplete: "off",
                          errors: errors[0],
                          placeholder: _vm.$t("username") + " Protheus",
                          "icon-left": true,
                        },
                        model: {
                          value: _vm.auth.userpass,
                          callback: function ($$v) {
                            _vm.$set(_vm.auth, "userpass", $$v)
                          },
                          expression: "auth.userpass",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-12" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t("integrations.forms.protheus.auth.monitorTable"),
                rules: "required|numeric",
                name: "integrationSystemProtheusAuthMonitorTable",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("InputText", {
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          placeholder: _vm.$t(
                            "integrations.forms.protheus.auth.monitorTable"
                          ),
                        },
                        model: {
                          value: _vm.auth.monitorTable,
                          callback: function ($$v) {
                            _vm.$set(_vm.auth, "monitorTable", $$v)
                          },
                          expression: "auth.monitorTable",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }