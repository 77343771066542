
import mixins from 'vue-typed-mixins';
import Vue2Dropzone, { DropzoneFile, DropzoneOptions } from 'vue2-dropzone';
import NotificationMixin from '@/mixins/NotificationMixin';

export default mixins(NotificationMixin).extend({
  name: 'UploadDropzone',
  components: {
    vueDropzone: Vue2Dropzone,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    config: {
      type: Object as () => DropzoneOptions,
      default: () => ({}),
      required: false,
    },
    disable: {
      type: Boolean as () => boolean,
      default: false,
    },
    removeFiles: {
      type: Array as () => Array<DropzoneFile>,
      default: () => ([]),
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 1,
        acceptedFiles: '.pfx,.p12',
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('remove'),
        headers: { 'My-Awesome-Header': 'header value' },
        ...this.config,
      } as DropzoneOptions,
    };
  },
  computed: {
    vue2Dropzone(): InstanceType<typeof Vue2Dropzone> {
      return this.$refs.myVueDropzone as InstanceType<typeof Vue2Dropzone>;
    },
  },
  watch: {
    removeFiles(files: Array<File>): void {
      files.forEach((file) => {
        this.vue2Dropzone.removeFile(file);
      });
    },
  },
  methods: {
    dropzoneMounted() {
      if (this.disable) {
        this.vue2Dropzone.disable();
      }
    },

    // If autoProcessQueue option is disabled, you’ll need to call
    process() {
      return this.vue2Dropzone.processQueue();
    },

    added(files: Array<DropzoneFile>): void {
      this.$emit('dropzone:added', files);
    },

    removed(file: DropzoneFile, error: string | Error, xhr: XMLHttpRequest): void {
      this.$emit('dropzone:removed', { file, error, xhr });
    },

    sending(file: DropzoneFile, xhr: XMLHttpRequest, formData: FormData): void {
      this.$emit('dropzone:sending', { file, xhr, formData });
    },

    duplication(file: DropzoneFile): void {
      this.$emit('dropzone:duplication', file);
    },

    exceeded(file: DropzoneFile): void {
      this.vue2Dropzone.removeFile(file);

      this.warningToast({
        text: this.$t('maxUpload', {
          max: this.dropzoneOptions.maxFiles,
        }) as string,
      });
    },

    open(): void {
      this.vue2Dropzone.$el.click();
    },
  },

});
