
import Vue from 'vue';
import CompanyGroupForm, { CreateCompanyGroupForm, EditCompanyGroupForm } from '@/common/components/companyGroup/form/CompanyGroupForm.vue';
import { InUpdateFrom } from '@/common/components/companyGroup/types';

export default Vue.extend({
  name: 'CompanyGroupModal',
  components: {
    CompanyGroupForm,
  },
  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },
    editData: {
      type: Object as () => EditCompanyGroupForm,
      default: undefined,
    },
  },
  data: () => ({
    companyTemplate:
      `${process.env.VUE_APP_ESTABLISHMENT_REGISTER_TEMPLATE}` as string,
    payload: {
      companyGroup: {} as CreateCompanyGroupForm | EditCompanyGroupForm,
    },
  }),
  computed: {
    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },
    isUpdating(): boolean {
      return this.editData !== undefined;
    },
    isInsertingCompanies(): boolean {
      return this.editData?.inUpdateFrom === InUpdateFrom.Company;
    },
    groupName(): string {
      return this.editData.data.groupName;
    },
  },
  methods: {
    async confirm() {
      const isValid = await this.validCompanyForm();
      if (isValid) {
        if (this.isUpdating) {
          if (this.isInsertingCompanies) {
            this.$emit('companyRegistrations', this.payload.companyGroup);
          } else {
            this.$emit('companyGroupUpdate', this.payload.companyGroup);
          }
        } else {
          this.$emit('registration', this.payload.companyGroup);
        }
        this.displayModal = false;
      }
    },
    async validCompanyForm(): Promise<boolean> {
      const companyGroupForm = this.$refs.companyGroupForm as (
        InstanceType<typeof CompanyGroupForm>
      );
      return companyGroupForm.formValid();
    },
  },
});
