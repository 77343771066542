import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  integrations: {
    title: 'Faça a integração com o seu ERP e/ou com os Softwares que você utiliza:',
    myToken: {
      label: 'Meu Token',
      loading: 'carregando...',
    },
    purpose: {
      accounting: 'Contábil',
      tax: 'Fiscal',
      financial: 'Financeiro',
      crm: 'CRM',
    },
    message: {
      copiedToken: 'Token copiado',
      copiedIntegrationId: 'ID integração copiado',
      fetchRegistersError: 'Não foi possível econtrar os dados de Sistema de Integração',
    },
    card: {
      noRegisters: {
        clickToRegister: 'Toque no botão acima para cadastrar um Sistema de Integração',
      },
    },
    table: {
      header: {
        systems: 'Sistemas',
        version: 'Versão',
        integrationToken: 'Token de integração',
        user: 'Usuário',
        password: 'Senha',
        ipAndPort: 'IP / Porta',
        integrationPurpose: 'Finalidade',
        integrationId: 'ID integração',
      },
      body: {
        tooltip: {
          delete: 'Excluir',
          edit: 'Editar',
        },
      },
    },
    modal: {
      title: 'Realizar Integração com Sistema',
      action: {
        update: '@:update',
        register: '@:register',
      },
    },
    forms: {
      select: '@:select',
      protheus: {
        auth: {
          title: 'Autenticação Protheus',
          monitorTable: 'Tabela Monitor',
        },
        parameters: {
          title: 'Parâmetros Protheus',
          usePurchaseOrderLabel: 'Utiliza Pedido de Compra',
          orderSharingBetweenBranchesLabel: 'Compartilhamento de pedidos entre filiais',
          performsAccountingOnDocumentEntryLabel: 'Realiza Contabilização On-line na entrada do documento',
          supplierTaxWithholdingsLabel: 'Tratamento de retenções de impostos de Fornecedores',
          orderSharingBetweenBranchesOptions: {
            shared: 'Compartilhado',
            exclusive: 'Exclusivo',
          },
          supplierTaxWithholdingsOptions: {
            adjust: 'Ajustar cadastro do Fornecedor',
            validate: 'Apenas validar as retenções',
          },
        },
      },
      sapb1: {
        auth: {
          title: 'Autenticação SAP B1',
        },
        databaseSection: {
          title: 'Banco de dados SAP B1',
          databaseType: 'Tipo de banco de dados',
          databaseServer: 'Server do banco de dados',
          databaseName: 'Nome do banco de dados',
          databaseUser: 'Usuário do banco de dados',
          databasePassword: 'Senha do banco de dados',
        },
        parameters: {
          title: 'Parâmetros SAP B1',
          sendPurchaseOrder: 'Enviar pedido de compra',
          launchInvoice: 'Lançar nota fiscal',
          sendAccountsChart: 'Enviar plano de contas',
          sendSupplierRegistration: 'Enviar cadastro do Fornecedor',
          sendUmbrellaContract: 'Enviar contrato guarda-chuva',
          payment: 'Baixar pagamentos',
          sendTaxCode: 'Enviar código de imposto',
        },
      },
    },
    form: {
      establishment: {
        label: 'Selecione as empresas para cadastrar o sistema de integração:',
        placeholder: 'Selecione',
      },
      integrationPurpose: {
        label: 'Finalidade da integração',
      },
      integrationSystem: {
        label: 'Selecione um sistema',
        placeholder: 'Selecione',
      },
      integrationSystemVersion: {
        label: 'Versão',
        placeholder: 'Ex.: 1.12',
      },
    },
    delete: {
      confirmation: {
        title: 'Excluir Sistema de Integração',
        message: 'Confirme a exclusão do Sistema de Integração.',
        success: 'Sistema de Integração excluída com sucesso.',
        error: 'Não foi possível excluír o Sistema de Integração.',
      },
    },
    update: {
      success: 'Sistema de Integração atualizado com sucesso',
      error: 'Não foi possível atualizar o Sistema de Integração',
    },
    create: {
      success: 'Sistema de Integração inserido com sucesso',
      error: 'Não foi possível inserir o Sistema de Integração',
    },
    optionsSectionForm: {
      title: 'Opções de Integração',
      dropdownLabel: 'Em caso de falha na integração, reiniciar o processo a cada',
      inputLabel: 'Integrar quantos documentos por consulta do ERP',
      inputPlaceholder: 'Ex.: 200',
    },

  },
};

export const en = {
  integrations: {
    title: 'Integrate with your ERP and/or with the Software you use:',
    myToken: {
      label: 'My Token',
      loading: 'loading...',
    },
    message: {
      copiedToken: 'Token copied',
      copiedIntegrationId: 'Integration id copied',
      msgError: 'Unable to find Integration System data',
    },
  },
};

export const es = {
  integrations: {
    title: 'Integre con su ERP y / o con el Software que utiliza:',
    myToken: {
      label: 'Mi Token',
      loading: 'cargando...',
    },
    message: {
      copiedToken: 'Token copiado',
      copiedIntegrationId: 'ID copiado',
      msgError: 'No se pudieron encontrar los datos del sistema de integración',
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
