import { render, staticRenderFns } from "./OnboardingSteps.vue?vue&type=template&id=49eb2b37&scoped=true&"
import script from "./OnboardingSteps.vue?vue&type=script&lang=ts&"
export * from "./OnboardingSteps.vue?vue&type=script&lang=ts&"
import style0 from "./OnboardingSteps.vue?vue&type=style&index=0&id=49eb2b37&lang=scss&"
import style1 from "./OnboardingSteps.vue?vue&type=style&index=1&id=49eb2b37&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49eb2b37",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/roit-company-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49eb2b37')) {
      api.createRecord('49eb2b37', component.options)
    } else {
      api.reload('49eb2b37', component.options)
    }
    module.hot.accept("./OnboardingSteps.vue?vue&type=template&id=49eb2b37&scoped=true&", function () {
      api.rerender('49eb2b37', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/onboarding-bank/components/OnboardingSteps.vue"
export default component.exports