
import Vue from 'vue';
import DateLocale from '@/common/classes/dateLocale';

export default Vue.extend({
  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  data() {
    return {
      dates2: null,
      ptBr: DateLocale.ptBR,
      value: [] as string[],
      teste: [
        { name: 'Teste1', code: '1' },
        { name: 'Teste2', code: '2' },
        { name: 'Teste3', code: '3' },
        { name: 'Teste4', code: '4' },
        { name: 'Teste5', code: '5' },
      ],
    };
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },
  },
});
