import state from './moduleAuthState';
import mutations from './moduleAuthMutations';
import actions from './moduleAuthActions';
import getters from './moduleAuthGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
