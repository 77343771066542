/* eslint-disable */
const isNumeric = (value: string): boolean => {
    return !isNaN(+value)
}

const isInRange = (value: number, minValue: string, maxValue: string): boolean => {
    return value >= +minValue && value <= +maxValue
}

function validate(value: string, args: { minValue: string, maxValue: string }) {
    if (value === '*') { return true }

    const { minValue, maxValue } = args
    const allowedCharacterRegex = new RegExp('[,-]')

    if (allowedCharacterRegex.test(value)) {
        const values = value.includes('-') ? value.trim().split('-') : value.trim().split(',');
        
        if(value.includes('-')) {
            const smallestNumber = value.split('-')[0]
            const higherNumber = value.split('-')[1]
            if(smallestNumber >= higherNumber) {
                return false
            }
        }

        for (const currentValue of values) {

            if (
                !isNumeric(currentValue)
                || !isInRange(+currentValue, minValue, maxValue)
                || currentValue.includes(' ')
            ) {

                return false
            }
        }

        return true
    }

    return isNumeric(value) ? isInRange(+value, minValue, maxValue) : false
}

export default validate;
