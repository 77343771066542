/* eslint-disable */
import DateValidate from './date';
import { VeeValidateParams } from '../../types';

export const validator = {
  getMessage(): string {
    return 'The date is not valid';
  },

  validate(value: any, { format } : VeeValidateParams): boolean {
    return DateValidate(value, format);
  },

  params:['format']
};
