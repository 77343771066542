
import Vue from 'vue';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import { SapB1Params } from '@/resources/integrations/types';

type Option = {
  value: string;
  label: string;
}

type Database = {
  type: string;
  name: string;
  server: string;
  username: string;
  userpass: string;
}

export default Vue.extend({
  name: 'SapB1DatabaseSectionForm',
  components: {
    InputPassword,
  },
  props: {
    systemParameters: {
      type: Object as () => SapB1Params,
      required: true,
    },
  },
  data() {
    const {
      type = '',
      name = '',
      server = '',
      username = '',
      userpass,
    } = this.systemParameters?.database || {};

    return {
      database: {
        type,
        name,
        server,
        username,
        userpass,
      } as Database,
    };
  },
  computed: {
    sapB1DatabaseTypes(): Option[] {
      return [
        {
          value: '1',
          label: 'MSSQL',
        },
        {
          value: '2',
          label: 'DB 2',
        },
        {
          value: '3',
          label: 'SYBASE',
        },
        {
          value: '4',
          label: 'MSSQL2005',
        },
        {
          value: '5',
          label: 'MAXDB',
        },
        {
          value: '6',
          label: 'MSSQL2008',
        },
        {
          value: '7',
          label: 'MSSQL2012',
        },
        {
          value: '8',
          label: 'MSSQL2014',
        },
        {
          value: '9',
          label: 'HANADB',
        },
        {
          value: '10',
          label: 'MSSQL2016',
        },
        {
          value: '11',
          label: 'MSSQL2017',
        },
        {
          value: '15',
          label: 'MSSQL2019',
        },
      ];
    },
  },
  watch: {
    database: {
      deep: true,
      handler(database: Database) {
        this.$emit('update:systemParameters', { ...this.systemParameters, database, __type: 'SapB1Params' });
      },
    },
  },
});
