var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-pagination", {
    staticClass: "r-pagination",
    attrs: {
      layout: "prev, pager, next",
      total: _vm.totalItems,
      "page-size": _vm.pageSize,
      small: "",
    },
    on: {
      "current-change": function ($event) {
        return _vm.$emit("change", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }