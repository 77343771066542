var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Form",
    [
      _c("ValidationObserver", { ref: "form", attrs: { slim: "" } }, [
        _c("div", { staticClass: "pt-2 pb-4" }, [
          _c("span", { staticClass: "text-grey" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("addCompany.companyForm.groupNameAndAddCompanies")
                ) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "p-fluid p-formgrid p-grid" }, [
          _c(
            "div",
            { staticClass: "p-col-12 p-md-6 p-field" },
            [
              _c("input-validation", {
                attrs: {
                  label: _vm.$t(
                    "addCompany.companyForm.fields.groupName.label"
                  ),
                  name: _vm.$t("addCompany.companyForm.fields.groupName.label"),
                  rules: "required",
                  mode: "lazy",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("InputText", {
                          class: { "p-invalid": errors[0] },
                          attrs: {
                            placeholder: _vm.$t(
                              "addCompany.companyForm.fields.groupName.placeholder"
                            ),
                          },
                          model: {
                            value: _vm.form.groupName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "groupName", $$v)
                            },
                            expression: "form.groupName",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "p-fluid p-formgrid p-grid fields-wrapper px-3 pt-1 pb-1 mx-0",
          },
          _vm._l(_vm.form.companies, function (company, i) {
            return _c(
              "div",
              { key: i, staticClass: "p-field p-col-3 mb-0" },
              [
                i < 16
                  ? _c("InputMask", {
                      staticClass: "pt-3",
                      attrs: { mask: "99.999.999/9999-99", unmask: true },
                      model: {
                        value: company.nationalRegister,
                        callback: function ($$v) {
                          _vm.$set(company, "nationalRegister", $$v)
                        },
                        expression: "company.nationalRegister",
                      },
                    })
                  : _c("InputMask", {
                      staticClass: "pt-3 no-border",
                      attrs: { mask: "99.999.999/9999-99", unmask: true },
                      model: {
                        value: company.nationalRegister,
                        callback: function ($$v) {
                          _vm.$set(company, "nationalRegister", $$v)
                        },
                        expression: "company.nationalRegister",
                      },
                    }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }