/* eslint-disable */
import hour from './specificHourCron';

export const validator = {
  getMessage (field: any) { // will be added to default English messages.
    return 'Invalid hour format'
  },
  validate (value: any) {
    return hour(value);
  }
}
