
import Vue from 'vue';
import { CommonParameter } from '@/resources/integrations/types';

type Option = {value: string; label: string}

export default Vue.extend({
  name: 'IntegrationOptionsSectionForm',
  props: {
    commonParameter: {
      type: Object as () => CommonParameter,
      required: true,
    },
  },
  data() {
    const { retryAt, maxDownloadDocs = '1' } = this.commonParameter;
    return {
      integrationOptions: {
        retryAt,
        maxDownloadDocs,
      } as CommonParameter,
    };
  },
  computed: {
    whenFailRetryEachList(): Option[] {
      return [
        {
          value: '1800',
          label: `30 ${this.$t('minutes')}`,
        },
        {
          value: '3600',
          label: `1 ${this.$t('hour')}`,
        },
        {
          value: '5400',
          label: `1 ${this.$t('hour')} e 30 ${this.$t('minutes')}`,
        },
        {
          value: '7200',
          label: `2 ${this.$t('hours')}`,
        },
        {
          value: '900',
          label: `2 ${this.$t('hours')} e 30 ${this.$t('minutes')}`,
        },
        {
          value: '10800',
          label: `3 ${this.$t('hours')}`,
        },
      ];
    },
  },
  watch: {
    integrationOptions: {
      deep: true,
      handler(integrationOptions: CommonParameter) {
        this.$emit('update:commonParameter', integrationOptions);
      },
    }
    ,
  },
});
