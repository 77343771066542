
import Vue from 'vue';
import { mapGetters } from 'vuex';
import SupportInternationalization from '@/common/components/supportInternationalization/SupportInternationalization.vue';

export default Vue.extend({
  name: 'Start',
  components: {
    SupportInternationalization,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
});
