
import { FormRef, IntegrationSystem } from '@/types';
import IntegrationSystemForm from '@/resources/integrations/components/forms/IntegrationSystemForm.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import mixins from 'vue-typed-mixins';

export default mixins(NotificationMixin).extend({
  name: 'IntegrationSystemFormModal',

  components: {
    IntegrationSystemForm,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    formPayload: {
      type: Object as () => IntegrationSystem,
      default: {} as IntegrationSystem,
    },
  },

  computed: {
    modal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean) {
        this.$emit('update:showModal', value);
      },
    },
    isUpdate(): boolean {
      const { formPayload } = this;
      return formPayload?.id !== undefined;
    },
    title(): string {
      const messagePath = 'integrations.modal.title';
      return this.$t(messagePath) as string;
    },
    registerButtonLabel(): string {
      const messagePath = this.isUpdate ? 'integrations.modal.action.update' : 'integrations.modal.action.register';
      return this.$t(messagePath) as string;
    },
  },

  methods: {
    async confirm() {
      const form: any = this.$refs.form as FormRef;
      form.submit();
    },
    onSubmitEmit(event: Event) {
      this.$emit('onSubmit', event);
    },
    submittedEmit(event: Event) {
      this.$emit('submitted', event);
    },
  },
});
