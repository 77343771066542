import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  companyGroupComponent: {
    modal: {
      title: {
        registerGroupCompany: 'Cadastrar Grupo e Empresas',
        addCompaniesToGroup: 'Adicionar Empresas ao Grupo',
        editGroup: 'Editar Grupo',
      },
    },
    tabs: {
      groupCompanyTitle: 'Cadastrar Empresa (CNPJ)',
    },
    form: {
      addGroupAndCompanies: 'Nomeie o grupo, informe o CNPJ da matriz e demais campos da tela. Não se preocupe com os estabelecimentos (Filiais) eles serão cadastrados de forma automática.',
      addCompanies: 'Adicione empresas ao grupo abaixo informando os CNPJs.',
      updateGroup: 'Atualize o nome do grupo.',
      fields: {
        groupName: {
          label: 'Nome do Grupo',
          placeholder: 'Defina um nome para o Grupo',
        },
        cnpj: 'CNPJ',
        taxRegime: 'Reg. Tributário',
        polling: 'Apuração',
        retreat: 'Recolhimento',
        placeholder: {
          cnpj: '99.999.999/9999-99',
          taxRegime: 'Selecione o Regime',
          polling: 'Selecione a Apuração',
          retreat: 'Selecione o Recolhimento',
        },
      },
    },
    notice: {
      processingWarning: {
        title: {
          register: 'Cadastro em lote realizado!',
          transfer: 'Transferência realizada!',
        },
        message: 'Encontra-se neste momento em processamento, aguarde alguns instantes e recarregue a página',
      },
      notFoundParentCompany: {
        title: 'Atenção!',
        message: 'Empresa não cadastrada, o CNPJ informado é de uma filial e sua matriz não existe em nossa base de dados.',
      },
      clickToRegister: {
        title: 'Toque no botão acima para cadastrar sua(s) Empresa(s)',
        addNewCompany: 'Adicionar nova empresa',
      },
    },
    tooltip: {
      addCompanies: 'Adicionar Empresas',
      editGroup: 'Editar Grupo',
    },
  },
};

export const es = {
  companyGroupComponent: {
    modal: {
      title: {
        registerGroupCompany: 'Registrar Grupo y Empresas',
        addCompaniesToGroup: 'Añadir Empresas al Grupo',
        editGroup: 'Editar Grupo',
      },
    },
    tabs: {
      groupCompanyTitle: 'Registrar Empresa (CNPJ)',
    },
    form: {
      addGroupAndCompanies: 'Nombre el grupo, informe al CNPJ de la matriz y otros campos en pantalla. No te preocupes por los establecimientos (Sucursales) se darán de alta automáticamente.',
      addCompanies: 'Agregue empresas al grupo a continuación informando a los CNPJ.',
      updateGroup: 'Actualizar nombre del grupo.',
      fields: {
        groupName: {
          label: 'Nombre del Grupo',
          placeholder: 'Establecer un nombre para el Grupo',
        },
        cnpj: 'CNPJ',
        taxRegime: 'Régimen Fiscal',
        polling: 'Votación',
        retreat: 'Retirada',
        placeholder: {
          cnpj: '99.999.999/9999-99',
          taxRegime: 'Selecciona el Régimen Fiscal',
          polling: 'Seleccione la Votación',
          retreat: 'Seleccione el Retiro',
        },
      },
    },
    notice: {
      processingWarning: {
        title: {
          register: '¡Registro de lote hecho!',
          transfer: '¡Transferir de lote hecho!',
        },
        message: 'Actualmente se está procesando, espere unos momentos y vuelva a cargar la página',
      },
      notFoundParentCompany: {
        title: 'Atención!',
        message: 'Empresa no registrada, informó el CNPJ es de sucursal y su matriz no existe en nuestra base de datos.',
      },
      clickToRegister: {
        title: 'Toque el botón de arriba para registrar su (s) empresa (s)',
        addNewCompany: 'Añadir nueva empresa',
      },
    },
    tooltip: {
      addCompanies: 'Añadir Empresas',
      editGroup: 'Editar Grupo',
    },
  },
};

export const en = {
  companyGroupComponent: {
    modal: {
      title: {
        registerGroupCompany: 'Register Group and Companies',
        addCompaniesToGroup: 'Add Companies to Group',
        editGroup: 'Edit Group',
      },
    },
    tabs: {
      groupCompanyTitle: 'Register Company (CNPJ)',
    },
    form: {
      addGroupAndCompanies: "Name the group, inform the CNPJ of the matrix and other fields on the screen. Don't worry about the establishments (Branches) they will be registered automatically.",
      addCompanies: 'Add companies to the group below informing the CNPJs.',
      updateGroup: 'Update the group name.',
      fields: {
        groupName: {
          label: "Group's name",
          placeholder: 'Set a name for the Group',
        },
        cnpj: 'National Register',
        taxRegime: 'Tax Regime',
        polling: 'Polling',
        retreat: 'Retreat',
        placeholder: {
          cnpj: '99.999.999/9999-99',
          taxRegime: 'Select the Tax Regime',
          polling: 'Select the Polling',
          retreat: 'Select the Retreat',
        },
      },
    },
    notice: {
      processingWarning: {
        title: {
          register: 'Batch registration performed!',
          transfer: 'Transfer performed!',
        },
        message: 'It is currently processing, wait a few moments and reload the page',
      },
      notFoundParentCompany: {
        title: 'Attention!',
        message: 'Unregistered company, the CNPJ informed is from a branch and its matrix does not exist in our database.',
      },
      clickToRegister: {
        title: 'Tap the button above to register your Company(s)',
        addNewCompany: 'Add new company',
      },
    },
    tooltip: {
      addCompanies: 'Add Companies',
      editGroup: 'Edit Group',
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
