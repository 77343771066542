
import { mapState } from 'vuex';
import mixins from 'vue-typed-mixins';
import OnboardMixin from '@/resources/onboarding/OnboardMixin';
import StepButtons from '@/resources/onboarding/components/StepButtons.vue';
import RegisterResponsibleModal from '@/resources/onboarding/components/RegisterResponsibleModal.vue';
import ResponsibleTable from '@/resources/my-products/components/responsible/ResponsibleTable.vue';
import Responsible from '@/mixins/ResponsibleMixin';
import UsersAdminService from '@/common/services/UsersAdminService';

type Responsible = {
  name: string;
  document: string;
  mail: string;
  job: string;
  products: Array<{ name: string; key: string }>;
};

export default mixins(OnboardMixin, Responsible).extend({
  name: 'StepResponsible',
  components: {
    StepButtons, RegisterResponsibleModal, ResponsibleTable,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.auth.user,
    }),
    disableNext(): boolean {
      const responsible = this.responsible.table.data
        .find((item: Responsible) => item.products.length > 0);
      return responsible === undefined;
    },
  },
  methods: {
    async findAdminUsers(search = '') {
      this.handleLoading(true);
      this.responsible.table.loading = true;
      const { data } = (await UsersAdminService.findAdminUsers(search)).data;

      this.responsible.table.data = data || [];

      this.responsible.table.loading = false;
      this.handleLoading(false);
    },
  },
});
