export type CompanyInformation = {
  nationalRegister: string;
  retreat: string;
  polling: string;
  taxRegime: string;
}
export type CreateCompanyGroupPayload = {
  groupName: string;
  companies: CompanyInformation[];
};

export type EditCompanyGroupPayload = CreateCompanyGroupPayload & {
  companyGroupId: string;
};

export enum InUpdateFrom {
  CompanyGroup = 'companyGroup', Company = 'company',
}
