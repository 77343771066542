import { RouteConfig } from 'vue-router';

export default [
  {
    path: '/digital-certificates',
    name: 'digital-certificates',
    redirect: { name: 'digitalCertificates' },
    component: () => import(/* webpackChunkName: "digital-certificates" */ '@/layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'digitalCertificates',
        component: () => import(/* webpackChunkName: "digital-certificates" */ '@/resources/digital-certificates/RegisterDigitalCertificate.vue'),
      },
    ],
  },
] as Array<RouteConfig>;
