var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "registerDigitalCertificate" },
    [
      _c("DigitalCertificateValidateModal", {
        attrs: { "show-modal": _vm.showCertificateValidateModal },
        on: {
          "update:showModal": function ($event) {
            _vm.showCertificateValidateModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showCertificateValidateModal = $event
          },
        },
      }),
      _c("DigitalCertificateModalUpload", {
        ref: "uploadModal",
        attrs: { show: _vm.showDigCertModal, files: _vm.files },
        on: {
          "update:show": function ($event) {
            return _vm.updateModal($event)
          },
          sending: function ($event) {
            return _vm.fileSubmit($event)
          },
        },
      }),
      _vm.$route.name === "digitalCertificates"
        ? _c("span", { staticClass: "p-d-block pb-4 fs14 grey300" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("digitalCertificates.view.title")) + " "
            ),
          ])
        : _vm._e(),
      _vm.messageErrors.length
        ? _c("DigitalCertificateMessage", {
            staticClass: "p-mt-0",
            model: {
              value: _vm.messageErrors,
              callback: function ($$v) {
                _vm.messageErrors = $$v
              },
              expression: "messageErrors",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "p-field p-grid" }, [
        _c(
          "div",
          { staticClass: "p-col-3 mt-0" },
          [
            _c("Button", {
              staticClass: "outlined p-field",
              attrs: {
                icon: "icon-r-time-config",
                label: _vm.$t(
                  "digitalCertificates.validateCertificate.expirationAlertButton"
                ),
              },
              on: {
                click: function ($event) {
                  _vm.showCertificateValidateModal = true
                },
              },
            }),
          ],
          1
        ),
        _vm.$route.name === "digitalCertificates"
          ? _c("div", { staticClass: "p-col-9" }, [
              _c(
                "div",
                { staticClass: "p-d-flex p-jc-end" },
                [
                  _c("input-validation", {
                    ref: "search",
                    attrs: { slim: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "p-input-icon-left",
                                  staticStyle: { width: "300px" },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "icon-r-magnifier-tool grey100 ml-1",
                                  }),
                                  _c("InputText", {
                                    ref: "inputSearch",
                                    class: [
                                      { "p-invalid": errors[0] },
                                      "w-100",
                                      "no-box",
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("search"),
                                    },
                                    on: { input: _vm.certificateSearch },
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3885305232
                    ),
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "p-field mb-8 mt-4" },
        [
          _c("UploadDropzone", {
            ref: "uploadDropzone",
            attrs: {
              config: _vm.dropzoneOptions,
              description: _vm.$t("digitalCertificates.dropzone.title"),
              "remove-files": _vm.removeFiles,
            },
            on: {
              "dropzone:added": function ($event) {
                return _vm.fileAdded($event)
              },
              "dropzone:removed": function ($event) {
                return _vm.fileRemoved($event)
              },
              "dropzone:sending": function ($event) {
                return _vm.fileSending($event)
              },
              "dropzone:duplication": function ($event) {
                return _vm.fileDuplicate($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-field" },
        [
          _c("DigitalCertificateTable", {
            ref: "tableCertificates",
            attrs: { rows: _vm.certificates, loading: _vm.tableLoading },
            on: {
              "certificate:delete": function ($event) {
                return _vm.certificateDelete($event)
              },
              "certificate:canSEFAZNoteDownloadUpdate": function ($event) {
                return _vm.certificateCanSEFAZNotaDownloadUpdate($event)
              },
              "certificate:download": function ($event) {
                return _vm.downloadCertificate($event)
              },
              "certificate:update": function ($event) {
                return _vm.openUpdateCertificate()
              },
            },
          }),
        ],
        1
      ),
      _vm.certificates.length > 0
        ? _c(
            "infinite-loading",
            {
              on: {
                infinite: function ($event) {
                  !_vm.isSearchLoading && _vm.infiniteHandler($event)
                },
              },
            },
            [
              _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }),
              _c("span", { attrs: { slot: "no-results" }, slot: "no-results" }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }