var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "digital-certificate" },
    [
      _c("custom-table", {
        ref: "tableCertificate",
        attrs: {
          "columns-data": _vm.header,
          data: _vm.rowsData,
          loading: _vm.loading,
          "page-size-prop": _vm.pageSize,
          "row-length": _vm.rowsData.length,
        },
        scopedSlots: _vm._u([
          {
            key: "columns",
            fn: function ({ item, column }) {
              return [
                column === "cnpj"
                  ? _c("td", { staticClass: "p-pl-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("CnpjFormat")(item.nationalRegister)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                column === "razaoSocial"
                  ? _c("td", [_vm._v(" " + _vm._s(item.companyName) + " ")])
                  : _vm._e(),
                column === "nomeArquivo"
                  ? _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "p-link",
                          attrs: { href: item.url, target: "_blank" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadCertificate(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.fileName) + " ")]
                      ),
                    ])
                  : _vm._e(),
                column === "validade"
                  ? _c("td", [
                      _vm._v(
                        " " + _vm._s(_vm._f("dateFormat")(item.validate)) + " "
                      ),
                    ])
                  : _vm._e(),
                column === "status"
                  ? _c(
                      "td",
                      [
                        _c("DigitalCertificateStatus", {
                          attrs: { row: item },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                column === "canSEFAZNoteDownload"
                  ? _c(
                      "td",
                      { staticClass: "pl-42px" },
                      [
                        _c("Checkbox", {
                          attrs: { binary: true },
                          on: {
                            change: function ($event) {
                              return _vm.handleCanSEFAZNoteDownload(item)
                            },
                          },
                          model: {
                            value: item.canSEFAZNoteDownload,
                            callback: function ($$v) {
                              _vm.$set(item, "canSEFAZNoteDownload", $$v)
                            },
                            expression: "item.canSEFAZNoteDownload",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t("update"),
                          expression: "$t('update')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.updateCertificate()
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-r-file-upload-2" })]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t("delete"),
                          expression: "$t('delete')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.deleteCertificate(item)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-r-trash" })]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }