
import Vue from 'vue';
import { mapMutations } from 'vuex';
import OnboardingButtons from '@/resources/onboarding-bank/components/OnboardingButtons.vue';

export default Vue.extend({
  name: 'WelcomeVideo',
  components: { OnboardingButtons },
  mounted() {
    this.handleWithoutMenu(false);
  },
  methods: {
    redirectWithMenu() {
      this.handleWithoutMenu(false);
      this.$router.push('/welcome-video');
    },
    ...mapMutations({
      handleWithoutMenu: 'handleWithoutMenu',
    }),
  },
});
