var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      staticClass: "text-125",
      style: { minWidth: "auto" },
      attrs: {
        header: _vm.$t(`onboardingBank.${_vm.page}.${_vm.model}.header`),
        modal: true,
        visible: _vm.modal,
        "content-style": { overflow: "visible", borderRadius: "10px" },
      },
      on: {
        "update:visible": function ($event) {
          _vm.modal = $event
        },
      },
    },
    [
      _c("p", { staticClass: "mt-3 family-sfPro-regular" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(`onboardingBank.${_vm.page}.${_vm.model}.description`)
            ) +
            " "
        ),
      ]),
      _c("div", [_vm._t("default")], 2),
      _c(
        "div",
        { staticClass: "mt-3 mb-3 text-right" },
        [
          _c("Button", {
            staticClass: "outlined mr-3",
            attrs: {
              label: _vm.$t(
                `onboardingBank.${_vm.page}.${_vm.model}.closeButton`
              ),
            },
            on: {
              click: function ($event) {
                return _vm.$emit("update:showModal", false)
              },
            },
          }),
          _c("Button", {
            staticClass: "white-button-icon",
            attrs: {
              label: _vm.$t(
                `onboardingBank.${_vm.page}.${_vm.model}.downloadButton`
              ),
              "icon-pos": "left",
              icon: "icon-r-download ",
            },
            on: {
              click: function ($event) {
                return _vm.downloadCsvFile()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }