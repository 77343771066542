var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "relative", attrs: { id: "accountant-robot" } },
    [
      _c(
        "ModelTableModal",
        {
          attrs: {
            "show-modal": _vm.showModal,
            page: "accountantRobot",
            model: _vm.modelDoc,
          },
          on: {
            "update:showModal": function ($event) {
              _vm.showModal = $event
            },
            "update:show-modal": function ($event) {
              _vm.showModal = $event
            },
          },
        },
        [
          _vm.modelDoc === "modelAccount" ? _c("ModelAccountTable") : _vm._e(),
          _vm.modelDoc === "productLaunch"
            ? _c("ModelProductLaunchTable")
            : _vm._e(),
          _vm.modelDoc === "serviceLaunch"
            ? _c("ModelServiceLaunchTable")
            : _vm._e(),
        ],
        1
      ),
      _c("img", {
        staticClass: "absolute -left-28 top-60",
        attrs: {
          src: require("@/common/assets/images/onboarding-bank/dots-gray.png"),
          alt: "Pontinhos cinzas",
        },
      }),
      _c("div", { staticClass: "container mt-5" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-xl-6 pl-0 pr-10" },
            [
              _c("h3", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("onboardingBank.accountantRobot.text.how")) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("onboardingBank.accountantRobot.text.first")
                    ) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("onboardingBank.accountantRobot.text.second")
                    ) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("onboardingBank.accountantRobot.text.third")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "flex flex-col items-start" }, [
                _c(
                  "button",
                  {
                    staticClass: "download-csv mb-2",
                    on: {
                      click: function ($event) {
                        return _vm.toogleModalWithDoc("modelAccount")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "download-icon",
                      attrs: {
                        src: require("@/common/assets/icons/download.svg"),
                        alt: "Download Icon",
                      },
                    }),
                    _c("span", { staticClass: "download-text pl-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("onboardingBank.accountantRobot.csv.account")
                          ) +
                          " "
                      ),
                    ]),
                    _c("br"),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "download-csv mb-2",
                    on: {
                      click: function ($event) {
                        return _vm.toogleModalWithDoc("productLaunch")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "download-icon",
                      attrs: {
                        src: require("@/common/assets/icons/download.svg"),
                        alt: "Download Icon",
                      },
                    }),
                    _c("span", { staticClass: "download-text pl-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("onboardingBank.accountantRobot.csv.product")
                          ) +
                          " "
                      ),
                    ]),
                    _c("br"),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "download-csv mb-2",
                    on: {
                      click: function ($event) {
                        return _vm.toogleModalWithDoc("serviceLaunch")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "download-icon",
                      attrs: {
                        src: require("@/common/assets/icons/download.svg"),
                        alt: "Download Icon",
                      },
                    }),
                    _c("span", { staticClass: "download-text pl-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "onboardingBank.accountantRobot.csv.services"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("br"),
                  ]
                ),
              ]),
              _c("OnboardingButtons", { staticClass: "mt-5" }),
            ],
            1
          ),
          _vm._m(0),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "col-lg-12 col-xl-6", attrs: { id: "img-accountant" } },
      [
        _c("img", {
          staticClass: "w-full",
          attrs: {
            src: require("@/common/assets/images/onboarding-bank/bg-tax.png"),
            alt: "Robo Contabil",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }