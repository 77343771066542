var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "p-0 m-0 fluid", attrs: { id: "onboarding" } },
    [
      _c("div", { staticClass: "row p-0 m-0 flex h-20" }, [
        _c("img", {
          staticClass: "ml-5 logo",
          attrs: {
            height: "48px",
            src: require("@/common/assets/images/logo-roit-solutions-blue.svg"),
            alt: "Logo Roit branca",
          },
        }),
        _c("div", { staticClass: "return-icon" }, [
          _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.down",
                value: _vm.$t("onboardingBank.returnAdmin"),
                expression: "$t('onboardingBank.returnAdmin')",
                modifiers: { down: true },
              },
            ],
            staticClass: "pointer icon-r-arrow-left-single",
            attrs: { height: "48px" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/my-products")
              },
            },
          }),
        ]),
        _c(
          "div",
          { staticClass: "support" },
          [
            _c("SupportInternationalization", {
              staticClass: "centerInternationalization",
            }),
          ],
          1
        ),
      ]),
      _c("hr", { staticClass: "m-0" }),
      _c(
        "div",
        { staticClass: "container page-container" },
        [
          _c("Steps", {
            ref: "steps",
            staticClass: "mx-auto onboarding-steps",
            staticStyle: { width: "600px" },
            attrs: { model: _vm.items, readonly: true },
          }),
          _c("keep-alive", [_c("router-view")], 1),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }