import axios, { CancelTokenSource } from '@/common/http';
import { UserAdmin } from '@/types';

class UsersAdminService {
  private service: typeof axios = axios;

  private cancelTokenSource: CancelTokenSource | undefined;

  private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/users-admin`;

  private cancelToken(): CancelTokenSource {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
    return this.service.CancelToken.source();
  }

  public findAdminUsers(search = '') {
    let { baseUrl } = this;

    if (search) {
      baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
    }
    this.cancelTokenSource = this.cancelToken();

    return this.service.get(baseUrl, {
      cancelToken: this.cancelTokenSource.token,
    });
  }

  public findAdminUsersGrouped(search = '') {
    let baseUrl = `${this.baseUrl}/groups`;

    if (search) {
      baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
    }
    this.cancelTokenSource = this.cancelToken();

    return this.service.get(baseUrl, {
      cancelToken: this.cancelTokenSource.token,
    });
  }

  public createUserAdmin(user: UserAdmin) {
    const baseUrl = `${this.baseUrl}`;

    return this.service.post(baseUrl, user);
  }

  public deleteUserAdmin(email: string) {
    const baseUrl = `${this.baseUrl}/${email}`;

    return this.service.delete(baseUrl);
  }

  public updateUserAdmin(user: UserAdmin) {
    const baseUrl = `${this.baseUrl}/${user.email}`;
    const { email, products } = user;

    return this.service.patch(baseUrl, {
      email,
      products,
    });
  }

  public updateProductsUserAdmin(email: string, products: string[]) {
    const baseUrl = `${this.baseUrl}/${email}/products`;

    return this.service.put(baseUrl, {
      products,
    });
  }
}

export default new UsersAdminService();
