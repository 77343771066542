<template>
  <div
    class="card rounded-lg mt-4"
    style="height: 282px"
  >
    <div
      class="d-flex flex-column align-items-center justify-content-center"
      style="height: 100%; color: #03181E54"
    >
      <div>
        <img
          :src="require('@/common/assets/icons/icn_cadastrar_empresa.svg')"
          height="106px"
          width="117px"
        >
      </div>
      <div class="fs16">
        {{ $t('companyGroupComponent.notice.clickToRegister.title') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyGroupNotFound',
};
</script>
