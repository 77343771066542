var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "onboarding-bank table-container" }, [
    _c("div", { staticClass: "mr-4" }, [
      _c("table", { staticClass: "w-full" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.cdModel.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.cdModel.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.cdModel.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.referringItem.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.referringItem.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.referringItem.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxRegime.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxRegime.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "h-auto description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxRegime.regimes.first"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "h-auto description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxRegime.regimes.second"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "h-auto description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxRegime.regimes.third"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.movementType.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.movementType.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.movementType.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.operationType.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.operationType.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.operationType.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CNAEDestination.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CNAEDestination.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CNAEDestination.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CNAEOrigin.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CNAEOrigin.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CNAEOrigin.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CFOPCode.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CFOPCode.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CFOPCode.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IBGEDestinationCode.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IBGEDestinationCode.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IBGEDestinationCode.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IBGEOriginCode.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IBGEOriginCode.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IBGEOriginCode.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxpayer.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxpayer.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "h-auto description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxpayer.options.description"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "h-auto description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxpayer.options.first"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "h-auto description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxpayer.options.second"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "h-auto description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.taxpayer.options.third"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.acronymStateDestination.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.acronymStateDestination.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.acronymStateDestination.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.acronymStateOrigin.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.acronymStateOrigin.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.acronymStateOrigin.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldINSSAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldINSSAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldINSSAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldCSLLAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldCSLLAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldCSLLAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldPISAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldPISAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldPISAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldCOFINSAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldCOFINSAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldCOFINSAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldISSQNAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldISSQNAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldISSQNAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.PISAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.PISAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.PISAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTPIS.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTPIS.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTPIS.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.COFINSAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.COFINSAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.COFINSAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTCOFINS.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTCOFINS.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTCOFINS.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IPIAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IPIAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.IPIAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTIPI.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTIPI.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTIPI.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSInterAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSInterAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSInterAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTICMS.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTICMS.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.CSTICMS.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.MVAPAUTA.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.MVAPAUTA.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.MVAPAUTA.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.NQIPICode.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.NQIPICode.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.NQIPICode.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquotReduction.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquotReduction.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquotReduction.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquotDiff.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquotDiff.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.ICMSAliquotDiff.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldIRRFAliquot.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "type" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldIRRFAliquot.type"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("td", [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "onboardingBank.taxRobot.modelCompanies.withheldIRRFAliquot.description"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }