export default [
  {
    path: '/users-and-contacts',
    name: 'users-and-contacts',
    redirect: { name: 'users-and-contacts:profiles' },
    component: () => import(/* webpackChunkName: "users-and-contacts" */ '@/resources/users-and-contacts/UsersAndContactsTemplate.vue'),
    children: [
      {
        path: '/users-and-contacts/profiles',
        name: 'users-and-contacts:profiles',
        component: () => import(/* webpackChunkName: "users-and-contacts-profiles" */ '@/resources/profile-management/ProfileManagementView.vue'),
      },
      {
        path: '/users-and-contacts/users',
        name: 'users-and-contacts:users',
        component: () => import(/* webpackChunkName: "users-and-contacts-users" */ '@/resources/users/Users.vue'),
      },
      {
        path: '/users-and-contacts/contacts',
        name: 'users-and-contacts:contacts',
        component: () => import(/* webpackChunkName: "users-and-contacts-contacts" */ '@/resources/contacts/Contacts.vue'),
      },
    ],
  },
];
