import { render, staticRenderFns } from "./StepButtons.vue?vue&type=template&id=387ca83d&scoped=true&"
import script from "./StepButtons.vue?vue&type=script&lang=ts&"
export * from "./StepButtons.vue?vue&type=script&lang=ts&"
import style0 from "./StepButtons.vue?vue&type=style&index=0&id=387ca83d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387ca83d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/roit-company-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('387ca83d')) {
      api.createRecord('387ca83d', component.options)
    } else {
      api.reload('387ca83d', component.options)
    }
    module.hot.accept("./StepButtons.vue?vue&type=template&id=387ca83d&scoped=true&", function () {
      api.rerender('387ca83d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/onboarding/components/StepButtons.vue"
export default component.exports