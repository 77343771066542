var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("Loading"),
      _c("Toast", { attrs: { "base-z-index": 1000 } }),
      _vm.loadedApp
        ? _c(
            "div",
            [
              _vm.firstAccess
                ? _c("router-view", { key: _vm.$route.fullPath })
                : _c(
                    "div",
                    [
                      _vm.renderMenu
                        ? _c("custom-menu", {
                            attrs: {
                              control: _vm.controlCLoseOpen,
                              collapse: _vm.isCollapse,
                            },
                            on: {
                              "update:control": function ($event) {
                                _vm.controlCLoseOpen = $event
                              },
                              "update:collapse": function ($event) {
                                _vm.isCollapse = $event
                              },
                            },
                          })
                        : _vm._e(),
                      _c(
                        "main",
                        {
                          class: {
                            "collapse-open":
                              _vm.controlCLoseOpen === true
                                ? !_vm.isCollapse
                                : "",
                            "collapse-close": _vm.isCollapse,
                          },
                          attrs: { "v-if": _vm.renderApp },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "main-content" },
                            [_vm.userLoggedIn ? _c("router-view") : _vm._e()],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }