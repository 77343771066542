var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Card",
    { staticClass: "page-card border" },
    [
      _c("template", { slot: "header" }, [_vm._t("header")], 2),
      _c("template", { slot: "title" }, [_vm._t("title")], 2),
      _c("template", { slot: "content" }, [_vm._t("content")], 2),
      _c("template", { slot: "footer" }, [_vm._t("footer")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }