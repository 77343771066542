export default {
  handleLoading(state: any, loadingTrigger: any) {
    state.loadingTrigger = loadingTrigger;
  },
  handleReloadApp(state: any) {
    state.reloadApp = Math.random();
  },
  handleLocaleChange(state: any, locale: string) {
    state.locale = locale;
  },
  handleRenderMenu(state: any, status: boolean) {
    state.renderMenu = status;
  },
  handleWithoutMenu(state: any, status: any) {
    state.onboardingWithoutMenu = status;
    const x = document.getElementsByClassName('collapse-close');
    // eslint-disable-next-line
    for (let i = 0; i < x.length; i++) {
      if (status) {
        x[i].classList.remove('p-0');
      } else {
        x[i].classList.add('p-0');
      }
    }
  },
};
