
import Vue, { PropOptions } from 'vue';
import { DigitalCertificate } from '@/types';

export default Vue.extend({
  name: 'DigitalCertificateStatus',
  props: {
    row: {
      type: Object as () => object,
      required: true,
    } as PropOptions,
  },
  computed: {
    getStatus(): string {
      const { validate } = this.row as DigitalCertificate;

      if (this.$moment(validate).isAfter(new Date())) {
        return 'active';
      }

      return 'inactive';
    },
  },
});
