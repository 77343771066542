import { State } from './state';

const getters = {
  supplier: (state: State) => (state.supplier),
  supplierMasters: (state: State) => (state.supplier?.masters),
  isSupplier: (state: State) => (!!state.supplier),
  supplierId: (state: State) => (state.supplier?.id),
};

export default getters;
