
import StorageService from '@/common/services/onboarding-bank/StorageService';
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import { StorageCsvPayload } from '../../../../common/model/StorageCsvPayload';

export default mixins(NotificationMixin).extend({
  name: 'ModelTableModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      csvFileName: '' as string,
    };
  },
  computed: {
    modal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean) {
        this.$emit('update:showModal', value);
      },
    },
  },
  methods: {
    async downloadCsvFile(): Promise<void> {
      this.infoToast({
        text: this.$t('onboardingBank.downloadCsvInfo') as string,
      }, {
        life: 10000,
      });

      const fileName = await this.getFilenameCsv();
      const payload = new StorageCsvPayload(fileName);
      try {
        if (this.page === 'accountantRobot') {
          const { data } = await StorageService.downloadCsvAccountant(payload);
          window.open(data as string, '_blank');
        } else {
          const { data } = await StorageService.downloadCsvTax(payload);
          window.open(data as string, '_blank');
        }
        this.clearToast();
      } catch {
        this.errorToast({
          text: this.$t('onboardingBank.downloadCsvError') as string,
        });
      }
    },
    getFilenameCsv(): string {
      if (this.model === 'modelCompanies') {
        return this.$t(`onboardingBank.${this.page}.csv.companies`).toString();
      } if (this.model === 'modelAccount') {
        return this.$t(`onboardingBank.${this.page}.csv.account`).toString();
      } if (this.model === 'productLaunch') {
        return this.$t(`onboardingBank.${this.page}.csv.product`).toString();
      } if (this.model === 'serviceLaunch') {
        return this.$t(`onboardingBank.${this.page}.csv.services`).toString();
      }
      return '';
    },

  },
});
