import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  onboarding: {
    finish: {
      finalizeProcess: {
        error: {
          message: 'Ocorreu uma falha ao tentar finalizar o processo, tente novamente em instates.',
        },
      },
    },
  },
};

export const es = {
  onboarding: {
    finish: {
      finalizeProcess: {
        error: {
          message: 'Hubo un error al intentar finalizar el proceso, intente nuevamente en instancias.',
        },
      },
    },
  },
};

export const en = {
  onboarding: {
    finish: {
      finalizeProcess: {
        error: {
          message: 'There was a failure trying to end the process, try again in instances.',
        },
      },
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
