var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "PageCard",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("HeaderSection", {
            on: {
              "on:create": function ($event) {
                _vm.showModal = true
              },
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _c("IntegrationSystemFormModal", {
            attrs: { "form-payload": _vm.item, "show-modal": _vm.showModal },
            on: {
              "update:showModal": function ($event) {
                _vm.showModal = $event
              },
              "update:show-modal": function ($event) {
                _vm.showModal = $event
              },
              submitted: _vm.integrationSystemFormSubmitted,
            },
          }),
          _c(
            "div",
            { staticClass: "integration" },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _c("ContentSection", {
                    attrs: { "reload-table": _vm.reloadTable },
                    on: {
                      "update:reloadTable": function ($event) {
                        _vm.reloadTable = $event
                      },
                      "update:reload-table": function ($event) {
                        _vm.reloadTable = $event
                      },
                      editIntegrationSystem: function ($event) {
                        return _vm.editItem($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }