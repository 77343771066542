import axios from '@/common/http';
import { Company } from '@/types';

export default {
  SET_LOGGED_USER(state: any, { user }: any) {
    state.user = user;
    localStorage.setItem('id', JSON.stringify(user));
  },

  SET_TOKEN(state: any, token: any) {
    axios.defaults.headers.common = {
      Authorization: token,
    };

    state.token = token;
  },

  SET_ACCEPT_LANGUAGE(state: any) {
    switch (state.user.country) {
      case 'BR':
        state.acceptLanguage = 'pt-BR';
        break;
      default:
        state.acceptLanguage = 'en';
    }
  },

  SET_USER_COMPANY(state: any, company: Company) {
    state.userCompany = company;
  },

  LOGOUT(state: any) {
    state.token = '';
    state.user = {};
    state.userCompany = {};
    localStorage.removeItem('pt');
    (window as any).location = process.env.VUE_APP_AUTH_SIGN_OUT_REDIRECT;
  },

  SET_FIRST_ACCESS(state: any, firstAccess: any) {
    state.firstAccess = firstAccess;
  },
  SET_PERMISSIONS(state: any, permissions: any) {
    state.permissions = permissions;
  },
};
