
import Vue from 'vue';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import { SapEccParams } from '@/resources/integrations/types';

type Auth = SapEccParams['auth']

export default Vue.extend({
  name: 'SapEccAuthSectionForm',
  components: {
    InputPassword,
  },
  props: {
    systemParameters: {
      type: Object as () => SapEccParams,
      required: true,
    },
  },
  data() {
    const {
      username = '',
      userpass,
    } = this.systemParameters.auth || {};

    return {
      auth: {
        username,
        userpass,
      } as Auth,
    };
  },
  watch: {
    auth: {
      deep: true,
      handler(auth: Auth) {
        this.$emit('update:systemParameters', { ...this.systemParameters, auth, __type: 'SapEccParams' });
      },
    },
  },
});
