var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("Header", { staticClass: "header-bank" }, [
        _c("div", { attrs: { id: "company" } }, [
          _c("div", { staticClass: "btn-menu-wrap" }, [
            _c(
              "div",
              { attrs: { id: "navToogle" }, on: { click: _vm.showMenu } },
              [_c("i", { staticClass: "icon-r-nav-collapse" })]
            ),
          ]),
          _c("div", { attrs: { id: "brand" } }, [
            _c("img", {
              staticStyle: { height: "25px" },
              attrs: {
                src: require("@/common/assets/images/logo-roit-admin.svg"),
                alt: "",
              },
              on: { click: _vm.returnHome },
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end align-items-center",
              attrs: { id: "user" },
            },
            [
              _c(
                "div",
                { attrs: { id: "bar__return-to-bank-wrap" } },
                [
                  _c(
                    "Button",
                    {
                      staticClass: "bar__btn-return-to-bank outlined flat",
                      on: { click: _vm.returnToBank },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("menu.headerBar.btnGoToMagicFlow"))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "user-profile" },
                [
                  _c(
                    "el-dropdown",
                    { attrs: { trigger: "click" } },
                    [
                      _c(
                        "span",
                        { staticClass: "el-dropdown-link" },
                        [
                          _c(
                            "el-avatar",
                            {
                              staticClass: "bg-light gold-ring",
                              class: { "leading-9": !_vm.avatar },
                            },
                            [
                              _vm.avatar !== null
                                ? _c("img", {
                                    staticClass: "profile-pic",
                                    attrs: { src: _vm.avatar },
                                  })
                                : _vm._e(),
                              _vm.avatar === null
                                ? _c("i", { staticClass: "icon-r-user" })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "dropdown-user",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        [
                          _c("div", { staticClass: "user__wrap" }, [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center flex-col mb-2",
                                },
                                [
                                  _c(
                                    "el-avatar",
                                    {
                                      staticClass:
                                        "user__profile-pic flex items-center flex-col justify-center",
                                    },
                                    [
                                      _vm.avatar !== null
                                        ? _c("img", {
                                            attrs: { src: _vm.avatar },
                                          })
                                        : _vm._e(),
                                      _vm.avatar === null
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                background: "#f9fafb",
                                                margin: "4px",
                                                "border-radius": "50%",
                                                height: "3.8rem",
                                                width: "3.8rem",
                                                display: "flex",
                                                "justify-content": "center",
                                                "align-items": "center",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-r-user w-8 h-8",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "user__bio" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold text-truncate user__bio__name",
                                  },
                                  [_c("span", [_vm._v(_vm._s(_vm.user.name))])]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-truncate user__bio__text",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.user.company.name)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-truncate user__bio__text",
                                  },
                                  [_c("span", [_vm._v(_vm._s(_vm.user.email))])]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "user__return-to-bank-wrap mt-3",
                                },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass:
                                        "user__btn-return-to-bank outlined flat",
                                      on: { click: _vm.returnToBank },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "menu.headerBar.btnGoToMagicFlow"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", [
                                _c("ul", { staticClass: "user__options" }, [
                                  _c(
                                    "li",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push("/profile")
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("menu.headerBar.myData")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "Button",
                                {
                                  staticClass: "outlined flat btn-getout",
                                  on: {
                                    click: function ($event) {
                                      return _vm.logout()
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "mx-auto" }, [
                                    _vm._v("Sair"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "aside",
        {
          class: { "menu-shadow": !_vm.isCollapse && !_vm.controlCLoseOpen },
          attrs: { id: "menu-lateral" },
          on: { mouseenter: _vm.timeOutCollapse, mouseleave: _vm.onMouseLeave },
        },
        [
          _c(
            "el-menu",
            {
              staticClass: "menu-lateral",
              attrs: {
                "default-active": "1",
                collapse: _vm.isCollapse,
                "unique-opened": true,
              },
            },
            [
              _vm.$authHandle.handlePermissionMenu("bankadminmyproductsmenu")
                ? _c(
                    "router-link",
                    { attrs: { to: "/my-products" } },
                    [
                      _c(
                        "el-menu-item",
                        {
                          ref: "firtElement",
                          staticClass: "'order-1'",
                          attrs: { index: "1" },
                        },
                        [
                          _c("i", { staticClass: "icon-r-box-idea" }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("menu.sideBar.myProducts")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$authHandle.handlePermissionMenu("bankadminmycompaniesmenu")
                ? _c(
                    "router-link",
                    { attrs: { to: "/my-companies" } },
                    [
                      _c(
                        "el-menu-item",
                        { staticClass: "order-2", attrs: { index: "2" } },
                        [
                          _c("i", { staticClass: "icon-r-briefcase" }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("menu.sideBar.myCompanies")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$authHandle.handlePermissionMenu(
                "bankadminuserandcontactsmenu"
              )
                ? _c(
                    "router-link",
                    { attrs: { to: "/users-and-contacts" } },
                    [
                      _c(
                        "el-menu-item",
                        { staticClass: "order-3", attrs: { index: "3" } },
                        [
                          _c("i", { staticClass: "icon-r-followers" }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("menu.sideBar.users")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$authHandle.handlePermissionMenu(
                "bankadmindigitalcertificatesmenu"
              )
                ? _c(
                    "router-link",
                    { attrs: { to: "/digital-certificates" } },
                    [
                      _c(
                        "el-menu-item",
                        { staticClass: "order-4", attrs: { index: "4" } },
                        [
                          _c("i", { staticClass: "icon-r-key" }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("menu.sideBar.digitalCertificates")
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$authHandle.handlePermissionMenu("bankadminintegrationsmenu")
                ? _c(
                    "router-link",
                    { attrs: { to: "/integrations" } },
                    [
                      _c(
                        "el-menu-item",
                        { staticClass: "order-5", attrs: { index: "5" } },
                        [
                          _c("i", { staticClass: "icon-r-puzzle" }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("menu.sideBar.integrations"))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$authHandle.handlePermissionMenu("bankadminaccesspassmenu")
                ? _c(
                    "router-link",
                    { attrs: { to: "/access-pass" } },
                    [
                      _c(
                        "el-menu-item",
                        { staticClass: "order-7", attrs: { index: "7" } },
                        [
                          _c("i", {
                            staticClass: "icon-r-padlock-permissions",
                          }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("menu.sideBar.accessPass")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$authHandle.handlePermissionMenu(
                "bankadminautomatictaxinvoicemenu"
              )
                ? _c(
                    "router-link",
                    { attrs: { to: "/automatic-tax-invoice-inbound" } },
                    [
                      _c(
                        "el-menu-item",
                        { staticClass: "order-8", attrs: { index: "8" } },
                        [
                          _c("i", { staticClass: "icon-r-upload-automatic" }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("menu.sideBar.taxInvoiceInbound"))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$authHandle.handlePermissionMenu("bankadmintaskmanagermenu")
                ? _c(
                    "router-link",
                    { attrs: { to: "/task-manager" } },
                    [
                      _c(
                        "el-menu-item",
                        { staticClass: "order-9", attrs: { index: "9" } },
                        [
                          _c("i", { staticClass: "icon-r-calendar-time" }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("menu.sideBar.taskManager")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("GenerateReportModal", {
        attrs: { control: _vm.showReportModal },
        on: {
          "update:control": function ($event) {
            _vm.showReportModal = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }