var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationProvider", {
    ref: "validationProvider",
    attrs: {
      vid: _vm.vid,
      name: _vm.name,
      rules: _vm.rules,
      "custom-messages": _vm.customMessages,
      immediate: _vm.immediate,
      mode: _vm.mode,
      tag: _vm.tag,
      slim: _vm.slim,
      debounce: _vm.debounce,
      persist: _vm.persist,
      bails: _vm.bails,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ errors, pending }) {
            return [
              _vm.label
                ? _c(
                    "label",
                    {
                      staticClass: "fs13 label-inputs",
                      attrs: { for: _vm.name },
                    },
                    [_vm._v(" " + _vm._s(_vm.label) + " ")]
                  )
                : _vm._e(),
              _vm._t("default", null, { errors: errors, pending: pending }),
              !_vm.slim
                ? _c("small", {
                    class: {
                      "p-invalid": errors[0],
                      ...{ ...(errors[0] ? _vm.classInvalidText : {}) },
                    },
                    domProps: { innerHTML: _vm._s(errors[0]) },
                  })
                : _vm._e(),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }