
import Vue from 'vue';
import { mapMutations } from 'vuex';
import SupportInternationalization from './components/SupportInternationalization.vue';

export default Vue.extend({
  name: 'ConfigHomeScreen',
  components: {
    SupportInternationalization,
  },
  mounted() {
    this.handleWithoutMenu(false);
  },
  methods: {
    ...mapMutations({
      handleWithoutMenu: 'handleWithoutMenu',
    }),
    goToBank(): void {
      const { token } = this.$store.state.auth;
      window.location.href = `${process.env.VUE_APP_ROIT_BANK_URL}?token=${token}&originRedirect=t`;
    },
  },
});
