import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  digitalCertificates: {
    dialogs: {
      delete: {
        title: 'Excluir Certificado Digital',
        message: 'Confirma a exclusão deste Certificado Digital?',
      },
      cantDeleteOnlyUpdate: {
        title: 'Excluir Certificado Digital',
        message: 'O certificado digital não pode ser excluir, pois está sendo utilizado para as baixas automáticas de documentos.',
        extraMessage: `
          Para excluí-lo é necessário remover todos os vínculos com as baixas automáticas.
          <br />
          <br />
          É possível atualizar o certificado digital enviando o novo sem a necessidade de excluir. Deseja atualizar o certificado digital?
        `,
      },
    },
    view: {
      title: 'Cadastre novos certificados digitais ou configure os alertas de vencimento.',
      pageTitle: 'Upload de Certificados Digitais',
      pageSubtitle: 'Selecione uma empresa ou cadastre uma nova para enviar o Certificado Digital.',
      dropdown: {
        label: 'Selecione uma empresa',
        placeholder: 'Selecione uma empresa',
      },
    },
    table: {
      headers: {
        document: 'CNPJ',
        companyName: 'Razão Social',
        fileName: 'Nome do arquivo',
        password: 'Senha',
        validity: 'Validade',
        status: 'Status',
        SEFAZNoteDownload: 'Download NFe/CTe SEFAZ',
      },
      listMsgError: 'Não foi possível encontrar os certificados cadastrados',
      employeeNotFound: 'Não foi possível encontrar os certificados cadastrados. Não encontramos o seu registro no quadro de funcionários.',
      errorSearch: 'Não foi possível fazer a busca',
    },
    modal: {
      title: 'Autorizar Upload de Certificado(s)',
      subtitle: 'Para fazer o upload do(s) certificado(s), é necessário informar a senha para autorização.',
    },
    dropzone: {
      title: 'Arraste aqui o arquivo (.PFX ou .P12) do Certificado Digital ou clique para selecioná-lo',
      duplicate: 'Certificado {name} já foi adicionado',
      msgIncorrectFile: 'Extensão de arquivo inválida',
      msgCorruptCertificate: 'O certificado digital selecionado para upload está corrompido!',
    },
    form: {
      fields: {
        password: {
          label: 'Senha',
        },
      },
    },
    notifications: {
      msgDropzoneNotCompany: 'Selecione uma empresa',
      msgSuccessInclude: 'Certificados Digitais incluídos com sucesso!',
      msgErrorInclude: 'Não foi possível incluir o Certificado Digital',
      msgErrorEstablishmentNotFound: 'Empresa dona do certificado não encontrada, cadastre a empresa antes do certificado',
      msgErrorAlreadyExists: 'O certificado já está cadastrado!',
      userMasterNotSameFromEstab: 'O certificado enviado não pertence a uma empresa vinculada ao usuário!',
      passwordIncorrect: 'Senha inválida!',
      msgDeleteSuccess: 'Certificado Digital excluído com sucesso!',
      msgDeleteError: 'Não foi possível excluir o Certificado Digital',
      titleMessageError: 'Os seguintes erros foram reportados:',
      msgUpdateCanSEFAZNotaDownloadSuccess: 'Atualizado pode fazer download na Sefaz para o Certificado Digital!',
      msgUpdateError: 'Não foi possível atualizar o Certificado Digital',
    },
    validateCertificate: {
      expirationAlertButton: 'Alertas de vencimento',
      modal: {
        header: 'Alertas de Vencimento',
        description: 'Configure a periodicidade e os e-mails para onde serão enviados os alertas de vencimento de seus certificados.',
        radioLabel: 'Enviar alerta antes de:',
        inputLabel: 'E-mail',
        placeholder: 'Digite um e-mail',
        daysOptions: {
          sixty: '60 dias',
          thirty: '30 dias',
          five: '5 dias',
          one: '1 dia',
        },
        formInvalid: 'Digite um email válido e pressione Enter',
        requestCertificateAlertError: 'Falha ao registrar as informações, tente novamente!',
        requestCertificateAlertSuccess: {
          update: 'Alerta de vencimento atualizado com sucesso!',
          create: 'Alerta de vencimento criado com sucesso!',
        },
        repeatedEmailError: 'Este email já existe',
        emptySaveModal: {
          header: 'Atenção!',
          description: 'Ao salvar o alerta sem conter ao menos um e-mail cadastrado, o mesmo será inativado. Deseja mesmo realizar este procedimento?',
        },
        confirmRemoveItemModal: {
          header: 'Excluir e-mail',
          description: 'Confirma a exclusão deste e-mail?',
        },
      },
    },
  },
};

export const es = {
  digitalCertificates: {
    dialogs: {
      delete: {
        title: 'Eliminar Certificado Digital',
        message: '¿Confirma la eliminación de este Certificado Digital?',
      },
      cantDeleteOnlyUpdate: {
        title: 'Eliminar Certificado Digital',
        message: 'El certificado digital no se puede eliminar, ya que se está utilizando para las cancelaciones automáticas de documentos.',
        extraMessage: `
          Para eliminarlo, es necesario eliminar todos los vínculos con las cancelaciones automáticas.
          <br />
          <br />
          Es posible actualizar el certificado digital enviando el nuevo sin la necesidad de eliminarlo. ¿Quieres actualizar el certificado digital?
        `,
      },
    },
    view: {
      title: 'Registre nuevos certificados digitales o configure las alertas de vencimiento.',
      pageTitle: 'Upload de Certificados Digitales',
      pageSubtitle: 'Seleccione una empresa o registre una nueva para enviar el Certificado Digital.',
      dropdown: {
        label: 'Seleccione una empresa',
        placeholder: 'Seleccione una empresa',
      },
    },
    table: {
      headers: {
        document: 'CNPJ',
        companyName: 'Razón Social',
        fileName: 'Nombre del archivo',
        password: 'Contraseña',
        validity: 'Validez',
        status: 'Estado',
        SEFAZNoteDownload: 'Descargar NFe/CTe SEFAZ',
      },
      listMsgError: 'No se pudieron encontrar los certificados registrados',
      employeeNotFound: 'No se pudieron encontrar los certificados registrados. No encontramos su registro en el cuadro de empleados.',
      errorSearch: 'No se pudo realizar la búsqueda',
    },
    modal: {
      title: 'Autorizar la carga de certificado(s)',
      subtitle: 'Para cargar el certificado(s), debe ingresar la contraseña para autorizar.',
    },
    dropzone: {
      title: 'Arrastre aquí el archivo (.PFX o .P12) del Certificado Digital o haga clic para seleccionarlo',
      duplicate: 'Certificado {name} ya ha sido agregado',
      msgIncorrectFile: 'Extensión de archivo inválida',
      msgCorruptCertificate: '¡El certificado digital seleccionado para cargar está dañado!',
    },
    form: {
      fields: {
        password: {
          label: 'Contraseña',
        },
      },
    },
    notifications: {
      msgDropzoneNotCompany: 'Seleccione una empresa',
      msgSuccessInclude: '¡Certificados digitales incluidos con éxito!',
      msgErrorInclude: 'No se pudo incluir el Certificado Digital',
      msgErrorEstablishmentNotFound: 'Empresa propietaria del certificado no encontrada, registre la empresa antes del certificado',
      msgErrorAlreadyExists: '¡El certificado ya está registrado!',
      userMasterNotSameFromEstab: '¡El certificado enviado no pertenece a una empresa vinculada al usuario!',
      passwordIncorrect: '¡Contraseña inválida!',
      msgDeleteSuccess: '¡Certificado digital eliminado con éxito!',
      msgDeleteError: 'No se pudo eliminar el Certificado Digital',
      titleMessageError: 'Se informaron los siguientes errores:',
      msgUpdateCanSEFAZNotaDownloadSuccess: '¡Actualizado puede descargar en Sefaz para el Certificado Digital!',
      msgUpdateError: 'No se pudo actualizar el Certificado Digital',
    },
    validateCertificate: {
      expirationAlertButton: 'Alertas de vencimiento',
      modal: {
        header: 'Alertas de Vencimiento',
        description: 'Configure la periodicidad y los correos electrónicos a los que se enviarán las alertas de vencimiento de sus certificados.',
        radioLabel: 'Enviar alerta antes de:',
        inputLabel: 'Correo electrónico',
        placeholder: 'Ingrese un correo electrónico',
        daysOptions: {
          sixty: '60 días',
          thirty: '30 días',
          five: '5 días',
          one: '1 día',
        },
        formInvalid: 'Ingrese un correo electrónico válido y presione Enter',
        requestCertificateAlertError: '¡Error al registrar la información, inténtelo de nuevo!',
        requestCertificateAlertSuccess: {
          update: '¡Alerta de vencimiento actualizada con éxito!',
          create: '¡Alerta de vencimiento creada con éxito!',
        },
        repeatedEmailError: 'Este correo electrónico ya existe',
        emptySaveModal: {
          header: '¡Atención!',
          description: 'Al guardar la alerta sin contener al menos un correo electrónico registrado, se desactivará. ¿Realmente quieres hacer este procedimiento?',
        },
        confirmRemoveItemModal: {
          header: 'Eliminar correo electrónico',
          description: '¿Confirma la eliminación de este correo electrónico?',
        },
      },
    },
  },
};

export const en = {
  digitalCertificates: {
    dialogs: {
      delete: {
        title: 'Delete Digital Certificate',
        message: 'Confirm the deletion of this Digital Certificate?',
      },
      cantDeleteOnlyUpdate: {
        title: 'Delete Digital Certificate',
        message: 'The digital certificate cannot be deleted, as it is being used for automatic document cancellations.',
        extraMessage: `
          To delete it, it is necessary to remove all links with automatic cancellations.
          <br />
          <br />
          It is possible to update the digital certificate by sending the new one without the need to delete it. Do you want to update the digital certificate?
        `,
      },
    },
    view: {
      title: 'Register new digital certificates or configure expiration alerts.',
      pageTitle: 'Upload Digital Certificates',
      pageSubtitle: 'Select a company or register a new one to send the Digital Certificate.',
      dropdown: {
        label: 'Select a company',
        placeholder: 'Select a company',
      },
    },
    table: {
      headers: {
        document: 'CNPJ',
        companyName: 'Company Name',
        fileName: 'File Name',
        password: 'Password',
        validity: 'Validity',
        status: 'Status',
        SEFAZNoteDownload: 'Download NFe/CTe SEFAZ',
      },
      listMsgError: 'No registered certificates were found',
      employeeNotFound: 'No registered certificates were found. We did not find your record in the employee box.',
      errorSearch: 'Search could not be performed',
    },
    modal: {
      title: 'Authorize the upload of certificate(s)',
      subtitle: 'To upload the certificate(s), you must enter the password to authorize.',
    },
    dropzone: {
      title: 'Drag and drop the Digital Certificate file (.PFX or .P12) here or click to select it',
      duplicate: 'Certificate {name} has already been added',
      msgIncorrectFile: 'Invalid file extension',
      msgCorruptCertificate: 'The selected Digital Certificate to upload is corrupted!',
    },
    form: {
      fields: {
        password: {
          label: 'Password',
        },
      },
    },
    notifications: {
      msgDropzoneNotCompany: 'Select a company',
      msgSuccessInclude: 'Digital certificates successfully included!',
      msgErrorInclude: 'Digital Certificate could not be included',
      msgErrorEstablishmentNotFound: 'Certificate owner company not found, register the company before the certificate',
      msgErrorAlreadyExists: 'The certificate is already registered!',
      userMasterNotSameFromEstab: 'The certificate sent does not belong to a company linked to the user!',
      passwordIncorrect: 'Invalid password!',
      msgDeleteSuccess: 'Digital certificate successfully deleted!',
      msgDeleteError: 'Digital Certificate could not be deleted',
      titleMessageError: 'The following errors were reported:',
      msgUpdateCanSEFAZNotaDownloadSuccess: 'Updated can download in Sefaz for Digital Certificate!',
      msgUpdateError: 'Digital Certificate could not be updated',
    },
    validateCertificate: {
      expirationAlertButton: 'Expiration alerts',
      modal: {
        header: 'Expiration Alerts',
        description: 'Configure the periodicity and emails to which the expiration alerts of your certificates will be sent.',
        radioLabel: 'Send alert before:',
        inputLabel: 'Email',
        placeholder: 'Enter an email',
        daysOptions: {
          sixty: '60 days',
          thirty: '30 days',
          five: '5 days',
          one: '1 day',
        },
        formInvalid: 'Enter a valid email and press Enter',
        requestCertificateAlertError: 'Error registering information, try again!',
        requestCertificateAlertSuccess: {
          update: 'Expiration alert successfully updated!',
          create: 'Expiration alert successfully created!',
        },
        repeatedEmailError: 'This email already exists',
        emptySaveModal: {
          header: 'Attention!',
          description: 'By saving the alert without containing at least one registered email, it will be deactivated. Do you really want to do this procedure?',
        },
        confirmRemoveItemModal: {
          header: 'Delete email',
          description: 'Do you confirm the deletion of this email?',
        },
      },
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
