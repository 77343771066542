var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "roit-data-table" },
    [
      _c(
        "DataTable",
        {
          attrs: {
            selection: _vm.selected,
            value: _vm.rows,
            "removable-sort": "",
            "edit-mode": _vm.editMode,
          },
          on: {
            "update:selection": function ($event) {
              _vm.selected = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "empty",
                fn: function () {
                  return [_vm._t("empty")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }