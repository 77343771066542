import moduleAuth from '../store/auth/moduleAuth';
/* eslint-disable */
export class AuthHandle {

    handlePermissionMenu = function (value: string) {
        const permissions = moduleAuth.state.permissions;

        const permissionIndex = permissions.roles.findIndex(
            (permission: string) => permission.split('::')[1] === value || permission.split('::')[1] === 'bankadmin',
        );

        if (permissionIndex < 0) {
            return false;
        }
        return true;
    }
}
/* eslint-disable */
