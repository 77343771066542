
import Vue from 'vue';

export default Vue.extend({
  name: 'StepButtons',
  props: {
    visibleBack: {
      type: Boolean,
      default: true,
    },
    visibleJump: {
      type: Boolean,
      default: true,
    },
    visibleNext: {
      type: Boolean,
      default: true,
    },
    visibleFinish: {
      type: Boolean,
    },
    disableJump: {
      type: Boolean,
    },
    disableNext: {
      type: Boolean,
    },
    disableFinish: {
      type: Boolean,
    },
  },
});
