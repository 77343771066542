import Vue from 'vue';
import Menu from './menu/Menu.vue';
import Table from './table/Table.vue';
import Alert from './alert/Alert.vue';

import UploadDropzone from './upload-dropzone/UploadDropzone.vue';
import AccountBalance from './accountBalance/AccountBalance.vue';
import RecuseFileModal from './recuseFileModal/RecuseFileModal.vue';
import GenerateReportModal from './generateReportModal/GenerateReportModal.vue';
import AddCompanyModal from './addCompanyModal/AddCompanyModal.vue';
import ReportSenderModal from './reportSenderModal/ReportSenderModal.vue';
import Pagination from './table/Pagination.vue';
import InputValidation from './input-validation/InputValidation.vue';
import CompanyGroupModal from './companyGroup/modal/CompanyGroupModal.vue';

Vue.component('custom-menu', Menu);
Vue.component('custom-table', Table);
Vue.component('account-balance', AccountBalance);
Vue.component('recuse-file-modal', RecuseFileModal);
Vue.component('generate-report-modal', GenerateReportModal);
Vue.component('add-company-modal', AddCompanyModal);
Vue.component('company-group-modal', CompanyGroupModal);
Vue.component('report-sender-modal', ReportSenderModal);
Vue.component('upload-dropzone', UploadDropzone);
Vue.component('custom-pagination', Pagination);
Vue.component('input-validation', InputValidation);
Vue.component('r-alert', Alert);
