
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import ChangePasswordForm from '@/common/components/changePassword';
import TwoColumn from '@/layouts/TwoColumn.vue';

export default mixins().extend({
  name: 'ChangePassword',
  components: {
    ChangePasswordForm,
    TwoColumn,
  },
  data() {
    return {
      payload: {
        userId: this.$store.state.auth.user.id,
        username: this.$store.state.auth.user.login,
      },
      token: this.$store.state.auth.token,
      prevRoute: null,
    };
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    handleSubmit() {
      this.handleLoading(true);
    },
    handleResponse() {
      this.handleLoading(false);
    },
  },
});
