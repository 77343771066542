import axios, { AxiosInstance, CancelTokenSource } from '@/common/http';

export type HttpServiceProps = {
  baseURL: string;
}

abstract class HttpService {
  private instance: AxiosInstance;

  private cancelTS?: CancelTokenSource;

  constructor({ baseURL }: HttpServiceProps) {
    this.instance = axios.create({ baseURL });
    this.observerAuthToken();
  }

  get cancelToken(): CancelTokenSource {
    if (this.cancelTS) {
      this.cancelTS.cancel();
    }

    return axios.CancelToken.source();
  }

  get service() {
    return this.instance;
  }

  private observerAuthToken(): void {
    this.instance.interceptors.request.use(
      (config) => {
        const { headers } = axios.defaults;
        return {
          ...config,
          headers,
        };
      },
      (error) => Promise.reject(error),
    );
  }
}

export default HttpService;
