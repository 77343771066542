var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "deleteAllButton" }, [
    _c("div", { staticClass: "deleteBorder d-flex" }, [
      _c(
        "div",
        {
          attrs: { id: "clearAllButtonDiv" },
          on: {
            click: function ($event) {
              return _vm.$emit("delete-all:clear", true)
            },
          },
        },
        [
          _c("Checkbox", {
            attrs: { "model-value": true, binary: "", disabled: "" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center mx-3 pointer",
          attrs: { id: "deleteAllButtonDiv" },
          on: {
            click: function ($event) {
              return _vm.$emit("delete-all:delete", true)
            },
          },
        },
        [
          _c("i", {
            staticClass: "icon-r-trash fs18",
            staticStyle: { color: "#d83636" },
          }),
          _c(
            "span",
            { staticClass: "fs14 ml-2", staticStyle: { color: "#03181EDE" } },
            [_vm._v(_vm._s(_vm.$t("deleteAll")))]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }