import axios from '@/common/http';
import { Pagination } from '@/types';

class HeadOfficeEstablishmentService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1/head-office-establishments`;

  async list() {
    const { data: response } = await this.service.get(`${this.baseUrl}/master-company-information`);
    return response.data;
  }

  async findHeadOfficeAndCheckHasBranchByCompanyId(
    companies: string[], pagination?: Pagination,
  ) {
    const url = new URL(`${this.baseUrl}/search/with-branch-info`);

    if (pagination?.perPage !== undefined && pagination?.page !== undefined) {
      url.searchParams.append('page', String(pagination?.page));
      url.searchParams.append('perPage', String(pagination?.perPage));
    }
    const { data: response } = await this.service.post(url.toString(), companies);

    return response.data;
  }
}

export default new HeadOfficeEstablishmentService();
