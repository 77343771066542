
import Vue, { PropOptions } from 'vue';
import { Establishment } from '@roit-intern/roit-company-interfaces';
import StatusCompanyTable from '@/common/components/company/table/StatusCompanyTable.vue';
import numberRemoveFormat from '@/common/filters/NumberRemoveFormat';
import { EstablishmentWithBranchs, PaginationMetadata } from '@/types';
import EstablishmentService from '@/common/services/EstablishmentService';

export default Vue.extend({
  name: 'EditableCompanyTable',

  components: {
    StatusCompanyTable,
  },

  props: {
    rows: {
      type: Array as () => Establishment[],
      required: true,
    },
    loading: {
      type: Boolean as () => boolean,
      default: false,
    } as PropOptions,
    totalEstablisments: {
      type: Number as () => number,
      required: true,
    },
  },

  data() {
    return {
      tableKey: 0,
      rowsData: [] as EstablishmentWithBranchs[],
      allItemsSelected: false as boolean,
      selectedItems: [] as Array<string>,
      tributaryRegime: [
        { name: 'SIMPLES', value: 'SIMPLES' },
        { name: 'PRESUMIDO', value: 'PRESUMIDO' },
        { name: 'REAL', value: 'REAL' },
        { name: 'ARBITRADO', value: 'ARBITRADO' },
        { name: 'ISENTO', value: 'ISENTO' },
        { name: 'IMUNE', value: 'IMUNE' },
      ] as Array<{ name: string; value: string }>,
      polling: [
        { name: 'COMPETÊNCIA', value: 'COMPETENCIA' },
        { name: 'ESTIMATIVA', value: 'ESTIMATIVA' },
        { name: 'CAIXA', value: 'CAIXA' },
        { name: 'TRIMESTRAL', value: 'TRIMESTRAL' },
        { name: 'ANUAL', value: 'ANUAL' },
        { name: 'OUTROS', value: 'OUTROS' },
      ] as Array<{ name: string; value: string }>,
      retreat: [
        { name: 'TRIBUTO E DOCUMENTO', value: 'document' },
        { name: 'TRIBUTO E FORNECEDOR', value: 'provider' },
        { name: 'ACUMULADO POR TRIBUTO', value: 'tax' },
      ] as Array<{ name: string; value: string }>,
      collapsed: [] as string[],
      initialSort: 'nationalRegister',
      totalBranchs: 0,
      header: {
        alias: [
          'tree',
          this.$t('registerCompanyTable.headers.cnpj'),
          this.$t('registerCompanyTable.headers.razaoSocial'),
          this.$t('registerCompanyTable.headers.city'),
          this.$t('registerCompanyTable.headers.uf'),
          this.$t('registerCompanyTable.headers.status'),
          this.$t('registerCompanyTable.headers.ie'),
          this.$t('registerCompanyTable.headers.im'),
          this.$t('registerCompanyTable.headers.rt'),
          this.$t('registerCompanyTable.headers.polling'),
          this.$t('registerCompanyTable.headers.retreat'),
          null,
        ],
        columnsToShow: [
          'tree',
          'nationalRegister',
          'companyName',
          'address.city',
          'address.state',
          'legalSituation',
          'stateRegistration',
          'municipalRegistration',
          'company.taxRegime.type',
          'company.taxRegime.assessment',
          'retreat',
          '',
        ],
      },
      totalRowLength: 0,
      paginate: false,
    };
  },
  watch: {
    allItemsSelected(value: boolean): void {
      if (value) {
        this.rowsData.forEach((
          establishment: EstablishmentWithBranchs,
        ) => {
          this.selectedItems.push(establishment.nationalRegister);

          // eslint-disable-next-line no-unused-expressions
          establishment.branchs?.forEach((branch: Establishment) => {
            this.selectedItems.push(branch.nationalRegister);
          });
        });
      } else {
        this.selectedItems = [];
      }
    },

    rows: {
      immediate: true,
      handler(values: Array<{headOffice: Establishment; hasBranch: boolean}>): void {
        this.rowsData = values.map((item) => {
          if (item.hasBranch) {
            return {
              ...item.headOffice,
              branchs: [{} as Establishment],
            };
          }

          return {
            ...item.headOffice,
            branchs: [],
          };
        });
      },
    },
    totalEstablisments: {
      immediate: true,
      handler(value): void {
        this.paginate = value > 10;
        this.totalRowLength = value;
      },
    },

    loading(value: boolean): void {
      if (value) {
        this.selectedItems = [];
      }
    },
  },
  methods: {
    editCompany(company: Establishment, index: number): void {
      this.$emit('company:update', {
        index,
        company: {
          ...company,
          stateRegistration: numberRemoveFormat(company.stateRegistration),
        },
      });
    },

    deleteCompany(company: EstablishmentWithBranchs): void {
      const cnpjs = Array(numberRemoveFormat(company.nationalRegister));

      if (company && company?.branchs && company.branchs.length > 0) {
        company.branchs.forEach((comp) => {
          cnpjs.push(numberRemoveFormat(comp.nationalRegister));
        });
      }

      this.$emit('company:delete', cnpjs);
    },

    getStatusActive(status: string): boolean {
      if (['ativa', 'ativo'].includes(status?.toLowerCase())) {
        return true;
      }

      return false;
    },
    async expandable(item: Establishment) {
      const headOfficeIdx = this.rowsData.findIndex((data) => data.id === item.id);

      if (this.rowsData[headOfficeIdx].branchs[0]?.id) {
        return;
      }
      this.$emit('update:loading', true);

      const data = await EstablishmentService.findByCompanyId(
        item.companyId,
        true,
        { page: 0, perPage: 11, serializer: 'my-companies' },
      ) as unknown as {establishments: Establishment[]; metadata: PaginationMetadata};

      const branchs = data.establishments.filter((estab) => estab.type.toLowerCase() === 'filial');

      this.rowsData[headOfficeIdx].branchs.splice(0, 1, ...branchs.slice(0, 11));
      this.$emit('update:loading', false);
    },
    getTypedBranchs(
      establishments: Establishment & {branchs: Establishment[]},
    ): Establishment[] {
      return establishments.branchs.filter((branch) => branch.id);
    },
    updateCurrentPage(currentPage: number) {
      this.$emit('paginateEstablishments', currentPage);
    },
    updatePageSize(pageSize: number) {
      this.$emit('updatePageSize', pageSize);
    },
    seeMoreItens({ companyId, companyName }: Establishment) {
      this.$router.push({ name: 'company-table', params: { companyId, companyName } });
    },
  },
});
