
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import { IntegrationSystem } from '@/types';
import PageCard from '@/common/template/PageCard.vue';
import IntegrationSystemFormModal from '@/resources/integrations/components/modals/IntegrationSystemFormModal.vue';
import locales from './locales';
import HeaderSection from './components/sections/HeaderSection.vue';
import ContentSection from './components/sections/ContentSection.vue';
import './validates';

export default mixins(NotificationMixin).extend({
  name: 'Integrations',
  i18n: {
    messages: locales,
  },
  components: {
    PageCard,
    IntegrationSystemFormModal,
    HeaderSection,
    ContentSection,
  },
  data() {
    return {
      showModal: false,
      item: {} as IntegrationSystem,
      reloadTable: false,
    };
  },
  watch: {
    showModal(showModal) {
      if (!showModal) {
        this.item = {} as IntegrationSystem;
      }
    },
  },
  methods: {
    editItem(item: IntegrationSystem) {
      this.item = item;
      this.showModal = true;
    },
    integrationSystemFormSubmitted() {
      this.showModal = false;
      this.reloadTable = true;
    },
  },
});
