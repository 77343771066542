
import Vue from 'vue';
import { mapGetters } from 'vuex';
import OnboardingButtons from '@/resources/onboarding-bank/components/OnboardingButtons.vue';
import ModelTableModal from './components/tables/ModelTableModal.vue';
import ModelCompaniesTable from './components/tables/ModelCompaniesTable.vue';

export default Vue.extend({
  components: { OnboardingButtons, ModelTableModal, ModelCompaniesTable },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    toogleModal() {
      this.showModal = !this.showModal;
    },
  },
});
