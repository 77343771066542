var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "d-flex justify-content-end align-items-center pb-5 step-buttons",
    },
    [
      _vm.visibleBack
        ? _vm._t("back", function () {
            return [
              _c("Button", {
                staticClass: "outlined",
                attrs: {
                  id: "stepBackButton",
                  label: _vm.$t("onboarding.stepButtons.back"),
                  "icon-pos": "left",
                  icon: "icon-r-chevron-left",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("back")
                  },
                },
              }),
            ]
          })
        : _vm._e(),
      _c("div", { staticClass: "flex-grow-1" }),
      _vm.visibleJump
        ? _vm._t("jump", function () {
            return [
              _c("Button", {
                staticClass: "clear",
                attrs: {
                  id: "stepJumpButton",
                  disabled: _vm.disableJump,
                  label: _vm.$t("onboarding.stepButtons.jumpStep"),
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("jump")
                  },
                },
              }),
            ]
          })
        : _vm._e(),
      _vm.visibleNext
        ? _vm._t("next", function () {
            return [
              _c("Button", {
                class: [
                  "primary next-step",
                  { "shadow-none": _vm.disableNext },
                ],
                attrs: {
                  id: "stepNextButton",
                  disabled: _vm.disableNext,
                  label: _vm.$t("onboarding.stepButtons.nextStep"),
                  "icon-pos": "right",
                  icon: "icon-r-chevron-right",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("next")
                  },
                },
              }),
            ]
          })
        : _vm._e(),
      _vm.visibleFinish
        ? _vm._t("finish", function () {
            return [
              _c("Button", {
                staticClass: "p-button-raised",
                attrs: {
                  id: "stepFinishButton",
                  disabled: _vm.disableFinish,
                  label: _vm.$t("onboarding.stepButtons.finishStep"),
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("finish")
                  },
                },
              }),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }