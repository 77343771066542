
import Vue from 'vue';
import locales from '../../locales';

export default Vue.extend({
  name: 'IntegrationsEmpty',

  i18n: {
    messages: locales,
  },
});
