
import Vue from 'vue';

export default Vue.extend({
  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  data() {
    return {
      value: [] as string | number[],
    };
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },
  },
});
