var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "div",
      { staticClass: "inline-flex" },
      [
        _vm.contacts
          ? _c(
              "div",
              { staticClass: "group-smartphone d-flex align-items-center" },
              [
                _c("i", {
                  staticClass: "icon-r-smartphone bg-color-secondary",
                }),
                _c("span", { staticClass: "color-secondary text-sm" }, [
                  _vm._v("0800-942-3019"),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "el-dropdown",
          {
            staticClass: "d-flex",
            attrs: { type: "primary" },
            on: { command: _vm.changeLocale },
          },
          [
            _c(
              "el-button",
              { attrs: { type: "primary", plain: "" } },
              [
                _c("flag", {
                  attrs: { iso: _vm.selectedFlag, squared: false },
                }),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              _vm._l(_vm.languages, function (entry) {
                return _c(
                  "el-dropdown-item",
                  { key: entry.language, attrs: { command: entry } },
                  [
                    _c(
                      "span",
                      [
                        _c("flag", {
                          attrs: { iso: entry.flag, squared: false },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }