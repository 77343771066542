class ConvertFile {
  // eslint-disable-next-line class-methods-use-this
  public toBase64(file: File): Promise<FileReader> {
    return new Promise<FileReader>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // @ts-ignore
      reader.onload = () => resolve(reader.result.toString().replace(/^data:(.*,)?/, ''));
      reader.onerror = (error) => reject(error);
    });
  }
}

export default new ConvertFile();
