var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid px-2" }, [
    _c("div", { staticClass: "row pt-4 mb-4" }, [
      _c("div", { staticClass: "col-12 grey300" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("integrations.title")))]),
      ]),
    ]),
    _c("div", { staticClass: "row p-ai-end" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-4 p-field" },
        [
          _c("Button", {
            staticClass: "p-button-raised",
            attrs: {
              icon: "icon-r-circle-plus bg-white",
              "icon-pos": "left",
              label: _vm.$t("onboarding.integrations.registerSystems"),
            },
            on: {
              click: function ($event) {
                return _vm.showFormCreate()
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-12 col-sm-12 col-md-4 ml-auto p-field" }, [
        _c("div", { staticClass: "my-token-wrapper" }, [
          _c("span", { staticClass: "my-token-label" }, [
            _vm._v(_vm._s(_vm.$t("integrations.myToken.label"))),
          ]),
          _c("div", { staticClass: "my-token-divisor" }),
          _c("div", { staticClass: "my-token-content flex" }, [
            _vm._m(0),
            _c("span", { staticClass: "my-token-value flex-grow" }, [
              _vm._v(_vm._s(_vm.myToken)),
            ]),
            _c(
              "div",
              {
                staticClass: "my-token-content__right flex-none",
                on: {
                  click: function ($event) {
                    return _vm.copyMyToken()
                  },
                },
              },
              [_c("i", { staticClass: "my-token-content__icon icon-r-copy" })]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "my-token-content__left flex-none" }, [
      _c("i", { staticClass: "my-token-content__icon icon-r-key" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }