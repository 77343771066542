var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid" }, [
    _vm._m(0),
    _c("div", { staticClass: "right-container" }, [
      _c("div", { staticClass: "fade-smooth-bar" }),
      _c(
        "div",
        { staticClass: "i18n" },
        [_c("SupportInternationalization")],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "content-wrap" }, [
        _vm.title
          ? _c("div", { staticClass: "title-outside" }, [
              _c("h3", [_vm._v(_vm._s(_vm.title))]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "content" }, [
          _vm.title
            ? _c("div", { staticClass: "title-inside" }, [
                _c("h3", [_vm._v(_vm._s(_vm.title))]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-center" },
            [_vm._t("default")],
            2
          ),
          _c("div", { staticClass: "form-blur" }),
          _c("div", { staticClass: "bg" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left-container" }, [
      _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("@/common/assets/images/logo-roit-solutions-white.svg"),
          alt: "logomarca roit",
        },
      }),
      _c("img", {
        staticClass: "banner",
        attrs: {
          src: require("@/common/assets/images/bg-web.jpg"),
          alt: "mulher setada a mesa de frente a laptop",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "logo-on-mobile-wrap" }, [
      _c("div", { staticClass: "entry-logo" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("@/common/assets/images/logo-roit-solutions-white.svg"),
            alt: "logomarca roit",
          },
        }),
      ]),
      _c("div", { staticClass: "bg-mobile" }, [
        _c("img", {
          attrs: {
            src: require("@/common/assets/images/bg-mobile.jpg"),
            alt: "mulher setada a mesa de frente a laptop",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }