var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Form",
    [
      _c(
        "ValidationObserver",
        { ref: "form", attrs: { slim: "" } },
        [
          !_vm.inUpdate
            ? [
                _c("div", { staticClass: "pt-2 pb-4" }, [
                  _c("span", { staticClass: "text-grey" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "companyGroupComponent.form.addGroupAndCompanies"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            : _vm.inUpdateCompanies
            ? [
                _c("div", { staticClass: "pt-2 pb-4" }, [
                  _c("span", { staticClass: "text-grey" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("companyGroupComponent.form.addCompanies")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
          _c("div", { staticClass: "grid grid-cols-12 pb20" }, [
            _c(
              "div",
              {
                class: {
                  "col-span-11":
                    (_vm.inUpdate && _vm.inUpdateCompanies) || !_vm.inUpdate,
                  "col-span-12": _vm.inUpdate && !_vm.inUpdateCompanies,
                },
              },
              [
                _c("div", { staticClass: "grid grid-cols-6" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "col-span-3":
                          (_vm.inUpdate && _vm.inUpdateCompanies) ||
                          !_vm.inUpdate,
                        "col-span-6": _vm.inUpdate && !_vm.inUpdateCompanies,
                      },
                    },
                    [
                      _c("span", { staticClass: "text-grey label" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "companyGroupComponent.form.fields.groupName.label"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("input-validation", {
                        attrs: {
                          name: _vm.$t(
                            "companyGroupComponent.form.fields.groupName.label"
                          ),
                          rules: "required",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("InputText", {
                                  class: {
                                    "p-invalid": errors[0],
                                    block: true,
                                    "w-full": true,
                                  },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "companyGroupComponent.form.fields.groupName.placeholder"
                                    ),
                                    disabled:
                                      _vm.inUpdate && _vm.inUpdateCompanies,
                                  },
                                  model: {
                                    value: _vm.form.groupName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "groupName", $$v)
                                    },
                                    expression: "form.groupName",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          (_vm.inUpdate && _vm.inUpdateCompanies) || !_vm.inUpdate
            ? _c(
                "div",
                {
                  staticClass: "pb-1 mx-0",
                  staticStyle: { "overflow-y": "auto", "max-height": "300px" },
                },
                _vm._l(_vm.disableSelects, function (disable, i) {
                  return _c("div", { key: i }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex grip grid-cols-12 pb-3",
                        attrs: { id: "registerCompanies" },
                      },
                      [
                        _c("div", { staticClass: "col-span-11" }, [
                          _c(
                            "div",
                            { staticClass: "flex grip grid-cols-4 gap-4" },
                            [
                              _c(
                                "div",
                                { staticClass: "col-span-1" },
                                [
                                  _vm.form.companies.indexOf(
                                    _vm.form.companies[i]
                                  ) === 0
                                    ? _c(
                                        "span",
                                        { staticClass: "text-grey label" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "companyGroupComponent.form.fields.cnpj"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("input-validation", {
                                    attrs: { rules: "required" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "p-input-icon-right",
                                                },
                                                [
                                                  _c("InputMask", {
                                                    class: {
                                                      "p-invalid": errors[0],
                                                      block: true,
                                                    },
                                                    attrs: {
                                                      mask: "99.999.999/9999-99",
                                                      placeholder: _vm.$t(
                                                        "companyGroupComponent.form.fields.placeholder.cnpj"
                                                      ),
                                                    },
                                                    on: {
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        return _vm.findCompanyDebounce(
                                                          i,
                                                          $event
                                                        )
                                                      },
                                                      paste: function ($event) {
                                                        return _vm.findCompanyDebounce(
                                                          i,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.companies[i]
                                                          .nationalRegister,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.companies[i],
                                                          "nationalRegister",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.companies[i].nationalRegister",
                                                    },
                                                  }),
                                                  _vm.loadings[i]
                                                    ? _c("i", {
                                                        staticClass:
                                                          "pi pi-spin pi-spinner",
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-span-1" },
                                [
                                  _vm.form.companies.indexOf(
                                    _vm.form.companies[i]
                                  ) === 0
                                    ? _c(
                                        "span",
                                        { staticClass: "text-grey label" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "companyGroupComponent.form.fields.taxRegime"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("input-validation", {
                                    attrs: {
                                      rules: _vm.disableInUpdateCompanies(
                                        disable
                                      )
                                        ? ""
                                        : "required",
                                      debounce: _vm.disableInUpdateCompanies(
                                        disable
                                      )
                                        ? 0
                                        : 100,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass:
                                                    "select-input-height-40",
                                                  class: {
                                                    "p-invalid": errors[0],
                                                    "w-full": true,
                                                    block: true,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm.disableInUpdateCompanies(
                                                        disable
                                                      ),
                                                    placeholder: _vm.$t(
                                                      "companyGroupComponent.form.fields.placeholder.taxRegime"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.companies[i]
                                                        .taxRegime,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.companies[i],
                                                        "taxRegime",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.companies[i].taxRegime",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.tributaryRegime,
                                                  function (
                                                    regime,
                                                    tributaryRegimeKey
                                                  ) {
                                                    return _c("el-option", {
                                                      key: tributaryRegimeKey,
                                                      attrs: {
                                                        label: regime.name,
                                                        value: regime.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-span-1" },
                                [
                                  _vm.form.companies.indexOf(
                                    _vm.form.companies[i]
                                  ) === 0
                                    ? _c(
                                        "span",
                                        { staticClass: "text-grey label" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "companyGroupComponent.form.fields.polling"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("input-validation", {
                                    attrs: {
                                      rules: _vm.disableInUpdateCompanies(
                                        disable
                                      )
                                        ? ""
                                        : "required",
                                      debounce: _vm.disableInUpdateCompanies(
                                        disable
                                      )
                                        ? 0
                                        : 100,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass:
                                                    "select-input-height-40",
                                                  class: {
                                                    "p-invalid": errors[0],
                                                    "w-full": true,
                                                    block: true,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm.disableInUpdateCompanies(
                                                        disable
                                                      ),
                                                    placeholder: _vm.$t(
                                                      "companyGroupComponent.form.fields.placeholder.polling"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.companies[i]
                                                        .polling,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.companies[i],
                                                        "polling",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.companies[i].polling",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.polling,
                                                  function (pol, pollingKey) {
                                                    return _c("el-option", {
                                                      key: pollingKey,
                                                      attrs: {
                                                        label: pol.name,
                                                        value: pol.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-span-1" },
                                [
                                  _vm.form.companies.indexOf(
                                    _vm.form.companies[i]
                                  ) === 0
                                    ? _c(
                                        "span",
                                        { staticClass: "text-grey label" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "companyGroupComponent.form.fields.retreat"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("input-validation", {
                                    attrs: {
                                      rules: _vm.disableInUpdateCompanies(
                                        disable
                                      )
                                        ? ""
                                        : "required",
                                      debounce: _vm.disableInUpdateCompanies(
                                        disable
                                      )
                                        ? 0
                                        : 100,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass:
                                                    "select-input-height-40",
                                                  class: {
                                                    "p-invalid": errors[0],
                                                    "w-full": true,
                                                    block: true,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm.disableInUpdateCompanies(
                                                        disable
                                                      ),
                                                    placeholder: _vm.$t(
                                                      "companyGroupComponent.form.fields.placeholder.retreat"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.companies[i]
                                                        .retreat,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.companies[i],
                                                        "retreat",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.companies[i].retreat",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.retreat,
                                                  function (ret, retreatKey) {
                                                    return _c("el-option", {
                                                      key: retreatKey,
                                                      attrs: {
                                                        label: ret.name,
                                                        value: ret.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "flex justify-center",
                            style: {
                              "padding-top": i === 0 ? "20px" : "inherit",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "relative mb-3 h-6 w-14 actions" },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top",
                                        value: _vm.$t("delete"),
                                        expression: "$t('delete')",
                                        modifiers: { top: true },
                                      },
                                    ],
                                    staticClass:
                                      "flex items-center justify-center action ml-3",
                                    class: { disabled: !_vm.canDeleteItem },
                                    on: {
                                      click: function ($event) {
                                        _vm.canDeleteItem &&
                                          _vm.deleteInfo(
                                            _vm.form.companies.indexOf(
                                              _vm.form.companies[i]
                                            )
                                          )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      class: {
                                        "icon-r-trash": true,
                                        color__grey: !_vm.canDeleteItem,
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          (_vm.inUpdate && _vm.inUpdateCompanies) || !_vm.inUpdate
            ? _c(
                "div",
                [
                  _c("Button", {
                    staticClass: "primary p-field",
                    attrs: {
                      icon: "icon-r-circle-plus",
                      label: _vm.$t(
                        "companyGroupComponent.notice.clickToRegister.addNewCompany"
                      ),
                    },
                    on: { click: _vm.addNewCompany },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }