
import Vue from 'vue';
import { PasswordRules } from './types';

export default Vue.extend({
  name: 'StepsChangePassword',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    rules: {
      type: Array as () => Array<PasswordRules>,
      required: true,
    },
    showSteps: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    totalValid(): number {
      return this.rules.reduce((prev: number, curr: PasswordRules) => {
        if (this.validate(curr.rule)) {
          // eslint-disable-next-line no-param-reassign
          prev += 1;
        }
        return prev;
      }, 0);
    },
  },
  methods: {
    validate(regex: RegExp): boolean {
      return regex.test(this.value);
    },
  },
});
