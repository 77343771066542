
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';
import changePassword from './services';
import StepsChangePassword from './StepsChangePassword.vue';
// import StepButtons from '@/resources/onboarding/components/StepButtons.vue';
import {
  ChangePassword, PasswordRules, VForm, Payload,
} from './types';
import locales from './locales';

export default mixins(NotificationMixin).extend({
  name: 'ChangePassword',
  mixins: [NotificationMixin],
  i18n: {
    messages: locales,
  },
  props: {
    firstAccess: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object as () => Payload,
      default: null,
    },
    authServiceToken: {
      type: String,
      default: null,
    },
    useDefaultBtnSubmit: {
      type: Boolean,
      default: true,
    },
    useDefaultBtnContinue: {
      type: Boolean,
      default: false,
    },
    savePassword: {
      type: Boolean,
    },
    validSave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOldPassword: false as boolean,
      showNewPassword: false as boolean,
      showConfirmPassword: false as boolean,
      success: false,
      confirmPassword: '',
      passwordRules: [
        {
          title: this.$t('rules.minCharacter'),
          rule: /^(?=.{8,})/,
        },
        {
          title: this.$t('rules.hasLyrics'),
          rule: /^(?=.*[a-z|A-Z])/,
        },
        {
          title: this.$t('rules.hasNumbers'),
          rule: /^(?=.*[0-9])/,
        },
        {
          title: this.$t('rules.hasSpecialCharacters'),
          rule: /^(?=.*[!@#$%^&*])/,
        },
      ] as Array<PasswordRules>,
      username: this.payload?.username,
      fields: {
        userId: this.payload?.userId || '',
        oldPassword: '',
        newPassword: '',
      } as ChangePassword,
    };
  },
  computed: {
    disableJump(): boolean {
      return !this.success;
    },
    savePass: {
      get(): boolean {
        return this.savePassword;
      },
      set(value: boolean) {
        this.$emit('update:savePassword', value);
      },
    },
  },
  watch: {
    payload(payload) {
      this.fields = payload;
    },
    fields() {
      this.emitIsValid();
    },
    confirmPassword() {
      this.emitIsValid();
    },
    async savePassword(bool: boolean) {
      if (bool) {
        await this.handleChangePassword();
        this.savePass = false;
      }
    },
  },
  components: {
    StepsChangePassword,
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    emitSubmit() {
      this.$emit('submit');
    },
    emitSuccess() {
      this.$emit('success');
    },
    emitError() {
      this.$emit('error');
    },
    async handleChangePassword(): Promise<void> {
      const valid: boolean = await this.formIsValid();

      if (!valid) {
        return;
      }

      this.emitSubmit();
      this.handleLoading(true);

      this.success = await this.changePassword();

      if (this.success) {
        this.successToast({
          text: this.$t('msgSuccess') as string,
        } as any);
      } else {
        this.emitError();
      }

      this.handleLoading(false);
    },
    async formIsValid(): Promise<boolean> {
      const form: any = this.$refs.form as VForm;
      return form.validate();
    },
    async changePassword(): Promise<boolean> {
      try {
        const valid: boolean = await this.formIsValid();

        if (!valid) {
          throw new Error('return_false');
        }

        if (this.fields.newPassword !== this.confirmPassword) {
          this.errorToast({
            text: this.$t('msgPasswordDoesNotMatch') as string,
          } as any);
          throw new Error('return_false');
        }

        if (this.fields.oldPassword === this.fields.newPassword) {
          this.errorToast({
            text: this.$t('msgErrorNotEqualPasswords') as string,
          } as any);
          throw new Error('return_false');
        }

        this.infoToast({
          text: this.$t('msgWorking') as string,
        });

        const userData: ChangePassword = {
          ...this.fields,
        };

        await changePassword(userData);

        return true;
      } catch (error) {
        const { message, response } = error as any;
        if (message !== 'return_false') {
          this.errorToast({
            text: this.errorPassword(response.data.message),
          } as any);
        }
        return false;
      }
    },
    errorPassword(message: any) {
      const whoMessage = message.toLowerCase().split(' ');
      const messageValue = whoMessage[0];

      const typeMessage: any = {
        current: this.$t('msnCurrentPasswordFail'),
        userpassword: this.$t('msnErrorChangePassword'),
        user: this.$t('msnUsernameNotFound'),
      };
      return typeMessage[messageValue.replace('/', '')];
    },
    emitIsValid() {
      const form: any = this.$refs.form as VForm;
      form.validate().then((valid: boolean) => {
        this.$emit('isValid', valid);
      });
    },
  },
});

