import Vue from 'vue';

export default Vue.extend({
  methods: {
    nextRoute(): void {
      const { nextRoute }: any = this.$route?.meta;
      this.$router.push(`/onboarding-bank/${nextRoute}`);
    },
    jumpStep(): void {
      this.nextRoute();
    },
    nextStep(): void {
      this.nextRoute();
    },
    backStep(): void {
      const { prevRoute }: any = this.$route?.meta;
      this.$router.push(`/onboarding-bank/${prevRoute}`);
    },
  },
});
