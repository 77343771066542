
import Vue from 'vue';
import DateFormat from '@/common/filters/DateFormat';
import { UserAdminList } from '@/types';

export default Vue.extend({
  name: 'ResponsibleTable',

  filters: {
    DateFormat,
  },
  props: {
    rows: {
      type: Array as () => UserAdminList[],
      required: true,
    },
    loading: {
      type: Boolean as () => boolean,
      required: false,
      default: false,
    },
    hideCheckbox: {
      type: Boolean as () => boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      allItemsSelected: false,
      selectedItems: [] as Array<string>,
      products: ['ROIT'],
      header: {
        alias: [
          'checkbox',
          this.$t('ManagerResponsible.headers.responsible'),
          this.$t('ManagerResponsible.headers.mail'),
          this.$t('ManagerResponsible.headers.job'),
          this.$t('ManagerResponsible.headers.products'),
        ],
        columnsToShow: [
          'checkbox',
          'fullName',
          'email',
          'job',
          'products',
        ],
      },
    };
  },
  computed: {
    rowsData: {
      get(): UserAdminList[] {
        return this.rows;
      },
      set(values: UserAdminList[]) {
        this.$emit('input', values);
      },
    },
  },
  watch: {
    hideCheckbox: {
      immediate: true,
      handler(hideCheckbox) {
        this.updateTableAlias(hideCheckbox);
        this.updateTableColumnsToShow(hideCheckbox);
      },
    },
  },
  methods: {
    handleCheckAll(): void {
      if (this.allItemsSelected) {
        this.selectedItems = this.rows.map((row: any) => row.userId);
      } else {
        this.selectedItems = [];
      }
    },
    updateTableHeaderCheckbox(hideCheckbox: boolean, session: 'alias' | 'columnsToShow'): void {
      if (session) {
        const head = this.header[session];
        const CHECKBOX = 'checkbox';
        const hasCheckbox = head.includes(CHECKBOX);
        if (hideCheckbox && hasCheckbox) {
          head.shift();
        } else if (!hasCheckbox && !hasCheckbox) {
          head.unshift(CHECKBOX);
        }
      }
    },
    updateTableAlias(hideCheckbox: boolean): void {
      this.updateTableHeaderCheckbox(hideCheckbox, 'alias');
    },
    updateTableColumnsToShow(hideCheckbox: boolean): void {
      this.updateTableHeaderCheckbox(hideCheckbox, 'columnsToShow');
    },
  },
});
