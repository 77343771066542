
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import TwoColumn from '@/layouts/TwoColumn.vue';
import { setTimeout } from 'timers';
import NotificationMixin from '@/mixins/NotificationMixin';
import locales from '@/resources/onboarding/locales';

export default mixins(NotificationMixin).extend({
  name: 'Finish',

  i18n: {
    messages: locales,
  },

  components: {
    TwoColumn,
  },

  props: {
    finish: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      onboardUpdated: false,
    };
  },

  async mounted() {
    if (this.finish) {
      this.handleLoading(true);
      await this.handleUpdateOnboard();
      this.handleLoading(false);
    }
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    async handleUpdateOnboard() {
      this.onboardUpdated = true;
    },

    async accessCompany() {
      this.handleLoading(true);

      const upRoute = () => {
        setTimeout(() => {
          this.$store.commit('auth/SET_FIRST_ACCESS', false);
          this.$router.push({ name: 'my-products' });
        }, 600);
      };

      if (this.onboardUpdated) {
        upRoute();
      } else {
        try {
          await this.handleUpdateOnboard();
          upRoute();
        } catch (error) {
          console.log(error);
          this.errorToast({
            text: this.$t('onboarding.finish.finalizeProcess.error.message') as string,
          });
        }
      }
      this.handleLoading(false);
    },
  },
});
