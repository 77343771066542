import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  automaticTaxInvoiceInbound: {
    header: {
      title: 'Cadastre e configure as baixas automáticas para as empresas (Matriz/Filiais).',
    },
    register: {
      headerRegister: 'Cadastrar Baixas Automáticas',
      headerEdit: 'Editar Baixas Automáticas',
      defaultCertificate: 'Certificado Padrão',
      selectCertificate: 'Selecione um Certificado',
      emptyCertificate: 'Nenhum certificado',
      company: 'Empresa',
      description: 'Selecione as opções de certificados e baixas automáticas abaixo.',
      xmls: "Ciência Auto. de XML's",
      table: {
        alias: {
          NFe: 'NFe',
          NFSe: 'NFSe',
          CTe: 'CTe',
          certificate: 'Certificado',
          municipalRegistration: 'Inscrição Municipal',
          legalSituation: 'Situação Legal',
          branches: 'Filiais',
          corporateName: 'Razão Social',
        },
        passwordModal: {
          supplierType: {
            label: 'Tipo de Fornecedor/Órgão',
            placeholder: 'Prefeitura',
          },
          username: 'Usuário',
          password: 'Senha',
          saveBtnLabel: 'Salvar Senha',
          updatePasswordFail: 'Falha ao salvar usuário e senha',
          updatePasswordSuccess: 'Usuário e senha salvos com sucesso',
        },
        getFail: 'Falha ao buscar os dados',
        municipalRegistrationWarning: 'Para habilitar a baixa automática de NFSe é necessário que a empresa possua Inscrição Municipal cadastrada.',
      },
    },
    companiesTable: {
      enableInbound: 'Habilitar Baixas',
      alias: {
        companies: 'Empresas',
        corporateName: 'Razão Social',
        certificate: 'Certificado',
        taxInvoiceInbound: 'Baixas Automáticas',
        automaticScience: 'Ciência Automática',
        status: 'Status',
      },
    },
    notice: {
      notFoundView: '',
    },
  },
};

export const es = {
  automaticTaxInvoiceInbound: {
    header: {
      title: 'Dar de alta y configurar cancelaciones automáticas para empresas (Sede/Sucursales).',
    },
    register: {
      headerRegister: 'Registrar cancelaciones automáticas',
      headerEdit: 'Editar cancelaciones automáticas',
      selectCertificate: 'Seleccione un certificado',
      emptyCertificate: 'Sin certificado',
      defaultCertificate: 'Certificado estándar',
      company: 'Empresa',
      description: 'Seleccione las opciones de certificados y cancelaciones automáticas a continuación.',
      xmls: "Ciencia Auto. XML's",
      table: {
        alias: {
          NFe: 'NFe',
          NFSe: 'NFSe',
          CTe: 'CTe',
          certificate: 'Certificado',
          municipalRegistration: 'Registro Municipal',
          legalSituation: 'Situación Legal',
          branches: 'Sucursales',
          corporateName: 'Razón social',
        },
        passwordModal: {
          supplierType: {
            label: 'Tipo de proveedor / agencia',
            placeholder: 'Ayuntamiento',
          },
          username: 'Usuario',
          password: 'Contraseña',
          saveBtnLabel: 'Guardar Contraseña',
          updatePasswordFail: 'No se pudo guardar el nombre de usuario y la contraseña',
          updatePasswordSuccess: 'Nombre de usuario y contraseña guardados con éxito',
        },
        getFail: 'Error al obtener datos',
        municipalRegistrationWarning: 'Para habilitar la cancelación automática de NFSe, la empresa debe tener un Registro Municipal registrado.',
      },
    },
    companiesTable: {
      enableInbound: 'Habilitar cancelaciones',
      alias: {
        companies: 'Empresas',
        corporateName: 'Razón social',
        certificate: 'Certificado',
        taxInvoiceInbound: 'Cancelaciones Automáticas',
        automaticScience: 'Ciencia Automática',
        status: 'Status',
      },
    },
    notice: {
      notFoundView: '',
    },
  },
};

export const en = {
  automaticTaxInvoiceInbound: {
    header: {
      title: 'Register and configure automatic taxInvoiceInbound for companies (Headquarters/Branches).',
    },
    register: {
      headerRegister: 'Register Automatic taxInvoiceInbound',
      headerEdit: 'Edit Automatic taxInvoiceInbound',
      selectCertificate: 'Select a Certificate',
      emptyCertificate: 'No certificate',
      defaultCertificate: 'Default Certificate',
      company: 'Company',
      description: 'Select certificates and taxInvoiceInbound options below.',
      xmls: "Science Auto. from XML's",
      table: {
        alias: {
          NFe: 'NFe',
          NFSe: 'NFSe',
          CTe: 'CTe',
          municipalRegistration: 'Municipal registration',
          certificate: 'Certificate',
          legalSituation: 'Legal Situation',
          branches: 'Branches',
          corporateName: 'Corporate Name',
        },
        passwordModal: {
          supplierType: {
            label: 'Type of Supplier/Agency',
            placeholder: 'City Hall',
          },
          username: 'User',
          password: 'Password',
          saveBtnLabel: 'Save Password',
          updatePasswordFail: 'Failed to save username and password',
          updatePasswordSuccess: 'Username and password saved successfully',
        },
        municipalRegistration: 'To enable the automatic write-off of NFSe, the company must have a registered Municipal Registration.',
        getFail: 'Failed to fetch data',
      },
    },
    companiesTable: {
      enableInbound: 'Enable Inbound',
      alias: {
        companies: 'Companies',
        corporateName: 'Corporate Name',
        certificate: 'Certificate',
        taxInvoiceInbound: 'Automatic taxInvoiceInbound',
        automaticScience: 'Automatic Science',
        status: 'Status',
      },
    },
    notice: {
      notFoundView: '',
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
