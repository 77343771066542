
import Vue from 'vue';

export default Vue.extend({
  name: 'RoitDataTable',
  props: {
    editMode: {
      type: String,
      default: null,
    },
    selectionValue: {
      type: Array as unknown as () => Array<never>,
      default() {
        return [];
      },
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selected: [],
  }),
  watch: {
    selected: {
      handler(value) {
        this.$emit('update:selectionValue', value);
      },
    },
  },
  created() {
    this.selected = this.selectionValue;
  },
});
