var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          style: { width: "50vw" },
          attrs: { header: "Header", visible: _vm.displayModal, modal: true },
          on: {
            "update:visible": function ($event) {
              _vm.displayModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("h3", [_vm._v("Recusa de Documento")])]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("Button", {
                    staticClass: "outlined p-button-danger p-button-raised",
                    attrs: { label: "Cancelar" },
                    on: {
                      click: function ($event) {
                        _vm.displayModal = false
                      },
                    },
                  }),
                  _c("Button", {
                    staticClass: "p-button-danger p-button-raised",
                    attrs: { label: "Recusar" },
                    on: {
                      click: function ($event) {
                        _vm.displayModal = false
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "p-fluid p-formgrid p-grid" }, [
            _c(
              "div",
              { staticClass: "p-field p-col-12" },
              [
                _c("label", { attrs: { for: "documento" } }, [
                  _vm._v("Documento"),
                ]),
                _c("InputText", {
                  staticClass: "form-input-disabled",
                  attrs: { id: "documento", type: "text", disabled: "" },
                  model: {
                    value: _vm.value1,
                    callback: function ($$v) {
                      _vm.value1 = $$v
                    },
                    expression: "value1",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "p-fluid p-formgrid p-grid" }, [
            _c(
              "div",
              { staticClass: "p-field p-col-12" },
              [
                _c("label", { attrs: { for: "motivo" } }, [_vm._v("Motivo")]),
                _c("Textarea", {
                  staticClass: "form-input",
                  attrs: {
                    id: "motivo",
                    rows: "5",
                    cols: "30",
                    placeholder: "Informe o motivo da recusa do documento",
                  },
                  model: {
                    value: _vm.value2,
                    callback: function ($$v) {
                      _vm.value2 = $$v
                    },
                    expression: "value2",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-start align-items-center options-inline",
            },
            [
              _c("span", [_vm._v("Deseja comunicar a recusa ao emissor?")]),
              _c(
                "div",
                { staticClass: "p-field-radiobutton mb-0 ml-2" },
                [
                  _c("RadioButton", {
                    attrs: { id: "nao", name: "city", value: false },
                    model: {
                      value: _vm.value3,
                      callback: function ($$v) {
                        _vm.value3 = $$v
                      },
                      expression: "value3",
                    },
                  }),
                  _c("label", { staticClass: "mb-0", attrs: { for: "nao" } }, [
                    _vm._v("Não"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "p-field-radiobutton mb-0 ml-2" },
                [
                  _c("RadioButton", {
                    attrs: { id: "sim", name: "city", value: true },
                    model: {
                      value: _vm.value3,
                      callback: function ($$v) {
                        _vm.value3 = $$v
                      },
                      expression: "value3",
                    },
                  }),
                  _c("label", { staticClass: "mb-0", attrs: { for: "sim" } }, [
                    _vm._v("Sim"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }