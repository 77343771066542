
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import SupportInternationalization from './components/SupportInternationalization.vue';

export default Vue.extend({
  name: 'StartOnboardingBank',
  components: {
    SupportInternationalization,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    this.handleWithoutMenu(false);
    this.handleRenderMenu(false);
  },
  methods: {
    redirectWithMenu() {
      this.$router.push('/onboarding-bank');
    },
    ...mapMutations({
      handleWithoutMenu: 'handleWithoutMenu',
      handleRenderMenu: 'handleRenderMenu',
    }),
  },
});
