import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  taskManager: {
    header: {
      title: 'Confira os agendamentos das tarefas:',
      button: 'Criar Tarefa',
    },
    table: {
      status: {
        enabled: 'Ativo',
        paused: 'Inativo',
        notRun: 'Ainda não foi executado',
      },
      comfirmModal: {
        deleteTask: {
          message: 'Confirmar a exclusão deste agendamento/serviço?',
          header: 'Excluir Agendamento',
        },
        runTask: {
          runMessage: 'Confirmar a execução deste agendamento/serviço?',
          resumeMessage: 'Confirmar a retomada deste agendamento/serviço?',
          runHeader: 'Executar Agendamento',
          resumeHeader: 'Retomar Agendamento',
          resumeAcceptLabel: 'Retomar',
        },
        pauseTask: {
          message: 'Confirmar a pausa deste agendamento/serviço?',
          header: 'Pausar Agendamento',
        },
      },
      services: {
        inboundSefaz: 'Baixa Automática de NFe',
        inboundSefazCTe: 'Baixa Automática de CTe',
        inboundNFSe: 'Baixa Automática de NFSe',
        billConsumptionEnergy: 'Baixa automática de Faturas de Energia',
        billConsumptionWater: 'Baixa automática de Faturas de Água',
        billConsumptionCommunication: 'Baixa automática de Faturas de Comunicação',
        billConsumptionGas: 'Baixa automática de Faturas de Gás',
      },
      alias: {
        service: 'Serviço',
        description: 'Descrição',
        status: 'Status',
        frenquency: 'Frequência',
        lastTask: 'Última Tarefa',
        lastTaskStatus: 'Status da Última Tarefa',
        nextTask: 'Próxima Tarefa',
      },
    },
    historic: {
      header: 'Histórico',
      description: ' Acompanhe o histórico do agendamento.',
    },
    configTask: {
      header: 'Editar Tarefa',
      title: 'Serviço',
      subtitleHour: 'Com que frequência essa tarefa ocorrerá?',
      everyHours: 'Todas às horas',
      specificHour: 'Hora específica',
      interval: 'Intervalo',
      everyXHours: 'A cada X horas',
      everyXMinutes: 'A cada X minutos',
      from: 'De',
      to: 'Até',
      everyDaysOfWeek: 'Todos os dias da semana',
      subtitleDayOfWeek: 'Quais dias da semana?',
      selectDaysOfWeek: 'Selecionar dias da semana',
      subtitleDayOfMonth: 'Quais dias do mês?',
      everyDaysOfMonth: 'Todos os dias',
      selectDaysOfMonth: 'Selecionar dias',
      everyXDaysOfMonth: 'A cada X dias',
      subtitleMonths: 'Como você deseja configurar este agendamento?',
      everyMonths: 'Todos os meses',
      selectMonths: 'Selecionar meses',
      everyXMounths: 'A cada X meses',
      helpText: 'Digite os dias separados por vírgula ou a faixa de dias separando por traço. \n\nExemplos:\n 1,2,5: Todos os dias 1,2 e 5 \n1-10: Todos os dias de 1 a 10',
      daysOfWeekInvalidMessage: 'Selecione ao menos 1 dia da semana',
      monthsInvalidMessage: 'Selecione ao menos 1 mês',
      daysOfWeekFirstLetter: {
        sunday: 'D',
        monday: 'S',
        tuesday: 'T',
        wednesday: 'Q',
        thursday: 'Q',
        friday: 'S',
        saturday: 'S',
      },
      daysOfWeek: {
        sunday: 'Domingo',
        monday: 'Segunda',
        tuesday: 'Terça',
        wednesday: 'Quarta',
        thursday: 'Quinta',
        friday: 'Sexta',
        saturday: 'Sabádo',
      },
      abbreviationMonths: {
        january: 'JAN',
        february: 'FEV',
        march: 'MAR',
        april: 'ABR',
        may: 'MAI',
        june: 'JUN',
        july: 'JUL',
        august: 'AGO',
        september: 'SET',
        october: 'OUT',
        november: 'NOV',
        december: 'DEZ',
      },
      months: {
        january: 'Janeiro',
        february: 'Fevereiro',
        march: 'Março',
        april: 'Abril',
        may: 'Maio',
        june: 'Junho',
        july: 'Julho',
        august: 'Agosto',
        september: 'Setembro',
        october: 'Outubro',
        november: 'Novembro',
        december: 'Dezembro',
      },
    },
    toast: {
      pauseFail: 'Falha ao pausar a tarefa',
      deleteFail: 'Falha ao deletar a tarefa',
      resumeFail: 'Falha ao retomar a tarefa',
      runFail: 'Falha ao executar a tarefa',
      getTasksFail: 'Falha ao buscar às tarefas',
      createFail: 'Falha ao criar a tarefa',
      updateFail: 'Falha ao atualizar a tarefa',
      pause: 'Tarefa pausada',
      delete: 'Tarefa deletada',
      resume: 'Tarefa retomada',
      run: 'Tarefa executada',
      create: 'Tarefa criada',
      update: 'Tarefa atualizada',
    },
    caption: {
      scheduled: 'Agendado',
    },
  },
};

export const en = {
  taskManager: {
    header: {
      title: 'Check the task schedules:',
      button: 'Create task',
    },
    table: {
      status: {
        enabled: 'Active',
        paused: 'Inactive',
        notRun: 'Has not yet run',
      },
      comfirmModal: {
        deleteTask: {
          message: 'Confirm the deletion of this schedule/service?',
          header: 'Delete Schedule',
        },
        runTask: {
          runMessage: 'Confirm the execution of this schedule/service?',
          resumeMessage: 'Confirm the resumption of this schedule/service?',
          runHeader: 'Execute Scheduling',
          resumeHeader: 'Resume Scheduling',
          resumeAcceptLabel: 'Resume',
        },
        pauseTask: {
          message: 'Confirm the break of this schedule/service?',
          header: 'Pause Scheduling',
        },
      },
      services: {
        inboundSefaz: 'NFe Invoice Inbound',
        inboundSefazCTe: 'CTe Invoice Inbound',
        inboundNFSe: 'NFSe Invoice Inbound',
        billConsumptionEnergy: 'Energy Invoices Inbound',
        billConsumptionWater: 'Water Invoices Inbound',
        billConsumptionCommunication: 'Communication Invoices Inbound',
        billConsumptionGas: 'Gas Invoices Inbound',
      },
      alias: {
        service: 'Service',
        description: 'Description',
        status: 'Status',
        frenquency: 'Frenquency',
        lastTask: 'Last Task',
        lastTaskStatus: 'Last Task Status',
        nextTask: 'Next task',
      },
    },
    historic: {
      header: 'Historic',
      description: 'Keep track of appointment history.',
    },
    configTask: {
      header: 'Edit Task',
      title: 'Service',
      subtitleHour: 'How often will this task occur?',
      everyHours: 'All the time',
      specificHour: 'Specific time',
      interval: 'Interval',
      everyXHours: 'Every X hours',
      everyXMinutes: 'Every X minutes',
      from: 'From',
      to: 'To',
      everyDaysOfWeek: 'Every day of the week',
      subtitleDayOfWeek: 'What days of the week?',
      selectDaysOfWeek: 'Select days of the week',
      subtitleDayOfMonth: 'Which days of the month?',
      everyDaysOfMonth: 'Every day',
      selectDaysOfMonth: 'Select days',
      everyXDaysOfMonth: 'Every X days',
      subtitleMonths: 'How do you want to configure this schedule?',
      everyMonths: 'Every month',
      selectMonths: 'Select months',
      everyXMounths: 'Every X months',
      helpText: 'Enter the days separated by a comma or the range of days separated by a dash. \n\nExamples:\n 1,2,5: Every day 1,2 and 5 \n1-10: Every day from 1 to 10',
      daysOfWeekInvalidMessage: 'Select at least 1 day of the week',
      monthsInvalidMessage: 'Please select at least 1 month',
      daysOfWeekFirstLetter: {
        sunday: 'S',
        monday: 'M',
        tuesday: 'T',
        wednesday: 'W',
        thursday: 'T',
        friday: 'F',
        saturday: 'S',
      },
      daysOfWeek: {
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
      },
      abbreviationMonths: {
        january: 'JAN',
        february: 'FEB',
        march: 'MAR',
        april: 'APR',
        may: 'MAY',
        june: 'JUN',
        july: 'JUL',
        august: 'AUG',
        september: 'SEP',
        october: 'OCT',
        november: 'NOV',
        december: 'DEC',
      },
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
      },
    },
    toast: {
      pauseFail: 'Failed to pause task',
      deleteFail: 'Failed to delete task',
      resumeFail: 'Failed to resume task',
      runFail: 'Failed to run task',
      getTasksFail: 'Failed to fetch tasks',
      createFail: 'Failed to create task',
      updateFail: 'Failed to update task',
      pause: 'Task paused',
      delete: 'Task deleted',
      resume: 'Resumed task',
      run: 'Task executed',
      create: 'Task created',
      update: 'Task updated',
    },
    caption: {
      scheduled: 'Scheduled',
    },
  },
};

export const es = {
  taskManager: {
    header: {
      title: 'Consulta los cronogramas de tareas:',
      button: 'Crear tarea',
    },
    table: {
      status: {
        enabled: 'Activo',
        paused: 'Inactivo',
        notRun: 'Aún no ejecutado',
      },
      comfirmModal: {
        deleteTask: {
          message: '¿Confirmar la eliminación de este horario/servicio?',
          header: 'Eliminar horario',
        },
        runTask: {
          runMessage: '¿Confirmar la ejecución de este horario/servicio??',
          resumeMessage: '¿Confirmar la reanudación de esta cita/servicio?',
          runHeader: 'Ejecutar programación',
          resumeHeader: 'Reanudar la programación',
          resumeAcceptLabel: 'Reanudar',
        },
        pauseTask: {
          message: '¿Confirmar la ruptura de esta cita/servicio?',
          header: 'Pausar programación',
        },
      },
      services: {
        inboundSefaz: 'Cancelación Automática de NFe',
        inboundSefazCTe: 'Cancelación Automática de NTe',
        inboundNFSe: 'Cancelación Automática de NFSe',
        billConsumptionEnergy: 'Cancelación automática de facturas de Energía',
        billConsumptionWater: 'Cancelación utomática de facturas de Agua',
        billConsumptionCommunication: 'Cancelación automática de facturas de Comunicación',
        billConsumptionGas: 'Cancelación automática de facturas de Gas',
      },
      alias: {
        service: 'Servicio',
        description: 'Descripción',
        status: 'Estado',
        frenquency: 'Frecuencia',
        lastTask: 'Última tarea',
        lastTaskStatus: 'Estado de la última tarea',
        nextTask: 'Siguiente tarea',
      },
    },
    historic: {
      header: 'Histórico',
      description: 'Mantenga un registro del historial de citas.',
    },
    configTask: {
      header: 'Editar tarea',
      title: 'Servicio',
      subtitleHour: '¿Con qué frecuencia ocurrirá esta tarea?',
      everyHours: 'Todo el tiempo',
      specificHour: 'Tiempo específico',
      interval: 'Romper',
      everyXHours: 'Cada X horas',
      everyXMinutes: 'Cada X minutos',
      from: 'En',
      to: 'hasta',
      everyDaysOfWeek: 'Todos los días de la semana',
      subtitleDayOfWeek: '¿Qué días de la semana?',
      selectDaysOfWeek: 'Seleccionar días de la semana',
      subtitleDayOfMonth: '¿Qué días del mes?',
      everyDaysOfMonth: 'Todos los días',
      selectDaysOfMonth: 'seleccionar días',
      everyXDaysOfMonth: 'Cada X dias',
      subtitleMonths: '¿Cómo quieres configurar este horario?',
      everyMonths: 'Todos los meses',
      selectMonths: 'Seleccionar meses',
      everyXMounths: 'Cada X meses',
      helpText: 'Introduzca los días separados por una coma o el rango de días separados por un guión. \n\nEjemplos:\n 1,2,5: Todos los días 1,2 y 5 \n1-10: Todos los días del 1 al 10',
      daysOfWeekInvalidMessage: 'Seleccione al menos 1 día de la semana',
      monthsInvalidMessage: 'Seleccione al menos 1 mes',
      daysOfWeekFirstLetter: {
        sunday: 'D',
        monday: 'L',
        tuesday: 'M',
        wednesday: 'M',
        thursday: 'J',
        friday: 'V',
        saturday: 'S',
      },
      daysOfWeek: {
        sunday: 'Domingo',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
      },
      abbreviationMonths: {
        january: 'ENE',
        february: 'FEB',
        march: 'MAR',
        april: 'ABR',
        may: 'MAYO',
        june: 'JUN',
        july: 'JUL',
        august: 'AGO',
        september: 'SEPT',
        october: 'OCT',
        november: 'NOV',
        december: 'DIC',
      },
      months: {
        january: 'Enero',
        february: 'Febrero',
        march: 'Marcha',
        april: 'April',
        may: 'Mayo',
        june: 'Junio',
        july: 'Julio',
        august: 'Agosto',
        september: 'Septiembre',
        october: 'Octubre',
        november: 'Noviembre',
        december: 'Diciembre',
      },
    },
    toast: {
      pauseFail: 'No se pudo pausar la tarea',
      deleteFail: 'No se pudo eliminar la tarea',
      resumeFail: 'No se pudo reanudar la tarea',
      runFail: 'Error al ejecutar la tarea',
      getTasksFail: 'No se pudieron recuperar las tareas',
      createFail: 'No se pudo crear la tarea',
      updateFail: 'No se pudo actualizar la tarea',
      pause: 'Tarea en pausa',
      delete: 'Tarea eliminada',
      resume: 'Tarea reanudada',
      run: 'Tarea realizada',
      create: 'Tarea creada',
      update: 'Tarea actualizada',
    },
    caption: {
      scheduled: 'Programado',
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
