import axios, { CancelTokenSource } from '@/common/http';
import { DisableEmployee, EnableEmployee } from '@/types';

type Profile = {
  id: string;
  name: string;
}

export enum AllowedAccessTypesEnum {
  LOGIN_PASS = 'LOGIN_PASS', SSO = 'SSO'
}

export type UserCreate = {
  firstName: string;
  lastName: string;
  job: string;
  email: string;
  profiles: Profile[];
  userPhone?: string;
  responsabilities: string[];
  companyGroups?: string[];
  allowedAccessTypes: Array<keyof typeof AllowedAccessTypesEnum>;
}

export type UserUpdate = {
  firstName?: string;
  lastName?: string;
  job?: string;
  email?: string;
  profiles?: Profile[];
  userPhone?: string;
  responsabilities?: string[];
  allowedAccessTypes: Array<keyof typeof AllowedAccessTypesEnum>;
}

class UserService {
  private service: typeof axios = axios;

  private cancelTS?: CancelTokenSource;

  private baseURL = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/users`;

  private companyCoreRebornURL = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1`;

  private cancelToken(): CancelTokenSource {
    if (this.cancelTS) {
      this.cancelTS.cancel(UserService.name);
    }

    return this.service.CancelToken.source();
  }

  public findUsersByGroupId(groupId: string, search?: string) {
    let baseUrl = `${this.companyCoreRebornURL}/employee/company-users/${groupId}`;

    if (search) {
      baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
    }

    this.cancelTS = this.cancelToken();
    return this.service.get(baseUrl, {
      cancelToken: this.cancelTS.token,
    });
  }

  public createUser(payload: UserCreate) {
    return this.service.post(this.baseURL, payload);
  }

  public updateUser(userId?: string, payload?: UserUpdate) {
    return this.service.patch(`${this.baseURL}/${userId}`, payload);
  }

  public importUsers(file: string) {
    return this.service.post(`${this.baseURL}/import`, { file });
  }

  public findProfiles() {
    const baseUrl = `${this.companyCoreRebornURL}/profile-management/profiles-to-users`;

    return this.service.get(baseUrl);
  }

  public isCancelRequest(error: any): boolean {
    return this.service.isCancel(error);
  }

  public getOccupations() {
    const baseUrl = `${this.companyCoreRebornURL}/employee/occupations`;
    return this.service.get(baseUrl);
  }

  public disableUser(payload: DisableEmployee) {
    const baseUrl = `${this.companyCoreRebornURL}/employee/disable`;
    return this.service.post(baseUrl, payload);
  }

  public enableUser(payload: EnableEmployee) {
    const baseUrl = `${this.companyCoreRebornURL}/employee/enable`;
    return this.service.post(baseUrl, payload);
  }

  public disableUsersBatch(payload: DisableEmployee[]) {
    const baseUrl = `${this.companyCoreRebornURL}/employee/disable-batch`;
    return this.service.post(baseUrl, { data: payload });
  }

  public enableUsersBatch(payload: EnableEmployee[]) {
    const baseUrl = `${this.companyCoreRebornURL}/employee/enable-batch`;
    return this.service.post(baseUrl, { data: payload });
  }

  public paginationUsersAndSearch(data: {master?: string; supplier?: string}, search?: string) {
    const baseUrl = `${this.companyCoreRebornURL}/employee/pagination`;

    const params = new URLSearchParams();
    if (search) {
      params.append('search', search);
    }

    Object.keys(data).forEach((key) => {
      if (data[key as keyof typeof data]) {
        params.append(key, data[key as keyof typeof data] as string);
      }
    });

    this.cancelTS = this.cancelToken();

    return this.service.get(baseUrl, {
      params,
      cancelToken: this.cancelTS.token,
    });
  }
}

export default new UserService();
