import axios from '@/common/http';
import store from '@/store/auth/moduleAuth';
import { Certificate, CreateCertificateAlert, UpdateCertificateAlert } from '@/types';

class CertificateService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_CERTIFICATES_URL_GATEWAY}`;

  public async findCertificates(search = '', page?: number, perPage?: number): Promise<Certificate[]> {
    const url = new URL(`${this.baseUrl}/certificate`);

    if (perPage !== undefined && page !== undefined) {
      url.searchParams.append('page', String(page));
      url.searchParams.append('perPage', String(perPage));
    }
    if (search) {
      url.searchParams.append('search', search);
    }

    const result = await this.service.get<{data: Certificate[]}>(url.toString());
    return result.data.data;
  }

  public async createCertificates(certificates: Certificate) {
    return this.service.post(`${this.baseUrl}/certificate`, certificates, {
      headers: {
        'Accept-Language': store.state.acceptLanguage,
      },
    });
  }

  public deleteCertificate(id: any) {
    return this.service.delete(`${this.baseUrl}/certificate/${id}`);
  }

  public certificateContent(id: any) {
    return this.service.get(`${this.baseUrl}/certificate/${id}/content`);
  }

  public updateCanSEFAZNoteDownload(id: string, bool: boolean) {
    return this.service.patch(`${this.baseUrl}/certificate/${id}/update-can-sefaz-download`, {
      canSEFAZNoteDownload: bool,
    });
  }

  public async createCertificateAlert(
    payload: CreateCertificateAlert,
  ): Promise<CreateCertificateAlert> {
    return (await this.service.post(`${this.baseUrl}/certificate-alert`, payload)).data.data;
  }

  public async getCertificateAlert(masterId: string): Promise<CreateCertificateAlert> {
    return (await this.service.get(`${this.baseUrl}/certificate-alert/master/${masterId}`)).data.data;
  }

  public async updateCertificateAlert(
    payload: UpdateCertificateAlert,
  ): Promise<CreateCertificateAlert> {
    return (await this.service.patch(`${this.baseUrl}/certificate-alert/master/${payload.masterId}`, payload)).data.data;
  }
}

export default new CertificateService();
