import EmployeeService from '@/common/services/EmployeeService';
import HttpApiSupplierService from '@/common/services/HttpApiSupplierService';
import { ActionContext } from 'vuex';

export default {
  async handleSupplier({ commit }: ActionContext<any, any>): Promise<boolean> {
    const employee = await EmployeeService.me();

    if (!employee.supplier) {
      return false;
    }

    const supplier = await HttpApiSupplierService.supplier(employee.supplier);

    commit('SET_SUPPLIER', supplier);

    return true;
  },
};
