import HttpService from './HttpService';

class HttpApiSupplierService extends HttpService {
  private supplierUrn = '/suppliers';

  constructor() {
    super({ baseURL: process.env.VUE_APP_SUPPLIER_URL_GATEWAY as string });
  }

  async supplier(id: string) {
    const { data: response } = await this.service.get(`${this.supplierUrn}/${id}`);
    return response.data;
  }
}

export default new HttpApiSupplierService();
