
import Vue, { PropOptions } from 'vue';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import { RoitFile, VForm } from '@/types';

export default Vue.extend({
  name: 'DigitalCertificateModalUpload',
  components: {
    InputPassword,
  },
  props: {
    show: {
      type: Boolean as () => boolean,
      default: false,
    } as PropOptions,
    files: {
      type: Array as () => Array<RoitFile>,
      default: () => ([]),
    } as PropOptions,
  },
  computed: {
    showModal: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean): void {
        this.$emit('update:show', value);
      },
    },
  },
  methods: {
    submit() {
      const form: any = this.$refs.form as VForm;

      form.validate()
        .then((valid: boolean) => {
          if (!valid) return;
          this.$emit('sending', this.files.map((file: RoitFile) => ({
            ...file,
            readonly: true,
            sent: file.sent,
          }) as RoitFile));
          this.showModal = false;
        });
    },
  },
});
