import axios from 'axios';
import { RoitApiResponse } from '../../model/RoitApiResponse';
import { StorageCsvPayload } from '../../model/StorageCsvPayload';

class StorageService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY_BANK}/api/v1/storage`;

  public async downloadCsvAccountant(payload: StorageCsvPayload): Promise<RoitApiResponse> {
    const response = await this.service.post(`${this.baseUrl}/contabil-csv`, payload);
    return response.data as RoitApiResponse;
  }

  public async downloadCsvTax(payload: StorageCsvPayload): Promise<RoitApiResponse> {
    const response = await this.service.post(`${this.baseUrl}/fiscal-csv`, payload);
    return response.data as RoitApiResponse;
  }
}

export default new StorageService();
