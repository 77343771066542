import axios, { AxiosResponse } from '@/common/http';
import { ChangePassword } from '../types';

interface Response {
  data: string;
  errors: Array<any> | null;
  status: 'SUCCESS' | 'ERROR';
}

const changePassword = (userData: ChangePassword): Promise<AxiosResponse<Response>> => axios.post<Response>(`${process.env.VUE_APP_ROIT_IAM_AUTH_URL}/api/v1/authentication/change-password`, userData);

export default changePassword;
