
import Vue from 'vue';
import ProgressSpinner from 'primevue/progressspinner';
import { mapGetters } from 'vuex';

Vue.component('ProgressSpinner', ProgressSpinner);

export default Vue.extend({
  name: 'Loading',
  computed: {
    ...mapGetters(['loadingTrigger']),
  },
});
