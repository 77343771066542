var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("RegisterDigitalCertificate", {
        on: {
          "has-data": function ($event) {
            _vm.disableNext = !$event
          },
        },
      }),
      _c("r-alert", {
        attrs: {
          "text-title": _vm.textTitle,
          "text-content": _vm.textContent,
          "text-btn-ok": _vm.textBtnOk,
          "text-btn-cancel": _vm.textBtnCancel,
          visible: _vm.alertIsVisible,
        },
        on: {
          close: function ($event) {
            return _vm.hadleAlertIsVisible(false)
          },
          ok: function ($event) {
            return _vm.jumpStep()
          },
        },
      }),
      _c("StepButtons", {
        attrs: {
          "disable-next": _vm.disableNext,
          "visible-jump": _vm.disableNext,
        },
        on: {
          jump: function ($event) {
            return _vm.handleJumpStep()
          },
          next: function ($event) {
            return _vm.nextStep()
          },
          back: function ($event) {
            return _vm.backStep()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }