var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "onboarding-bank table-container" }, [
    _c("table", { staticClass: "w-full" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.CTBAccount.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.CTBAccount.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.CTBAccount.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountType.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountType.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountType.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountClassification.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountClassification.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountClassification.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountDescription.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountDescription.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.accountDescription.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.CNPJInt.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.CNPJInt.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.modelAccount.CNPJInt.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }