import { RouteConfig } from 'vue-router';

import WelcomeVideo from '@/resources/onboarding-bank/WelcomeVideo.vue';
import OnboardingSteps from '@/resources/onboarding-bank/components/OnboardingSteps.vue';
import TaxRobot from '@/resources/onboarding-bank/TaxRobot.vue';
import AccountantRobot from '@/resources/onboarding-bank/AccountantRobot.vue';
import ConfigHomeScreen from '@/resources/onboarding-bank/ConfigHomeScreen.vue';

export default [
  {
    path: '/onboarding-bank',
    redirect: '/onboarding-bank/welcome-video',
    component: OnboardingSteps,
    children: [
      {
        path: 'welcome-video',
        name: 'Welcome Video',
        component: WelcomeVideo,
        meta: {
          text: 'welcomeVideo',
          nextRoute: 'tax-robot',
        },
      },
      {
        path: 'tax-robot',
        name: 'Tax Robot',
        component: TaxRobot,
        meta: {
          text: 'taxRobot',
          nextRoute: 'accountant-robot',
          prevRoute: 'welcome-video',
        },
      },
      {
        path: 'accountant-robot',
        name: 'Accountant Robot',
        component: AccountantRobot,
        meta: {
          text: 'accountantRobot',
          nextRoute: 'config-home-screen',
          prevRoute: 'tax-robot',
        },
      },
    ],
  },
  {
    path: '/config-home-screen',
    name: 'Config Home Screen',
    component: ConfigHomeScreen,
  },
] as Array<RouteConfig>;
