
import NotificationMixin from '@/mixins/NotificationMixin';
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import { IntegrationSystem } from '@/types';
import locales from '../../locales';
import { IntegrationSystemPurpose } from '../../types';

export default mixins(NotificationMixin).extend({
  name: 'IntegrationsTable',
  i18n: {
    messages: locales,
  },
  props: {
    rows: {
      type: Array as () => Array<IntegrationSystem & {purposesLabel: string[]}>,
      default: () => [],
    } as PropOptions,

    loading: {
      type: Boolean as () => boolean,
      default: false,
    } as PropOptions,
  },
  data() {
    const integrationPurposes = [
      {
        label: this.$t('integrations.purpose.accounting') as string,
        value: IntegrationSystemPurpose.ACCOUNTING_CLASSIFICATION,
      },
      {
        label: this.$t('integrations.purpose.tax') as string,
        value: IntegrationSystemPurpose.TAX_ANALYSIS,
      },
      {
        label: this.$t('integrations.purpose.financial') as string,
        value: IntegrationSystemPurpose.FINANCIAL_BOT,
      },
    ];
    return {
      integrationPurposes,
      tableConfig: {
        alias: [
          this.$t('integrations.table.header.systems'),
          this.$t('integrations.table.header.version'),
          this.$t('integrations.table.header.integrationPurpose'),
          this.$t('integrations.table.header.integrationId'),
        ],
        columnsToShow: ['system', 'version', 'purposes', 'id'],
      },
    };
  },
  methods: {

    editSystem(integrationSystem: IntegrationSystem) {
      this.$emit('edit', integrationSystem);
    },

    deleteSystem(integrationSystem: IntegrationSystem) {
      this.$emit('delete', integrationSystem);
    },

    copyIntegrationId(integrationId: string) {
      navigator.clipboard.writeText(integrationId);
      this.successToast({
        text: this.$t('integrations.message.copiedIntegrationId') as string,
      } as any);
    },
  },
});
