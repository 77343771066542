var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    ref: "customTable",
    staticClass: "mt-3",
    attrs: {
      "columns-data": _vm.tableConfig,
      data: _vm.rows,
      sortable: true,
      loading: _vm.loading,
      paginate: _vm.rows && _vm.rows.length > 0,
      "row-length": _vm.rows.length,
    },
    scopedSlots: _vm._u([
      {
        key: "columns",
        fn: function ({ item, column }) {
          return [
            column === "system"
              ? _c("td", { staticClass: "p-pl-2" }, [
                  _vm._v(" " + _vm._s(item.system) + " "),
                ])
              : _vm._e(),
            column === "version"
              ? _c("td", [_vm._v(" " + _vm._s(item.version) + " ")])
              : _vm._e(),
            column === "purposes"
              ? _c("td", [
                  _c(
                    "div",
                    { staticClass: "p-formgroup-inline" },
                    _vm._l(
                      _vm.integrationPurposes,
                      function (integrationPurpose, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "p-field-checkbox p-m-0 p-p-1",
                          },
                          [
                            _c("Checkbox", {
                              attrs: {
                                value: integrationPurpose.value,
                                disabled: true,
                              },
                              model: {
                                value: item.purposes,
                                callback: function ($$v) {
                                  _vm.$set(item, "purposes", $$v)
                                },
                                expression: "item.purposes",
                              },
                            }),
                            _c("label", [
                              _vm._v(_vm._s(integrationPurpose.label)),
                            ]),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
            column === "id"
              ? _c("td", [
                  _c("div", { staticClass: "p-formgroup-inline" }, [
                    _c("span", { staticClass: "p-m-0 p-p-1" }, [
                      _vm._v(_vm._s(item.id)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "p-m-0 p-p-1 copied",
                        on: {
                          click: function ($event) {
                            return _vm.copyIntegrationId(item.id)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "my-token-content__icon icon-r-copy",
                        }),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "actions",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: _vm.$t("integrations.table.body.tooltip.edit"),
                      expression: "$t('integrations.table.body.tooltip.edit')",
                      modifiers: { top: true },
                    },
                  ],
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.editSystem(item)
                    },
                  },
                },
                [_c("i", { staticClass: "icon-r-edit" })]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: _vm.$t("integrations.table.body.tooltip.delete"),
                      expression:
                        "$t('integrations.table.body.tooltip.delete')",
                      modifiers: { top: true },
                    },
                  ],
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.deleteSystem(item)
                    },
                  },
                },
                [_c("i", { staticClass: "icon-r-trash" })]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }