import axios from '@/common/http';

class RoleService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_IAM_ROLES_URL}/api/v1`;

  public async getPermissionData() {
    return this.service.get(`${this.baseUrl}/user-permission/build-full-roles-tree`);
  }
}

export default RoleService;
