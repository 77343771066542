

import Vue from 'vue';
import locales from '@/common/components/companyGroup/locales';
import { CompanyGroup } from '@roit-intern/roit-company-interfaces';

export default Vue.extend({
  name: 'CompanyGroupAccordion',
  i18n: {
    messages: locales,
  },
  props: {
    companyGroup: {
      type: Object as () => CompanyGroup,
      required: true,
    },
    clickAdd: {
      type: Function,
      required: true,
    },
    clickUpdate: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleAdd() {
      this.clickAdd();
    },
    handleUpdate() {
      this.clickUpdate();
    },
    getEstablisments() {
      this.$emit('establishments:get');
    },
  },
});
