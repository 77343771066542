
import mixins from 'vue-typed-mixins';
import OnboardMixin from '@/resources/onboarding/OnboardMixin';
import Integrations from '@/resources/integrations/Integrations.vue';
import StepButtons from '@/resources/onboarding/components/StepButtons.vue';
import locales from './locales';

export default mixins(OnboardMixin).extend({
  name: 'StepIntegrations',
  i18n: {
    messages: locales,
  },
  components: {
    Integrations,
    StepButtons,
  },
  data() {
    return {
      disableNext: true,
      alertIsVisible: false,
    };
  },
  computed: {
    textTitle(): string {
      return this.$t('stepIntegrations.alert.textTitle') as string;
    },
    textContent(): string {
      return this.$t('stepIntegrations.alert.textContent') as string;
    },
    textBtnOk(): string {
      return this.$t('stepIntegrations.alert.textBtnOk') as string;
    },
    textBtnCancel(): string {
      return this.$t('stepIntegrations.alert.textBtnCancel') as string;
    },
  },
  methods: {
    nextStep(): void {
      this.$router.push({ name: 'Finish' });
    },
    jumpStep(): void {
      this.$router.push({ name: 'Finish' });
    },
    handleJumpStep(): void {
      if (this.disableNext === true) {
        this.hadleAlertIsVisible(true);
      } else {
        this.jumpStep();
      }
    },
    hadleAlertIsVisible(bool: boolean): void {
      this.alertIsVisible = bool;
    },
  },
});
