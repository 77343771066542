
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import GenerateReportModal from '@/common/components/generateReportModal/GenerateReportModal.vue';
// import defaultAvatar from '@/common/assets/images/use-avatar.svg';
// eslint-disable-next-line import/no-cycle
import { loadLanguageAsync } from '@/i18n';

export default Vue.extend({
  components: {
    GenerateReportModal,
  },
  props: {
    control: {
      type: Boolean,
      required: true,
    },
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedFlag: 'br',
      languages: [
        { flag: 'br', language: 'pt-br', title: '' },
        { flag: 'us', language: 'en', title: '' },
        { flag: 'es', language: 'es', title: '' },
      ],
      valuControlCLoseOpen: this.control,
      clickActive: false as boolean,
      valueIsCollapse: this.collapse,
      valueSearch: '',
      mouseLeft: false as boolean,
      showReportModal: false,
      avatar: null,
    };
  },
  computed: {
    ...mapGetters({
      permissions: 'auth/permissions',
      user: 'auth/user',
    }),
    controlCLoseOpen: {
      get(): boolean {
        return this.valuControlCLoseOpen;
      },
      set(value: boolean) {
        this.valuControlCLoseOpen = value;
        this.$emit('update:control', value);
      },
    },
    isCollapse: {
      get(): boolean {
        return this.valueIsCollapse;
      },
      set(value: boolean) {
        this.valueIsCollapse = value;
        this.$emit('update:collapse', value);
      },
    },
  },
  created() {
    // this.avatar = this.user.photoUrl || defaultAvatar;
  },
  mounted() {
    // Set pt-br as default language
    this.changeLocale({ language: 'pt-br', flag: 'br' });
  },
  methods: {
    ...mapMutations({
      logout: 'auth/LOGOUT',
    }),
    returnToBank(): void {
      window.location.href = `${process.env.VUE_APP_AUTH_REDIRECT}bankRefactor`;
    },
    timeOutCollapse() {
      if (!this.clickActive) {
        this.mouseLeft = false;
        setTimeout(() => {
          // If cursor still over menu, open it
          if (!this.mouseLeft) {
            this.isCollapse = false;
            this.mouseLeft = false;
          }
        }, 500);
      }
    },
    onMouseLeave() {
      if (!this.clickActive) {
        this.mouseLeft = true;
        this.isCollapse = true;
        this.controlCLoseOpen = false;
      }
    },
    changeLocale(entry: any) {
      loadLanguageAsync(entry.language);
      this.selectedFlag = entry.flag;
    },
    showMenu(): void {
      this.clickActive = !this.clickActive;
      this.isCollapse = !this.isCollapse;
      this.controlCLoseOpen = !this.controlCLoseOpen;
    },
    returnHome(): void {
      ((this.$refs.firtElement as Vue).$el as HTMLElement).click();
    },
  },
});
