import './element';
import './bootstrap';
import './primevue';
import './moment';
import './vee-validate';
import './sweet-alert';
import './dragToScroll';
import './the-mask';
import './maska';
import './perfect-scrollbar';
import './vue-flag-icon';
