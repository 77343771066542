var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "welcome-video mt-12 flex justify-center gap-10" },
        [
          _vm._m(0),
          _c("div", { staticClass: "flex flex-col justify-center gap-6" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("welcomeVideo.start.title.normal")) + " "
              ),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.$t("welcomeVideo.start.title.bold"))),
              ]),
            ]),
            _c("p", { staticClass: "message" }, [
              _vm._v(" " + _vm._s(_vm.$t("welcomeVideo.start.message")) + " "),
            ]),
          ]),
        ]
      ),
      _c("OnboardingButtons", { attrs: { "visible-back": false } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", { staticClass: "video-background relative" }, [
        _c("iframe", {
          staticClass: "video rounded border-none relative z-10",
          attrs: {
            src: "https://www.youtube.com/embed/1749t9FJXfw",
            allowfullscreen: "",
          },
        }),
        _c("img", {
          staticClass: "absolute -bottom-9 -left-10 z-0",
          attrs: {
            src: require("@/common/assets/images/onboarding-bank/dots.png"),
            alt: "Pontinhos",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }