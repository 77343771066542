import validate from './domain';

export const validator = {
  getMessage() {
    return 'Invalid Domain';
  },
  validate(value: any) {
    return validate(value);
  },
};
