var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      staticClass: "p-input-icon-right",
      class: [{ "p-input-icon-left": _vm.iconLeft }],
    },
    [
      _c("InputText", {
        ref: "inputPassword",
        class: [
          `${_vm.inputTextSizeClass}`,
          { "p-invalid": _vm.errors },
          { pwd: !_vm.showPassword },
        ],
        staticStyle: { width: "100%" },
        attrs: {
          id: _vm.id,
          autocomplete: _vm.autocomplete,
          placeholder: _vm.placeholder,
          readonly: _vm.readonly,
          disabled: _vm.disabled,
          type: "text",
        },
        model: {
          value: _vm.getValue,
          callback: function ($$v) {
            _vm.getValue = $$v
          },
          expression: "getValue",
        },
      }),
      _vm.iconLeft
        ? _c("i", { staticClass: "icon-r-padlock-password" })
        : _vm._e(),
      _c("i", {
        ref: "btnToggle",
        staticClass: "mr-1 action-icon",
        class: [_vm.showPassword ? "icon-r-eye-hide" : "icon-r-eye"],
        on: {
          click: function ($event) {
            _vm.showPassword = !_vm.showPassword
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }