var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("fieldset", { staticClass: "mt-2" }, [
    _c("legend", [
      _vm._v(_vm._s(_vm.$t("integrations.forms.protheus.parameters.title"))),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-12" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.protheus.parameters.usePurchaseOrderLabel"
                ),
                name: "usePurchaseOrder",
                vid: "usePurchaseOrder",
                rules: "required",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "p-formgroup-inline" },
                        _vm._l(_vm.yesNoOptions, function (yesNoOption, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "p-field-checkbox mb-0.5" },
                            [
                              _c("RadioButton", {
                                class: { "p-invalid": errors[0] },
                                attrs: {
                                  id: "usePurchaseOrder",
                                  name: "usePurchaseOrder",
                                  value: yesNoOption.value,
                                },
                                model: {
                                  value: _vm.parameter.usePurchaseOrder,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.parameter,
                                      "usePurchaseOrder",
                                      $$v
                                    )
                                  },
                                  expression: "parameter.usePurchaseOrder",
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: yesNoOption.label } },
                                [_vm._v(_vm._s(yesNoOption.label))]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-12" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.protheus.parameters.orderSharingBetweenBranchesLabel"
                ),
                name: "orderSharingBetweenBranches",
                vid: "orderSharingBetweenBranches",
                rules: "required",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "p-formgroup-inline" },
                        _vm._l(
                          _vm.orderSharingBetweenBranchesOptions,
                          function (orderSharingBetweenBranchesOption, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "p-field-checkbox mb-0.5",
                              },
                              [
                                _c("RadioButton", {
                                  class: { "p-invalid": errors[0] },
                                  attrs: {
                                    id: "orderSharingBetweenBranches",
                                    name: "orderSharingBetweenBranches",
                                    value:
                                      orderSharingBetweenBranchesOption.value,
                                  },
                                  model: {
                                    value:
                                      _vm.parameter.orderSharingBetweenBranches,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.parameter,
                                        "orderSharingBetweenBranches",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "parameter.orderSharingBetweenBranches",
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: orderSharingBetweenBranchesOption.label,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          orderSharingBetweenBranchesOption.label
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-12" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.protheus.parameters.performsAccountingOnDocumentEntryLabel"
                ),
                name: "performsAccountingOnDocumentEntry",
                vid: "performsAccountingOnDocumentEntry",
                rules: "required",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "p-formgroup-inline" },
                        _vm._l(_vm.yesNoOptions, function (yesNoOption, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "p-field-checkbox mb-0.5" },
                            [
                              _c("RadioButton", {
                                class: { "p-invalid": errors[0] },
                                attrs: {
                                  id: "performsAccountingOnDocumentEntry",
                                  name: "performsAccountingOnDocumentEntry",
                                  value: yesNoOption.value,
                                },
                                model: {
                                  value:
                                    _vm.parameter
                                      .performsAccountingOnDocumentEntry,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.parameter,
                                      "performsAccountingOnDocumentEntry",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "parameter.performsAccountingOnDocumentEntry",
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: yesNoOption.label } },
                                [_vm._v(" " + _vm._s(yesNoOption.label) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-12" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.protheus.parameters.supplierTaxWithholdingsLabel"
                ),
                name: "supplierTaxWithholdings",
                vid: "supplierTaxWithholdings",
                rules: "required",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "p-formgroup-inline" },
                        _vm._l(
                          _vm.supplierTaxWithholdingsOptions,
                          function (supplierTaxWithholdingsOption, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "p-field-checkbox mb-0.5",
                              },
                              [
                                _c("RadioButton", {
                                  class: { "p-invalid": errors[0] },
                                  attrs: {
                                    id: "supplierTaxWithholdings",
                                    name: "supplierTaxWithholdings",
                                    value: supplierTaxWithholdingsOption.value,
                                  },
                                  model: {
                                    value:
                                      _vm.parameter.supplierTaxWithholdings,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.parameter,
                                        "supplierTaxWithholdings",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "parameter.supplierTaxWithholdings",
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: supplierTaxWithholdingsOption.label,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          supplierTaxWithholdingsOption.label
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }