var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("fieldset", { staticClass: "mt-2" }, [
    _c("legend", [
      _vm._v(_vm._s(_vm.$t("integrations.forms.sapb1.parameters.title"))),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendPurchaseOrder"
                ),
                name: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendPurchaseOrder"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("Dropdown", {
                        staticClass: "rounded-lg",
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          options: _vm.eachAnyMinuteOptions,
                          "option-label": "label",
                          "option-value": "value",
                          placeholder: _vm.$t("integrations.forms.select"),
                          filter: true,
                        },
                        model: {
                          value: _vm.parameter.sendPurchaseOrderEvery,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.parameter,
                              "sendPurchaseOrderEvery",
                              $$v
                            )
                          },
                          expression: "parameter.sendPurchaseOrderEvery",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.sapb1.parameters.launchInvoice"
                ),
                name: _vm.$t(
                  "integrations.forms.sapb1.parameters.launchInvoice"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("Dropdown", {
                        staticClass: "rounded-lg",
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          options: _vm.eachAnyMinuteOptions,
                          "option-label": "label",
                          "option-value": "value",
                          placeholder: _vm.$t("integrations.forms.select"),
                          filter: true,
                        },
                        model: {
                          value: _vm.parameter.launchInvoiceEvery,
                          callback: function ($$v) {
                            _vm.$set(_vm.parameter, "launchInvoiceEvery", $$v)
                          },
                          expression: "parameter.launchInvoiceEvery",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendAccountsChart"
                ),
                name: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendAccountsChart"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("Dropdown", {
                        staticClass: "rounded-lg",
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          options: _vm.eachAnyMinuteOptions,
                          "option-label": "label",
                          "option-value": "value",
                          placeholder: _vm.$t("integrations.forms.select"),
                          filter: true,
                        },
                        model: {
                          value: _vm.parameter.sendAccountsChartEvery,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.parameter,
                              "sendAccountsChartEvery",
                              $$v
                            )
                          },
                          expression: "parameter.sendAccountsChartEvery",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendSupplierRegistration"
                ),
                name: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendSupplierRegistration"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("Dropdown", {
                        staticClass: "rounded-lg",
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          options: _vm.eachAnyMinuteOptions,
                          "option-label": "label",
                          "option-value": "value",
                          placeholder: _vm.$t("integrations.forms.select"),
                          filter: true,
                        },
                        model: {
                          value: _vm.parameter.sendSupplierRegistrationEvery,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.parameter,
                              "sendSupplierRegistrationEvery",
                              $$v
                            )
                          },
                          expression: "parameter.sendSupplierRegistrationEvery",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendUmbrellaContract"
                ),
                name: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendUmbrellaContract"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("Dropdown", {
                        staticClass: "rounded-lg",
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          options: _vm.eachAnyMinuteOptions,
                          "option-label": "label",
                          "option-value": "value",
                          placeholder: _vm.$t("integrations.forms.select"),
                          filter: true,
                        },
                        model: {
                          value: _vm.parameter.sendUmbrellaContractEvery,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.parameter,
                              "sendUmbrellaContractEvery",
                              $$v
                            )
                          },
                          expression: "parameter.sendUmbrellaContractEvery",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t("integrations.forms.sapb1.parameters.payment"),
                name: _vm.$t("integrations.forms.sapb1.parameters.payment"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("Dropdown", {
                        staticClass: "rounded-lg",
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          options: _vm.eachAnyMinuteOptions,
                          "option-label": "label",
                          "option-value": "value",
                          placeholder: _vm.$t("integrations.forms.select"),
                          filter: true,
                        },
                        model: {
                          value: _vm.parameter.paymentEvery,
                          callback: function ($$v) {
                            _vm.$set(_vm.parameter, "paymentEvery", $$v)
                          },
                          expression: "parameter.paymentEvery",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t(
                  "integrations.forms.sapb1.parameters.sendTaxCode"
                ),
                name: _vm.$t("integrations.forms.sapb1.parameters.sendTaxCode"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("Dropdown", {
                        staticClass: "rounded-lg",
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          options: _vm.eachAnyMinuteOptions,
                          "option-label": "label",
                          "option-value": "value",
                          placeholder: _vm.$t("integrations.forms.select"),
                          filter: true,
                        },
                        model: {
                          value: _vm.parameter.sendTaxCodeEvery,
                          callback: function ($$v) {
                            _vm.$set(_vm.parameter, "sendTaxCodeEvery", $$v)
                          },
                          expression: "parameter.sendTaxCodeEvery",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }