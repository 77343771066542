var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "my-4" },
    [
      _c("p", { staticClass: "text-sm color-black87" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "digitalCertificates.validateCertificate.modal.description"
              )
            ) +
            " "
        ),
      ]),
      _c("label", { staticClass: "sf-pro-regular" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("digitalCertificates.validateCertificate.modal.radioLabel")
            ) +
            " "
        ),
      ]),
      _c("div", { staticClass: "flex gap-4 mt-2 mb-3" }, [
        _c(
          "div",
          { staticClass: "flex itens-center" },
          [
            _c("RadioButton", {
              staticClass: "mr-2",
              attrs: { id: "sixty", value: 60 },
              model: {
                value: _vm.days,
                callback: function ($$v) {
                  _vm.days = $$v
                },
                expression: "days",
              },
            }),
            _c(
              "label",
              {
                staticClass: "color-black87 text-sm sf-pro-regular",
                attrs: { for: "sixty" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "digitalCertificates.validateCertificate.modal.daysOptions.sixty"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex itens-center" },
          [
            _c("RadioButton", {
              staticClass: "mr-2",
              attrs: { id: "thirty", value: 30 },
              model: {
                value: _vm.days,
                callback: function ($$v) {
                  _vm.days = $$v
                },
                expression: "days",
              },
            }),
            _c(
              "label",
              {
                staticClass: "color-black87 text-sm sf-pro-regular",
                attrs: { for: "thirty" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "digitalCertificates.validateCertificate.modal.daysOptions.thirty"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex itens-center" },
          [
            _c("RadioButton", {
              staticClass: "mr-2",
              attrs: { id: "five", value: 5 },
              model: {
                value: _vm.days,
                callback: function ($$v) {
                  _vm.days = $$v
                },
                expression: "days",
              },
            }),
            _c(
              "label",
              {
                staticClass: "color-black87 text-sm sf-pro-regular",
                attrs: { for: "five" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "digitalCertificates.validateCertificate.modal.daysOptions.five"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex itens-center" },
          [
            _c("RadioButton", {
              staticClass: "mr-2",
              attrs: { id: "one", value: 1 },
              model: {
                value: _vm.days,
                callback: function ($$v) {
                  _vm.days = $$v
                },
                expression: "days",
              },
            }),
            _c(
              "label",
              {
                staticClass: "color-black87 text-sm sf-pro-regular",
                attrs: { for: "one" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "digitalCertificates.validateCertificate.modal.daysOptions.one"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "label",
            { staticClass: "sf-pro-regular", attrs: { for: "emails" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "digitalCertificates.validateCertificate.modal.inputLabel"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "ValidationObserver",
            { ref: "form", attrs: { slim: "" } },
            [
              _c("input-validation", {
                attrs: { name: "email", rules: "email", mode: "passive" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex gap-2" },
                          [
                            _c("InputText", {
                              staticClass: "w-full",
                              class: {
                                "p-invalid":
                                  errors[0] ||
                                  _vm.repeatedEmail ||
                                  _vm.emptyInput,
                              },
                              attrs: {
                                id: "input",
                                placeholder: _vm.$t(
                                  "digitalCertificates.validateCertificate.modal.placeholder"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.resetErrorFields()
                                },
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.updateEmailsList.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.input,
                                callback: function ($$v) {
                                  _vm.input = $$v
                                },
                                expression: "input",
                              },
                            }),
                            _c("Button", {
                              staticClass: "outlined",
                              attrs: {
                                label:
                                  _vm.indexItemEditing !== undefined
                                    ? _vm.$t("edit")
                                    : _vm.$t(`add`),
                                icon:
                                  _vm.indexItemEditing !== undefined
                                    ? ""
                                    : "icon-r-circle-plus",
                              },
                              on: { click: _vm.updateEmailsList },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "small",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.repeatedEmail,
                  expression: "repeatedEmail",
                },
              ],
              staticClass: "p-invalid",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "digitalCertificates.validateCertificate.modal.repeatedEmailError"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "small",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.emptyInput,
                  expression: "emptyInput",
                },
              ],
              staticClass: "p-invalid",
            },
            [_vm._v(" " + _vm._s(_vm.$t("requiredField")) + " ")]
          ),
          _c("hr", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.emails.length,
                expression: "emails.length",
              },
            ],
          }),
          _c(
            "label",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.emails.length,
                  expression: "emails.length",
                },
              ],
              staticClass: "sf-pro-bold ml-3",
              attrs: { for: "emails" },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "digitalCertificates.validateCertificate.modal.inputLabel"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c("custom-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.emails.length,
                expression: "emails.length",
              },
            ],
            staticClass: "max-h-52 h-auto overflow-y-auto",
            attrs: { "columns-data": _vm.tableHeader, data: _vm.emails },
            scopedSlots: _vm._u([
              {
                key: "columns",
                fn: function ({ item, column }) {
                  return [
                    column === "email"
                      ? _c("td", { staticClass: "w-44 sf-pro-medium" }, [
                          _vm._v(" " + _vm._s(item) + " "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "actions",
                fn: function ({ item }) {
                  return [
                    _c("div", { staticClass: "actions" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "outline-none border-none bg-transparent flex justify-center items-center",
                          on: {
                            click: function ($event) {
                              return _vm.editItem(item)
                            },
                          },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.top.html",
                                value: _vm.$t("edit"),
                                expression: "$t('edit')",
                                modifiers: { top: true, html: true },
                              },
                            ],
                            staticClass: "icon-r-edit",
                          }),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "outline-none border-none bg-transparent flex justify-center items-center",
                          on: {
                            click: function ($event) {
                              return _vm.removeItem(item)
                            },
                          },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.top.html",
                                value: _vm.$t("delete"),
                                expression: "$t('delete')",
                                modifiers: { top: true, html: true },
                              },
                            ],
                            staticClass: "icon-r-trash",
                          }),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          staticClass: "text-125",
          style: { minWidth: "400px", maxWidth: "400px" },
          attrs: {
            header: _vm.$t(
              "digitalCertificates.validateCertificate.modal.confirmRemoveItemModal.header"
            ),
            modal: true,
            visible: _vm.confirmRemoveItemModal,
            "content-style": { overflow: "visible", borderRadius: "10px" },
          },
          on: {
            "update:visible": function ($event) {
              _vm.confirmRemoveItemModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c("Button", {
                        staticClass: "outlined mr-3 danger",
                        attrs: { label: _vm.$t(`no`) },
                        on: {
                          click: function ($event) {
                            _vm.confirmRemoveItemModal = false
                          },
                        },
                      }),
                      _c("Button", {
                        staticClass: "danger",
                        attrs: { label: _vm.$t("yes") },
                        on: {
                          click: function ($event) {
                            return _vm.confirmRemoveItem()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "my-4" }, [
            _c("p", { staticClass: "text-sm color-black87" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "digitalCertificates.validateCertificate.modal.confirmRemoveItemModal.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }