
import Vue from 'vue';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import { SapB1Params } from '../../../types';

type Auth = SapB1Params['auth']

export default Vue.extend({
  name: 'SapB1AuthSectionForm',
  components: {
    InputPassword,
  },
  props: {
    systemParameters: {
      type: Object as () => SapB1Params,
      required: true,
    },
  },
  data() {
    const {
      username = '',
      userpass,
      pathAttachment = 'C:\\Temp\\Achivements\\',
    } = this.systemParameters.auth || {};

    return {
      auth: {
        username,
        userpass,
        pathAttachment,
      } as Auth,
    };
  },
  watch: {
    auth: {
      deep: true,
      handler(auth: Auth) {
        this.$emit('update:systemParameters', { ...this.systemParameters, auth, __type: 'SapB1Params' });
      },
    },
  },
});
