
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import { IntegrationSystem } from '@/types';
import IntegrationService from '@/common/services/IntegrationService';
import NotificationMixin from '@/mixins/NotificationMixin';
import { SweetAlertResult } from 'sweetalert2';
import IntegrationsTable from '@/resources/integrations/components/tables/IntegrationsTable.vue';
import IntegrationsEmpty from '@/resources/integrations/components/tables/IntegrationsEmpty.vue';
import { IntegrationSystemPurpose } from '../../types';

export default mixins(NotificationMixin).extend({
  name: 'ContentSection',
  components: {
    IntegrationsEmpty,
    IntegrationsTable,
  },
  props: {
    reloadTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      component: 'IntegrationsEmpty',
      loadingTable: false,
      integrations: [] as IntegrationSystem[],
    };
  },
  watch: {
    integrations: {
      deep: true,
      handler(integrations) {
        if (integrations.length) {
          this.component = 'IntegrationsTable';
        } else {
          this.component = 'IntegrationsEmpty';
        }

        const hasData = integrations && integrations.length > 0;
        this.$emit('has-data', hasData);
      },
    },
    reloadTable(reloadTable) {
      if (reloadTable) {
        this.getIntegrations();
        this.$emit('update:reloadTable', false);
      }
    },
  },
  async mounted() {
    if (this.token && this.userLoggedIn) {
      this.getIntegrations();
    }
  },
  computed: mapGetters({
    token: 'auth/token',
    userLoggedIn: 'auth/userLoggedIn',
    user: 'auth/user',
  }),
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async getIntegrations() {
      try {
        // this.handleLoading(true);
        this.loadingTable = true;

        const systems: Array<IntegrationSystem & {purposesLabel: string[]}> = (
          await IntegrationService.getIntegrations()
        ).data.data;

        this.integrations = systems.map((system) => {
          const integrationPurposes: string[] = [];

          if (system?.purposes?.includes(IntegrationSystemPurpose.ACCOUNTING_CLASSIFICATION)) {
            integrationPurposes.push('Contábil');
          }
          if (system?.purposes?.includes(IntegrationSystemPurpose.TAX_ANALYSIS)) {
            integrationPurposes.push('Fiscal');
          }
          if (system?.purposes?.includes(IntegrationSystemPurpose.FINANCIAL_BOT)) {
            integrationPurposes.push('Financeiro');
          }

          return {
            ...system,
            purposesLabel: integrationPurposes,
          };
        });
      } catch (e) {
        this.errorToast({
          text: this.$t('integrations.message.fetchRegistersError') as string,
        } as any);
      } finally {
        // this.handleLoading(false);
        this.loadingTable = false;
      }
    },

    async deleteIntegration(system: IntegrationSystem): Promise<void> {
      try {
        const { isConfirmed }: SweetAlertResult = await this.confirmDelete({
          title: this.$t('integrations.delete.confirmation.title') as string,
          text: this.$t('integrations.delete.confirmation.message') as string,
        });

        if (!isConfirmed) return;

        this.handleLoading(true);

        await IntegrationService.deleteIntegration(system.id);

        this.successToast({
          text: this.$t('integrations.delete.confirmation.success') as string,
        } as any);

        await this.getIntegrations();
      } catch (e) {
        this.errorToast({
          text: this.$t('integrations.delete.confirmation.error') as string,
        } as any);
      } finally {
        this.handleLoading(false);
      }
    },

    editIntegration(system: IntegrationSystem): void {
      this.$emit('editIntegrationSystem', system);
    },
  },
});
