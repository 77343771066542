import Vue from 'vue';
import Vuex from 'vuex';
import { CustomStore } from '@/types';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import moduleAuth from './auth/moduleAuth';
import masterModule from './master';
import supplierModule from './supplier';

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    master: masterModule,
    supplier: supplierModule,
  },
}) as CustomStore;
