import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  addCompany: {
    modalTitle: 'Cadastrar Grupo e Empresas',
    tabs: {
      addCompanyTitle: 'Cadastrar Empresa (CNPJ)',
    },
    companyForm: {
      groupNameAndAddCompanies: 'Nomeie o grupo e informe abaixo os CNPJs das empresas que deseja cadastrar:',
      fields: {
        groupName: {
          label: 'Nome do Grupo',
          placeholder: 'Defina um nome para o Grupo',
        },
      },
    },
  },
};

export const es = {
  addCompany: {
    modalTitle: 'Registrar Grupo y Empresas',
    tabs: {
      addCompanyTitle: 'Registrar Empresa (CNPJ)',
    },
    companyForm: {
      groupNameAndAddCompanies: 'Nombre el grupo e informe a continuación los CNPJ de las empresas que desea registrar:',
      fields: {
        groupName: {
          label: 'Nombre del grupo',
          placeholder: 'Establecer un nombre para el grupo',
        },
      },
    },
  },
};

export const en = {
  addCompany: {
    addCompany: {
      modalTitle: 'Register Group and Companies',
      tabs: {
        addCompanyTitle: 'Register Company (CNPJ)',
      },
      companyForm: {
        groupNameAndAddCompanies: 'Name the group and inform below the CNPJs of the companies you wish to register:',
        fields: {
          groupName: {
            label: "Group's name",
            placeholder: 'Set a name for the Group',
          },
        },
      },
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es: {},
  en: {},
};

export default locales;
