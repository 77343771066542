
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import { DigitalCertificate } from '@/types';
import Table from '@/common/components/table/Table.vue';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import DigitalCertificateStatus from '@/resources/digital-certificates/components/DigitalCertificateStatus.vue';
import CnpjFormat from '@/common/filters/CnpjFormat';
import DateFormat from '@/common/filters/DateFormat';
import NotificationMixin from '@/mixins/NotificationMixin';

export default mixins(NotificationMixin).extend({
  name: 'DigitalCertificateTable',

  filters: {
    CnpjFormat,
    dateFormat(value: string) {
      return DateFormat(value, { input: 'YYYY-MM-DD', output: 'DD/MM/YYYY' });
    },
  },

  components: {
    CustomTable: Table,
    InputPassword,
    DigitalCertificateStatus,
  },

  props: {
    rows: {
      type: Array as () => Array<DigitalCertificate>,
      required: true,
    } as PropOptions,
    loading: {
      type: Boolean as () => boolean,
      default: false,
    } as PropOptions,
  },

  data() {
    return {
      rowsData: Object.assign([], this.rows),
      pageSize: 25 as number,
      header: {
        alias: [
          this.$t('digitalCertificates.table.headers.document'),
          this.$t('digitalCertificates.table.headers.companyName'),
          this.$t('digitalCertificates.table.headers.fileName'),
          this.$t('digitalCertificates.table.headers.validity'),
          this.$t('digitalCertificates.table.headers.status'),
          this.$t('digitalCertificates.table.headers.SEFAZNoteDownload'),
        ],
        columnsToShow: [
          'cnpj',
          'razaoSocial',
          'nomeArquivo',
          'validade',
          'status',
          'canSEFAZNoteDownload',
        ],
      },
    };
  },

  watch: {
    rows(companies: Array<DigitalCertificate>): void {
      this.rowsData = [];
      this.rowsData = companies;
      this.pageSize = companies.length;
    },
  },

  methods: {
    deleteCertificate(certificate: DigitalCertificate): void {
      this.$emit('certificate:delete', certificate);
    },
    handleCanSEFAZNoteDownload(certificate: DigitalCertificate): void {
      this.$emit('certificate:canSEFAZNoteDownloadUpdate', certificate);
    },
    downloadCertificate(certificate: DigitalCertificate): void {
      this.$emit('certificate:download', certificate);
    },
    updateCertificate(): void {
      this.$emit('certificate:update');
    },
  },

});
