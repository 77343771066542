var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { maxWidth: "367px", width: "100%" },
      attrs: { visible: _vm.isVisible, modal: "" },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("span", { staticClass: "c-title" }, [
                _vm._v(" " + _vm._s(_vm.textTitle) + " "),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("Button", {
                staticClass: "outlined p-button-raised",
                attrs: { label: _vm.textBtnCancel },
                on: {
                  click: function ($event) {
                    return _vm.handleBtnClose()
                  },
                },
              }),
              _c("Button", {
                staticClass: "p-button-raised mr-0",
                attrs: { id: "confirmButton", label: _vm.textBtnOk },
                on: {
                  click: function ($event) {
                    return _vm.handleBtnOk()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "py-6" }, [
        _c("p", { staticClass: "text-sm my-0" }, [
          _vm._v(" " + _vm._s(_vm.textContent) + " "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }