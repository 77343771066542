import { Route, RouteConfig } from 'vue-router';

export default [
  {
    path: '/billing',
    name: 'billing',
    redirect: { name: 'billing-index' },
    component: () => import(/* webpackChunkName: "billing" */ '@/resources/billing/BillingPage.vue'),
    children: [
      {
        path: '',
        name: 'billing-index',
        component: () => import(/* webpackChunkName: "billing" */ '@/resources/billing/sections/BillingSection.vue'),
      },
      {
        path: 'information',
        name: 'billing-information',
        component: () => import(/* webpackChunkName: "billing" */ '@/resources/billing/sections/BillingInformationSection.vue'),
      },
      {
        path: 'information/manager',
        name: 'billing-information-manager',
        component: () => import(/* webpackChunkName: "billing" */ '@/resources/billing/sections/BillingInformationManagerSection.vue'),
        props: (route: Route) => ({
          paymentMethods: route.params.paymentMethods,
        }),
      },
    ],
  },
] as Array<RouteConfig>;
