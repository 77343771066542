import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
  profileManagement: {
    createProfile: 'Criar Perfil',
    permissionsType: {
      master: {
        companyGroups: {
          plural: 'grupos',
          singular: 'grupo',
        },
        companies: {
          plural: 'empresas',
          singular: 'empresa',
        },
        establishments: {
          plural: 'estabelecimentos',
          singular: 'estabelecimento',
        },
      },
      supplier: {
        masters: {
          plural: 'clientes',
          singular: 'cliente',
        },
      },
    },
    tableProfiles: {
      header: {
        name: 'Nome Perfil',
        affectedProducts: 'Produtos Afetados',
        permissionType: 'Tipo de Permissão',
      },
      affectedProduct: {
        inboundPortal: 'Portal de Entradas',
        admin: 'ROIT Admin',
        supplierManagement: 'Gestão de Fornecedores',
        supplierPortal: 'Portal do Fornecedor',
      },
    },
    formModal: {
      create: {
        title: 'Cadastrar Perfil',
      },
      update: {
        title: 'Atualizar Perfil',
      },
    },
    alerts: {
      successCreate: {
        message: 'Criado com sucesso',
      },
      successUpdate: {
        message: 'Atualizado com sucesso',
      },
      failRequest: {
        message: 'Falha na requisição',
      },
      failProfileNameInUse: {
        message: 'Não foi possível realizar a solicitação. Nome do perfil está em uso por outro.',
      },
      failLoadCompanyGroups: {
        message: 'Não foi possível carregar os grupos de empresas',
      },
      failLoadCompanies: {
        message: 'Não foi possível carregar as empresas',
      },
      failLoadEstablishments: {
        message: 'Não foi possível carregar os estabelecimentos',
      },
      failLoadMastersNames: {
        message: 'Não foi possível carregar os nomes dos clientes',
      },
    },
    form: {
      title: 'Nomeie o perfil e selecione as permissões nos produtos desejados.',
      profile: {
        label: 'Nome do Perfil',
        placeholder: 'Ex.: Perfil Esteira Completa',
      },
      companyGroup: {
        label: 'Selecione um grupo',
        placeholder: 'Selecione',
      },
      company: {
        label: 'Selecione uma empresa',
        placeholder: 'Selecione',
      },
      establishment: {
        label: 'Selecione um estabelecimento',
        placeholder: 'Selecione',
      },
      permission: {
        label: 'Selecione as Permissões',
        placeholder: 'Selecionar Permissões',
      },
      supplierLinkedMaster: {
        label: 'Selecione um cliente',
        placeholder: 'Selecione',
      },
      __extraValidation: {
        profileName: 'Nome de perfil indisponível',
      },
      bankPermissionsOptions: {
        'MAGICFLOW-OCREXTRACTION': 'Extração',
        'MAGICFLOW-ERP': 'Integrações',
        'MAGICFLOW-LINK': 'Robô de Vínculos',
        'MAGICFLOW-TAXANALYSIS': 'Robô Fiscal',
        'MAGICFLOW-COMPLEMENTATION': 'Complementação',
        'MAGICFLOW-ACCOUNTCLASSIFICATION': 'Robô Contábil',
        'MAGICFLOW-CLASSIFICATION': 'Classificação',
        'MAGICFLOW-FINANCIAL': 'Visualizar documentos',
        'PAYFINANCIALANALYST-ADMIN': 'Incluir/Editar pagamentos',
        'MAGICFLOWDELETER-ADMIN': 'Excluir documentos',
        ANALISTAFISCALMASTER: 'Robô Fiscal - Criar cenários fiscais',
        APPROVALENGINE: 'Motor de Aprovações',
        'PROCESSED-DOCUMENTS-IO-ADMIN': 'Documentos Processados',

      },
      bankAdminPermissionsOptions: {
        BANKADMIN: 'ROIT Admin',
        BANKADMINACCESSPASSMENU: 'Senhas de Acesso',
        BANKADMINBILLINGMENU: 'Faturamento',
        BANKADMINDIGITALCERTIFICATESMENU: 'Certificados Digitais',
        BANKADMININTEGRATIONSMENU: 'Integrações',
        BANKADMINMYCOMPANIESMENU: 'Minhas Empresas',
        BANKADMINMYPRODUCTSMENU: 'Meus Produtos',
        BANKADMINAUTOMATICTAXINVOICEMENU: 'Baixas Automáticas',
        BANKADMINUSERANDCONTACTSMENU: 'Usuários',
        BANKADMINTASKMANAGERMENU: 'Gerenciador de tarefas',
      },
      supplierManagementPermissionsOptions: {
        'PDF-ADICIONARFORNECEDOR-EDITOR': 'Adicionar fornecedores',
        'PDF-VIZUALIZARFORNECEDORES-VIEWER': 'Visualizar fornecedores',
        'PDF-EDITARFORNECEDORES-EDITOR': 'Editar fornecedores',
      },
      supplierPermissionsOptions: {
        'PDF-ADICIONARFORNECEDOR-EDITOR': 'Adicionar fornecedores',
        'PDF-VIZUALIZARFORNECEDORES-VIEWER': 'Visualizar fornecedores',
        'PDF-EDITARFORNECEDORES-EDITOR': 'Editar fornecedores',
      },
      section: {
        adminPermissions: 'Permissões ROIT Admin',
        inboundPortalPermissions: 'Permissões Portal de Entradas',
        hierarchyBusinessLevel: 'Vincular permissões', // Nível de Hierarquia de Negócio
        supplierPortalPermissions: 'Permissões Portal de Fornecedores',
        supplierManagementPermissions: 'Permissões Gestão de Fornecedores',
        supplierPortalLinkedMaster: 'Vincular clientes',
      },
      dropdownLinkValidation: {
        msnAddValueSelected: 'Clique no botão vincular para incluir o valor selecionado',
      },
    },
    alreadyEnteredBy: 'Já inserido por',
    hierarchyBusinessLevelTableForm: {
      name: 'Nome',
      nationalRegister: 'CNPJ',
      level: 'Tipo de empresa',
    },
    supplierPortalLinkedMasterTableForm: {
      name: 'Nome',
    },
    clientType: {
      master: 'Cliente ROIT',
      supplier: 'Fornecedor',
    },
  },
};

export const es = {
  profileManagement: {
    createProfile: 'Crear perfil',
    permissionsType: {
      master: {
        companyGroups: {
          plural: 'grupos',
          singular: 'grupo',
        },
        companies: {
          plural: 'compañías',
          singular: 'compañía',
        },
        establishments: {
          plural: 'establecimientos',
          singular: 'establecimiento',
        },
      },
      supplier: {
        masters: {
          plural: 'clientes',
          singular: 'cliente',
        },
      },
    },
    tableProfiles: {
      header: {
        name: 'Nombre de perfil',
        groups: 'Grupos',
        permissions: 'Permisos',
      },
      affectedProduct: {
        inboundPortal: 'Portal de Entradas',
        admin: 'ROIT Admin',
      },
    },
    formModal: {
      create: {
        title: 'Registrar Perfil',
      },
      update: {
        title: 'Actualización del Perfil',
      },
    },
    alerts: {
      successCreate: {
        message: 'Creado con éxito',
      },
      successUpdate: {
        message: 'Actualizado exitosamente',
      },
      failRequest: {
        message: 'Solicitud fallida',
      },
      failProfileNameInUse: {
        message: 'No se puede completar la solicitud. El nombre del perfil está en uso por otro.',
      },
      failLoadCompanyGroups: {
        message: 'No se pudo cargar los grupos de empresas',
      },
      failLoadCompanies: {
        message: 'No se pudo cargar las empresas',
      },
      failLoadEstablishments: {
        message: 'No se pudo cargar los establecimientos',
      },
      failLoadMastersNames: {
        message: 'No se pudo cargar los nombres de los clientes',
      },
    },
    form: {
      title: 'Asigne un nombre al perfil y seleccione los permisos en los productos deseados.',
      profile: {
        label: 'Nombre de Perfil',
        placeholder: 'Ej.: Perfil de Banda Completa',
      },
      companyGroup: {
        label: 'Seleccionar Grupos de Empresas',
        placeholder: 'Seleccionar Grupos',
      },
      permission: {
        label: 'Seleccionar Permisos',
        placeholder: 'Seleccionar Permisos',
      },
      __extraValidation: {
        profileName: 'Nombre de perfil no disponible',
      },
      bankPermissionsOptions: {
        'MAGICFLOW-OCREXTRACTION': 'Extracción',
        'MAGICFLOW-ERP': 'Integraciones',
        'MAGICFLOW-LINK': 'Robot de Enlaces',
        'MAGICFLOW-TAXANALYSIS': 'Robot de Impuestos',
        'MAGICFLOW-COMPLEMENTATION': 'Complementación',
        'MAGICFLOW-ACCOUNTCLASSIFICATION': 'Robot de Contabilidad',
        'MAGICFLOW-CLASSIFICATION': 'Clasificación',
        'MAGICFLOW-FINANCIAL': 'Financiero - Ver documentos',
        'PAYFINANCIALANALYST-ADMIN': 'Financiero - Agregar/Editar pagos',
        'MAGICFLOWDELETER-ADMIN': 'Eliminar documentos',
        ANALISTAFISCALMASTER: 'Robot de Impuestos - Crear scenarios fiscales',
        APPROVALENGINE: 'Motor de Aprobación',
        'PROCESSED-DOCUMENTS-IO-ADMIN': 'Documentos Procesados',
      },
      bankAdminPermissionsOptions: {
        BANKADMIN: 'ROIT Admin',
        BANKADMINACCESSPASSMENU: 'Admin - Contraseñas de Acceso',
        BANKADMINBILLINGMENU: 'Admin - Facturación',
        BANKADMINDIGITALCERTIFICATESMENU: 'Admin - Certificados Digitales',
        BANKADMININTEGRATIONSMENU: 'Admin - Integraciones',
        BANKADMINMYCOMPANIESMENU: 'Admin - Mis Empresas',
        BANKADMINMYPRODUCTSMENU: 'Admin - Mis Productos',
        BANKADMINAUTOMATICTAXINVOICEMENU: 'Admin - Cancelaciones automáticas',
        BANKADMINUSERANDCONTACTSMENU: 'Admin - Usuarios',
        BANKADMINTASKMANAGERMENU: 'Admin - Gerente de tareas',
      },
      supplierPermissionsOptions: {
        'PDF-ADICIONARFORNECEDOR-EDITOR': 'Agregar proveedores',
        'PDF-VIZUALIZARFORNECEDORES-VIEWER': 'Ver proveedores',
        'PDF-EDITARFORNECEDORES-EDITOR': 'Editar proveedores',
      },
      section: {
        adminPermissions: 'Permisos ROIT Admin',
        inboundPortalPermissions: 'Permisos Portal de Entrada',
        hierarchyBusinessLevel: 'Permisos de enlace',
        supplierPortalPermissions: 'Permisos Portal de Proveedores',
      },
      dropdownLinkValidation: {
        msnAddValueSelected: 'Haga clic en el botón de enlace para incluir el valor seleccionado',
      },
    },
    alreadyEnteredBy: 'Already entered by',
    hierarchyBusinessLevelTableForm: {
      name: 'Nombre',
      nationalRegister: 'Registro Nacional',
      level: 'Tipo de empresa',
    },
  },
};

export const en = {
  profileManagement: {
    createProfile: 'Create Profile',
    permissionsType: {
      master: {
        companyGroups: {
          plural: 'groups',
          singular: 'grup',
        },
        companies: {
          plural: 'companies',
          singular: 'company',
        },
        establishments: {
          plural: 'establishments',
          singular: 'establishment',
        },
      },
      supplier: {
        masters: {
          plural: 'clients',
          singular: 'client',
        },
      },
    },
    tableProfiles: {
      header: {
        name: 'Profile Name',
        groups: 'Groups',
        permissions: 'Permissions',
      },
      affectedProduct: {
        inboundPortal: 'Entrance Portal',
        admin: 'ROIT Admin',
      },
    },
    formModal: {
      create: {
        title: 'Register Profile',
      },
      update: {
        title: 'Update Profile',
      },
    },
    alerts: {
      successCreate: {
        message: 'Created successfully',
      },
      successUpdate: {
        message: 'Successfully updated',
      },
      failRequest: {
        message: 'Request failed',
      },
      failProfileNameInUse: {
        message: 'Unable to complete the request. Profile name is in use by another.',
      },
      failLoadCompanyGroups: {
        message: 'Unable to load company groups',
      },
      failLoadCompanies: {
        message: 'Unable to load companies',
      },
      failLoadEstablishments: {
        message: 'Unable to load establishments',
      },
      failLoadMastersNames: {
        message: 'Unable to load customer names',
      },
    },
    form: {
      title: 'Name the profile and select the permissions on the desired products.',
      profile: {
        label: 'Profile Name',
        placeholder: 'Eg.: Full Belt Profile',
      },
      companyGroup: {
        label: 'Select Company Groups',
        placeholder: 'Select Groups',
      },
      permission: {
        label: 'Select Permissions',
        placeholder: 'Select Permissions',
      },
      __extraValidation: {
        profileName: 'Profile name unavailable',
      },
      bankPermissionsOptions: {
        'MAGICFLOW-OCREXTRACTION': 'Extraction',
        'MAGICFLOW-ERP': 'Integrations',
        'MAGICFLOW-LINK': 'Links Robot',
        'MAGICFLOW-TAXANALYSIS': 'Tax Robot',
        'MAGICFLOW-COMPLEMENTATION': 'Complementation',
        'MAGICFLOW-ACCOUNTCLASSIFICATION': 'Accounting Robot',
        'MAGICFLOW-CLASSIFICATION': 'Classification',
        'MAGICFLOW-FINANCIAL': 'Financial - View documents',
        'PAYFINANCIALANALYST-ADMIN': 'Financial - Add/Edit Payments',
        'MAGICFLOWDELETER-ADMIN': 'Delete documents',
        ANALISTAFISCALMASTER: 'Tax Robot - Create Tax Scenarios',
        APPROVALENGINE: 'Approval Engine',
        'PROCESSED-DOCUMENTS-IO-ADMIN': 'Processed Documents',
      },
      bankAdminPermissionsOptions: {
        BANKADMIN: 'ROIT Admin',
        BANKADMINACCESSPASSMENU: 'Admin - Access Passwords',
        BANKADMINBILLINGMENU: 'Admin - Billing',
        BANKADMINDIGITALCERTIFICATESMENU: 'Admin - Digital Certificates',
        BANKADMININTEGRATIONSMENU: 'Admin - Integrations',
        BANKADMINMYCOMPANIESMENU: 'Admin - My Companies',
        BANKADMINMYPRODUCTSMENU: 'Admin - My Products',
        BANKADMINAUTOMATICTAXINVOICEMENU: 'Admin - Automatic Write-offs',
        BANKADMINUSERANDCONTACTSMENU: 'Admin - Users',
        BANKADMINTASKMANAGERMENU: 'Admin - Gerenciador de tarefas',
      },
      supplierPermissionsOptions: {
        'PDF-ADICIONARFORNECEDOR-EDITOR': 'Add suppliers',
        'PDF-VIZUALIZARFORNECEDORES-VIEWER': 'View suppliers',
        'PDF-EDITARFORNECEDORES-EDITOR': 'Edit suppliers',
      },
      section: {
        adminPermissions: 'Permissions ROIT Admin',
        inboundPortalPermissions: 'Permissions Entrance Portal',
        hierarchyBusinessLevel: 'Link Permissions',
        supplierPortalPermissions: 'Permissions Supplier Portal',
      },
      dropdownLinkValidation: {
        msnAddValueSelected: 'Click the link button to include the selected value',
      },
    },
    alreadyEnteredBy: 'Already entered by',
    hierarchyBusinessLevelTableForm: {
      name: 'Name',
      nationalRegister: 'Nationl Register',
      level: 'Company Type',
    },
  },
};

const locales: LocaleMessages = {
  'pt-br': ptBr,
  es,
  en,
};

export default locales;
