import AuthService from '@/common/services/AuthService';
import CompanyService from '@/common/services/CompanyService';
import IamService from '@/common/services/IamService';
import RoleService from '@/common/services/RoleService';
import { Company } from '@/types';
import { Master } from '@roit-intern/roit-company-interfaces';
import { ActionContext } from 'vuex';

export default {
  async handleLoginFromAuthentication(
    { dispatch }: ActionContext<any, any>,
    authenticationToken: string,
  ): Promise<void> {
    const authService = new AuthService();

    const authorizationToken = await authService.authorization(authenticationToken);
    await dispatch(
      'handleLogin',
      authorizationToken,
    );
  },

  async handleLogin(
    { dispatch, commit }: ActionContext<any, any>,
    authorizationToken: string,
  ): Promise<void> {
    localStorage.setItem('pt', authorizationToken);

    commit('SET_TOKEN', authorizationToken);
    commit('SET_ACCEPT_LANGUAGE');

    const promises = [
      dispatch('handleUserInfo'),
      dispatch('handleMasterCompanyInfo'),
      dispatch('supplier/handleSupplier', undefined, { root: true }),
      dispatch('handlePermissionData'),
    ];

    const [
      userInfoResponse,
      masterCompanyInfoResponse,
      supplierResponse,
      permissionResponse,
    ] = await Promise.all(promises);

    const permission: string[] = (permissionResponse.roles.filter((res: string) => res.toLowerCase().match(/bankadmin/i)));

    const noUserInfo = !userInfoResponse;
    const noMasterInfo = !masterCompanyInfoResponse || masterCompanyInfoResponse.master === false;
    const noPermissions = permission.length === 0;
    const noSupplier = !supplierResponse;
    const noClient = noMasterInfo && noSupplier;

    const noAccessPermission = noUserInfo || noPermissions || noClient;

    if (noAccessPermission) {
      throw new Error('user_info_not_found');
    }

    commit('SET_LOGGED_USER', { user: userInfoResponse });
    commit('SET_USER_COMPANY', { ...masterCompanyInfoResponse });
    commit('SET_PERMISSIONS', permissionResponse);
    commit('master/SET_MASTER', masterCompanyInfoResponse, { root: true });
  },

  async handleMasterCompanyInfo({ _ }: any): Promise<Master> {
    return CompanyService.masterInformation();
  },

  async handleUserInfo({ _ }: any): Promise<Company> {
    const iamService = new IamService();
    return (await iamService.getUserInfo()).data.data;
  },

  async handlePermissionData({ _ }: any): Promise<any> {
    const roleService = new RoleService();
    return (await roleService.getPermissionData()).data.data;
  },

  async handleLoginFromLocalToken(
    { dispatch }: ActionContext<any, any>,
    authorizationToken: string,
  ): Promise<void> {
    await dispatch(
      'handleLogin',
      authorizationToken,
    );
  },
};
