
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: Object as () => {
        h1: string;
        p: string;
      },
      default: {
        h1: '',
        p: '',
      },
    },
  },
  data() {
    return {
      option1: null,
      option2: null,
      option3: null,
      teste: [
        { name: 'Teste1', code: '1' },
        { name: 'Teste2', code: '2' },
        { name: 'Teste3', code: '3' },
        { name: 'Teste4', code: '4' },
        { name: 'Teste5', code: '5' },
      ],
    };
  },
  methods: {
    validProp(): boolean {
      return this.title.h1 !== '' || this.title.p !== '';
    },
  },
});
