
import MasterService from '@/common/services/MasterService';
import NotificationMixin from '@/mixins/NotificationMixin';
import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';
import locales from '../../locales';

export default mixins(NotificationMixin).extend({
  name: 'HeaderSection',

  i18n: {
    messages: locales,
  },

  data() {
    return {
      myToken: this.$t('integrations.myToken.loading') as string,
    };
  },

  async mounted() {
    if (this.token && this.userLoggedIn) {
      this.getExternalToken();
    }
  },

  computed: mapGetters({
    token: 'auth/token',
    userLoggedIn: 'auth/userLoggedIn',
    user: 'auth/user',
  }),

  methods: {
    showFormCreate() {
      this.$emit('on:create');
    },

    async getExternalToken() {
      try {
        const { masterId } = this.$store.state.auth.userCompany;
        const { externalTokenId } = await MasterService.getMasterById(masterId);
        this.myToken = externalTokenId || 'n/d';
      } catch (error) {
        console.log('error', error);
      }
    },

    copyMyToken() {
      navigator.clipboard.writeText(this.myToken);
      this.successToast({
        text: this.$t('integrations.message.copiedToken') as string,
      } as any);
    },
  },
});
