var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { maxWidth: "800px", width: "800px" },
      attrs: { visible: _vm.modal, modal: true },
      on: {
        "update:visible": function ($event) {
          _vm.modal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_vm._v(" " + _vm._s(_vm.title) + " ")]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("Button", {
                staticClass: "outlined p-button-raised",
                attrs: { id: "cancelButton", label: _vm.$t("cancel") },
                on: {
                  click: function ($event) {
                    _vm.modal = false
                  },
                },
              }),
              _c("Button", {
                staticClass: "raised next-step",
                attrs: { id: "registerButton", label: _vm.registerButtonLabel },
                on: {
                  click: function ($event) {
                    return _vm.confirm()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "py-3.5" },
        [
          _c("IntegrationSystemForm", {
            ref: "form",
            attrs: { autoload: _vm.showModal, payload: _vm.formPayload },
            on: { onSubmit: _vm.onSubmitEmit, submitted: _vm.submittedEmit },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }