
import Vue, { PropOptions } from 'vue';

export default Vue.extend({
  name: 'InputPassword',
  props: {
    id: {
      type: String as () => string,
      default: () => new Date().getTime().toString(),
    } as PropOptions,
    value: {
      type: String as () => string,
      default: '',
    } as PropOptions,
    placeholder: {
      type: String as () => string,
      default: '',
    } as PropOptions,
    readonly: {
      type: Boolean as () => boolean,
      default: false,
    } as PropOptions,
    disabled: {
      type: Boolean as () => boolean,
      default: false,
    } as PropOptions,
    size: {
      type: String as () => string,
      default: '',
      validator: (value: string): boolean => (!(value && ['sm', 'lg'].indexOf(value) === -1)),
    } as PropOptions,
    iconLeft: {
      type: Boolean as () => boolean,
      default: false,
    } as PropOptions,
    autocomplete: {
      type: String,
      default: '',
    },
    errors: {
      type: String as () => string,
      default: undefined,
    } as PropOptions,
  },
  data: () => ({
    showPassword: false as boolean,
  }),
  computed: {
    getValue: {
      get(): string {
        return this.value;
      },
      set(value: string): void {
        this.$emit('input', value);
      },
    },
    inputTextSizeClass(): string {
      if (this.size !== undefined) {
        return `p-inputtext-${this.size}`;
      }
      return '';
    },
  },
});
