

import { VForm } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'DigitalCertificateValidateModalContent',
  props: {
    daysProp: {
      type: Number,
      required: true,
    },
    emailsProp: {
      type: Array as () => string[],
      required: true,
    },
  },
  data() {
    return {
      days: this.daysProp,
      emails: this.emailsProp,
      originalEmails: this.emailsProp,
      input: '',
      tableHeader: {
        alias: [],
        columnsToShow: [
          'email',
        ],
      },
      indexItemEditing: undefined as undefined | number,
      repeatedEmail: false,
      emptyInput: false,
      confirmRemoveItemModal: false,
      removedItem: '',
    };
  },
  computed: {
    inputEqualEditValue(): boolean {
      if (this.indexItemEditing !== undefined) {
        return this.input === this.emails[this.indexItemEditing];
      }
      return false;
    },
    emailsIncludeInput(): boolean {
      return this.emails.includes(this.input);
    },
  },
  watch: {
    days(value) {
      this.$emit('updateData:days', value);
    },
    emails(value) {
      this.$emit('updateData:emails', value);
    },
  },
  methods: {
    resetErrorFields() {
      this.repeatedEmail = false;
      this.emptyInput = false;
    },
    validateForm() {
      const form = this.$refs.form as VForm;
      return form.validate();
    },
    async updateEmailsList(): Promise<void> {
      const form = this.$refs.form as VForm;
      const isValid = await form.validate();

      if (
        isValid
        && (!this.emailsIncludeInput || this.inputEqualEditValue)
        && this.input
      ) {
        if (this.indexItemEditing !== undefined) {
          this.emails.splice(this.indexItemEditing, 1, this.input);
          this.indexItemEditing = undefined;
          this.input = '';
          return;
        }

        this.emails.push(this.input);
        this.$emit('updateData:disableSaveButton', false);
        this.input = '';
      } else if (this.emailsIncludeInput) {
        this.repeatedEmail = true;
      } else if (!this.input) {
        this.emptyInput = true;
      }
    },
    editItem(item: string): void {
      this.indexItemEditing = this.emails.findIndex(
        (value: string) => value === item,
      );
      this.input = item;
    },
    removeItem(item: string): void {
      this.removedItem = item;
      this.confirmRemoveItemModal = true;
    },
    confirmRemoveItem(): void {
      const indexItem = this.emails.findIndex(
        (value: string) => value === this.removedItem,
      );

      this.emails.splice(indexItem, 1);

      this.confirmRemoveItemModal = false;
    },
  },
});
