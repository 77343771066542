import mixins from 'vue-typed-mixins';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { chain } from 'lodash';

import NotificationMixin from '@/mixins/NotificationMixin';
import NumberRemoveFormat from '@/common/filters/NumberRemoveFormat';
import CompanyService from '@/common/services/CompanyService';
import CnpjFormat from '@/common/filters/CnpjFormat';
import { HttpErrorResponse } from '@/types';
import EstablishmentService from '@/common/services/EstablishmentService';
import { EditCompanyGroupPayload } from '@/common/components/companyGroup/types';
import CompanyGroupService from '@/common/services/CompanyGroupService';

type CNPJError = {
  cnpj: string;
  status: string;
  message: string;
};

export default mixins(NotificationMixin).extend({
  data: () => ({
    messageErrors: [] as Array<CNPJError>,
    showProcessingWarning: false,
  }),

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
      masterId: 'auth/masterId',
    }),
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    ...mapState({
      locale: 'auth/acceptLanguage',
    }),

    async registerEstablishmentsLoaded(file: File): Promise<void> {
      try {
        this.handleLoading(true);

        const result = (await CompanyService.importCompaniesFromFile(file)).data.data;

        const errors = this.getErrors(result);

        if (errors.length) {
          this.messageErrors = errors.map((error: CNPJError) => ({
            ...error,
            cnpj: CnpjFormat(error.cnpj),
          }));

          this.errorToast({
            text: this.$t('onboarding.registerCompany.table.errorInRegistration') as string,
          });
          this.showProcessingWarning = false;
        }

        this.successToast({
          text: this.$t('onboarding.registerCompany.table.successRegistration') as string,
        });

        this.showProcessingWarning = true;
      } catch ({ response }) {
        this.handleErrors(response as HttpErrorResponse);
        this.showProcessingWarning = false;
      } finally {
        this.handleLoading(false);
      }
    },

    async companyRegistrations(
      payload: EditCompanyGroupPayload,
    ): Promise<boolean> {
      try {
        const { companyGroupId, companies } = payload;

        const cleanCompanies = companies
          .filter((company) => company.nationalRegister)
          .map((company) => ({
            ...company,
            nationalRegister: NumberRemoveFormat(company.nationalRegister),
          }));

        await CompanyGroupService
          .companiesRegistrations(companyGroupId, { companies: cleanCompanies });

        this.showProcessingWarning = true;

        return true;
      } catch ({ response }) {
        this.handleErrors(response as HttpErrorResponse);
        this.showProcessingWarning = false;
        return false;
      }
    },

    async registerCompaniesLoaded(file: File): Promise<void> {
      try {
        this.handleLoading(true);

        const result = (await CompanyService.importCompanies(file)).data.data;

        const errors = this.getErrors(result);

        if (errors.length) {
          this.messageErrors = errors.map((error: CNPJError) => ({
            ...error,
            cnpj: CnpjFormat(error.cnpj),
          }));

          this.errorToast({
            text: this.$t('onboarding.registerCompany.table.errorInRegistration') as string,
          });
        }

        this.successToast({
          text: this.$t('onboarding.registerCompany.table.successRegistration') as string,
        });
      } catch ({ response }) {
        this.handleErrors(response as HttpErrorResponse);
      } finally {
        this.handleLoading(false);
      }
    },

    async updateCompany(company: any): Promise<void> {
      try {
        this.handleLoading(true);

        const payloadEstablishment = {
          nationalRegister: company.nationalRegister.replace(/\D/g, ''),
          stateRegistration: company?.stateRegistration || '',
          municipalRegistration: company?.municipalRegistration || '',
        };
        await EstablishmentService.updateEstablishment(payloadEstablishment);

        if (company?.company?.taxRegime?.type) {
          const payloadCompany = {
            companyId: company.companyId,
            taxRegime: {
              assessment: company.company.taxRegime.assessment,
              type: company.company.taxRegime.type,
            },
          };
          await CompanyService.addTaxRegime(payloadCompany);
        }

        if (company?.company?.taxGuide) {
          const payloadCompany = {
            companyId: company.companyId,
            taxGuide: company.company.taxGuide,
          };
          await CompanyService.addTaxGuide(payloadCompany);
        }

        this.successToast({
          text: this.$t('onboarding.registerCompany.table.successUpdate') as string,
        });
      } catch (error) {
        if (!(error instanceof TypeError)) {
          const e = error as HttpErrorResponse;
          this.handleErrors(e);
          throw new Error(e.data.message);
        }
      } finally {
        this.handleLoading(false);
      }
    },

    getErrors(errors: Array<any>): Array<any> {
      return errors.filter((error: any) => error.status === 'error');
    },

    handleErrors(response: HttpErrorResponse): void {
      if (response?.data?.errors) {
        let { errors } = response.data;

        errors = chain(errors[0].children)
          .map((error: any) => ({
            cnpj: null,
            status: 'ERROR',
            message: error.children[0].validations[0],
          }))
          .uniqBy('message')
          .value();

        this.messageErrors = errors as Array<CNPJError>;

        this.errorToast({
          text: this.$t('onboarding.registerCompany.table.errorInRegistration') as string,
        });

        return;
      }

      if (/[{}]/.test(response?.data?.message)) {
        let listErrors = JSON.parse(response.data.message);

        listErrors = chain(listErrors)
          .map((error: CNPJError) => ({
            ...error,
            cnpj: CnpjFormat(error.cnpj),
          }))
          .uniqBy('cnpj')
          .value();

        this.messageErrors = listErrors as Array<CNPJError>;

        this.errorToast({
          text: this.$t('onboarding.registerCompany.table.errorInRegistration') as string,
        });

        return;
      }

      this.errorToast({
        text: this.$t('onboarding.registerCompany.table.errorRegistration', {
          errors: response?.data?.message,
        }) as string,
      });
    },
  },
});
