import cardValidator from 'card-validator';

function validate(cardNumber: string, allowed: string) {
  let number = '';

  if (cardNumber.length) {
    number = cardNumber.replace(/[^\d]+/gi, '');
  }

  const { card, isValid } = cardValidator.number(number);

  return card ? allowed.includes(card.type) && isValid : false;
}

export default validate;
