var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "div",
      { staticClass: "d-flex justify-content-end" },
      [
        _c(
          "a",
          {
            staticClass: "d-flex align-items-center mr-1 mt-5",
            attrs: {
              href: "https://share.hsforms.com/1xjxIm2CMR3GZ4ZecTpC-9w4o3k1?__hstc=112993563.6a01fe455480393dc955f63338499669.1603458375880.1610484791825.1611249243365.72&__hssc=112993563.1.1611249243365&__hsfp=176440949",
              target: "_blank",
            },
          },
          [
            _c("i", { staticClass: "icon-r-circle-question mr-2" }),
            _c("span", { staticClass: "font" }, [
              _vm._v(_vm._s(_vm.$t("onboardingBank.start.support"))),
            ]),
          ]
        ),
        _c(
          "el-dropdown",
          { staticClass: "d-flex mr-5 mt-5", attrs: { type: "primary" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary", plain: "" } },
              [
                _c("flag", {
                  attrs: { iso: _vm.selectedFlag, squared: false },
                }),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              _vm._l(_vm.languages, function (entry) {
                return _c("el-dropdown-item", { key: entry.language }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.changeLocale(entry)
                        },
                      },
                    },
                    [
                      _c("flag", {
                        attrs: { iso: entry.flag, squared: false },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }