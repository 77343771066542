var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "row mb-5" }, [
      _vm.validProp()
        ? _c("div", { staticClass: "col-12 col-lg-4 title" }, [
            _c("h1", [_vm._v(" " + _vm._s(_vm.title.h1) + " ")]),
            _c("p", [_vm._v(" " + _vm._s(_vm.title.p) + " ")]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          class: {
            "col-12": true,
            "col-lg-12": !_vm.validProp(),
            "d-flex": true,
            "justify-content-start": true,
            "justify-content-lg-end": true,
            "align-items-start": true,
            "col-lg-8": _vm.validProp(),
          },
        },
        [
          _c("div", { staticClass: "content-info d-flex align-items-center" }, [
            _c("div", { staticClass: "p-fluid p-formgrid p-col-4" }, [
              _c(
                "div",
                { staticClass: "p-field mb-0" },
                [
                  _c("label", { attrs: { for: "username1" } }, [
                    _vm._v("Selecione uma Empresa"),
                  ]),
                  _c("Dropdown", {
                    staticClass: "border-radius-6",
                    attrs: {
                      options: _vm.teste,
                      "option-label": "name",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.option1,
                      callback: function ($$v) {
                        _vm.option1 = $$v
                      },
                      expression: "option1",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "p-fluid p-formgrid p-col-4" }, [
              _c(
                "div",
                { staticClass: "p-field mb-0" },
                [
                  _c("label", { attrs: { for: "username2" } }, [
                    _vm._v("Selecione um grupo"),
                  ]),
                  _c("Dropdown", {
                    staticClass: "border-radius-6",
                    attrs: {
                      options: _vm.teste,
                      "option-label": "name",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.option2,
                      callback: function ($$v) {
                        _vm.option2 = $$v
                      },
                      expression: "option2",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "p-fluid p-formgrid p-col-4" }, [
              _c(
                "div",
                { staticClass: "p-field mb-0" },
                [
                  _c("label", { attrs: { for: "username3" } }, [
                    _vm._v("Selecione uma equipe"),
                  ]),
                  _c("Dropdown", {
                    staticClass: "border-radius-6",
                    attrs: {
                      options: _vm.teste,
                      "option-label": "name",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.option3,
                      callback: function ($$v) {
                        _vm.option3 = $$v
                      },
                      expression: "option3",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }