export type Master = {
  masterId: number;
  name: string;
  email: string;
}

export type State = {
  master: Master | null;
}

export default {
  master: null as Master | null,
};
