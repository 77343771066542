var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "w-full h-screen flex" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "relative flex-1" },
      [
        _c("SupportInternationalization"),
        _c(
          "div",
          { staticClass: "flex items-center justify-center w-full height" },
          [
            _c("div", { staticClass: "centered-text p-10 rounded" }, [
              _c("h2", { staticClass: "mb-4" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("configHomeScreen.start.hello")) + " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("configHomeScreen.start.ready.firstNormal")) +
                    " "
                ),
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("configHomeScreen.start.ready.secondBold")
                      ) +
                      " "
                  ),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("configHomeScreen.start.ready.thirdNormal")) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("configHomeScreen.start.message.firstNormal")
                    ) +
                    " "
                ),
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("configHomeScreen.start.message.secondBold")
                      ) +
                      " "
                  ),
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("configHomeScreen.start.message.thirdNormal")
                    ) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("configHomeScreen.start.technology.firstNormal")
                    ) +
                    " "
                ),
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("configHomeScreen.start.technology.secondBold")
                      ) +
                      " "
                  ),
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("configHomeScreen.start.technology.thirdNormal")
                    ) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "mt-12" },
                [
                  _c(
                    "Button",
                    { staticClass: "button", on: { click: _vm.goToBank } },
                    [
                      _c("span", { staticClass: "button__text mx-auto" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("configHomeScreen.start.buttonText")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "relative" }, [
      _c("img", {
        staticClass: "img h-full",
        attrs: {
          src: require("@/common/assets/images/onboarding-bank/bg-config-home.jpg"),
          alt: "Background Inicial",
        },
      }),
      _c("img", {
        staticClass: "absolute top-12 left-12",
        attrs: {
          width: "236",
          src: require("@/common/assets/images/logo-roit-solutions-white.svg"),
          alt: "Logo ROIT Bank",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }