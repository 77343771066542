import { LocaleMessages } from 'vue-i18n';

const locales: LocaleMessages = {
  'pt-br': {
    stepIntegrations: {
      alert: {
        textTitle: 'Atenção!',
        textContent: 'Nenhum sistema cadastrado. Tem certeza que deseja pular esta etapa?',
        textBtnOk: 'Pular etapa',
        textBtnCancel: 'Voltar',
      },
    },
  },
  es: {
    stepIntegrations: {
      alert: {
        textTitle: '¡Atención!',
        textContent: 'Ningún sistema registrado. ¿Está seguro de que desea omitir este paso?',
        textBtnOk: 'Omitir paso',
        textBtnCancel: 'Atrás',
      },
    },
  },
  en: {
    stepIntegrations: {
      alert: {
        textTitle: 'Attention!',
        textContent: 'No registered system. Are you sure you want to skip this step?',
        textBtnOk: 'Skip step',
        textBtnCancel: 'Back',
      },
    },
  },
};

export default locales;
