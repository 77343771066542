import { LocaleMessages } from 'vue-i18n';

const locales: LocaleMessages = {
  'pt-br': {
    stepCompanies: {
      warnMandatoryFieldFilling: '* Os campos "Regime Tributário" e "Apuração" são de preenchimento obrigatório.',
    },
  },
  es: {
    stepCompanies: {
      warnMandatoryFieldFilling: '* Los campos "Régimen fiscal" y "Fiscalidad" son obligatorios.',
    },
  },
  en: {
    stepCompanies: {
      warnMandatoryFieldFilling: '* The fields "Tax Regime" and "Taxation" are mandatory.',
    },
  },
};

export default locales;
