<template>
  <el-pagination
    class="r-pagination"
    layout="prev, pager, next"
    :total="totalItems"
    :page-size="pageSize"
    small
    @current-change="$emit('change', $event)"
  />
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    totalItems: {
      default: 0,
      type: Number,
      required: false,
    },
    pageSize: {
      default: 0,
      type: Number,
      required: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/common/assets/scss/_variables.scss";

.r-pagination {
  display: flex;
  align-items: center;
  float: right;

  .btn-next,
  .btn-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px !important;
    height: 26px !important;
    padding: 0 !important;
    border-radius: 4px;
    background-color: #F2F3F5 !important;
    color: #152E3E;
  }

  .btn-prev {
    margin-right: 8px !important;
  }

  .btn-next {
    margin-left: 8px !important;
  }

  .el-pager {
    li {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px !important;
      color: #183242DE;
      font-family: $family-medium;

      &.number {
        font-size: 14px;
      }

      &.active {
        border-radius: 4px;
        color: #ffffff;
        background-color: $color-primary;
      }
    }
  }
}
</style>
