
import Vue from 'vue';
import CompanyForm from './CompanyForm.vue';
import { GroupAndEstablishmentsPayload } from './types';

export default Vue.extend({
  components: {
    CompanyForm,
  },
  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  data: () => ({
    activeIndex: 0,
    tabActive: true,
    companyTemplate:
      `${process.env.VUE_APP_ESTABLISHMENT_REGISTER_TEMPLATE}` as string,
    loaded: '',
    payload: {
      addCompany: {} as GroupAndEstablishmentsPayload,
    },
  }),
  computed: {
    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },
  },
  methods: {
    async confirm() {
      const isValid = await this.validCompanyForm();
      if (isValid) {
        if (this.tabActive === true) {
          this.$emit('company:registrations', this.payload.addCompany);
        } else {
          this.$emit('company:loaded', this.loaded);
        }
      }
    },
    async validCompanyForm(): Promise<boolean> {
      const companyForm = this.$refs.companyForm as InstanceType<typeof CompanyForm>;
      return companyForm.formValid();
    },
  },
});
