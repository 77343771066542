var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("fieldset", { staticClass: "mt-2" }, [
    _c("legend", [
      _vm._v(_vm._s(_vm.$t("integrations.optionsSectionForm.title"))),
    ]),
    _c("div", { staticClass: "p-grid p-fluid" }, [
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t("integrations.optionsSectionForm.dropdownLabel"),
                name: _vm.$t("integrations.optionsSectionForm.dropdownLabel"),
                rules: "required",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("Dropdown", {
                        staticClass: "rounded-lg",
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          options: _vm.whenFailRetryEachList,
                          "option-label": "label",
                          "option-value": "value",
                          placeholder: _vm.$t("integrations.forms.select"),
                          filter: true,
                        },
                        model: {
                          value: _vm.integrationOptions.retryAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.integrationOptions, "retryAt", $$v)
                          },
                          expression: "integrationOptions.retryAt",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "p-col-6" }, [
        _c(
          "div",
          { staticClass: "p-field mb-0" },
          [
            _c("input-validation", {
              attrs: {
                label: _vm.$t("integrations.optionsSectionForm.inputLabel"),
                name: "integrationSystemOptionsMaxDownloadDocs",
                rules: "required|numeric|min_value:1|max_value:100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("InputText", {
                        class: { "p-invalid": errors[0] },
                        attrs: {
                          placeholder: _vm.$t(
                            "integrations.optionsSectionForm.inputPlaceholder"
                          ),
                        },
                        model: {
                          value: _vm.integrationOptions.maxDownloadDocs,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.integrationOptions,
                              "maxDownloadDocs",
                              $$v
                            )
                          },
                          expression: "integrationOptions.maxDownloadDocs",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }