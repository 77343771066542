import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import MyProductsRoutes from '@/resources/my-products/routes';
import MyCompaniesRoutes from '@/resources/my-companies/routes';
// import UsersRoutes from '@/resources/users/routes';
import IntegrationsRoutes from '@/resources/integrations/routes';
import DigitalCertificatesRoutes from '@/resources/digital-certificates/routes';
import BillingRoutes from '@/resources/billing/routes';
import ProfileRoutes from '@/resources/profile/routes';
import AutomaticTaxInvoiceInboundRoutes from '@/resources/automatic-tax-invoice-inbound/routes';
import OnboardingBankRoutes from '@/resources/onboarding-bank/routes';
import AccessPassRoutes from '@/resources/access-pass/routes';
import UsersAndContactsRoutes from '@/resources/users-and-contacts/routes';
import TaskManagerRoutes from '@/resources/task-manager/routes';

import {
  ChangePassword,
  Finish,
  Start,
} from '@/resources/onboarding/views';
import StartOnboardingBank from '@/resources/onboarding-bank/StartOnboardingBank.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
  },
  {
    path: '/start-onboarding-bank',
    name: 'StartOnboardingBank',
    component: StartOnboardingBank,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/finish',
    name: 'Finish',
    component: Finish,
    props: (route: Route) => ({
      finish: route.params.finish,
    }),
  },
  ...MyProductsRoutes,
  ...MyCompaniesRoutes,
  ...IntegrationsRoutes,
  ...DigitalCertificatesRoutes,
  ...BillingRoutes,
  ...ProfileRoutes,
  ...OnboardingBankRoutes,
  ...AccessPassRoutes,
  ...UsersAndContactsRoutes,
  ...AutomaticTaxInvoiceInboundRoutes,
  ...TaskManagerRoutes,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// TODO: Get lang from vuex
/* router.beforeEach((to, from, next) => {
  const { lang } = to.params;
  loadLanguageAsync(lang).then(() => next());
}); */

export default router;
