var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "rules-grid" },
      _vm._l(_vm.rules, function (rule, id) {
        return _c("div", { key: id }, [
          _c("i", {
            class: [
              _vm.validate(rule.rule) ? "pi-check success" : "pi-times fail",
              "pi fs12",
            ],
          }),
          _c("span", { staticClass: "ml-1 fs12" }, [
            _vm._v(_vm._s(rule.title)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }