import { LocaleMessages } from 'vue-i18n';

const locale: LocaleMessages = {
  'pt-br': {
    rAlert: {
      textTitle: 'Titulo não definido',
      textContent: 'Conteúdo não definido',
      textBtnOk: 'Aceitar',
      textBtnCancel: 'Cancelar',
    },
  },
  es: {
    rAlert: {
      textTitle: 'Título no definido',
      textContent: 'Contenido no definido',
      textBtnOk: 'Aceptar',
      textBtnCancel: 'Cancelar',
    },
  },
  en: {
    rAlert: {
      textTitle: 'Undefined title',
      textContent: 'Undefined Content',
      textBtnOk: 'Accept',
      textBtnCancel: 'Cancel',
    },
  },
};

export default locale;
