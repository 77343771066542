var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Form",
    [
      _c(
        "ValidationObserver",
        { ref: "form", attrs: { slim: "" } },
        [
          _c("div", { staticClass: "p-grid p-fluid" }, [
            _c("div", { staticClass: "p-col-12" }, [
              _c(
                "div",
                { staticClass: "p-field mb-0" },
                [
                  _c("input-validation", {
                    attrs: {
                      label: _vm.$t("integrations.form.establishment.label"),
                      name: _vm.$t("integrations.form.establishment.label"),
                      rules: "required",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "p-col-12 p-0" },
                              [
                                _c("MultiSelect", {
                                  staticClass: "rounded-lg",
                                  class: { "p-invalid": errors[0] },
                                  attrs: {
                                    "input-id": "companies",
                                    options: _vm.companies,
                                    "option-label": "optionLabel",
                                    "option-value": "name",
                                    placeholder: _vm.$t(
                                      "integrations.form.establishment.placeholder"
                                    ),
                                    display: "chip",
                                    filter: true,
                                  },
                                  model: {
                                    value: _vm.formData.companies,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "companies", $$v)
                                    },
                                    expression: "formData.companies",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "p-grid p-fluid" }, [
            _c("div", { staticClass: "p-col-12" }, [
              _c(
                "div",
                { staticClass: "p-field mb-0" },
                [
                  _c("input-validation", {
                    attrs: {
                      label: _vm.$t(
                        "integrations.form.integrationPurpose.label"
                      ),
                      vid: "finalities",
                      rules: "required",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "p-formgroup-inline" },
                              _vm._l(
                                _vm.integrationPurposes,
                                function (integrationPurpose, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticClass: "p-field-checkbox mb-0.5",
                                    },
                                    [
                                      _c("Checkbox", {
                                        ref: "purpose",
                                        refInFor: true,
                                        class: { "p-invalid": errors[0] },
                                        attrs: {
                                          id: integrationPurpose,
                                          value: integrationPurpose.value,
                                        },
                                        model: {
                                          value: _vm.formData.purposes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "purposes",
                                              $$v
                                            )
                                          },
                                          expression: "formData.purposes",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: integrationPurpose } },
                                        [
                                          _vm._v(
                                            _vm._s(integrationPurpose.label)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "p-grid p-fluid" }, [
            _c("div", { staticClass: "p-col-6" }, [
              _c(
                "div",
                { staticClass: "p-field mb-0" },
                [
                  _c("input-validation", {
                    attrs: {
                      label: _vm.$t(
                        "integrations.form.integrationSystem.label"
                      ),
                      rules: "required",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("Dropdown", {
                              ref: "system",
                              staticClass: "rounded-lg",
                              class: { "p-invalid": errors[0] },
                              attrs: {
                                "input-id": "system",
                                filter: true,
                                options: _vm.systemOptions,
                                "option-label": "label",
                                "option-value": "value",
                                placeholder: _vm.$t(
                                  "integrations.form.integrationSystem.placeholder"
                                ),
                              },
                              model: {
                                value: _vm.formData.system,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "system", $$v)
                                },
                                expression: "formData.system",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "p-col-2" }, [
              _c(
                "div",
                { staticClass: "p-field mb-0" },
                [
                  _c("input-validation", {
                    attrs: {
                      label: _vm.$t(
                        "integrations.form.integrationSystemVersion.label"
                      ),
                      name: _vm.$t(
                        "integrations.form.integrationSystemVersion.label"
                      ),
                      rules: { required: true },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("InputText", {
                              class: { "p-invalid": errors[0] },
                              attrs: {
                                placeholder: _vm.$t(
                                  "integrations.form.integrationSystemVersion.placeholder"
                                ),
                              },
                              model: {
                                value: _vm.formData.version,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "version", $$v)
                                },
                                expression: "formData.version",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.formData.system === _vm.SAPB1
              ? _c(
                  "div",
                  { key: _vm.SAPB1 },
                  [
                    _c("SapB1AuthSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                    _c("SapB1DatabaseSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                    _c("SapB1ParametersSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.system === _vm.PROTHEUS
              ? _c(
                  "div",
                  { key: _vm.PROTHEUS },
                  [
                    _c("ProtheusAuthSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                    _c("ProtheusParametersSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.system === _vm.SAPECC
              ? _c(
                  "div",
                  { key: _vm.SAPECC },
                  [
                    _c("SapEccAuthSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.system === _vm.SAPS4HANA
              ? _c(
                  "div",
                  { key: _vm.SAPS4HANA },
                  [
                    _c("S4HanaAuthSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.system === _vm.NETSUITE
              ? _c(
                  "div",
                  { key: _vm.NETSUITE },
                  [
                    _c("NetSuiteAuthSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.system === _vm.QUESTOR
              ? _c(
                  "div",
                  { key: _vm.QUESTOR },
                  [
                    _c("QuestorAuthSectionForm", {
                      attrs: { "system-parameters": _vm.systemParameter },
                      on: {
                        "update:systemParameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                        "update:system-parameters": function ($event) {
                          _vm.systemParameter = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.formData.system
              ? _c(
                  "div",
                  { key: "INTEGRATION_OPTION" },
                  [
                    _c("IntegrationOptionsSectionForm", {
                      attrs: { "common-parameter": _vm.commonParameter },
                      on: {
                        "update:commonParameter": function ($event) {
                          _vm.commonParameter = $event
                        },
                        "update:common-parameter": function ($event) {
                          _vm.commonParameter = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }