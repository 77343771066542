var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("TwoColumn", [
    _c(
      "div",
      { staticClass: "flex flex-col items-center" },
      [
        _c(
          "div",
          {
            staticClass:
              "flex flex-col items-center justify-center w-16 h-16 mb-6 bg-green rounded-full",
          },
          [_c("i", { staticClass: "icon-r-check h-8 w-8 bg-white" })]
        ),
        _c("h2", { staticClass: "mb-8 px-6 text-center font-weight-bold" }, [
          _vm._v(" " + _vm._s(_vm.$t("onboarding.finish.message")) + " "),
        ]),
        _c(
          "Button",
          {
            staticClass: "p-button-raised button",
            on: {
              click: function ($event) {
                return _vm.accessCompany()
              },
            },
          },
          [
            _c("span", { staticClass: "button__text mx-auto" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("onboarding.finish.buttonTextPt1")) + " "
              ),
              _c("br"),
              _vm._v(
                " " + _vm._s(_vm.$t("onboarding.finish.buttonTextPt2")) + " "
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }