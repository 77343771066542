var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          attrs: {
            visible: _vm.displayModal,
            modal: true,
            "content-style": { overflow: "visible" },
          },
          on: {
            "update:visible": function ($event) {
              _vm.displayModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("h3", { staticClass: "not-found-parent-company__title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "companyGroupComponent.notice.notFoundParentCompany.title"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("Button", {
                    staticClass: "not-found-parent-company__btn-close outlined",
                    attrs: { label: _vm.$t("close") },
                    on: {
                      click: function ($event) {
                        _vm.displayModal = false
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "not-found-parent-company__body" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "companyGroupComponent.notice.notFoundParentCompany.message"
                  )
                )
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }