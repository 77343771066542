import mixins from 'vue-typed-mixins';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { chain } from 'lodash';

import NotificationMixin from '@/mixins/NotificationMixin';
import NumberRemoveFormat from '@/common/filters/NumberRemoveFormat';
import CnpjFormat from '@/common/filters/CnpjFormat';
import { HttpErrorResponse } from '@/types';
import { CreateCompanyGroupPayload, EditCompanyGroupPayload } from '@/common/components/companyGroup/types';
import CompanyGroupService from '@/common/services/CompanyGroupService';
import CompanyMixin from './CompanyMixin';

export type CNPJError = {
  cnpj: string;
  status: string;
  message: string;
};

export default mixins(NotificationMixin, CompanyMixin).extend({
  data: () => ({
    messageErrors: [] as Array<CNPJError>,
    showProcessingWarning: false,
  }),

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
      masterId: 'auth/masterId',
    }),
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    ...mapState({
      locale: 'auth/acceptLanguage',
    }),

    async companyGroupRegistration(
      createCompanyGroup: CreateCompanyGroupPayload,
    ): Promise<boolean> {
      try {
        const { companies, groupName } = createCompanyGroup;
        const { masterId } = this;

        const companiesInformation = companies
          .map((res) => ({ ...res, nationalRegister: NumberRemoveFormat(res.nationalRegister) }));

        await CompanyGroupService
          .createCompanyGroup({
            name: groupName, master: masterId, companiesInformation,
          });

        this.showProcessingWarning = true;
        return true;
      } catch ({ response }) {
        this.handleErrors(response as HttpErrorResponse);
        this.showProcessingWarning = false;
        return false;
      }
    },

    async companyGroupUpdate(
      editGroupData: EditCompanyGroupPayload,
    ): Promise<boolean> {
      try {
        const { companyGroupId, groupName } = editGroupData;

        await CompanyGroupService
          .companyGroupNameUpgrade(companyGroupId, groupName);

        return true;
      } catch ({ response }) {
        this.handleErrors(response as HttpErrorResponse);
        return false;
      }
    },

    getErrors(errors: Array<any>): Array<any> {
      return errors.filter((error: any) => error.status === 'error');
    },

    handleErrors(response: HttpErrorResponse): void {
      if (response?.data?.errors) {
        let { errors } = response.data;

        errors = chain(errors[0].children)
          .map((error: any) => ({
            cnpj: null,
            status: 'ERROR',
            message: error.children[0].validations[0],
          }))
          .uniqBy('message')
          .value();

        this.messageErrors = errors as Array<CNPJError>;

        this.errorToast({
          text: this.$t('onboarding.registerCompany.table.errorInRegistration') as string,
        });

        return;
      }

      if (/[{}]/.test(response?.data?.message)) {
        let listErrors = JSON.parse(response.data.message);

        listErrors = chain(listErrors)
          .map((error: CNPJError) => ({
            ...error,
            cnpj: CnpjFormat(error.cnpj),
          }))
          .uniqBy('cnpj')
          .value();

        this.messageErrors = listErrors as Array<CNPJError>;

        this.errorToast({
          text: this.$t('onboarding.registerCompany.table.errorInRegistration') as string,
        });

        return;
      }

      this.errorToast({
        text: this.$t('onboarding.registerCompany.table.errorRegistration', {
          errors: response?.data?.message,
        }) as string,
      });
    },
  },
});
