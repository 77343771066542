import CreditCardValidate from './credit-card';
import { VeeValidateParams } from '../../types';

export const validator = {
  getMessage() {
    return 'Invalid Credit Card';
  },

  validate(value: any, { allowed }: VeeValidateParams): boolean {
    return CreditCardValidate(value, allowed);
  },

  params: ['allowed'],
};
