var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "onboarding-bank table-container" }, [
    _c("table", { staticClass: "w-full" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CNPJOrigin.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CNPJOrigin.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CNPJOrigin.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CNPJDestination.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CNPJDestination.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CNPJDestination.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.NCMCode.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.NCMCode.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.NCMCode.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CFOPCode.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CFOPCode.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.CFOPCode.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.unitaryValue.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.unitaryValue.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.unitaryValue.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.bookValue.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.bookValue.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.bookValue.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.debitAccount.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "type" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.debitAccount.type"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("td", [
            _c("p", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "onboardingBank.accountantRobot.productLaunch.debitAccount.description"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }