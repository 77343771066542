import axios from '@/common/http';

class MasterService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1/master`;

  getBaseUrl() {
    return this.baseUrl;
  }

  async getMasterById(masterId: string) {
    const { data: response } = await this.service.get(`${this.baseUrl}/${masterId}`);
    return response.data;
  }

  async getMastersNames(masterIds: string[]) {
    const url = `${this.baseUrl}/names/list`;
    const { data: response } = await this.service
      .post<{data: {id: string; name: string}[]}>(url, { ids: masterIds });
    return response.data;
  }
}

export default new MasterService();
