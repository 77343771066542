
import ConvertFile from '@/common/classes/ConvertFile';
import RoitDataTable from '@/common/components/datatable/RoitDataTable.vue';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import LoaderSpinner from '@/common/components/LoaderSpinner/LoaderSpinner.vue';
import UploadDropzone from '@/common/components/upload-dropzone/UploadDropzone.vue';
import ParamNotFoundError from '@/common/errors/ParamNotFoundError';
import CnpjFormat from '@/common/filters/CnpjFormat';
import DateFormat from '@/common/filters/DateFormat';
import CertificateService from '@/common/services/CertificateService';
import NotificationMixin from '@/mixins/NotificationMixin';
import DigitalCertificateMessage from '@/resources/digital-certificates/components/DigitalCertificateMessage.vue';
import DigitalCertificateModalUpload from '@/resources/digital-certificates/components/DigitalCertificateModalUpload.vue';
import DigitalCertificateTable from '@/resources/digital-certificates/components/DigitalCertificateTable.vue';
import DigitalCertificateValidateModal from '@/resources/digital-certificates/components/DigitalCertificateValidateModal.vue';
import {
  Certificate,
  Company, DigitalCertificate,
  RoitFile,
} from '@/types';
import { debounce } from 'lodash';
// import BaseError from '@/common/errors/BaseError';
import InfiniteLoading from 'vue-infinite-loading';
import mixins from 'vue-typed-mixins';
import Teleport from 'vue2-teleport';
import { mapGetters, mapMutations } from 'vuex';
import request from 'axios';
import locales from './locales';

export default mixins(NotificationMixin).extend({
  name: 'RegisterDigitalCertificate',

  i18n: {
    messages: locales,
  },

  components: {
    UploadDropzone,
    RoitDataTable,
    InputPassword,
    DigitalCertificateModalUpload,
    DigitalCertificateTable,
    DigitalCertificateMessage,
    InfiniteLoading,
    LoaderSpinner,
    Teleport,
    DigitalCertificateValidateModal,
  },

  filters: {
    CnpjFormat,
    DateFormat,
  },

  data: () => ({
    showDigCertModal: false as boolean,
    showCompanyModal: false as boolean,
    modalCompanyKey: 0 as number,
    tableLoading: false as boolean,
    dropzoneOptions: {
      addRemoveLinks: true,
      dictInvalidFileType: '',
    },
    files: [] as Array<RoitFile>,
    removeFiles: [] as Array<File>,
    selectedCompany: {} as Company,
    certificates: [] as Array<Certificate>,
    search: '',
    messageErrors: [] as Array<string>,
    page: 0 as number,
    perPage: 25 as number,
    perPageEstablishment: 50,
    pageEstablishment: 0,
    hasNextPage: true,
    showLoaderSpinner: false,
    showCertificateValidateModal: false,
    isSearchLoading: false,
  }),

  async mounted() {
    this.dropzoneOptions.dictInvalidFileType = this.$t('digitalCertificates.notifications.msgIncorrectFile') as string;
    if (this.token && this.userLoggedIn) {
      this.getCertificates();
    }
  },

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),

    uploadDropzone(): InstanceType<typeof UploadDropzone> {
      return this.$refs.uploadDropzone as InstanceType<typeof UploadDropzone>;
    },
  },

  watch: {
    token(token: string): void {
      if (token && this.userLoggedIn) {
        this.getCertificates();
      }
    },

    selectedCompany(): void {
      (this.files as Array<RoitFile>).forEach((file) => {
        (this.removeFiles as Array<File>).push(file.certificado.file);
      });
    },

    showCompanyModal(): void {
      this.modalCompanyKey += 1;
    },

    certificates(data: []): void {
      const hasData = data && data.length > 0;
      this.$emit('has-data', hasData);
    },

    messageErrors(msn: any[]) {
      if (msn && msn.length > 0) {
        const errors = {
          userMasterNotSameFromEstab: 'userMasterNotSameFromEstab',
          certificateAlreadyExists: 'certificateAlreadyExists',
          passwordIncorrect: 'passwordIncorrect',
          msgCorruptCertificate: 'DerInputStream.getLength(): lengthTag=111, too big.',
        };

        this.messageErrors = msn.map((item) => {
          if (item.message === errors.userMasterNotSameFromEstab) {
            return {
              ...item,
              message: this.$t('digitalCertificates.notifications.userMasterNotSameFromEstab'),
            };
          }

          if (item.message === errors.certificateAlreadyExists) {
            return {
              ...item,
              message: this.$t('digitalCertificates.notifications.msgErrorAlreadyExists'),
            };
          }

          if (item.message === errors.passwordIncorrect) {
            return {
              ...item,
              message: this.$t('digitalCertificates.notifications.passwordIncorrect'),
            };
          }

          if (item.message === errors.msgCorruptCertificate) {
            return {
              ...item,
              message: this.$t('digitalCertificates.notifications.msgCorruptCertificate'),
            };
          }

          return item;
        });
      }
    },
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    clearUpload(): void {
      this.selectedCompany = {} as Company;
      this.removeFiles = this.files.map((file) => file.certificado.file);
    },

    async getCertificates(loading = true, fileHandled?: boolean): Promise<Certificate[]> {
      try {
        this.handleLoading(loading);
        this.tableLoading = loading;

        const certificates = await CertificateService.findCertificates(
          this.search,
          this.page,
          this.perPage,
        );
        this.page += 1;

        if (fileHandled) {
          this.certificates = certificates;
        } else {
          this.certificates.push(...certificates);
        }

        return certificates;
      } catch (e) {
        const error = e as any;
        if ('message' in error && error.message === 'Employee not found') {
          this.errorToast({
            text: this.$t('digitalCertificates.table.employeeNotFound') as string,
          });
          throw e;
        }

        this.errorToast({
          text: this.$t('digitalCertificates.table.listMsgError') as string,
        });
        throw e;
      } finally {
        this.handleLoading(false);
        this.tableLoading = false;
      }
    },

    updateModal(action: boolean): void {
      this.showDigCertModal = action;

      this.files = this.files.map((file: RoitFile) => {
        if (file.readonly && file.senha) {
          return file;
        }

        return {
          ...file,
          senha: '',
        };
      });

      this.removeFiles = this.files
        .filter((file: RoitFile) => !file.senha)
        .map((file: RoitFile) => file.certificado.file);

      this.files = this.files.filter((file: RoitFile) => file.senha);
    },

    fileAdded(files: Array<File>): void {
      if (files[0].type !== 'application/x-pkcs12') {
        this.removeFiles = Array.from(files);

        this.warningToast({
          text: this.$t('digitalCertificates.notifications.msgIncorrectFile') as string,
        });
      }
    },

    fileRemoved(dropzone: any): void {
      const { file } = dropzone;

      if (this.files.length) {
        this.files = this.files.filter(({ certificado }) => certificado.name !== file.name);
      }
    },

    fileSending(dropzone: any): void {
      const { file } = dropzone;

      ConvertFile.toBase64(file)
        .then((base64) => {
          this.files.push({
            certificado: {
              name: file.name,
              file,
              base64,
            },
            senha: '',
          } as RoitFile);

          this.showDigCertModal = true;
        });
    },

    async fileSubmit(files: Array<RoitFile>): Promise<void> {
      try {
        this.handleLoading(true);

        const [filesSending] = files
          .filter((file: RoitFile) => !file.sent)
          .map((file: RoitFile) => ({
            ...file,
            sent: true,
          }));

        this.files = Object.assign(files, filesSending);

        const payload = {
          razaoSocial: this.selectedCompany.name,
          file: {
            fileName: filesSending.certificado.name,
            password: filesSending.senha,
            base64: filesSending.certificado.base64,
            canSEFAZNoteDownload: false,
          },
        } as unknown as Certificate;

        const result = (await CertificateService.createCertificates(payload)).data.data;

        const success = result.some((r: any) => r.status === 'success');

        if (success) {
          this.successToast({
            text: this.$t('digitalCertificates.notifications.msgSuccessInclude') as string,
          });
        }

        this.page = 0;
        this.messageErrors = result.filter((r: any) => r.status === 'error');
        await this.getCertificates(true, true);
      } catch (e) {
        if (request.isAxiosError(e) && e.response) {
          if (e.response.data.message.toLowerCase() === 'establishment not found') {
            this.errorToast({
              text: this.$t('digitalCertificates.notifications.msgErrorEstablishmentNotFound') as string,
            });
          } else {
            this.errorToast({
              text: this.$t('digitalCertificates.notifications.msgErrorInclude') as string,
            });
          }
        }
      } finally {
        this.handleLoading(false);
        this.clearUpload();
      }
    },

    fileDuplicate(file: File): void {
      this.warningToast({
        text: this.$t('digitalCertificates.dropzone.duplicate', {
          name: file.name,
        }) as string,
      } as any);
    },

    onlyUpdate(certificate: DigitalCertificate): boolean {
      return !!certificate?.inUse;
    },

    openUpdateCertificate(): void {
      this.uploadDropzone.open();
    },

    async certificateDelete(certificate: DigitalCertificate): Promise<void> {
      try {
        if (this.onlyUpdate(certificate)) {
          const { isConfirmed } = await this.confirmNotDeleteOnlyUpdate({
            title: this.$t('digitalCertificates.dialogs.cantDeleteOnlyUpdate.title') as string,
            text: this.$t('digitalCertificates.dialogs.cantDeleteOnlyUpdate.message') as string,
            extraText: this.$t('digitalCertificates.dialogs.cantDeleteOnlyUpdate.extraMessage') as string,
          });

          if (!isConfirmed) return;

          this.openUpdateCertificate();
          return;
        }

        const { isConfirmed } = await this.confirmDelete({
          title: this.$t('digitalCertificates.dialogs.delete.title') as string,
          text: this.$t('digitalCertificates.dialogs.delete.message') as string,
        });

        if (!isConfirmed) return;

        this.handleLoading(true);

        // await CertificateService.deleteCertificate(certificate.id);

        this.successToast({
          text: this.$t('digitalCertificates.notifications.msgDeleteSuccess') as string,
        });

        this.page = 0;
        await this.getCertificates(true, true);
      } catch (e) {
        console.log(e);
        this.errorToast({
          text: this.$t('digitalCertificates.notifications.msgDeleteError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async certificateCanSEFAZNotaDownloadUpdate(certificate: DigitalCertificate): Promise<void> {
      try {
        this.handleLoading(true);

        const { id, canSEFAZNoteDownload } = certificate;

        if (id === undefined || canSEFAZNoteDownload === undefined) {
          throw new ParamNotFoundError('o registro está sem id referencia');
        }

        await CertificateService.updateCanSEFAZNoteDownload(id, canSEFAZNoteDownload);

        this.successToast({
          text: this.$t('digitalCertificates.notifications.msgUpdateCanSEFAZNotaDownloadSuccess') as string,
        });
      } catch (e) {
        // TODO: verificar a origem do erro
        this.errorToast({
          text: this.$t('digitalCertificates.notifications.msgUpdateError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async certificateSearch(): Promise<void> {
      try {
        const { valid } = await (this.$refs.search as any).validate();

        if (
          (valid || this.search.length >= 2)
          && this.search.length >= 2
          && this.search.length <= 40
        ) {
          this.searchable();
        } else if (this.search === '') {
          this.searchable();
        }
      } catch (e) {
        this.errorToast({
          text: this.$t('digitalCertificates.table.errorSearch') as string,
        });
      }
    },
    async infiniteHandler($state: any) {
      let result = [];

      const certificatesLength = this.certificates.length;

      if (certificatesLength > 0 && certificatesLength >= this.perPage) {
        result = await this.getCertificates(false);
      }

      if (result.length > 0) {
        $state.loaded();
      } else {
        $state.complete();
      }
      $state.complete();
    },
    searchable: debounce(
      // eslint-disable-next-line func-names
      async function () {
        // @ts-ignore
        this.page = 0;
        // @ts-ignore
        this.isSearchLoading = true;
        // @ts-ignore
        this.certificates = [];

        try {
          // @ts-ignore
          await this.getCertificates();
        } catch {
          // empty
        }
        // @ts-ignore
        this.isSearchLoading = false;
      },
      600,
    ),
    async addEvent() {
      const dropdown = (this.$refs.selectCompany as Vue).$el;
      const target = dropdown.querySelector('.p-dropdown-items-wrapper') as Element;
      target.addEventListener('scroll', async (e: Event) => {
        if (e.target !== null) {
          const { scrollHeight, scrollTop, clientHeight } = e.target as Element;
          if (
            (scrollTop + clientHeight) === scrollHeight
            && this.hasNextPage
            && !this.showLoaderSpinner
          ) {
            this.showLoaderSpinner = true;
            this.showLoaderSpinner = false;
          }
        }
      });
    },

    async downloadCertificate(certificate: DigitalCertificate) {
      const data = await CertificateService.certificateContent(certificate.id);
      const linkSource = `data:application/pfx;base64,${data.data.data}`;

      const downloadLink = document.createElement('a');

      const fileName = certificate.fileName ?? 'certificate.pfx';

      downloadLink.href = linkSource;

      downloadLink.download = fileName;

      downloadLink.click();
    },
  },
});
