export default [
  {
    path: '/my-products',
    name: 'my-products',
    redirect: { name: 'my-products-page' },
    component: () => import(/* webpackChunkName: "my-products" */ '@/resources/my-products/MyProductsTemplate.vue'),
    children: [
      {
        path: '',
        name: 'my-products-page',
        component: () => import(/* webpackChunkName: "my-products" */ '@/resources/my-products/MyProductsPage.vue'),
      },
      {
        path: '/manage-responsible',
        name: 'manage-responsible',
        component: () => import(/* webpackChunkName: "my-products" */ '@/resources/my-products/ManagerResponsible.vue'),
      },
    ],
  },
];
