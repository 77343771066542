import { mapGetters, mapMutations } from 'vuex';
import { SweetAlertResult } from 'sweetalert2';
import mixins from 'vue-typed-mixins';
import UsersAdminService from '@/common/services/UsersAdminService';
import { UserAdmin, UserAdminList } from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';

const formatToUserAdmin = (responsible: UserAdminList): UserAdmin => {
  const {
    id,
    job,
    email,
    lastName,
    name,
    products,
  } = responsible;
  return {
    id,
    job,
    email,
    firstName: name,
    lastName,
    products,
  };
};

export default mixins(NotificationMixin).extend({
  data: () => ({
    responsible: {
      table: {
        loading: false,
        data: [] as any[],
      },
      payload: undefined as UserAdmin | undefined,
      showModal: false as boolean,
      modalKey: 0 as number,
      search: '' as string,
      selfResponsible: false,
    },
  }),
  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),
    responsibleEmptySearch(): boolean {
      return this.responsible.search.trim().length <= 2;
    },

  },
  watch: {
    responsibleEmptySearch: {
      handler(isEmpty: boolean): void{
        if (isEmpty) {
          this.findAdminUsers();
        }
      },
    },
    'responsible.showModal': {
      handler(value: boolean): void {
        if (!value) {
          this.responsible.modalKey += 1;
          this.responsible.payload = undefined;
          this.responsible.selfResponsible = false;
        }
      },
    },
    'responsible.search': {
      handler(value: string): void {
        this.$nextTick(() => {
          if (!this.responsibleEmptySearch) {
            this.findAdminUsers(value.trim());
          }
        });
      },
    },
    token: {
      immediate: true,
      async handler(token: string): Promise<void> {
        await this.$nextTick();
        if (token && this.userLoggedIn) {
          this.findAdminUsers();
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async findAdminUsers(search = '') {
      this.responsible.table.loading = true;
      this.responsible.table.data = (await UsersAdminService.findAdminUsersGrouped(search))
        .data.data;

      this.responsible.table.loading = false;
    },
    async addNewResponsible(responsible: UserAdmin): Promise<void> {
      try {
        this.handleLoading(true);
        await UsersAdminService.createUserAdmin(responsible);
        this.successToast({
          text: this.$t('ManagerResponsible.addNewResponsible.msgSuccess') as string,
        } as any);
        this.findAdminUsers();
        this.responsible.showModal = false;
      } catch (e) {
        this.errorToast({
          text: this.$t('ManagerResponsible.addNewResponsible.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
    async updateUserAdmin(responsible: UserAdmin): Promise<void> {
      try {
        this.handleLoading(true);
        await UsersAdminService.updateUserAdmin(responsible);
        this.successToast({
          text: this.$t('ManagerResponsible.updateUserAdmin.msgSuccess') as string,
        } as any);
        this.findAdminUsers();
        this.responsible.showModal = false;
      } catch (e) {
        this.errorToast({
          text: this.$t('ManagerResponsible.updateUserAdmin.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
    async updateProductsUserAdmin(responsible: UserAdmin): Promise<void> {
      try {
        this.handleLoading(true);
        await UsersAdminService.updateProductsUserAdmin(responsible.email, responsible.products);
        this.successToast({
          text: this.$t('ManagerResponsible.updateUserAdmin.msgSuccess') as string,
        } as any);
        this.findAdminUsers();
        this.responsible.showModal = false;
      } catch (e) {
        this.errorToast({
          text: this.$t('ManagerResponsible.updateUserAdmin.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
    async editResponsible(responsible: UserAdminList): Promise<void> {
      this.responsible.payload = formatToUserAdmin(responsible);
      await this.$nextTick();
      this.responsible.showModal = true;
    },
    deleteResponsible(responsible: UserAdminList) {
      this.confirmDelete({
        title: this.$t('ManagerResponsible.deleteResponsible.actionTitle') as string,
        text: this.$t('ManagerResponsible.deleteResponsible.actionMessage') as string,
        extraText: responsible.fullName as string,
      }).then(async ({ isConfirmed }: SweetAlertResult) => {
        if (isConfirmed) {
          try {
            const { email } = formatToUserAdmin(responsible);
            this.handleLoading(true);
            await UsersAdminService.deleteUserAdmin(email);
            this.successToast({
              text: this.$t('ManagerResponsible.deleteResponsible.msgSuccess') as string,
            } as any);
          } catch (e) {
            this.errorToast({
              text: this.$t('ManagerResponsible.deleteResponsible.msgError') as string,
            });
          } finally {
            this.handleLoading(false);
          }
        }
      });
    },
  },
});
