var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { width: "600px" },
      attrs: {
        visible: _vm.modal,
        header: "Cadastrar Responsável",
        modal: true,
        "content-style": { overflow: "visible" },
      },
      on: {
        "update:visible": function ($event) {
          _vm.modal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("Button", {
                staticClass: "outlined p-button-raised",
                attrs: { id: "cancelButton", label: _vm.$t("cancel") },
                on: {
                  click: function ($event) {
                    _vm.modal = false
                  },
                },
              }),
              _c("Button", {
                staticClass: "p-button-raised next-step",
                attrs: {
                  id: "registerButton",
                  label: _vm.$t(`${_vm.isUpdate ? "update" : "register"}`),
                },
                on: {
                  click: function ($event) {
                    return _vm.confirm()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "pt-4 pb-2" },
        [
          _c("ValidationObserver", { ref: "form", attrs: { slim: "" } }, [
            _c("div", { staticClass: "p-grid p-fluid" }, [
              _c("div", { staticClass: "p-col-6" }, [
                _c(
                  "div",
                  { staticClass: "p-field" },
                  [
                    _c("input-validation", {
                      attrs: {
                        label: _vm.$t(
                          "onboarding.registerResponsible.form.name"
                        ),
                        name: _vm.$t(
                          "onboarding.registerResponsible.form.name"
                        ),
                        rules: "required|max:240|alpha",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("InputText", {
                                class: { "p-invalid": errors[0] },
                                attrs: {
                                  id: "name",
                                  maxlength: "240",
                                  disabled: _vm.isUpdate || _vm.selfResponsible,
                                  placeholder: _vm.$t(
                                    "onboarding.registerResponsible.form.namePlaceholder"
                                  ),
                                  type: "text",
                                },
                                model: {
                                  value: _vm.payload.firstName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "firstName", $$v)
                                  },
                                  expression: "payload.firstName",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "p-col-6" }, [
                _c(
                  "div",
                  { staticClass: "p-field" },
                  [
                    _c("input-validation", {
                      attrs: {
                        label: _vm.$t(
                          "onboarding.registerResponsible.form.surname"
                        ),
                        name: _vm.$t(
                          "onboarding.registerResponsible.form.surname"
                        ),
                        rules: "required|max:240|alpha_spaces",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("InputText", {
                                class: { "p-invalid": errors[0] },
                                attrs: {
                                  id: "surname",
                                  maxlength: "240",
                                  placeholder: _vm.$t(
                                    "onboarding.registerResponsible.form.surnamePlaceholder"
                                  ),
                                  disabled: _vm.isUpdate || _vm.selfResponsible,
                                },
                                model: {
                                  value: _vm.payload.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "lastName", $$v)
                                  },
                                  expression: "payload.lastName",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "p-col-6" }, [
                _c(
                  "div",
                  { staticClass: "p-field" },
                  [
                    _c("input-validation", {
                      attrs: {
                        label: _vm.$t(
                          "onboarding.registerResponsible.form.mail"
                        ),
                        name: _vm.$t(
                          "onboarding.registerResponsible.form.mail"
                        ),
                        rules: "required|email",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("InputText", {
                                class: { "p-invalid": errors[0] },
                                attrs: {
                                  id: "mail",
                                  disabled: _vm.selfResponsible,
                                  placeholder: _vm.$t(
                                    "onboarding.registerResponsible.form.mailPlaceholder"
                                  ),
                                  type: "text",
                                },
                                model: {
                                  value: _vm.payload.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "email", $$v)
                                  },
                                  expression: "payload.email",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "p-col-6" }, [
                _c(
                  "div",
                  { staticClass: "p-field" },
                  [
                    _c("input-validation", {
                      attrs: {
                        label: _vm.$t(
                          "onboarding.registerResponsible.form.job"
                        ),
                        name: _vm.$t("onboarding.registerResponsible.form.job"),
                        rules: "required",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("Dropdown", {
                                class: { "p-invalid": errors[0] },
                                attrs: {
                                  options: _vm.occupations,
                                  "option-label": "optionLabel",
                                  "option-value": "name",
                                  placeholder: _vm.$t(
                                    "onboarding.registerResponsible.form.jobPlaceholder"
                                  ),
                                  disabled: _vm.isUpdate,
                                  filter: true,
                                  "empty-filter-message":
                                    _vm.$t("noResultsFound"),
                                },
                                model: {
                                  value: _vm.payload.job,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "job", $$v)
                                  },
                                  expression: "payload.job",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "p-col-6" }, [
                _c("div", { staticClass: "p-field" }, [
                  _c(
                    "span",
                    [
                      _c("label", { staticClass: "fs13 label-inputs" }, [
                        _vm._v(" Selecione o(s) Produto(s) "),
                      ]),
                      _c("small"),
                      _vm._l(_vm.products, function (product, idx) {
                        return _c(
                          "div",
                          { key: idx, staticClass: "p-field-checkbox mt-2" },
                          [
                            _c("Checkbox", {
                              attrs: {
                                id: product + idx,
                                value: product,
                                name: "products",
                              },
                              model: {
                                value: _vm.payload.products,
                                callback: function ($$v) {
                                  _vm.$set(_vm.payload, "products", $$v)
                                },
                                expression: "payload.products",
                              },
                            }),
                            _c("label", { attrs: { for: product + idx } }, [
                              _vm._v(_vm._s(product)),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }