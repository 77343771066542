var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          style: { maxWidth: "800px" },
          attrs: {
            visible: _vm.displayModal,
            modal: true,
            "content-style": { overflow: "visible" },
          },
          on: {
            "update:visible": function ($event) {
              _vm.displayModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(" " + _vm._s(_vm.$t("addCompany.modalTitle")) + " "),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("Button", {
                    staticClass: "outlined",
                    attrs: { label: _vm.$t("cancel") },
                    on: {
                      click: function ($event) {
                        _vm.displayModal = false
                      },
                    },
                  }),
                  _c("Button", {
                    staticClass: "p-button-raised mr-0",
                    attrs: { label: _vm.$t("register") },
                    on: { click: _vm.confirm },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "tab-view",
            {
              attrs: { "active-index": _vm.activeIndex },
              on: {
                "update:activeIndex": function ($event) {
                  _vm.activeIndex = $event
                },
                "update:active-index": function ($event) {
                  _vm.activeIndex = $event
                },
              },
            },
            [
              _c(
                "tab-panel",
                {
                  attrs: {
                    header: _vm.$t("addCompany.tabs.addCompanyTitle"),
                    active: _vm.tabActive[true],
                  },
                  on: {
                    "update:active": function ($event) {
                      return _vm.$set(_vm.tabActive, true, $event)
                    },
                  },
                },
                [
                  _c("CompanyForm", {
                    ref: "companyForm",
                    on: {
                      validForm: function ($event) {
                        _vm.addCompanyIsValid = $event
                      },
                    },
                    model: {
                      value: _vm.payload.addCompany,
                      callback: function ($$v) {
                        _vm.$set(_vm.payload, "addCompany", $$v)
                      },
                      expression: "payload.addCompany",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }