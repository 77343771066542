import { LocaleMessages } from 'vue-i18n';
import { I18n } from './types';

const locale: LocaleMessages = {
  'pt-br': {
    pageTitle: 'Primeiro, você precisa alterar sua senha…',
    pageSubtitle: 'Para garantirmos sua segurança, solicitamos a alteração da senha no 1º acesso. *',
    observation: '* Sempre que precisar ou desejar, poderá alterar a sua senha acessando seu perfil, nas informações pessoais.',
    user: 'Usuário',
    passwordHint: 'A senha deve conter 8 ou mais caracteres com uma combinação de letras, números e símbolos.',
    oldPassword: 'Senha Atual',
    newPassword: 'Nova Senha',
    confirmNewPassword: 'Confirmar Nova Senha',
    changePasswordButton: 'Alterar e Continuar',
    msgError: 'Falha na alteração de senha',
    msgPasswordDoesNotMatch: 'As senhas digitadas não são iguais',
    msgErrorNotEqualPasswords: 'A nova senha precisa ser diferente da senha atual',
    msgSuccess: 'Senha alterada com sucesso!',
    msgWorking: 'Processando a requisição...',
    rules: {
      minCharacter: 'Mínimo de 8 caracteres',
      hasLyrics: 'Possui letras',
      hasNumbers: 'Possui números',
      hasSpecialCharacters: 'Possui caracteres especiais',
    },
    msnErrorChangePassword: 'Erro ao alterar senha',
    msnUsernameNotFound: 'Usuário inexistente',
    msnCurrentPasswordFail: 'A senha atual não está correta',
  } as I18n,
  en: {
    pageTitle: 'First, you need to change your password…',
    pageSubtitle: 'To ensure your security, we ask you to change your password on the 1st login *',
    observation: '* Whenever you need or want, you can change your password by accessing your profile, in the personal information.',
    user: 'User',
    passwordHint: 'The password must contain 8 or more characters with a combination of letters, numbers and symbols.',
    oldPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    changePasswordButton: 'Change and Continue',
    msgError: 'Password change failed',
    msgPasswordDoesNotMatch: 'The passwords entered do not match',
    msgErrorNotEqualPasswords: 'The new password must be different from the current password',
    msgSuccess: 'Password changed successfully!',
    msgWorking: 'Processing the request...',
    rules: {
      minCharacter: 'Minimum 8 characters',
      hasLyrics: 'It has lyrics',
      hasNumbers: 'It has numbers',
      hasSpecialCharacters: 'It has special characters',
    },
    msnErrorChangePassword: 'Error changing password',
    msnUsernameNotFound: 'Non-existent User',
    msnCurrentPasswordFail: 'The current password is not correct',

  } as I18n,
  es: {
    pageTitle: 'Primero, necesita cambiar su contraseña ...',
    pageSubtitle: 'Para garantizar su seguridad, le solicitamos que cambie su contraseña en el primer inicio de sesión *',
    observation: '* Siempre que lo necesites o quieras, puedes cambiar tu contraseña accediendo a tu perfil, en la información personal.',
    user: 'Usuario',
    passwordHint: 'La contraseña debe contener 8 o más caracteres con una combinación de letras, números y símbolos.',
    oldPassword: 'Introduce tu contraseña actual',
    newPassword: 'Nueva contraseña',
    confirmNewPassword: 'Confirmar nueva contraseña',
    changePasswordButton: 'Cambiar y continuar',
    msgError: 'Error al cambiar la contraseña',
    msgPasswordDoesNotMatch: 'Las contraseñas ingresadas no coinciden',
    msgErrorNotEqualPasswords: 'La nueva contraseña debe ser diferente de la contraseña actual',
    msgSuccess: '¡La contraseña se cambió correctamente!',
    msgWorking: 'Procesando la solicitud ...',
    rules: {
      minCharacter: 'Mínimo 8 caracteres',
      hasLyrics: 'Tiene letras',
      hasNumbers: 'Tiene números',
      hasSpecialCharacters: 'Tiene caracteres especiales',
    },
    msnErrorChangePassword: 'Error al cambiar la contraseña',
    msnUsernameNotFound: 'Usuario inexistente',
    msnCurrentPasswordFail: 'La contraseña actual no es correcta',
  } as I18n,
};

export default locale;
