
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import Axios from 'axios';

import Loading from '@/common/components/loading/Loading.vue';
import moduleAuth from './store/auth/moduleAuth';
import NotificationMixin from './mixins/NotificationMixin';

export default mixins(NotificationMixin).extend({
  name: 'App',
  components: { Loading },
  data() {
    return {
      controlCLoseOpen: false,
      isCollapse: true,
      loadedApp: false,
    };
  },
  computed: {
    ...mapGetters({
      reloadApp: 'reloadApp',
      renderApp: 'renderApp',
      renderMenu: 'renderMenu',
      userLoggedIn: 'auth/userLoggedIn',
      user: 'auth/user',
      firstAccess: 'auth/firstAccess',
      acceptLanguage: 'auth/acceptLanguage',
    }),
  },
  watch: {
    acceptLanguage(val: string) {
      if (val) {
        this.$moment.locale(val.toLowerCase());
      }
    },
  },
  mounted() {
    if (process.env.VUE_APP_ENVIRONMENT === 'prod') {
      const script = document.createElement('script');
      script.src = 'https://fast.conpass.io/2UleTRrDT39ag.js';
      document.body.appendChild(script);

      document.addEventListener('onconpassload', () => {
        window.Conpass.init({
          name: this.user.name,
          email: this.user.email,
        });
      });
    }

    this.handleLoading(true);

    this.$router.onReady(
      () => this.login(),
      () => this.errorRedirectToAuth(),
    );
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
      handleRenderMenu: 'handleRenderMenu',
    }),

    async devLogin() {
      const form = {
        login: process.env.VUE_APP_AUTH_LOGIN,
        password: process.env.VUE_APP_AUTH_PASSWORD,
        authenticationType: 'CPF',
        system: 'COMPANY',
        origin: 'WEB',
      };
      return Axios.post(
        'https://sandbox.roit.ai/iam-authentication/public/api/v1/authentication/custom-auth',
        form,
      );
    },

    errorRedirectToAuth() {
      (window as any).location = process.env.VUE_APP_AUTH_SIGN_OUT_REDIRECT_ERROR;
    },

    handleErrorAndRedirect() {
      localStorage.removeItem('id');
      localStorage.removeItem('pt');
      this.errorRedirectToAuth();
    },

    async login() {
      this.handleLoading(true);

      const onDev = async () => {
        if (process.env?.VUE_APP_ENVIRONMENT === 'dev') {
          localStorage.removeItem('id');
          localStorage.removeItem('pt');
          try {
            const login = await this.devLogin();
            return login.data.data;
          } catch {
            // empty
          }
        }
        return null;
      };

      const query = { ...this.$route.query };
      const { token, ...queryRest } = query;

      const tempToken = token || await onDev();
      const localToken = localStorage.getItem('pt') as string;

      if (token || Object.keys(queryRest).length > 0) {
        this.$router.replace({ query: queryRest });
      }

      if (tempToken && !localToken) {
        await this.handleLoginFromAuthentication(tempToken);
      } else if (localToken) {
        await this.handleLoginFromLocalToken(localToken);
      } else {
        this.errorRedirectToAuth();
      }
    },

    async handleLoginFromAuthentication(authenticationToken: string): Promise<void> {
      this.handleLoading(true);

      try {
        await this.$store.dispatch(
          'auth/handleLoginFromAuthentication',
          authenticationToken,
        );

        this.comeOnRoute();

        this.handleLoading(false);
      } catch (error) {
        console.log(error);
        this.handleLoading(false);
        this.handleErrorAndRedirect();
      }
    },

    async handleLoginFromLocalToken(authenticationToken: string): Promise<void> {
      this.handleLoading(true);

      try {
        await this.$store.dispatch(
          'auth/handleLoginFromLocalToken',
          authenticationToken,
        );
        this.comeOnRoute();

        this.handleLoading(false);
      } catch (error) {
        console.log(error);
        this.handleLoading(false);
        this.handleErrorAndRedirect();
      }

      this.$store.commit('auth/SET_ACCEPT_LANGUAGE');
    },

    handleFirstRoute(): string {
      const { permissions } = moduleAuth.state;

      let route = 'my-products-page';

      const routes = {
        bankadmin: 'my-products-page',
        bankadminmyproductsmenu: 'my-products-page',
        bankadminmycompaniesmenu: 'my-companies-page',
        bankadminuserandcontactsmenu: 'users-and-contacts',
        bankadmindigitalcertificatesmenu: 'digital-certificates',
        bankadminintegrationsmenu: 'integrations',
        bankadminbillingmenu: 'billing',
        bankadminaccesspassmenu: 'accessPass',
        bankadminautomatictaxinvoicemenu: 'automatic-taxInvoiceInbound',
      };

      const filteredPermissions: string[] = [];

      permissions.roles.filter((res: string) => res.toLowerCase().includes('bankadmin'))
        .findIndex((perm: string) => {
          const permission = perm.split('::')[1].toLowerCase();
          filteredPermissions.push(permission);

          return null;
        });

      const keys = Object.keys(routes);

      const sortedKeys = filteredPermissions.sort((a, b) => (
        keys.indexOf(a) - keys.indexOf(b)
      ));

      sortedKeys.reverse().forEach((key) => {
        const routeFound = (routes[key as keyof object]);
        if (routeFound) {
          route = routeFound;
        }
      });

      return route || 'my-products';
    },

    comeOnRoute() {
      this.loadedApp = true;
      this.$store.commit('auth/SET_FIRST_ACCESS', false);
      this.handleLoading(false);
      this.$router.push({ name: this.handleFirstRoute() });
    },
  },
});
