import BaseError from './BaseError';

// TODO: adicionar internacionalização para os erros
class ParamNotFoundError extends BaseError {
  constructor(name: string, description = 'Param not found.') {
    super(name, description);
  }
}

export default ParamNotFoundError;
