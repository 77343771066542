
import Vue, { PropOptions } from 'vue';

type CNPJError = {
  cnpj: string;
  status: string;
  message: string;
};

export default Vue.extend({
  name: 'DigitalCertificateMessage',

  props: {
    value: {
      type: Array as () => Array<CNPJError>,
      required: true,
    } as PropOptions,

    type: {
      type: String as () => string,
      default: 'error',
      validator: (value: string): boolean => ['success', 'info', 'warn', 'error'].includes(value),
    } as PropOptions,
  },

  mounted() {
    const close: any = document.querySelector('.p-message-close');
    if (close) {
      close.addEventListener('click', this.onClose);
    }
  },

  methods: {
    onClose() {
      this.$emit('input', []);
    },
  },
});
