/* eslint-disable @typescript-eslint/camelcase */
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';
import { localize } from 'vee-validate';

const names = {
};

const dictionary = {
  en: {
    messages: en.messages,
    names,
    fields: {
      integrationSystemOptionsMaxDownloadDocs: {
        numeric: 'Must only contain numbers',
        min_value: (_fieldName: string, rule: any) => `The value must be greater than or equal to ${rule.min}`,
      },
      integrationSystemProtheusAuthMonitorTable: {
        numeric: 'Must only contain numbers',
      },
    },
  },
  es: {
    messages: es.messages,
    names,
    fields: {
      integrationSystemOptionsMaxDownloadDocs: {
        numeric: 'Solo debe contener números',
        min_value: (_fieldName: string, rule: any) => `El valor debe ser mayor o igual a ${rule.min}`,
      },
      integrationSystemProtheusAuthMonitorTable: {
        numeric: 'Solo debe contener números',
      },
    },
  },
  pt_BR: {
    messages: pt_BR.messages,
    names,
    fields: {
      integrationSystemOptionsMaxDownloadDocs: {
        numeric: 'Deve conter apenas números',
        min_value: (_fieldName: string, rule: any) => `O valor deve ser maior ou igual a ${rule.min}`,
      },
      integrationSystemProtheusAuthMonitorTable: {
        numeric: 'Deve conter apenas números',
      },
    },
  },
};

localize(dictionary);
