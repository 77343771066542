export type Option = { value: string; label: string }

export type SapB1Params = {
  auth: {
    username: string;
    userpass: string;
    pathAttachment: string;
  };
  database: {
    type: string;
    server: string;
    name: string;
    username: string;
    userpass: string;
  };
  parameter: {
    sendPurchaseOrderEvery: string;
    launchInvoiceEvery: string;
    sendAccountsChartEvery: string;
    sendSupplierRegistrationEvery: string;
    sendUmbrellaContractEvery: string;
    paymentEvery: string;
    sendTaxCodeEvery: string;
  };
}

export type ProtheusParams = {
  auth: {
    username: string;
    userpass: string;
    monitorTable: string;
  };
  parameter: {
    usePurchaseOrder: 'Y' | 'N';
    orderSharingBetweenBranches: 'Y' | 'N';
    performsAccountingOnDocumentEntry: 'Y' | 'N';
    supplierTaxWithholdings: 'ADJUST' | 'VALIDATE';
  };
}

export type SapEccParams = {
  auth: {
    username: string;
    userpass: string;
  };
}

export type S4HanaParams = {
  auth: {
    username: string;
    userpass: string;
  };
}

export type NetSuiteParams = {
  auth: {
    username: string;
    userpass: string;
  };
}

export type QuestorParams = {
  auth: {
    username: string;
    userpass: string;
  };
}

export type SystemParameter =
  SapB1Params
  | ProtheusParams
  | SapEccParams
  | S4HanaParams
  | NetSuiteParams
  | QuestorParams;

export type CommonParameter = {
  retryAt: string;
  maxDownloadDocs: string;
}

export enum IntegrationSystemPurpose {
  ACCOUNTING_CLASSIFICATION = 'ACCOUNTING_CLASSIFICATION',
  TAX_ANALYSIS = 'TAX_ANALYSIS',
  FINANCIAL_BOT = 'FINANCIAL_BOT',
}

export interface IntegrationSystem {
  id?: string;
  companies: string[];
  system: string;
  version: string;
  purposes: IntegrationSystemPurpose[];
  systemParameter: SystemParameter;
  commonParameter: CommonParameter;
}
