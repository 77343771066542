var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    ref: "companyTable",
    staticClass: "my-companies",
    attrs: {
      "columns-data": _vm.header,
      data: _vm.rowsData,
      loading: _vm.loading,
      expandable: true,
      paginate: _vm.paginate,
      "page-size-prop": 10,
      "sort-data": false,
      "row-length": _vm.totalRowLength,
      "initial-sort": _vm.initialSort,
      "expandable-items": "branchs",
      "expandable-event": _vm.expandable,
      "see-more-itens-label": "Ver mais filiais",
    },
    on: {
      currPage: _vm.updateCurrentPage,
      pageSize: _vm.updatePageSize,
      seeMoreItens: function ($event) {
        return _vm.seeMoreItens($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function ({ column }) {
          return [
            column === "tree"
              ? _c("th", { staticClass: "pl-0 text-center" })
              : _vm._e(),
          ]
        },
      },
      {
        key: "columns",
        fn: function ({ item, column }) {
          return [
            column === "tree" ? _c("td", { staticClass: "hidden" }) : _vm._e(),
            column === "nationalRegister"
              ? _c(
                  "td",
                  { staticClass: "w-150" },
                  [
                    _c("InputMask", {
                      class: [
                        {
                          "text-danger": !_vm.getStatusActive(
                            item.legalSituation
                          ),
                        },
                        "p-inputtext-sm",
                      ],
                      attrs: { mask: "99.999.999/9999-99", readonly: true },
                      model: {
                        value: item.nationalRegister,
                        callback: function ($$v) {
                          _vm.$set(item, "nationalRegister", $$v)
                        },
                        expression: "item.nationalRegister",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            column === "companyName"
              ? _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top",
                        value: {
                          value: item.companyName,
                          disabled:
                            !item.companyName || item.companyName.length < 40,
                        },
                        expression:
                          "{\n        value: item.companyName,\n        disabled: !item.companyName || item.companyName.length < 40\n      }",
                        modifiers: { top: true },
                      },
                    ],
                    staticClass: "w-280",
                  },
                  [_vm._v(" " + _vm._s(item.companyName) + " ")]
                )
              : _vm._e(),
            column === "address.city"
              ? _c("td", { staticClass: "w-150" }, [
                  _vm._v(" " + _vm._s(item.address && item.address.city) + " "),
                ])
              : _vm._e(),
            column === "address.state"
              ? _c("td", [
                  _vm._v(
                    " " + _vm._s(item.address && item.address.state) + " "
                  ),
                ])
              : _vm._e(),
            column === "legalSituation"
              ? _c(
                  "td",
                  [
                    _c("StatusCompanyTable", {
                      attrs: { status: item.legalSituation || "" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            column === "stateRegistration"
              ? _c(
                  "td",
                  { staticClass: "w-130" },
                  [
                    _c("InputText", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###.###"],
                          expression: "['###.###.###.###']",
                        },
                      ],
                      staticClass: "p-inputtext-sm",
                      model: {
                        value: item.stateRegistration,
                        callback: function ($$v) {
                          _vm.$set(item, "stateRegistration", $$v)
                        },
                        expression: "item.stateRegistration",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            column === "municipalRegistration"
              ? _c(
                  "td",
                  { staticClass: "w-185" },
                  [
                    _c("InputText", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["####################"],
                          expression: "['####################']",
                        },
                      ],
                      staticClass: "p-inputtext-sm",
                      model: {
                        value: item.municipalRegistration,
                        callback: function ($$v) {
                          _vm.$set(item, "municipalRegistration", $$v)
                        },
                        expression: "item.municipalRegistration",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            column === "company.taxRegime.type"
              ? _c("td", { staticClass: "w-185" }, [
                  item.type === "MATRIZ"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "" },
                              model: {
                                value: item.company.taxRegime.type,
                                callback: function ($$v) {
                                  _vm.$set(item.company.taxRegime, "type", $$v)
                                },
                                expression: "item.company.taxRegime.type",
                              },
                            },
                            _vm._l(_vm.tributaryRegime, function (regime, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: {
                                  label: regime.name,
                                  value: regime.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            column === "company.taxRegime.assessment"
              ? _c("td", { staticClass: "w-150" }, [
                  item.type === "MATRIZ"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "" },
                              model: {
                                value: item.company.taxRegime.assessment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item.company.taxRegime,
                                    "assessment",
                                    $$v
                                  )
                                },
                                expression: "item.company.taxRegime.assessment",
                              },
                            },
                            _vm._l(_vm.polling, function (poll, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: poll.name, value: poll.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            column === "retreat"
              ? _c("td", { staticClass: "w-160" }, [
                  item.type === "MATRIZ"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "" },
                              model: {
                                value: item.company.taxGuide,
                                callback: function ($$v) {
                                  _vm.$set(item.company, "taxGuide", $$v)
                                },
                                expression: "item.company.taxGuide",
                              },
                            },
                            _vm._l(_vm.retreat, function (ret, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: ret.name, value: ret.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "expandable",
        fn: function ({ item, classLine, index }) {
          return _vm._l(_vm.getTypedBranchs(item), function (value, idx) {
            return _c(
              "tr",
              {
                key: `row-expandable-${index}-${idx}`,
                class: [classLine, "r-custom-table__row"],
              },
              [
                _c("td", { staticClass: "hidden" }),
                _c("td", { staticClass: "w-0" }),
                _c(
                  "td",
                  [
                    _c("InputMask", {
                      class: [
                        {
                          "text-danger":
                            _vm.getStatusActive(value.legalSituation) !== true,
                        },
                        "p-inputtext-sm",
                      ],
                      attrs: { mask: "99.999.999/9999-99", readonly: true },
                      model: {
                        value: value.nationalRegister,
                        callback: function ($$v) {
                          _vm.$set(value, "nationalRegister", $$v)
                        },
                        expression: "value.nationalRegister",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", [_vm._v(" " + _vm._s(value.companyName) + " ")]),
                _c("td", [_vm._v(" " + _vm._s(value.address.city) + " ")]),
                _c("td", [_vm._v(" " + _vm._s(value.address.state) + " ")]),
                _c(
                  "td",
                  [
                    _c("StatusCompanyTable", {
                      attrs: { status: value.legalSituation },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("InputText", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###.###"],
                          expression: "['###.###.###.###']",
                        },
                      ],
                      staticClass: "p-inputtext-sm",
                      model: {
                        value: value.stateRegistration,
                        callback: function ($$v) {
                          _vm.$set(value, "stateRegistration", $$v)
                        },
                        expression: "value.stateRegistration",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("InputText", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["####################"],
                          expression: "['####################']",
                        },
                      ],
                      staticClass: "p-inputtext-sm",
                      model: {
                        value: value.municipalRegistration,
                        callback: function ($$v) {
                          _vm.$set(value, "municipalRegistration", $$v)
                        },
                        expression: "value.municipalRegistration",
                      },
                    }),
                  ],
                  1
                ),
                _c("td"),
                _c("td"),
                _c("td"),
                _c("td", [_c("div", { staticStyle: { width: "100px" } })]),
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t("update"),
                          expression: "$t('update')",
                          modifiers: { top: true },
                        },
                      ],
                      ref: "editCompany",
                      refInFor: true,
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.editCompany(value, index)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "icon-r-circle-accept color__secondary",
                      }),
                    ]
                  ),
                ]),
              ]
            )
          })
        },
      },
      {
        key: "actions",
        fn: function ({ item, index }) {
          return [
            _c("td", [_c("div", { staticStyle: { width: "50px" } })]),
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: _vm.$t("update"),
                      expression: "$t('update')",
                      modifiers: { top: true },
                    },
                  ],
                  ref: "editCompany",
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.editCompany(item, index)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "icon-r-circle-accept color__secondary",
                  }),
                ]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }