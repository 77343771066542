import axios from '@/common/http';

class IamService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_IAM_URL}/api/v1`;

  public async getUserInfo() {
    return this.service.get(`${this.baseUrl}/users`);
  }
}

export default IamService;
