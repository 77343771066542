
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { loadLanguageAsync } from '@/i18n';

export default Vue.extend({
  name: 'SupportInternationalization',
  data: () => ({
    selectedFlag: 'br',
    languages: [
      { flag: 'br', language: 'pt-br', title: '' },
      { flag: 'us', language: 'en', title: '' },
      { flag: 'es', language: 'es', title: '' },
    ],
  }),
  methods: {
    ...mapMutations({
      handleReloadApp: 'handleReloadApp',
    }),
    changeLocale(entry: any) {
      loadLanguageAsync(entry.language).then(() => {
        this.selectedFlag = entry.flag;
        this.handleReloadApp();
      });
    },
  },
});
