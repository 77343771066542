
import { ProtheusParams } from '@/resources/integrations/types';
import Vue from 'vue';

type Options = {value: string; label: string}
type Parameter = ProtheusParams['parameter']

export default Vue.extend({
  name: 'ProtheusParametersSectionForm',
  props: {
    systemParameters: {
      type: Object as () => ProtheusParams,
      required: true,
    },
  },
  data() {
    const {
      usePurchaseOrder = '',
      orderSharingBetweenBranches = '',
      performsAccountingOnDocumentEntry = '',
      supplierTaxWithholdings = '',
    } = this.systemParameters.parameter || {};

    return {
      parameter: {
        usePurchaseOrder,
        orderSharingBetweenBranches,
        performsAccountingOnDocumentEntry,
        supplierTaxWithholdings,
      } as Parameter,
    };
  },
  computed: {
    yesNoOptions(): Options[] {
      return [{
        value: 'Y',
        label: this.$t('yes') as string,
      },
      {
        value: 'N',
        label: this.$t('no') as string,
      }];
    },
    orderSharingBetweenBranchesOptions(): Options[] {
      return [{
        value: 'Y',
        label: this.$t('integrations.forms.protheus.parameters.orderSharingBetweenBranchesOptions.shared') as string,
      },
      {
        value: 'N',
        label: this.$t('integrations.forms.protheus.parameters.orderSharingBetweenBranchesOptions.exclusive') as string,
      }];
    },
    supplierTaxWithholdingsOptions(): Options[] {
      return [{
        value: 'ADJUST',
        label: this.$t('integrations.forms.protheus.parameters.supplierTaxWithholdingsOptions.adjust') as string,
      },
      {
        value: 'VALIDATE',
        label: this.$t('integrations.forms.protheus.parameters.supplierTaxWithholdingsOptions.validate') as string,
      }];
    },
  },
  watch: {
    parameter: {
      deep: true,
      handler(parameter: Parameter) {
        this.$emit('update:systemParameters', { ...this.systemParameters, parameter, __type: 'ProtheusParams' });
      },
    },
  },
});
