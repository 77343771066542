var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.loadingTrigger
    ? _c(
        "div",
        { attrs: { id: "fullscreenLoader" } },
        [_c("ProgressSpinner", { attrs: { "stroke-width": "2" } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }