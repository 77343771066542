
import Vue from 'vue';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import { QuestorParams } from '@/resources/integrations/types';

type Auth = QuestorParams['auth']

export default Vue.extend({
  name: 'QuestorAuthSectionForm',
  components: {
    InputPassword,
  },
  props: {
    systemParameters: {
      type: Object as () => QuestorParams,
      required: true,
    },
  },
  data() {
    const {
      username = '',
      userpass,
    } = this.systemParameters.auth || {};

    return {
      auth: {
        username,
        userpass,
      } as Auth,
    };
  },
  watch: {
    auth: {
      deep: true,
      handler(auth: Auth) {
        this.$emit('update:systemParameters', { ...this.systemParameters, auth, __type: 'QuestorParams' });
      },
    },

  },
});
