var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.showCompanyGroupNotFound
        ? _c("div", [_c("CompanyGroupNotFound")], 1)
        : _vm._l(
            _vm.search ? _vm.searchCompanyGroups : _vm.companyGroups,
            function (companyGroup, index) {
              return _c(
                "CompanyGroupAccordion",
                {
                  key: index,
                  attrs: {
                    "company-group": companyGroup,
                    "click-add": () => _vm.btnRegisterCompany(companyGroup),
                    "click-update": () =>
                      _vm.btnUpdateCompanyGroup(companyGroup),
                  },
                  on: {
                    "establishments:get": () =>
                      _vm.getEstablishments(companyGroup),
                  },
                },
                [
                  _c("EditableCompanyTable", {
                    ref: "editableCompanyTable",
                    refInFor: true,
                    attrs: {
                      rows: companyGroup.establishments,
                      "total-establisments": _vm.totalEstablisments,
                      loading: _vm.loading[index],
                    },
                    on: {
                      "update:loading": function ($event) {
                        return _vm.$set(_vm.loading, index, $event)
                      },
                      "company:update": function ($event) {
                        return _vm.establishmentUpdate($event)
                      },
                      paginateEstablishments: ($event) =>
                        _vm.paginateEstablishments($event, companyGroup),
                      updatePageSize: ($event) =>
                        _vm.updatePageSize($event, companyGroup),
                    },
                  }),
                ],
                1
              )
            }
          ),
      _vm.companyGroups.length > 0 && !_vm.search
        ? _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
            _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }),
            _c("span", { attrs: { slot: "no-results" }, slot: "no-results" }),
          ])
        : _vm._e(),
      _c("CompanyGroupModal", {
        attrs: { control: _vm.showModal, "edit-data": _vm.modalData },
        on: {
          "update:control": function ($event) {
            _vm.showModal = $event
          },
          companyGroupUpdate: function ($event) {
            return _vm.handleCompanyGroupUpdate($event)
          },
          companyRegistrations: function ($event) {
            return _vm.handleCompanyRegistrations($event)
          },
        },
      }),
      _c("ParentCompanyModalNotFound", {
        attrs: {
          "message-errors": _vm.messageErrors,
          control: _vm.showParentCompanyModalNotFound,
        },
        on: {
          "update:control": function ($event) {
            _vm.showParentCompanyModalNotFound = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }