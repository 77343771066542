
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { loadLanguageAsync } from '@/i18n';
import { I18nFlagType } from '@/types';

export default Vue.extend({
  name: 'SupportInternationalization',
  props: {
    contacts: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedFlag: 'br',
    languages: [
      { flag: 'br', language: 'pt-br', title: '' } as I18nFlagType,
      { flag: 'us', language: 'en', title: '' } as I18nFlagType,
      { flag: 'es', language: 'es', title: '' } as I18nFlagType,
    ],
  }),
  methods: {
    ...mapMutations({
      handleReloadApp: 'handleReloadApp',
    }),
    changeLocale(entry: I18nFlagType) {
      loadLanguageAsync(entry.language).then(() => {
        this.selectedFlag = entry.flag;
        this.handleReloadApp();
      });
    },
  },
});
