import axios, { CancelTokenSource } from '@/common/http';
import {
  EstablishmentsGrouped,
  MunicipalRegistrationChanged,
  PaginationSerializer,
  SelectOption,
  UpdateCertificatesInBatchPayload,
} from '@/types';
import { Establishment } from '@roit-intern/roit-company-interfaces';

interface Header {
  title: string;
  value: string;
}

class EstablishmentService {
  private service: typeof axios = axios;

  private cancelTokenSource?: CancelTokenSource;

  private baseUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1`;

  public findSearch(search = '', perPage?: number, page?: number) {
    let { baseUrl } = this;
    baseUrl = baseUrl.concat('/establishment');

    if (perPage && page !== undefined && page >= 0 && !search) baseUrl = baseUrl.concat(`?page=${page}&perPage=${perPage}`);

    if (search) {
      baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
    }

    return this.service.get(baseUrl);
  }

  public async selectOptions(
    perPage?: number,
    page?: number,
  ): Promise<Array<Establishment & SelectOption>> {
    const { data: response } = page !== undefined && page >= 0 && perPage ? await this.findSearch('', perPage, page) : await this.findSearch();

    return response.data
      .filter((item: Establishment) => item.companyName)
      .map((comp: Establishment) => {
        const partialCnpj = comp.nationalRegister
          .substring(comp.nationalRegister.length - 6)
          .replace(/(\d{4})(\d{2})/g, '$1-$2');

        return {
          ...comp,
          name: `${comp.companyName} (${partialCnpj})`,
          optionValue: comp.id,
          optionLabel: `${comp.companyName} (${partialCnpj})`,
        };
      });
  }

  private getHeader(header?: Header) {
    this.cancelTokenSource = this.cancelToken();

    if (header) {
      return {
        headers: {
          cancelToken: this.cancelTokenSource.token,
          header,
        },
      };
    }

    return {
      cancelToken: this.cancelTokenSource.token,
    };
  }

  private cancelToken(): CancelTokenSource {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
    return this.service.CancelToken.source();
  }

  public findEstablishmentsGrouped(
    search?: string,
    page?: number,
    perPage?: number,
    serializer?: string,
  ) {
    let baseUrl = `${this.baseUrl}/establishment/find-grouped`;

    if (serializer) {
      baseUrl = baseUrl.concat(`?serializer=${serializer}`);
    }

    if (perPage && !search) {
      baseUrl = baseUrl.concat(`${serializer ? '&' : '?'}page=${page}&perPage=${perPage}`);
    }

    if (search) {
      baseUrl = baseUrl.concat(`${serializer ? '&' : '?'}search=${encodeURIComponent(search)}`);
    }

    return this.service.get(baseUrl, this.getHeader());
  }

  async updateEstablishment(establishment: Partial<Establishment>) {
    const baseUrl = `${this.baseUrl}/establishment/update-establishment`;

    const payload = {
      establishmentId: establishment.nationalRegister,
      stateRegistration: establishment.stateRegistration,
      municipalRegistration: establishment.municipalRegistration,
    };

    return this.service.patch(
      baseUrl,
      payload,
      this.getHeader(),
    );
  }

  public async selectEstabsGrouped(): Promise<EstablishmentsGrouped[] & SelectOption[]> {
    const { data: response } = await this.findEstablishmentsGrouped();

    return response.data
      .filter((item: EstablishmentsGrouped) => item.groupName)
      .map((comp: EstablishmentsGrouped) => ({
        ...comp,
        optionValue: comp.groupId,
        optionLabel: comp.groupName,
      }));
  }

  public async selectOptionsByMaster(
    perPage?: number,
    page?: number,
  ): Promise<Establishment[] & SelectOption[]> {
    let { baseUrl } = this;
    baseUrl = baseUrl.concat(`/establishment/by-master-id?page=${page}&perPage=${perPage}`);

    const { data: response } = await this.service.get(baseUrl);

    return response.data
      .filter((item: Establishment) => item.companyName)
      .map((comp: Establishment) => {
        const partialCnpj = comp.nationalRegister
          .substring(comp.nationalRegister.length - 6)
          .replace(/(\d{4})(\d{2})/g, '$1-$2');

        return {
          ...comp,
          name: `${comp.companyName} (${partialCnpj})`,
          optionLabel: `${comp.companyName} (${partialCnpj})`,
        };
      });
  }

  async updateCertificatesInBatch(
    payload: UpdateCertificatesInBatchPayload[],
  ) {
    const baseUrl = `${this.baseUrl}/establishment/certificates-batch`;

    return this.service.patch(
      baseUrl,
      payload,
      this.getHeader(),
    );
  }

  async updateMunicipalRegistrationInBatch(establishments: MunicipalRegistrationChanged[]) {
    const baseUrl = `${this.baseUrl}/establishment/municipal-registration-batch`;

    return this.service.put(
      baseUrl,
      { establishments },
      this.getHeader(),
    );
  }

  async findByCompanyId(
    companyId: string, metadata: boolean, paginationSerializer?: PaginationSerializer,
  ): Promise<Establishment[]> {
    const url = new URL(`${this.baseUrl}/establishment/company-id/${companyId}`);

    if (paginationSerializer) {
      const { perPage, page, serializer } = paginationSerializer;
      if (perPage !== undefined && page !== undefined) {
        url.searchParams.append('page', String(page));
        url.searchParams.append('perPage', String(perPage));
      }
      if (serializer) {
        url.searchParams.append('serializer', serializer);
      }
    }

    if (metadata) {
      url.searchParams.append('metadata', String(metadata));
    }

    const result = await this.service.get(
      url.toString(),
      this.getHeader(),
    );

    return result.data.data;
  }
}

export default new EstablishmentService();
